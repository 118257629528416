import React, {useContext, Fragment} from 'react';

import PageTitle from '../widgets/pageTitle';
import {useWindowSize} from '../../helpers/helper';
import DisplayList from '../widgets/displayList';
import Status from '../widgets/status';
import ImageHoverZoom from '../widgets/imageHoverZoom';
import ClientContext from '../../helpers/ClientContext';
import useMissionClock from '../../hooks/useMissionClock';
import useListItems from '../../hooks/useListItems';

const StatusBoard = () => {
    const screenSize = useWindowSize();
    const {widgetState} = useContext(ClientContext);
    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);
    const assets = useListItems('assets');

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`
            }}>
                <div style={{position: 'absolute', left: '1200px', top: '80px'}}>
                    <PageTitle
                        data="Map"
                        fontFamily="Courier New"
                        fontSize={25}
                        color="white"
                    />
                </div>
                <div style={{
                    borderRight: '3px solid white',
                    height: '825px',
                    position: 'absolute',
                    left: '520px',
                    top: '150px',
                }}>
                    <div style={{
                        position: 'absolute',
                        left: '-475px',
                        top: '30px',
                        fontFamily: 'Courier New',
                        fontWeight: 'bold',
                        color: 'white',
                        fontSize: '1.3rem',
                    }}>
                        <table className="timeTable">
                            <tbody>
                            <tr>
                                <td>Zulu</td>
                                <td>{missionClock.utcString}</td>
                            </tr>
                            <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                                <td>T-Minus</td>
                                <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                            </tr>
                            <tr>
                                <td>LiftOff</td>
                                <td style={{
                                    backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                    textAlign: 'right'
                                }}>
                                    {missionClock.liftoffString}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    {/*Assets Div*/}
                    <div style={{
                        position: 'absolute',
                        left: '-470px',
                        top: '215px'
                    }}>
                        <p style={{
                            color: 'white',
                            textAlign: 'left',
                            fontFamily: 'Courier New',
                            fontSize: '25px'
                        }}
                        >
                            <b>Status Board:</b>
                        </p>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4,max-content)',
                            gap: '0 2rem',
                            fontSize: '1.3rem'
                        }}>
                            {assets.map((item, index) => (
                                <Fragment key={index}>
                                    <div style={{
                                        color: 'white',
                                        fontFamily: 'Courier New'
                                    }}
                                    >
                                        <b>{item.text}</b>
                                    </div>
                                    <div style={{paddingRight: '30px'}}>
                                        <Status radius={13} bounds={{Infinity: item.color}}/>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    {/*Conditions Div*/}
                    <div style={{
                        position: 'absolute',
                        left: '-470px',
                        top: '470px',
                        paddingRight: '1rem',
                    }}>
                        <p style={{
                            color: 'white',
                            textAlign: 'left',
                            fontFamily: 'Courier New',
                            fontSize: '25px'
                        }}>
                            <b>Status:</b>
                        </p>
                        <div style={{fontSize: '1.3rem'}}>
                            <DisplayList
                                listName="conditions"
                                textColor="white"
                                paddingLeft="0"
                                paddingBottom="0.7rem"
                            />
                        </div>
                    </div>
                </div>
                <hr
                    style={{
                        backgroundColor: 'white',
                        width: '480px',
                        height: '2px',
                        position: 'absolute',
                        left: '40px',
                        top: '325px'
                    }}
                />
                <hr
                    style={{
                        backgroundColor: 'white',
                        width: '480px',
                        height: '2px',
                        position: 'absolute',
                        left: '40px',
                        top: '590px'
                    }}
                />
                {/*Image div*/}
                <div style={{
                    left: '700px',
                    top: '200px',
                    position: 'absolute'
                }}>
                    <ImageHoverZoom
                        graphData={widgetState.location.data}
                        //[maxLat, minLat, maxLon, minLon]
                        // maxMinData={['.707044', '-.49952', '.3128', '-.1262']}
                        maxMinData={['100', '0', '100', '0']}
                        imgHeight={300 * 2}
                        imgWidth={500 * 2}
                        zoom //If zoom, you get lens and smaller zoomed in screen
                        xPos={widgetState.locations.x}
                        yPos={widgetState.locations.y}
                        scaleX={screenSize.width / 2048}
                        scaleY={(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)}
                        lines={[
                            {color: 'red', xKey: 'lon', yKey: 'lat'},
                            {color: 'blue', xKey: 'lon2', yKey: 'lat2'},
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default StatusBoard;
