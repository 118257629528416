import React, {useEffect} from 'react';
import Notif from './notification';

type Props = {
    notifications: {
        message: string,
        color: string,
        key: number,
        timeout?: number,
    }[];
    closeNotification: () => void;
}

const Toast = ({notifications, closeNotification}: Props) => {
    useEffect(() => {
        if(!notifications.length){
            return;
        }

        setTimeout(() => {
            const toasts = document.querySelectorAll('.toast');
            toasts[toasts.length - 1].classList.remove('noHeight');
        }, 25); // can't figure out how to do it without timeout


    }, [notifications]);

    if(!notifications.length){
        return <></>;
    }


    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'fit-content(20em)',
            position: 'fixed',
            right: '1em',
            bottom: '1em',
            gap: '0.3em',
            zIndex: 101, // modals have z-index of 101 bc mui-datatables have z-index of 100
        }}>
            {
                notifications.map(({message, color, key, timeout}) => {
                    return <Notif key={key} message={message} timeoutLen={timeout} closeNotification={closeNotification}
                                  uniqueKey={key} color={color}/>;
                })
            }
        </div>
    );
};

export default Toast;
