import {ReactNode} from "react";


const ClassificationBanner = (props: { children: ReactNode }) => {

    const classification: String = "Unclassified";

    return (
        <div className={'classification-banner-with-content'}>
            <div className={'classification-banner'}>{classification}</div>
            <div className="page-content-wrapper">{props.children}</div>
        </div>
    )
}

export default ClassificationBanner;
