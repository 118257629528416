import React, {useEffect, useState, useContext} from 'react';
import Select, {Options, StylesConfig} from 'react-select';
import axios from 'axios';
import useListItems from '../../hooks/useListItems';
import ClientContext from '../../helpers/ClientContext';
import {ConfigurationType, RunType, UserType, Option, RunUserType} from '../../helpers/types';
import MUIDataTable, {MUIDataTableColumn, MUIDataTableMeta} from 'mui-datatables';

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

type RunTypes = {
    [index: string]: RunType
}

type ConfigurationTypes = {
    [index: string]: ConfigurationType
}

const Runs = () => {
    const {addNotification} = useContext(ClientContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
    const [curConfiguration, setCurConfiguration] = useState('');
    const [savedRuns, setSavedRuns] = useState<RunTypes>({});
    const [options, setOptions] = useState<Option[]>([]);
    const [savedConfigurations, setSavedConfigurations] = useState<ConfigurationTypes>({});
    const uncheckedUsers = useListItems('users');
    const [users, setUsers] = useState<UserType[]>([]);
    const uncheckedRuns = useListItems('runs');
    const [runs, setRuns] = useState<RunType[]>([]);
    const uncheckedConfigurations = useListItems('configurations');
    const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);
    const adminRunId = '60dc873e24bfd905989c690d';

    useEffect(() => {
        if(!Array.isArray(uncheckedUsers)){
            return;
        }
        setUsers(uncheckedUsers);
    }, [uncheckedUsers]);

    useEffect(() => {
        if(!Array.isArray(uncheckedRuns)){
            return;
        }
        setRuns(uncheckedRuns);
    }, [uncheckedRuns]);

    useEffect(() => {
        if(!Array.isArray(uncheckedConfigurations)){
            return;
        }
        setConfigurations(uncheckedConfigurations);
    }, [uncheckedConfigurations]);

    type IsMulti = true;
    const customStyles: StylesConfig<Option, IsMulti> = {
        option: (provided) => ({
            ...provided,
            borderBottom: '1px dotted black',
        })
    };

    /**
     *
     * @param event
     */
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;

        const nameInput = form.querySelector('#name') as HTMLInputElement;
        const dateInput = form.querySelector('#date') as HTMLInputElement;
        const run = {
            name: nameInput.value,
            date: dateInput.value,
            configurationID: curConfiguration,
            users: selectedUsers.map(option => option.value),
        };

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/runs/add`, run)
            .then(res => {
                if(res.data.error){
                    addNotification({message: res.data.message, color: 'red'});
                } else{
                    form.reset();
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                addNotification({message: error.message, color: 'red'});
            });
    };

    const openCreateRunForm = () => {
        setIsModalOpen(true);
        setSelectedUsers([]);
    };

    /**
     *
     * @param selectedOptions
     */
    const handleChange = (selectedOptions: Options<Option>) => {
        if(selectedOptions === null || !Array.isArray(selectedOptions)){
            return;
        }

        setSelectedUsers(selectedOptions);
    };

    /**
     *
     * @param run
     */
    const confirmDeleteRun = (run: RunType) => {
        const runId = run.id;

        if(window.confirm('Are you sure you want to remove the Run?')){
            axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/runs/delete`, {runId})
                .then(res => {
                    if(res.data.error){
                        addNotification({message: res.data.message, color: 'red'});
                    }
                })
                .catch(error => {
                    addNotification({message: error.message, color: 'red'});
                });
        }
    };

    /**
     *
     * @param run
     */
    const goToRun = (run: RunType) => {
        if(urlStart === 'http://localhost'){
            window.location.href = `${urlStart}:3000/${run.id}/home`;
        } else{
            window.location.href = `${urlStart}/${run.id}/home`;
        }
    };

    /**
     *
     * @param run
     */
    const copyUrlButton = (run: RunType) => {
        let copyUrl = '';
        if(urlStart === 'http://localhost'){
            copyUrl = `${urlStart}:3000/${run.id}/home`;
        } else{
            copyUrl = `${urlStart}/${run.id}/home`;
        }

        navigator.clipboard.writeText(copyUrl)
            .then(() => addNotification({message: 'Link Copied', color: 'green'}));
    };

    const columnsDef: MUIDataTableColumn[] = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
            },
        },
        {
            name: 'date',
            label: 'Date',
            options: {
                filter: false,
            },
        },
        {
            name: 'configurationID',
            label: 'Configuration',
            options: {
                customBodyRender: runConfigurationID => {
                    const config = Object.values(savedConfigurations).find((configuration: ConfigurationType) => {
                        return configuration.id === runConfigurationID;
                    });
                    if(!config){
                        return '';
                    }
                    return config.name;
                }
            },
        },
        {
            name: 'users',
            label: 'Users',
            options: {
                customBodyRender: runUsers => {
                    return runUsers.map((user: RunUserType) => {
                        const filteredUser = users.filter(curUser => curUser.id === user.userID);
                        if(filteredUser.length === 1){
                            return filteredUser[0].name;
                        }
                        return 'error';

                    }).join(', ');
                }
            },
        },
        {
            name: 'action',
            label: 'Action',
            options: {
                download: false,
                filter: false,
                customBodyRender: (value: any, tableData: MUIDataTableMeta) => {
                    const run = Object.values(savedRuns).find(curRun => curRun.name === tableData.rowData[0]);
                    if(!run){
                        return <div>Error</div>
                    }

                    return (
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    window.open(`${urlStart}${isDevEnvironment ? ':3000' : ''}/admin/runsNext/${run.id}`);
                                }}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Edit
                            </button>
                            <button
                                type="submit"
                                onClick={() => confirmDeleteRun(run)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    window.open(`${urlStart}${isDevEnvironment ? ':3000' : ''}/admin/reports/${run.id}`);
                                }}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Reports
                            </button>
                            <button
                                type="button"
                                onClick={() => goToRun(run)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Go To
                            </button>
                            <button
                                type="button"
                                onClick={() => copyUrlButton(run)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Copy Link
                            </button>
                        </div>
                    );
                }
            }
        }
    ];

    useEffect(() => {
        setOptions(
            users.map(user => ({
                label: user.name,
                value: user.id
            })));
    }, [users]);

    useEffect(() => {
        const newRuns: RunTypes = {};
        runs.filter(run => isDevEnvironment || run.id !== adminRunId).forEach(run => {
            newRuns[run.id] = run;
        });
        setSavedRuns(newRuns);

    }, [runs]);

    useEffect(() => {
        const newConfigurations: ConfigurationTypes = {};
        configurations.forEach(configuration => {
            newConfigurations[configuration.id] = configuration;
        });
        setSavedConfigurations(newConfigurations);


    }, [configurations]);

    return (
        <>
            <div className="grayBody"/>
            <div id="runs">
                {
                    isModalOpen &&
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 101, //mui-datatables header has a z-index of 100
                    }}
                    >
                        <form onSubmit={handleSubmit} style={{
                            backgroundColor: 'white',
                            width: '800px',
                            borderRadius: '0.5em',
                            padding: '1em',
                            marginBottom: '20em'
                        }}
                        >
                            <h3 style={{textAlign: 'center'}}>Create Run</h3>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: '0.3em 0.5em',
                                alignItems: 'center',
                            }}
                            >
                                <label htmlFor="name">Run Name: </label>
                                <input
                                    type="text"
                                    id="name"
                                    required
                                    className="form-control"
                                    placeholder="Run Name"
                                />
                                <label htmlFor="date">Date: </label>
                                <input
                                    type="date"
                                    id="date"
                                    required
                                    className="form-control"
                                    placeholder="Date"
                                />
                                <label htmlFor="configuration">Configuration</label>
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        setCurConfiguration(e.currentTarget.value);
                                    }}
                                    name="configurationId"
                                >
                                    <option selected disabled hidden>
                                        select a configuration
                                    </option>
                                    {
                                        Object.values(savedConfigurations).map((configuration, index) =>
                                            <option
                                                key={index}
                                                value={configuration.id}
                                            >
                                                {configuration.name}
                                            </option>
                                        )
                                    }
                                </select>
                                <label htmlFor="users">Users</label>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                        <Select
                                            styles={customStyles}
                                            name="filters"
                                            placeholder="Users"
                                            value={selectedUsers}
                                            options={options}
                                            onChange={handleChange}
                                            isMulti
                                        />
                                    </div>
                                    <button type="button" style={{borderRadius: '.5rem'}}
                                            onClick={() => handleChange(options)}
                                    >
                                        All
                                    </button>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                <input type="submit" value="Create Run" className="btn btn-primary"/>
                                <button onClick={() => setIsModalOpen(false)} type="button"
                                        style={{marginLeft: '0.3em', borderRadius: '0.5em'}}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                }
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1em',
                    color: 'black',
                    marginTop: '2em'
                }}
                >
                    <div className="table-page-container">
                        <h3 style={{color: 'black', textAlign: 'center'}}>Created Runs</h3>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <button type="button"
                                    onClick={openCreateRunForm}
                                    style={{
                                        borderRadius: '0.5em',
                                        backgroundColor: 'blue',
                                        borderColor: 'white',
                                        color: 'white'
                                    }}
                            >
                                Create Run
                            </button>
                        </div>
                        <MUIDataTable columns={columnsDef}
                                      data={Object.values(savedRuns)}
                                      title={'Created Runs'} options={{
                            selectableRows: 'none',
                            print: false,
                            downloadOptions: {filterOptions: {useDisplayedRowsOnly: true}, filename: 'ITAC Runs.csv'},
                        }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Runs;
