import React, {useContext, useLayoutEffect} from 'react';
import {Link, useRouteMatch, matchPath, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {trainingLinks, adminLinks} from './menuItems';
import itacLogo from '../../images/ITAC_logo.png';
import '../../App.css';
import ClientContext from '../../helpers/ClientContext';
import {roles} from '../../enums';
import {RunUserType, UserType, PageKeys} from '../../helpers/types';

type Props = {
    signOut: () => void;
    users: RunUserType[];
}

const Navbar = ({signOut, users}: Props) => {
    const location = useLocation();
    const {authedUser, run} = useContext(ClientContext);

    const getSignOutStyle = () => {
        if(authedUser.role !== roles.ADMIN || (authedUser.role === roles.ADMIN && window.location.pathname.includes('/admin'))){
            return {marginLeft: 'auto', marginRight: '1em'};
        }

        return {};
    };

    const isAllowed = (user: UserType, usersObj: RunUserType[], page: PageKeys) => {
        if(!usersObj){
            return false;
        }

        const found = usersObj.find(individuals => user.id === individuals.userID);
        if(!found){
            return false;
        }

        return found.permissionPages.includes(page);
    };

    type RouteParams = {
        id: string;
    }

    const path = matchPath<RouteParams>(window.location.pathname, {path: '/:id/*'});

    useLayoutEffect(() => {
        const navItems : NodeListOf<HTMLAnchorElement> | undefined | null = document.querySelector('.nav-items')?.querySelectorAll('a');
        if(navItems){
            //remove styling from previous page
            navItems.forEach(a => {
                a.style.backgroundColor = '';
                a.style.color = '';
                a.style.borderRadius = '';
            })
        }

        const navItem: HTMLAnchorElement | null = document.querySelector(`a[href="${window.location.pathname}"]`);
        if(navItem){
            navItem.style.backgroundColor = 'white';
            navItem.style.color = '#282929';
            navItem.style.borderRadius = '0.5rem';
        }
    }, [location]);

    return (
        <nav className="nav-items">
            <div style={{height: '100%', float: 'left'}}>
                <img src={itacLogo} className="nav-image" alt="TAC Logo"/>
            </div>
            <ul className="nav-menu">
                {(useRouteMatch('/admin/')) ?
                    adminLinks.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}>
                                    {item.title}
                                </Link>
                            </li>
                        );
                    })
                    :
                    path &&
                    trainingLinks.sort((a, b) => {
                        let aIndex = run.pageOrder.indexOf(a.page);
                        let bIndex = run.pageOrder.indexOf(b.page);

                        if(aIndex === -1){
                            aIndex = trainingLinks.length; // if new page, put at end
                        }
                        if(bIndex === -1){
                            bIndex = trainingLinks.length; // if new page, put at end
                        }

                        return aIndex - bIndex;
                    }).map((item, index) => {
                        return (
                            isAllowed(authedUser, users, item.page) &&
                            <li key={index}>
                                <Link className={item.cName} to={`/${path.params.id}${item.url}`}>
                                    {item.title}
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
            {
                path &&
                <Link style={{marginLeft: 'auto', marginRight: '1em'}} to={{ pathname: "https://moodle.canvas-inc.com" }} target="_blank">Module 1: LMS</Link>
            }
            {
                (path && authedUser.role === roles.ADMIN && !window.location.pathname.includes('/admin')) &&
                <a href="/admin" style={{marginRight: '1em'}}>
                    <button type="button" style={{borderRadius: '0.5rem', whiteSpace: 'nowrap'}}>
                        Admin
                    </button>
                </a>
            }
            {
                (path || window.location.pathname.includes('/admin')) &&
                <button data-tooltip={authedUser.name} type="button" style={{...getSignOutStyle(), marginRight: '1em', borderRadius: '0.5rem'}}
                        onClick={signOut}>
                    Sign out
                </button>
            }
        </nav>
    );
};

Navbar.propTypes = {
    signOut: PropTypes.func.isRequired,
};

export default Navbar;
