import React, {useContext} from 'react';

import {
    createGrid,
    getEndOfArray,
    useWindowSize,
    getFlightPathProps,
    getFlightPathTimeProps,
} from '../../helpers/helper';
import {
    conductorFramelock,
    conductorTable1,
    conductorTable2,
    conductorTable3,
    importantParamsTbl1,
    importantParamsTbl2,
    importantParamsTbl3,
    squibTbl
} from '../../helpers/tableConfigs';
import Horizon from '../widgets/horizon';
import Table from '../widgets/table';
import LineChartRechart from '../widgets/lineChartRechart';
import DisplayList from '../widgets/displayList';
import ImageHoverZoom from '../widgets/imageHoverZoom';
import ClientContext from '../../helpers/ClientContext';
import useMissionClock from '../../hooks/useMissionClock';
import ZoomedImage from '../widgets/zoomedImage';

const gridArray = createGrid(100);

const TestConductor = () => {
    const screenSize = useWindowSize();
    const {configuration, widgetState} = useContext(ClientContext);
    const missionClock = useMissionClock(widgetState.location.data[0].time, widgetState.utcTime, widgetState.isHolding);

    function getToFixed(number){
        if(number){
            return Number(number).toFixed(2);
        }
        return '';
    }

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}
            >
                <div style={{
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                    fontFamily: 'Courier New',
                    fontSize: '1.3rem',
                    fontWeight: 'bold',
                    color: 'white',
                    border: '1px solid white',
                }}
                >
                    <table className="utcTable" style={{width: '320px'}}>
                        <tbody>
                        <tr>
                            <td>Zulu</td>
                            <td style={{textAlign: 'right'}}>{missionClock.utcString}</td>
                        </tr>
                        <tr style={{color: missionClock.isLaunched ? 'green' : 'red'}}>
                            <td>T-Minus</td>
                            <td style={{textAlign: 'right'}}>{missionClock.tMinusString}</td>
                        </tr>
                        <tr>
                            <td>LiftOff</td>
                            <td style={{
                                backgroundColor: missionClock.liftoffString === 'Holding' ? 'red' : 'transparent',
                                textAlign: 'right'
                            }}
                            >
                                {missionClock.liftoffString}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {/*Conductor Framelock*/}
                <div style={{position: 'absolute', left: '340px', top: '1px'}}>
                    <Table
                        draggable={false}
                        grid={gridArray}
                        top={10}
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={conductorFramelock}
                        radius={12}
                        textSize="1.3rem"
                    />
                </div>
                <div style={{position: 'absolute', left: '577px', top: '230px'}}>
                    <Horizon
                        grid={gridArray}
                        pitch={parseFloat(
                            getEndOfArray(widgetState.location.data, 'pitch')
                        )}
                        roll={parseFloat(
                            getEndOfArray(widgetState.location.data, 'roll')
                        )}
                        width="275px"
                        height="275px"
                        demo={false}
                    />
                    <div style={{color: 'white', fontSize: '1.2rem'}}>
                        Mach: {getToFixed(widgetState.location.data.at(-1)?.velocity)}
                    </div>
                </div>
                <div style={{
                    border: 'solid thin white',
                    position: 'absolute',
                    left: '940px',
                    top: '65px',
                }}
                >
                    <p style={{
                        color: 'yellow',
                        textAlign: 'center',
                        fontFamily: 'Courier New',
                        fontSize: '1.2rem',
                        marginBottom: 0,
                    }}
                    >
                        Status
                    </p>
                    {/*Conductor Tbl1*/}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    >
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={conductorTable1}
                            radius={12}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={240}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                        {/*Conductor Tbl2*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={conductorTable2}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={240}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                        {/*Conductor Tbl3*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={conductorTable3}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={240}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                    </div>
                    <p style={{
                        color: 'yellow',
                        textAlign: 'center',
                        fontFamily: 'Courier New',
                        fontSize: '1.2rem',
                        marginBottom: 0,
                    }}
                    >
                        Post Squib Status
                    </p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    >
                        {/*squibTbl*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={squibTbl}
                            radius={12}
                            tableWidth={990}
                            tableHeight={40}
                            tableBorder="1px solid white"
                            textColor="teal"
                            textSize="1rem"
                        />
                    </div>
                    <p style={{
                        color: 'yellow',
                        textAlign: 'center',
                        fontFamily: 'Courier New',
                        fontSize: '1.2rem',
                        marginBottom: 0,
                    }}
                    >
                        Important Parameters
                    </p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    >
                        {/*importantParamTbl1*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={importantParamsTbl1}
                            radius={12}
                            tableBorder="1px solid white"
                            rowPadding="10px"
                            tableHeight={150}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                        {/*importantParamTbl2*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={importantParamsTbl2}
                            radius={12}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={150}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                        {/*importantParamTbl3*/}
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={importantParamsTbl3}
                            radius={12}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={150}
                            tableWidth={330}
                            textColor="teal"
                            textSize="1rem"
                        />
                    </div>
                </div>

                {/*Flight Profile Table*/}
                <div style={{position: 'absolute', left: '5px', top: '650px'}}>
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathTimeProps(configuration)}
                        chartTitle="Flight Profile"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        innerChartWidth={1080}
                        innerChartHeight={340}
                        outerChartWidth={1100}
                        outerChartHeight={400}
                        lines={[{key: 'offNom', color: 'blue'}, {key: 'alt', color: 'red', dashArray: '5 5'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceMultiple={50}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="talo"
                        type="number"
                        margin={{left: 50, right: 20, bottom: 25}}
                        tickFontSize="1.2em"
                        xLabel={{text: 'TALO (s)', offset: 5}}
                        yLabel={{text: 'Altitude (ft)', offset: 40}}
                    />
                </div>
                {/*Test Events div*/}
                <div style={{
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    borderColor: 'white',
                    height: 376,
                    width: 470,
                    position: 'absolute',
                    left: '1131px',
                    top: '650px',
                    overflowY: 'auto',
                    fontSize: '1.3rem',
                }}
                >
                    <p style={{color: 'yellow', textAlign: 'center', fontFamily: 'Courier New'}}><u>Critical Events</u>
                    </p>
                    <DisplayList listName="testEvents"/>
                </div>
                {/*Image div*/}
                <div style={{
                    top: '195px',
                    position: 'absolute'
                }}
                >
                    {
                        widgetState.locations.scale &&
                        <ZoomedImage
                            graphData={widgetState.location.data}
                            //[maxLat, minLat, maxLon, minLon]
                            maxMinData={['100', '0', '100', '0']}
                            imgHeight={300 * 1.2}
                            imgWidth={500 * 1.2}
                            xPos={widgetState.locations.x}
                            yPos={widgetState.locations.y}
                            lines={[
                                {color: 'red', xKey: 'lon', yKey: 'lat'},
                                {color: 'blue', xKey: 'lon2', yKey: 'lat2'},
                            ]}
                            scale={widgetState.locations.scale}
                            resultSize={{height: 400, width: 400}}
                            resultPos={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                        />
                        /*<ImageHoverZoom
                            graphData={widgetState.location.data}
                            //[maxLat, minLat, maxLon, minLon]
                            maxMinData={['100', '0', '100', '0']}
                            imgHeight={300 * 1.2}
                            imgWidth={500 * 1.2}
                            xPos={widgetState.locations.x}
                            yPos={widgetState.locations.y}
                            lines={[
                                {color: 'red', xKey: 'lon', yKey: 'lat'},
                                {color: 'blue', xKey: 'lon2', yKey: 'lat2'},
                            ]}
                        />*/
                    }
                </div>
            </div>
        </>
    );
};

export default TestConductor;
