import React from 'react';

type Props = {
    data: string;
    fontFamily: string;
    fontSize: number;
    color: string;
}

const PageTitle = (props: Props) => {
    const { data, fontFamily, fontSize, color} = props;
    const styles = {
        pageTitleStyle: {
            fontSize: fontSize + 'px',
            color,
            fontFamily,
            whiteSpace: 'nowrap' as const,
            float: 'right' as const,
            textAlign: 'right' as const
        },
        pageTitleWrapper: {
            paddingRight: '400px',
            textAlign: 'right' as const
        }
    };
    return (
        <div style={styles.pageTitleWrapper}>
            <h1 style={styles.pageTitleStyle}>
                <b>{data}</b>
            </h1>
        </div>
    );
}

export default PageTitle;
