import React, {useContext} from 'react';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import itacLogo from '../../images/ITAC_logo.png';
import '../../App.css';
import ClientContext from '../../helpers/ClientContext';
import {initializeApp} from 'firebase/app';
import {firebaseConfig} from '../../helpers/helper';
import axios from 'axios';

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);

type SignInProps = {
    setIsAuthenticated: (val: 0 | 1 | 2) => void;
    setAuthedUser: (user: {id: string}) => void;
}

const SignIn = ({setIsAuthenticated, setAuthedUser}: SignInProps) => {
    const {addNotification} = useContext(ClientContext);

    /**
     *
     * @param event
     */
    const resetPassword = (event: React.MouseEvent<HTMLSpanElement>) => {
        const target = event.currentTarget;
        const emailInput = target.closest('div')?.querySelector('#email') as HTMLInputElement;
        const email = emailInput?.value;
        if(!email){
            addNotification({message: 'Please enter an email address', color: 'red'});
            return;
        }

        const confirmation = window.confirm(`Are you sure you want to send the password reset link to ${email}?`);
        if(!confirmation){
            return;
        }

        const sentMessage = `If there was an account associated with ${email}, a reset password link was sent to ${email}.`;
        sendPasswordResetEmail(auth, email).then(() => {
            addNotification({message: sentMessage, color: 'green'});
        }).catch((error) => {
            const {code, message} = error;
            if(code === 'auth/user-not-found'){
                addNotification({message: sentMessage, color: 'green'});
            } else{
                addNotification({message, color: 'red'});
            }
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;

        const emailInput = form.querySelector('#email') as HTMLInputElement;
        const email = emailInput?.value;

        const passwordInput = form.querySelector('#password') as HTMLInputElement;
        const password = passwordInput?.value;

        try {
            const res = await axios.post<{user: {id: string}}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/logIn`, {
                email,
                password
            });
            const {user} = res.data;
            setIsAuthenticated(2);
            setAuthedUser(user);
        } catch (e: any) {
            const message = e.response?.data?.message || 'Error logging in';
            addNotification({message, color: 'red'});
        }
    };

    return (
        <>
            <div className="blackBody"/>
            <div className="logindiv">
                <form style={{color: 'white', padding: '10px'}} onSubmit={handleSubmit}>
                    <img style={{height: '75px', width: '200px'}} src={itacLogo} alt="TAC Logo"/>
                    <h1 style={{padding: '40px'}}>Sign In</h1>
                    <input style={{margin: '10px'}} id="email" type="email" placeholder="email"/>
                    <input id="password" type="password" placeholder="password"/><br></br>
                    <button style={{borderRadius: '0.5em 0.5em 0.5em 0.5em', margin: '10px'}} type="submit">Submit
                    </button>
                </form>
                {/*<span role="link" tabIndex={0} onClick={resetPassword}
                      style={{textDecoration: 'underline', color: '#074d62', cursor: 'pointer'}}>
                Reset Password
            </span>*/}
            </div>
        </>
    );
};

export default SignIn;
