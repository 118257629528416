import React, {useContext, useState, useEffect} from 'react';
import ClientContext from '../../helpers/ClientContext';
import useListItems from '../../hooks/useListItems';
import {getTMinusTime} from '../../helpers/helper';

type Props = {
    listName: string;
    canEdit ?: boolean;
    title: string;
    colors: {
        name: string;
        color: string;
        headerColor ?: string;
    }[]
    actions: number[];
    time: number;
}

const EditableTable = (props: Props) => {
    const {listName, canEdit = false, title, colors, actions, time} = props;
    const [value, setValue] = useState('');
    const uncheckedItems = useListItems(listName);
    const [items, setItems] = useState<{text: string; color: string;}[]>([]);
    const {client, configuration, authedUser} = useContext(ClientContext);

    useEffect(() => {
        if(!Array.isArray(uncheckedItems)){
            return;
        }

        setItems(uncheckedItems);
    }, [uncheckedItems])

    /**
     *
     * @param item
     */
    const sendData = (item: {text: string; color: string;}) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'addListItem',
                list: listName,
                item,
                updateRun: true,
            })
        );
    };

    /**
     *
     * @param event
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleRadioChange = (text: string, color: string, index: number, name: string) => {
        if(!client){
            return;
        }

        const action = actions[index] || null;
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'updateListItem',
                list: listName,
                text,
                identifier: `${title}: ${text} turned ${name || color}`,
                color,
                position: index + 1,
                action,
                positionId: authedUser.position,
                tMinus: getTMinusTime(time, configuration),
            }),
        );
    };

    /**
     *
     * @param event
     */
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(value){
            sendData({text: value, color: 'white'});
        }

        setValue('');
    };

    /**
     *
     * @param event
     */
    const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(!client){
            return;
        }

        const row = event.currentTarget.dataset.row;
        if(!row){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'removeListItem',
                list: listName,
                text: document.getElementsByClassName(row)[0].textContent,
                updateRun: true,
            }),
        );
    };

    function moveUp(index: number){
        if(!client || index === 0){ // already at top
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'moveListItemUp',
                list: listName,
                index,
            }),
        );
    }

    function moveDown(index: number){
        if(!client || index === items.length){ // already at bottom
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'moveListItemDown',
                list: listName,
                index,
            }),
        );
    }

    return (
        <div>
            {
                canEdit &&
                <form onSubmit={handleSubmit} style={{textAlign: 'center', marginTop: '0.1rem', marginBottom: '0.2rem'}}>
                    <input type="text" value={value} onChange={handleChange}/>
                    <button type="submit">Add</button>
                </form>
            }

            <div style={{padding: '0 1em 0.1em 1em'}}>
                <table className="editableTable" style={{borderCollapse: 'separate', borderSpacing: '0 0.1em'}}>
                    <thead>
                    <tr>
                        <td style={{backgroundColor: 'black'}}/>
                        {colors.map((item, index) => (
                            <td key={index} style={{
                                paddingLeft: '0.5em',
                                backgroundColor: 'black',
                                color: item.headerColor || item.color
                            }}>{item.name}</td>
                        ))}
                        {
                            canEdit &&
                            <td style={{backgroundColor: 'black'}}/>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) => (
                        <tr key={index} className="editableTableRow">
                            <td><span className={`listText ${item.text}${index}`}>{item.text}</span></td>
                            {colors.map((color, colorIndex) => (
                                <td key={`td${index}color${colorIndex}`} style={{textAlign: 'center'}}>
                                    <input onChange={() => handleRadioChange(item.text, color.color, colorIndex, color.name)}
                                           type="radio" name={`${item.text}radios`} value={color.color}
                                           checked={item.color === color.color}/>
                                </td>
                            ))}
                            {
                                canEdit &&
                                <td style={{display: 'flex'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '1em',}}>
                                        <button onClick={() => moveUp(index)} style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            lineHeight: '1rem'
                                        }}>
                                            &#11014;
                                        </button>
                                        <button onClick={() => moveDown(index)} style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            lineHeight: '1rem'
                                        }}>
                                            <span style={{marginTop: '-.2rem', paddingTop: '-.2rem'}}>&#11015;</span>
                                        </button>
                                    </div>
                                    <button
                                        style={{
                                            fontFamily: 'Arial',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        type="button" data-row={`${item.text}${index}`} onClick={handleRemove}>
                                        X
                                    </button>
                                </td>
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

EditableTable.defaultProps = {
    listName: '',
    colors: [],
    canEdit: false,
    actions: [],
};

export default EditableTable;
