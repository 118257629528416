export const ftlu = {
    head: ['', '1A', '1B', '2A', '2B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Bus Volt'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Int Power'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ALE SEP'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Load CLR'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTLU ARM'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const esad = {
    head: ['', '1A', '1B', '2A', '2B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'ESAD PWR'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ESAD ARM'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'VMON'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ESAD DST'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const cdrMonitors = {
    head: ['', '2A', '2B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Tone 1'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Tone 2'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Tone 5'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Tone 4'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const cdrCommands = {
    head: ['', 'A', 'B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'ARM'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'S2 DST'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'S1 DST'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const events = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'CES SEP'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'S1 IGN'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'S1 SEP'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'T0 SEP'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const ignition = {
    head: ['', '2A', '2B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'OPT RCVD'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'S1 ESAD'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },

            {
                type: 'dynamicText',
                key: 'y'
            }
        ]
    ]
};

export const pss = {
    head: ['', '1A', '1B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'BreakWire'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Disabled'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

export const missionStatus = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'UTC'
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        //Get the date and calculate day of the year
                        const now = new Date(data.utc);
                        const start = new Date(now.getFullYear(), 0, 0);
                        const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                        const oneDay = 1000 * 60 * 60 * 24;
                        const day = Math.floor(diff / oneDay).toString().padStart(3, '0');

                        //then get the rest of the date and return it formatted
                        const hour = now.getHours().toString().padStart(2, '0');
                        const min = now.getMinutes().toString().padStart(2, '0');
                        const sec = now.getSeconds().toString().padStart(2, '0');
                        const mill = now.getMilliseconds().toString().padStart(3, '0');
                        return {text: day + ':' + hour + ':' + min + ':' + sec + '.' + mill};
                    }
                )
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Liftoff'
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        //Get the date and calculate day of the year
                        const now = new Date(data.liftoff);
                        const start = new Date(now.getFullYear(), 0, 0);
                        const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                        const oneDay = 1000 * 60 * 60 * 24;
                        const day = Math.floor(diff / oneDay).toString().padStart(3, '0');

                        //then get the rest of the date and return it formatted
                        const hour = now.getHours().toString().padStart(2, '0');
                        const min = now.getMinutes().toString().padStart(2, '0');
                        const sec = now.getSeconds().toString().padStart(2, '0');
                        const mill = now.getMilliseconds().toString().padStart(3, '0');
                        return {text: day + ':' + hour + ':' + min + ':' + sec + '.' + mill};
                    }
                )
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TALO'
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        //initialize the variables we need to print
                        let diff;
                        let days = '000';
                        let hurs = '00';
                        let mins = '00';
                        let secs = '00';
                        let mils = '000';

                        //set up the return
                        const returnData = {
                            styles: {},
                            text: '-1'
                        };

                        //calculate which date is ahead and set text color
                        if(data.liftoff > data.utc){
                            diff = data.liftoff - data.utc;
                            returnData.styles.color = 'red';
                        } else{
                            diff = data.utc - data.liftoff;
                            returnData.styles.color = 'green';
                        }


                        //check if we are more than a day away, get how many, and take it from diff
                        if(diff / 86400000 >= 1){
                            days = Math.floor(diff / 86400000).toString().padStart(3, '0');
                            diff -= (days * 86400000);
                        }

                        //check if we are more than an hour away, get how many, and take it from diff
                        if(diff / 3600000 >= 1){
                            hurs = Math.floor(diff / 3600000).toString().padStart(2, '0');
                            diff -= (hurs * 3600000);
                        }

                        //check if we are more than a minute away, get how many, and take it from diff
                        if(diff / 60000 >= 1){
                            mins = Math.floor(diff / 60000).toString().padStart(2, '0');
                            diff -= (mins * 60000);
                        }

                        //check if we are more than a second away, get how many, and take it from diff
                        if(diff / 1000 >= 1){
                            secs = Math.floor(diff / 1000).toString().padStart(2, '0');
                            diff -= (secs * 1000);
                        }

                        //all we have left are the milliseconds difference, so keep it
                        else{
                            mils = diff.toString().padStart(3, '0');
                        }

                        //return
                        returnData.text = days + ':' + hurs + ':' + mins + ':' + secs + ':' + mils;
                        return returnData;
                    }
                )
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TALO'
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        //return based on which date is ahead, get the difference and convert to seconds
                        if(data.liftoff > data.utc){
                            return {
                                styles: {color: 'red'},
                                text: ((data.liftoff - data.utc) / 1000).toString()
                            };
                        }
                        return {
                            styles: {color: 'green'},
                            text: ((data.utc - data.liftoff) / 1000).toString()
                        };
                    }
                )
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Status'
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        if(data.liftoff > data.utc){
                            return {
                                styles: {color: 'red'},
                                text: 'Waiting'
                            };
                        }
                        return {
                            styles: {color: 'yellow'},
                            text: 'Counting'
                        };
                    }
                )
            }
        ]
    ]
};


export const framelock = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'GPS FRAMELOCK'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        if(data.x > data.y){
                            return {
                                styles: {color: 'red'},
                                text: 'No lock'
                            };
                        }
                        return {
                            styles: {color: 'green'},
                            text: 'Lock'
                        };
                    }
                )
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TRGT FRAMELOCK'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'compareText',
                getText: (
                    (data) => {
                        if(data.x > data.y){
                            return {
                                styles: {color: 'red'},
                                text: 'No lock'
                            };
                        }
                        return {
                            styles: {color: 'green'},
                            text: 'Lock'
                        };
                    }
                )
            }
        ]
    ]
};

export const trackSourceTiming = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'JAVAD'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'NavStrm'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'OrbNav'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SIGI'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ]
    ]
};

export const gpsSourceStatus = {
    head: ['','JAVAD','NavStrm','SIG'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'FOM:'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: '#SVs:'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'State 5'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ]
    ]
};

export const altitude = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'JAVAD'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'NavStrm'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'OrbNav'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SIGI'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ]
    ]
};

export const flightNavStatus = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'Flt Phase'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Flt Phase'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Phase Time'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'E+ Time'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Ign+ Time'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }
        ]
    ]
};

export const conductorTable1 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'FTR 1 GO'
            },
            {
                type: 'textOverColor',
                key: 'ftrOneGo',
                bounds: {
                    0: 'red',
                    1: 'green',
                    Infinity: 'red'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTR 1 CMD GO'
            },
            {
                type: 'textOverColor',
                key: 'ftrOneCmdGo',
                bounds: {
                    0: 'red',
                    1: 'green',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTR_1 Sig Strength TM Out',
            },
            {
                type: 'dynamicText',
                key: 'ftrOneSigStr'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TM Xmtr-TCK69'
            },
            {
                type: 'dynamicText',
                key: 'tmXmtrTck69'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SRM 1 DOME PS04'
            },
            {
                type: 'textOverColor',
                key: 'srmOneDome',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'AUR_Ready'
            },
            {
                type: 'textOverColor',
                key: 'aurReady',
                bounds: {
                    0: 'red',
                    1: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FCAS ECU Operational'
            },
            {
                type: 'dynamicText',
                key: 'fcasEcuOp'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'VMC Good'
            },
            {
                type: 'textOverColor',
                key: 'vmcGood',
                bounds: {
                    0: 'red',
                    1: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Gyro Good'
            },
            {
                type: 'dynamicText',
                key: 'gyroGood'
            }
        ]

    ]
};

export const conductorTable2 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'FTR 2 GO'
            },
            {
                type: 'dynamicText',
                key: 'ftrTwoGo',
                bounds: {
                    0: 'red',
                    1: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTR 2 CMD GO'
            },
            {
                type: 'textOverColor',
                key: 'ftrTwoCmdGo',
                bounds: {
                    0: 'red',
                    1: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTR_2 Sig Strength TM Out',
            },
            {
                type: 'dynamicText',
                key: 'ftrTwoSigStr'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTR_1 L-TCK58'
            },
            {
                type: 'dynamicText',
                key: 'ftrOneLTck'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SRM 2 DOME PS05'
            },
            {
                type: 'textOverColor',
                key: 'srmTwoDome',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'OFP Throughput'
            },
            {
                type: 'textOverColor',
                key: 'ofpThroughput',
                bounds: {
                    bounds: {
                        0: 'red',
                        5: 'green'
                    }
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Discretes In Expected'
            },
            {
                type: 'textOverColor',
                key: 'discretesInExpected',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'GPS Operational'
            },
            {
                type: 'textOverColor',
                key: 'gpsOperational',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PDU Good'
            },
            {
                type: 'dynamicText',
                key: 'pduGood'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Nav Status.TXA Quality'
            },
            {
                type: 'textOverColor',
                key: 'navStatus',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ]

    ]
};

export const conductorTable3 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'ESAD A POST GO'
            },
            {
                type: 'dynamicText',
                key: 'esadAPostGo'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ESAD B POST GO'
            },
            {
                type: 'dynamicText',
                key: 'esadBPostGo'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS-1 2BVDC Batt Volt'
            },
            {
                type: 'dynamicText',
                key: 'ftsOneBattVolt'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS-2 2BVDC Batt Volt'
            },
            {
                type: 'dynamicText',
                key: 'ftsTwoBattVolt'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PDU 28 Volts'
            },
            {
                type: 'textOverColor',
                key: 'pduVolts',
                bounds: {
                    26.99: 'red',
                    29.01: 'green',
                    Infinity: 'red',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SRM 3 DOME PS06'
            },
            {
                type: 'textOverColor',
                key: 'srmDomePs',
                bounds: {
                    0: 'red',
                    5: 'green',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'OfpCsum'
            },
            {
                type: 'textOverColor',
                key: 'ofpCsum',
                bounds: {
                    0: 'red',
                    5: 'green',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Discretes Out Expected'
            },
            {
                type: 'textOverColor',
                key: 'discretesOutExpected',
                bounds: {
                    0: 'red',
                    1: 'green',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'IMU Good'
            },
            {
                type: 'textOverColor',
                key: 'imuGood',
                bounds: {
                    0: 'red',
                    1: 'green',
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PDU Switch Expected'
            },
            {
                type: 'dynamicText',
                key: 'pduSwitchExpected'
            }
        ]

    ]
};

export const squibTbl = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'Fins Unlock'
            },
            {
                type: 'textOverColor',
                key: 'finsUnlock',
                bounds: {
                    0: 'red',
                    1: 'green',
                }
            },
            {
                type: 'staticText',
                value: 'Stage 1 Flight Mode'
            },
            {
                type: 'dynamicText',
                key: 'stageOneFlightMode'
            },
            {
                type: 'staticText',
                value: 'Stage 2 Flight Mode'
            },
            {
                type: 'dynamicText',
                key: 'stageTwoFlightMode'
            },
            {
                type: 'staticText',
                value: 'Stage 3 Flight Mode'
            },
            {
                type: 'dynamicText',
                key: 'stageThreeFlightMode'
            },
            /*{
                type: 'staticText',
                value: 'PDU 270 Volts'
            },
            {
                type: 'dynamicText',
                key: 'y'
            }*/
        ]
    ]
};

export const importantParamsTbl1 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'stage1SRM'
            },
            {
                type: 'dynamicText',
                key: 'stageOneSrm'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS Batt -TCK50'
            },
            {
                type: 'dynamicText',
                key: 'ftsBattTck'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS 1 Wire Cutter Relay'
            },
            {
                type: 'dynamicText',
                key: 'ftsOneWireCutterRelay'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'WEAPON STATE'
            },
            {
                type: 'dynamicText',
                key: 'weaponState'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS Stage 1 AP Safe'
            },
            {
                type: 'textOverColor',
                key: 'bcasStageOneApSafe',
                bounds: {
                    0: 'red',
                    5: 'green'
                }
            }
        ]
    ]
};

export const importantParamsTbl2 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'stage2SRM'
            },
            {
                type: 'dynamicText',
                key: 'stageTwoSrm'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS Batt R -TCK52'
            },
            {
                type: 'dynamicText',
                key: 'ftsBattR'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS 2 Wire Cutter Relay'
            },
            {
                type: 'dynamicText',
                key: 'ftsTwoWireCutterRelay'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Config Version'
            },
            {
                type: 'dynamicText',
                key: 'configVersion'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS Stage 2 AP Safe'
            },
            {
                type: 'textOverColor',
                key: 'bcasStageTwoApSafe',
                bounds: {
                    0: 'red',
                    5: 'green',
                }
            }
        ]
    ]
};

export const importantParamsTbl3 = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'stage3SRM'
            },
            {
                type: 'dynamicText',
                key: 'stageThreeSrm'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'MDU-TCK71'
            },
            {
                type: 'dynamicText',
                key: 'mduTck'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'VMC-TCK65'
            },
            {
                type: 'dynamicText',
                key: 'vmcTck'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PID On'
            },
            {
                type: 'dynamicText',
                key: 'pidOn'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS Stage 3 AP Safe'
            },
            {
                type: 'textOverColor',
                key: 'bcasStageThreeApSafe',
                bounds: {
                    0: 'red',
                    5: 'green',
                }
            }
        ]
    ]
};

export const conductorFramelock = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'Glider'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    // 30: 'red',
                    // 40: 'yellow',
                    Infinity: 'green'
                }
            },
            {
                type: 'staticText',
                value: 'SNS'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    // 30: 'red',
                    // 40: 'yellow',
                    Infinity: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'EMB'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    // 30: 'red',
                    // 40: 'yellow',
                    Infinity: 'green'
                }
            },
        ],
        [
            {
                type: 'staticText',
                value: 'CCSDS'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    // 30: 'red',
                    // 40: 'yellow',
                    Infinity: 'green'
                }
            },
        ]
    ]
};

export const exTable = {
    head: ['', '2A', '2B'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Ex Name 1'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Ex Name 2'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Ex Name 3'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Ex Name 4'
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    80: 'red',
                    100: 'yellow',
                    101: 'green'
                }
            },
            {
                type: 'light',
                key: 'y',
                bounds: {
                    100: 'yellow',
                    101: 'green'
                }
            }
        ]
    ]
};

