// this solution is from https://github.com/facebook/react/issues/11163
import { AudioHTMLAttributes, useEffect, useRef } from 'react'

type PropsType = AudioHTMLAttributes<HTMLAudioElement> & {
    srcObject: MediaStream,
    muted: boolean,
}

export default function CallAudio({srcObject, muted}: PropsType) {
    const refAudio = useRef<HTMLAudioElement>(null)

    useEffect(() => {
        if (!refAudio.current) return
        refAudio.current.srcObject = srcObject
    }, [srcObject])

    return <audio ref={refAudio} autoPlay muted={muted} />
}
