// Aircraft Integration Screen
export const ofpInfo = {
    head: ['OFP Information'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'OFP Version',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ofpVersion',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Config File Version',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'configFileVersion',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Config File Date',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'configFileDate',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SEP Version',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'sepVersion',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'iDKM Version',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'idkmVersion',
            }
        ],
    ]
};

//Aircraft Integration Mission Status
export const missionStatusAI = {
    head: ['Mission Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Store Location',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'storeLocation',
                color: 'green'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Current RT Address',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'currentRtAddress',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Warmup Complete',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'warmupComplete',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TM On',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'tmOn',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Store Station ID Rcvd',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'storeStationIdRcvd',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Power Dropout Status',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'powerDropoutStatus',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Last Bit Passed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'lastBitPassed',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'GPS Key Status',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'gpsKeyStatus',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Sats In Track',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'satsInTrack',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Almanac RCVD',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'almanacRcvd',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Ephemeris RCVD',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ephemerisRcvd',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Min GPS',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'minGps',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Environ Data Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'environDataRcvd',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Min TDS',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'minTds',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Min TXA',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'minTxa',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Safe To Release',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'safeToRelease',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TXA_Quality',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'txaQuality',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TXA_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'txaGood',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'AUR Ready',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'julisaAurReady',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS On',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ftsOn',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'FTS Batter On',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatOn',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Release Consent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'releaseConsent',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Valid CTS Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'validCtsRcvd',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'CTSS Latch',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ctssLatch',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Mated',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'mated',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Timeline Abort',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'timelineAbort',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'NVM Data Erased',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'nvmDataErased',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'NVM Weapon State',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'nvmWeaponState',
            }
        ],
    ]
};

// Aircraft Integration "SOT"
export const sot = {
    head: ['SOT'],
    rows: [
        [
            {
                type: 'staticText',
                value: ''
            },
            {
                type: 'staticText',
                value: 'FTS A',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'FTS B',
                color: 'yellow'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Arm State',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'armState',
            },
            {
                type: 'dynamicText',
                key: 'armState',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Terminate Cmd',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'terminateCmd',
            },
            {
                type: 'dynamicText',
                key: 'terminateCmd',
            }
        ],
    ]
};

//Aircraft Integration "Safety of Flight"
export const safetyOfFlight = {
    head: ['Safety of flight'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Glider Temp 1',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'gliderTempOne',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Glider Temp 2',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'gliderTempTwo',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PAA Temp 1',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'paaTempOne',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PAA Temp 2',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'paaTempTwo',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'PAA Temp 3',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'paaTempThree',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'CAS Temp 1',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'casTempOne',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'CAS Temp 2',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'casTempTwo',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'CAS Temp 3',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'casTempThree',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SESAD-Ready To Arm',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'sesadReadyToArm',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ISD Arm Pwr Valid',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdArmPwrValid',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ISD Inhibit Timer',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdInhibitTimer',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ISD 1760 Umbilical',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdUmbilical',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ISD MAS Status',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdMasStatus',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'SEP Initial Roll',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'sepInitialRoll',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'ISD Fire Present',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdFirePresent',
            }
        ],
    ]
};

// AircraftIntegration "Target"
export const target = {
    head: ['Target'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Lat',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'lat2',
            },
            {
                type: 'staticText',
                value: 'Deg',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Long',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'lon2',
            },
            {
                type: 'staticText',
                value: 'Deg',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Alt (HAE)',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'alt',
            },
            {
                type: 'staticText',
                value: 'kft',
            }
        ],
    ]
}

//Aircraft Integration "Flight Phase"
export const flightPhase = {
    head: ['Flight Phase'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Flight Phase 200Hz',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'flightPhaseTwoHun',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Flight Phase 100Hz',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'flightPhaseOneHun',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Flight Phase 50Hz',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'flightPhaseFifty',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Flight Phase 10Hz',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'flightPhaseTen',
            },
        ],
    ]
}

//Aircraft Integration "Captive Carry"
export const captiveCarry = {
    head: ['Captive Carry'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Overtemp Warning',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'overtempWarning',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Overtemp Caution',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'overtempCaution',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'TXA_Reinited',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'txaReinited',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'TXA_Halted',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'txaHalted',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Captive Carry',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'captiveCarry',
            },
        ],
    ]
}

//Aircraft Integration "BIT Status"
export const bitStatus = {
    head: ['BIT Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'BIT_Complete',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitComplete',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BIT_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitFail',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Critical_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitCritFail',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'MP_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitMpGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'GPS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitGpsGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitBcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'IMU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitImuGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'PDU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitPduGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BEM_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitBemGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ESAF_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitEsafGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'FCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitFcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'HOB_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitHobGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ISD_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitIsdGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'SESAD Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bitSesadGood',
            },
        ],
    ]
}

//Aircraft Integration "HLBR IBIT Status"
export const hlbrIbitStatus = {
    head: ['HLBR IBIT Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Complete',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitComplete',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BIT_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitBitFail',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Critical_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitCritFail',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'MP_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitMpGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'GPS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitGpsGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitBcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'IMU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitImuGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'PDU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitPduGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BEM_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitBemGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ESAF_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitEsafGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'FCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitFcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'HOB_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitHobGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ISD_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitIsdGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'SESAD Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ibitSesadGood',
            },
        ],
    ]
}

//Aircraft Integration "HLBR CBIT Status"
export const hlbrCbitStatus = {
    head: ['HLBR CBIT Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Complete',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitComplete',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'BIT_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitBitFail',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Critical_Fail',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitCritFail',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'MP_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitMpGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'GPS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitGpsGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitBcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'IMU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitImuGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'PDU_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitPduGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'BEM_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitBemGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ESAF_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitEsafGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'FCAS_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitFcasGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'HOB_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitHobGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ISD_Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitIsdGood',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'SESAD Good',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cbitSesadGood',
            },
        ],
    ]
}

//Aircraft Integration "HLBR CBIT Status"
export const weaponMonitorBit = {
    head: ['22T-09 Weapon Monitor Bit'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'VMC Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'vmcFault',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'GPS Receiver',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'gpsReceiver',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'CAS Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'casFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'IMU Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'PDU Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Bem Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ESAF Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'FCAS Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'HOB Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'ISD Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdFault',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'SESAD Fault',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'sesadFault',
            },
        ],
    ]
}

// Aircraft Integration "Continuity Loops"
export const continuityLoops = {
    head: ['Continuity Loops'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'MAS',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'mas'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Booster_Tb',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'boosterTb'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Sesad',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'sesad'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Isd',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isd'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Cas_Tb',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'casTb'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Cas',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'cas'
            }
        ],
    ]
}

// Aircraft Integration "Mission Info"
export const missionInfo = {
    head: ['Mission Info'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Mission Start',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
            {
                type: 'staticText',
                value: 'sec',
                color: 'white'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Mission Flight Time',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
            {
                type: 'staticText',
                value: 'sec',
                color: 'white'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Mission State',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
        ],
    ]
}