import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Peer, {MediaConnection} from "peerjs";
import {isDevEnvironment, urlStart} from "../../helpers/helper";
import useListItems from "../../hooks/useListItems";
import ClientContext from "../../helpers/ClientContext";
import {Calls, Room} from "./AudiosAndCallAudios";
import {WebSocket} from "../../helpers/types";

type Props = {
    calls: Calls;
    client: WebSocket;
    clientRef: React.RefObject<WebSocket>;
    firebaseId: string;
    isAuthenticated: 0 | 1 | 2;
    isClientConnected: boolean;
    rooms: Room[];
    setCallsHook: (calls: Calls) => void;
    setRoomsHook: (rooms: Room[]) => void;
    setUnMutedRooms: (rooms: number[]) => void;
    unMutedRooms: number[];
}

type Message = {
    data: string;
}

export default function Audios(props: Props) {
    const {
        calls,
        client,
        clientRef,
        firebaseId,
        isAuthenticated,
        isClientConnected,
        rooms,
        setCallsHook,
        setRoomsHook,
        setUnMutedRooms,
        unMutedRooms
    } = props;
    const {addNotification, authedUser, runId} = useContext(ClientContext);
    const [curRoom, setCurRoom] = useState<string | null>(null);
    const callsRef = useRef(calls);

    function setCalls(data: Calls) {
        callsRef.current = data;
        setCallsHook(data);
    }

    const myPeer = useRef<Peer | null>(null);
    const [micVolume, setMicVolume] = useState(0);
    const myStream = useRef<MediaStream | undefined>(undefined);
    const peerIdRef = useRef(null);
    const roomsRef = useRef(rooms);

    function setRooms(data: Room[]) {
        roomsRef.current = data;
        setRoomsHook(data);
    }

    const [shouldDisplayAudio, setShouldDisplayAudio] = useState(true);
    const userAudios = useListItems('userAudio', [], clientRef.current);
    const [volumes, _setVolumes] = useState<number[][]>([]);
    const volumesRef = useRef(volumes);

    function setVolumes(data: number[][]) {
        volumesRef.current = data;
        _setVolumes(data);
    }

    useEffect(() => {
        return () => {
            myPeer.current?.destroy();
            myPeer.current = null;
        }
    }, [isAuthenticated]);
    useEffect(() => {
        if (!isAuthenticated) {
            myPeer.current?.destroy();
            myPeer.current = null;
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (!firebaseId) {
            return;
        }

        const checkAudioLevel = setInterval(() => {
            const newVolumes = JSON.parse(JSON.stringify(volumesRef.current));
            roomsRef.current.forEach((room, index) => {
                if (!newVolumes[index]) {
                    newVolumes[index] = [];
                }
                let curVolume = 0;
                Object.keys(room.users).forEach(userId => {
                    if (userId === firebaseId) {
                        return;
                    }

                    const call = callsRef.current[userId]?.call;
                    if (!call) {
                        return;
                    }
                    const receiver = call.peerConnection.getReceivers()[0];
                    if (receiver && receiver.getSynchronizationSources) {
                        const source = receiver.getSynchronizationSources()[0];
                        if (source) {
                            curVolume += source.audioLevel || 0;
                        }
                    }
                });
                newVolumes[index].push(curVolume);
                if (newVolumes[index].length > 4) {
                    newVolumes[index].shift();
                }
                return room;
            });
            setVolumes(newVolumes);
        }, 125);

        return () => clearInterval(checkAudioLevel);
    }, [firebaseId]);

    useEffect(() => {
        if (!client) {
            return;
        }

        function handleMessage(message: Message) {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer.type === 'list' && dataFromServer.list === 'rooms') {
                setRooms(dataFromServer.data);
                const inRoom = Object.keys(dataFromServer.data).some(roomKey => {

                    if (dataFromServer.data[roomKey].users[firebaseId]) {
                        setCurRoom(roomKey);
                        return true;
                    }

                    return false;
                });
                if (!inRoom) {
                    setCurRoom(null);
                }
            }
        }

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getList',
                list: 'rooms'
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [client]);

    useEffect(() => {
        if (!myStream.current) {
            return;
        }
        myStream.current.getAudioTracks().forEach(track => {
            let audioEnabled = !!curRoom; //disable if not currently talking;
            if (Array.isArray(userAudios)) {
                userAudios.some(({userId, isDisabled}) => {
                    if (userId === firebaseId) {
                        if (isDisabled) {
                            setUnMutedRooms([]);
                        }
                        if (audioEnabled) {
                            audioEnabled = !isDisabled;
                        }
                        return true;
                    }
                    return false;
                });
            }

            track.enabled = audioEnabled;
        });
    }, [curRoom, userAudios]);

    useEffect(() => {
        if (!(isAuthenticated === 2 && isClientConnected)) {
            return;
        }

        async function handleAudio(message: Message) {
            const parsedMessage = JSON.parse(message.data);

            if (!parsedMessage || parsedMessage.data) {
                return;
            }

            if (parsedMessage.addAudio) {
                if (myPeer.current) { // should only happen on hot reload
                    return;
                }
                peerIdRef.current = parsedMessage.peerId;

                const stream = await updateStream(true);
                axios.get(`${urlStart}${isDevEnvironment ? ':5000' : ''}/twilio/nts-token`, {timeout: 5000})
                    .then((data) => {
                        if (data.data.error) {
                            addNotification({
                                message: `${data.data.message.code}: ${data.data.message.message}`,
                                color: 'red',
                            });
                            return;
                        }

                        myStream.current = stream;
                        type PeerOptions = {
                            host: string;
                            config: {
                                iceServers: string[];
                            }
                            port?: number;
                        }
                        const peerOptions: PeerOptions = {
                            host: `${window.location.hostname}`,
                            config: {
                                //iceServers: [], //FIXME: put this back if we want only local
                                iceServers: data.data.token.iceServers,
                            }
                        };
                        if (isDevEnvironment) { // prod server handles ports
                            peerOptions.port = 7000;
                        }

                        myPeer.current = new Peer(parsedMessage.peerId, peerOptions);
                        myPeer.current.on('call', call => { // when we are called
                            console.log('gettin a call');
                            call.answer(myStream.current);
                            addAudio(call, call.metadata.userId);
                        });
                        myPeer.current.on('error', error => {
                            console.log(error);
                        });

                        client.send(
                            JSON.stringify({
                                type: 'update',
                                content: 'twilioConnected',
                                name: authedUser.name,
                                peerId: parsedMessage.peerId,
                            })
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log('Could not get a token from server!');
                    });
            }

            if (parsedMessage.userJoined) {
                if (!myPeer.current || !myStream.current) {
                    return;
                }

                const call = myPeer.current.call(parsedMessage.peerId, myStream.current, {
                    metadata: {
                        userId: authedUser.id,
                        peerId: peerIdRef.current
                    }
                });
                console.log('calling ' + parsedMessage.peerId);
                addAudio(call, parsedMessage.databaseId);
            }

            if (parsedMessage.userDisconnected) {
                console.log('trying to close');
                callsRef.current[parsedMessage.databaseId]?.call.close();
            }
        }

        client.addEventListener('message', handleAudio);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'authReady'
            })
        );

        return () => client.removeEventListener('message', handleAudio);

    }, [isAuthenticated, isClientConnected]);

    /**
     *
     * @param call
     * @param databaseId
     */
    function addAudio(call: MediaConnection, databaseId: string) {
        call.on('stream', userAudioStream => {
            if (Object.keys(calls).includes(call.metadata.userId)) {
                return;
            }

            //prod server handles ports
            axios.get(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/getUser?userId=${databaseId}`)
                .then(res => {
                    const newCalls = {...callsRef.current};
                    newCalls[databaseId] = {
                        call,
                        name: res.data.user.name,
                        stream: userAudioStream
                    };

                    setCalls(newCalls);
                })
                .catch(error => {
                    addNotification({message: error, color: 'red'});
                });
        });
        call.on('close', () => {
            const newCalls = {...callsRef.current};
            delete newCalls[databaseId];
            setCalls(newCalls);
        });
    }

    /**
     *
     * @param event
     * @param roomKey
     */
    function joinRoom(event: React.MouseEvent, roomKey: string) {
        event.stopPropagation();

        if (curRoom === roomKey) { //race condition
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'joinRoom',
                newRoom: roomKey,
                curRoom,
                name: authedUser.name,
            })
        );
    }

    /**
     *
     * @param event
     * @param roomKey
     */
    function leaveRoom(event: React.MouseEvent, roomKey: number) {
        event.stopPropagation();

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'leaveRoom',
                firebaseId,
                roomKey,
            })
        );
    }

    function listenToRoom(roomKey: number) {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'listenToRoom',
                roomKey,
            })
        );
    }

    function muteRoom(roomKey: number) {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'unlistenToRoom',
                roomKey,
            })
        );
    }

    let audioContext;
    let isFirstClick = true;
    let listening = false;

    async function onMicrophoneGranted(stream: MediaStream) {
        // Instantiate just in the first time
        if (isFirstClick) {
            // Initialize AudioContext object
            audioContext = new AudioContext();

            // Adding an AudioWorkletProcessor
            // from another script with addModule method
            await audioContext.audioWorklet.addModule(process.env.PUBLIC_URL + '/vumeter-processor.js');

            // Creating a MediaStreamSource object
            // and sending a MediaStream object granted by
            // the user
            let microphone = audioContext.createMediaStreamSource(stream);

            // Creating AudioWorkletNode sending
            // context and name of processor registered
            // in vumeter-processor.js
            const node = new AudioWorkletNode(audioContext, 'vumeter');

            // Listing any message from AudioWorkletProcessor in its
            // process method here where you can know
            // the volume level
            node.port.onmessage = event => {
                let _volume = 0;
                if (event.data.volume) {
                    _volume = event.data.volume;
                    setMicVolume(_volume);
                }
            };

            // Now this is the way to
            // connect our microphone to
            // the AudioWorkletNode and output from audioContext
            microphone.connect(node).connect(audioContext.destination);

            isFirstClick = false;
        }

        listening = !listening;
    }

    function replaceTrack() {
        Object.values(callsRef.current).forEach(async call => {
            const newStream = await updateStream(false);
            myStream.current = newStream;
            call.call.peerConnection.getSenders()[0].replaceTrack(newStream?.getAudioTracks()[0] || null);
        });
    }

    function updateStream(initial: boolean) {
        if (!initial && !myStream.current) {
            return;
        }

        return navigator.mediaDevices.getUserMedia({
            audio: true
        }).then(stream => {
            onMicrophoneGranted(stream);
            stream.getAudioTracks().forEach(track => {
                const talking = roomsRef.current.some(room => {
                    return Object.keys(room.users).includes(firebaseId);
                });
                track.enabled = talking;
            });
            return stream;
        });
    }

    if (!firebaseId) {
        return <></>;
    }

    return (
        <>
            <div style={{paddingTop: '4.4rem', paddingBottom: '10px'}}>
                <button
                    title="Comm Refresh is used JUST to refresh your comms if your headset was not plugged in prior to accessing the site."
                    onClick={replaceTrack}
                >
                    Comm Refresh
                </button>
                <button title="Video explaining chat">
                    <Link to={`/${runId}/faq`}>
                        ?
                    </Link>
                </button>
                <button style={{borderRadius: '0.5rem'}} onClick={() => setShouldDisplayAudio(!shouldDisplayAudio)}>
                    {shouldDisplayAudio ? '-' : '+'}
                </button>
            </div>

            {rooms.map((room, roomKey) => {
                const listening = unMutedRooms.includes(roomKey);
                const userInRoom = room.usersInRoom.includes(firebaseId);
                let audioDisabled = false;
                if (Array.isArray(userAudios)) {
                    userAudios.some(({userId, isDisabled}) => {
                        if (userId === firebaseId) {
                            audioDisabled = isDisabled;
                            return true;
                        }
                        return false;
                    });
                }

                return (
                    <div key={roomKey} id={String(roomKey)}
                         style={{
                             minWidth: 65,
                             maxWidth: '103px',
                             // minHeight: 65,
                             backgroundColor: listening ? 'green' : 'red',
                             border: '1px solid white',
                             display: shouldDisplayAudio ? 'flex' : 'none',
                             cursor: 'pointer',
                             marginLeft: 'auto',
                             marginRight: 0

                         }}
                         onClick={() => {
                             if (listening) {
                                 muteRoom(roomKey);
                             } else {
                                 listenToRoom(roomKey);
                             }
                         }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: 1
                        }}
                        >
                            {
                                curRoom && String(roomKey) === curRoom &&
                                <div style={{
                                    width: '0.5rem',
                                    height: '0.5rem',
                                    backgroundColor: 'yellow',
                                    borderRadius: '50%',
                                    margin: '0.3rem',
                                    marginBottom: 0,
                                }}
                                />
                            }
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span style={{
                                        color: 'white',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                    >
                                        {room.title}
                                    </span>
                            </div>
                            <div style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                            >
                                <div style={{
                                    backgroundColor: '#e9e9ed',
                                    height: '.5rem',
                                    borderRadius: '.25rem',
                                    marginBottom: '.4rem',
                                    marginTop: '.4rem',
                                    border: '1px solid #838383',
                                    overflowX: 'hidden'
                                }}
                                >
                                    <div style={{
                                        backgroundColor: 'blue',
                                        width: `${volumes[roomKey]?.reduce((a, b) => a + b, 0) * 100 / .5 || 0}%`,
                                        height: '100%'
                                    }}
                                    />
                                </div>
                                {
                                    curRoom && String(roomKey) === curRoom &&
                                    <div style={{
                                        backgroundColor: '#e9e9ed',
                                        height: '.5rem',
                                        borderRadius: '.25rem',
                                        marginBottom: '.2rem',
                                        border: '1px solid #838383'
                                    }}
                                    >
                                        <div style={{
                                            backgroundColor: 'green',
                                            width: `${micVolume * 100}%`,
                                            height: '100%'
                                        }}
                                        />
                                    </div>
                                }
                            </div>
                            <div style={{display: 'flex'}}>
                                {curRoom && String(roomKey) === curRoom ?
                                    <button type="button" style={{fontSize: '.8rem', flex: 1}}
                                            onClick={(e) => leaveRoom(e, roomKey)} disabled={audioDisabled}
                                    >
                                        Stop
                                    </button>
                                    : userInRoom ?
                                        <button type="button" style={{fontSize: '.8rem', flex: 1}}
                                                onClick={(e) => joinRoom(e, String(roomKey))} disabled={audioDisabled}
                                        >
                                            Talk
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}