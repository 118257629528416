import React, {useEffect, useRef} from 'react';
import DraggableSnap from '../../js/DraggableSnap';

const {v4: uuidv4} = require('uuid');

type Props = {
    draggable?: boolean;
    grid: [number[], number[]];
    width: string;
    height: string;
    demo?: boolean;
    degree: number;
}

const CompassHeading = (props: Props) => {
    const {draggable, grid, width, height, demo, degree} = props;
    const containerID = uuidv4();
    const compassID = `compass_${new Date().getTime()}`;
    const compassRef = useRef<HTMLCanvasElement>(null);
    let divStyle = {};
    if (draggable) {
        divStyle = {
            position: 'absolute',
            top: '600px',
            left: '1300px',
            cursor: 'move'
        };
    }

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    //This method draws the plane
    const drawUI = (cnvs: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
        const canvas = cnvs;

        //Draw Body
        ctx.fillStyle = '#FFFFFF';
        ctx.rect(canvas.width / 2 - 10, canvas.height / 2 - 20, 20, 40);
        ctx.fill();

        //Draw Nose
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2, canvas.height / 2 - 30);
        ctx.lineTo(canvas.width / 2 - 10, canvas.height / 2 - 20);
        ctx.lineTo(canvas.width / 2 + 10, canvas.height / 2 - 20);
        ctx.fill();

        //Draw Wings
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2, canvas.height / 2 - 10);
        ctx.lineTo(canvas.width / 2 - 50, canvas.height / 2);
        ctx.lineTo(canvas.width / 2 + 50, canvas.height / 2);
        ctx.fill();

        //Draw Tail
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2, canvas.height / 2 + 10);
        ctx.lineTo(canvas.width / 2 - 20, canvas.height / 2 + 30);
        ctx.lineTo(canvas.width / 2 + 20, canvas.height / 2 + 30);
        ctx.fill();
    };

    const drawWorld = (cnvs: HTMLCanvasElement, ctx: CanvasRenderingContext2D, deg: number) => {
        const canvas = cnvs;

        // Draw the black background
        ctx.fillStyle = 'black';
        ctx.beginPath();
        ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 2,
            0,
            Math.PI * 2
        );
        ctx.fill();
        // Rotate everything but the black background
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.translate(-canvas.width / 2, -canvas.height / 2);

        // Draw the white circle
        ctx.fillStyle = '#FFFFFF';
        ctx.beginPath();
        ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 40,
            0,
            Math.PI * 2
        );
        ctx.fill();

        // Draw the grey circle
        ctx.fillStyle = '#4A4A56';
        ctx.beginPath();
        ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 42,
            0,
            Math.PI * 2
        );
        ctx.fill();

        // Draw the 4 long tick marks
        ctx.fillStyle = '#FFFFFF';
        ctx.beginPath();
        ctx.rect(canvas.width / 2 - 1, canvas.height / 2 - 120, 2, 60);
        ctx.fill();
        ctx.beginPath();
        ctx.rect(canvas.width / 2 - 1, canvas.height / 2 + 120 - 60, 2, 60);
        ctx.fill();
        ctx.beginPath();
        ctx.rect(canvas.width / 2 - 120, canvas.height / 2 - 1, 60, 2);
        ctx.fill();
        ctx.beginPath();
        ctx.rect(canvas.width / 2 + 120 - 60, canvas.height / 2 - 1, 60, 2);
        ctx.fill();

        // Draw every little tick mark
        ctx.strokeStyle = 'white';
        ctx.beginPath();
        let i = 10;
        while (i <= 360) {
            // Set default font
            ctx.font = '20px Arial';

            // Rotate 5 degrees and draw small tick
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((5 * Math.PI) / 180);
            ctx.translate(-canvas.width / 2, -canvas.height / 2);
            ctx.moveTo(canvas.width / 2, 80);
            ctx.lineTo(canvas.width / 2, 90);
            ctx.stroke();

            // Rotate another 5 degrees and draw bigger tick
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((5 * Math.PI) / 180);
            ctx.translate(-canvas.width / 2, -canvas.height / 2);
            ctx.moveTo(canvas.width / 2, 80);
            ctx.lineTo(canvas.width / 2, 100);
            ctx.stroke();

            // Write all the checkpoint markers
            switch (i) {
                case 30:
                    ctx.fillText('30', canvas.width / 2 - 10, 70);
                    break;
                case 60:
                    ctx.fillText('60', canvas.width / 2 - 10, 70);
                    break;
                case 90:
                    ctx.font = '30px Arial';
                    ctx.fillText('E', canvas.width / 2 - 10, 70);
                    break;
                case 120:
                    ctx.fillText('120', canvas.width / 2 - 10, 70);
                    break;
                case 150:
                    ctx.fillText('150', canvas.width / 2 - 10, 70);
                    break;
                case 180:
                    ctx.font = '30px Arial';
                    ctx.fillText('S', canvas.width / 2 - 10, 70);
                    break;
                case 210:
                    ctx.fillText('210', canvas.width / 2 - 10, 70);
                    break;
                case 240:
                    ctx.fillText('240', canvas.width / 2 - 10, 70);
                    break;
                case 270:
                    ctx.font = '30px Arial';
                    ctx.fillText('W', canvas.width / 2 - 10, 70);
                    break;
                case 300:
                    ctx.fillText('300', canvas.width / 2 - 10, 70);
                    break;
                case 330:
                    ctx.fillText('330', canvas.width / 2 - 10, 70);
                    break;
                case 360:
                    ctx.font = '30px Arial';
                    ctx.fillText('N', canvas.width / 2 - 10, 70);
                    break;
                default:
            }
            // Go until 360
            i += 10;
        }
        //Stop rotating
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((-deg * Math.PI) / 180);
        ctx.translate(-canvas.width / 2, -canvas.height / 2);

        // Draw UI
        drawUI(cnvs, ctx);
    };

    const compassWidget = async () => {
        if(!compassRef.current){
            return;
        }

        const canvas = compassRef.current;
        const ctx = canvas.getContext('2d');

        if(ctx === null){
            return;
        }

        let i = 0;

        while (demo) {
            drawWorld(canvas, ctx, i);
            await sleep(20);
            i++;
        }

        drawWorld(canvas, ctx, degree);
    };

    useEffect(() => {
        compassWidget().then();
        if (draggable) {
            const compassWrapper = new DraggableSnap(
                document.getElementById(containerID)
            );
            compassWrapper.addDragging(grid[0], grid[1]);
        }
    });

    return (
        <div id={containerID} draggable={draggable} style={divStyle}>
            <canvas
                id={compassID}
                ref={compassRef}
                width={width}
                height={height}
            />
        </div>
    );
};

export default CompassHeading;
