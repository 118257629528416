import React, {useRef, useEffect} from 'react';
import DraggableSnap from '../../js/DraggableSnap';

type Props = {
    draggable?: boolean;
    grid: [number[], number[]];
    pitch: number;
    roll: number;
    width: string;
    height: string;
    demo: boolean;
}

const Horizon = (props: Props) => {
    const {draggable = false, grid, pitch, roll, width, height, demo} = props;
    const containerRef = useRef<HTMLDivElement>(null!);
    const horizonRef = useRef<HTMLCanvasElement>(null!);
    let divStyle = {};
    if (draggable) {
        divStyle = {
            position: 'absolute',
            cursor: 'move'
        };
    }

    const colors = {
        green: '#54ED32',
        yellow: '#EDE432',
        red: '#E81C1C',
        brown: '#a8640e'
    };

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    /**
     * Draws the static UI that lies on top of the world
     * @param canvas
     * @param context
     */
    const drawUI = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
        context.fillStyle = colors.green;

        // Draw Cross hair
        context.fillRect(0, canvas.height / 2, canvas.width / 3, 7);
        context.fillRect(
            (canvas.width * 2) / 3,
            canvas.height / 2,
            canvas.width / 3,
            7
        );
        context.fillRect(canvas.width / 3 - 7, canvas.width / 2, 7, 15);
        context.fillRect((canvas.width * 2) / 3, canvas.width / 2, 7, 15);
        context.fillRect(canvas.width / 2 - 7, canvas.height / 2, 14, 7);

        // Draw Rotation Triangle
        context.beginPath();
        context.moveTo(canvas.width / 2, 10);
        context.lineTo(canvas.width / 2 - 8, 20);
        context.lineTo(canvas.width / 2 + 8, 20);
        context.fill();
    };

    /**
     * Draws the world at the pitch and roll
     * @param canvas
     * @param context
     * @param pitchDeg
     * @param rollDeg
     */
    const move = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, pitchDeg: number, rollDeg: number) => {
        // Clear the old world to redraw
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Convert the pitch to make sense in the context of the canvas
        const pitchChange = (pitchDeg + 90) / 90;

        //Controlling Roll
        const changeRoll = (-rollDeg * Math.PI) / 180;

        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate(changeRoll);
        context.translate(-canvas.width / 2, -canvas.height / 2);

        // Draw Sky
        context.fillStyle = 'blue';
        context.fillRect(
            -canvas.width / 2,
            -canvas.height / 2,
            canvas.width * 2,
            (canvas.height / 2) * pitchChange + canvas.height / 2 + 2
        );

        // Draw Ground
        context.fillStyle = colors.brown;
        context.fillRect(
            -canvas.width / 2,
            (canvas.height / 2) * pitchChange,
            canvas.width * 2,
            canvas.height * 1.5
        );

        // Draw horizon
        context.fillStyle = 'black';
        context.fillRect(
            -canvas.width / 2,
            (canvas.height / 2) * pitchChange,
            canvas.width * 2,
            1
        );

        // Draw Pitch Guides
        context.fillStyle = 'white';
        context.font = '12px Arial';
        let i = -90;
        while (i <= 90) {
            context.fillRect(
                canvas.width / 3,
                (canvas.height / 2) * pitchChange + i,
                canvas.width / 3,
                1
            );
            context.fillText(
                String(-i),
                canvas.width / 2 + 10,
                (canvas.height / 2) * pitchChange + i
            );
            i += 30;
        }

        // Draw Rotation Guides
        context.fillStyle = 'black';
        context.beginPath();
        context.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2,
            0,
            Math.PI * 2
        );
        context.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 10,
            0,
            Math.PI * 2,
            true
        );
        context.fill();
        context.fillStyle = 'white';
        context.beginPath();
        context.moveTo(canvas.width / 2 - 8, 1);
        context.lineTo(canvas.width / 2 + 8, 1);
        context.lineTo(canvas.width / 2, 10);
        context.fill();

        // Stop controlling Roll
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate(-changeRoll);
        context.translate(-canvas.width / 2, -canvas.height / 2);

        drawUI(canvas, context);
    };

    /**
     * The world is drawn as a specific pitch and roll then the UI is drawn so it does not rotate too.
     * @param canvas, the canvas to draw on
     * @param context, the 2D context to draw with
     * @param pitchDeg, amount of degrees to pitch (-90, 90)
     * @param rollDeg, amount of degrees to rotate (-90, 90)
     */
    const drawWorld = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, pitchDeg: number, rollDeg: number) => {
        move(canvas, context, pitchDeg, rollDeg);
        drawUI(canvas, context);
    };

    /**
     * This is where the Horizon widget starts. It will either go through a demo of full functionality, or
     * it will display the horizon at a specific pitch and roll.
     * @returns {Promise<void>}
     */
    const horizonWidget = async () => {
        // Get the canvas and its context. We will always be manipulating it.
        const canvas = horizonRef.current;
        if(!canvas){
            return;
        }

        const ctx = canvas.getContext('2d');
        if(!ctx){
            return;
        }

        // If the the demo is on, go through full motion, else
        if (demo) {
            // Set aside iterators for pitch and roll
            // Create a flag to keep track of how many times we pass 0
            // Create a stage indicator to control what stage of the demo we are on
            // Up indicates if we iterate in the positive or negative direction.
            let i = 0;
            let j = 0;
            let flag = 0;
            let stage = 0;
            let up = true;
            while (demo) {
                switch (stage) {
                    case 0:
                        // Draw the world as is
                        drawWorld(canvas, ctx, i, 0);

                        // If it is indicated we are iterating up, iterate up. Else, iterate down. Duh
                        if (up) {
                            i++;
                        } else {
                            i--;
                        }

                        // If we were iterating up but we have met or exceeded our boundary, change the direction
                        // we want to iterate and start iterating the opposite direction
                        if (i >= 90 && up) {
                            up = false;
                            i -= 2;
                        } else if (i <= -90 && !up) {
                            up = true;
                            i += 2;
                        }

                        // If we pass the horizon, keep track that we passed it.
                        if (i === 0) {
                            flag++;
                            // If we have met the horizon twice, move to the next stage
                            if (flag === 2) {
                                stage = 1;
                                flag = 0;
                            }
                        }

                        // Minor pause
                        await sleep(20);
                        break;

                    // Case 1 and 2 are the same but on different axes
                    case 1:
                        drawWorld(canvas, ctx, 0, j);
                        if (up) {
                            j++;
                        } else {
                            j--;
                        }
                        if (j >= 90 && up) {
                            up = false;
                            j -= 2;
                        } else if (j <= -90 && !up) {
                            up = true;
                            j += 2;
                        }
                        if (j === 0) {
                            flag++;
                            if (flag === 2) {
                                stage = 2;
                                flag = 0;
                            }
                        }

                        await sleep(20);
                        break;
                    case 2:
                        drawWorld(canvas, ctx, i, j);
                        if (up) {
                            i++;
                            j++;
                        } else {
                            i--;
                            j--;
                        }
                        if ((j >= 90 || i >= 90) && up) {
                            up = false;
                            i -= 2;
                            j -= 2;
                        } else if ((j <= -90 || i <= -90) && !up) {
                            up = true;
                            i += 2;
                            j += 2;
                        }
                        if (i === 0 || j === 0) {
                            flag++;
                            if (flag === 2) {
                                stage = 0;
                                flag = 0;
                            }
                        }
                        await sleep(20);
                        break;
                    default:
                        stage = 0;
                }
            }
        } else {
            drawWorld(canvas, ctx, pitch, roll);
        }
    };

    useEffect(() => {
        horizonWidget().then();
        if (draggable) {
            const horizonWrapper = new DraggableSnap(
                containerRef.current
            );
            horizonWrapper.addDragging(grid[0], grid[1]);
        }
    });

    return (
        <div ref={containerRef} draggable={draggable} style={divStyle}>
            <canvas
                ref={horizonRef}
                width={width}
                height={height}
                style={{border: '5px solid #000000'}}
            />
        </div>
    );
};

export default Horizon;
