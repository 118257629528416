import React, {useLayoutEffect, useEffect, useState, useRef, useContext} from 'react';
import '../../img.css';
import PropTypes from 'prop-types';
import massiveMap from '../../images/mapCropped.png';
import {adjustData, drawRoute} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';

const ZoomedImage = props => {
    const {
        graphData,
        maxMinData,
        imgHeight,
        imgWidth,
        xPos,
        yPos,
        scale,
        resultSize,
        resultPos,
        lines,
    } = props;

    const {widgetState, locationData} = useContext(ClientContext);
    const [points, setPoints] = useState(() => lines.map(() => ''));
    const isLoadingRef = useRef(true);

    const multiplier = scale * resultSize.width / imgWidth;

    const bounds = {
        //[maxLat, minLat, maxLon, minLon]
        maxLat: maxMinData[0],
        minLat: maxMinData[1],
        maxLon: maxMinData[2],
        minLon: maxMinData[3],
    };

    useEffect(() => {
        drawRoute(imgWidth, imgHeight, bounds, graphData, lines, points, setPoints);
    }, [graphData]);

    useEffect(() => {
        if(!isLoadingRef.current || widgetState.time === undefined){
            return;
        }
        const oldData = adjustData(0, widgetState.time, locationData.slice(0, widgetState.time + 1), widgetState.prevAdjustments);
        drawRoute(imgWidth, imgHeight, bounds, oldData, lines, points, setPoints);

    }, [widgetState.time])

    useLayoutEffect(() => {
        const result = document.querySelector('#result');

        /* Display what the lens "sees": */
        result.scrollLeft = xPos * imgWidth * multiplier;
        result.scrollTop = yPos * imgHeight * multiplier;
    }, [xPos, yPos, scale]);

    return (
        <div style={{height: imgHeight, width: imgWidth, position: 'relative'}}>
            <div id="result" style={{...resultSize, ...resultPos}}>
                <div style={{transformOrigin: '0 0', position: 'relative', transform: `scale(${multiplier}, ${multiplier})`}}>
                    <svg className="mapSVG" style={{height: imgHeight, width: imgWidth}}>
                        {
                            lines.map((line, index) => {
                                return (
                                    <polyline
                                        key={index}
                                        fill="none"
                                        stroke={line.color}
                                        strokeWidth={1}
                                        points={points[index]}
                                        // markerEnd="url(#triangle)"
                                    />
                                );
                            })
                        }
                    </svg>
                    <img height={imgHeight} width={imgWidth} src={massiveMap} alt="" />
                </div>
            </div>
        </div>
    );
};

ZoomedImage.defaultProps = {
    maxMinData: ['', '', '', ''],
    imgHeight: '',
    imgWidth: '',
    resultPos: {bottom: 0, left: 0},
    resultSize: {height: '200px', width: '200px'},
};

ZoomedImage.propTypes = {
    graphData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))).isRequired,
    xPos: PropTypes.number.isRequired,
    yPos: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    maxMinData: PropTypes.arrayOf(PropTypes.string),
    imgHeight: PropTypes.number,
    imgWidth: PropTypes.number,
    resultSize: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    resultPos: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default ZoomedImage;
