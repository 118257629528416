import React, {useState, useLayoutEffect, useRef} from 'react';

import DraggableSnap from '../../js/DraggableSnap';

/**
 * UUID helps with applying DraggableSnap to the correct component
 */
const {v4: uuidv4} = require('uuid');

const canvasStyle = {border: '2px solid #000000'};

type Props = {
    grid?: [number[], number[]];
    left?: number;
    top?: number;
    draggable?: boolean;
    radius?: number;
    data?: number;
    bounds: {[level: number]: string}
}

/**
 *
 * @param grid: attach the grid of the screen for draggable snapping
 * @param left: left position
 * @param top: top position
 * @param draggable: bool if status can be dragged
 * @param radius: defines the radius of the circle
 * @param data
 * @param bounds
 * @returns {JSX.Element}
 * @constructor
 */
const Status = ({grid = [[], []], left = 0, top = 0, draggable = false, radius = 12, data = 0, bounds}: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasID = useRef(`StatusCanvas_${new Date().getTime()}`!);
    const containerID = useRef(uuidv4()!);

    const [divStyle, setDivStyle] = useState({});

    /**
     * StatusWidget is implemented via HTML5 Canvas.
     * Status will display a Rectangle by default if 'type' is not defined.
     */
    const statusWidget = () => {
        if(!canvasRef.current){
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if(context === null){
            return;
        }

        const boundsVals = Object.keys(bounds);
        let color = '';
        boundsVals.some(limit => {
            if(data <= Number(limit)){
                color = bounds[Number(limit)];
                return true;
            }
            return false;
        });
        if(!color){
            color = bounds[Number(boundsVals[boundsVals.length - 1])];
        }

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = color;
        context.beginPath();
        context.arc(
            canvas.width / 2,
            canvas.height / 2,
            radius,
            0,
            2 * Math.PI
        );
        context.fill();
    };

    /**
     * After the component has mounted, Start the status widget and recall each update.
     */
    useLayoutEffect(() => {
        statusWidget();
    });

    /**
     * After the component has mounted, apply dragging to the widget.
     */
    useLayoutEffect(() => {
        if(draggable){
            const statWrapper = new DraggableSnap(
                document.getElementById(containerID.toString())
            );
            statWrapper.addDragging(grid[0], grid[1]);
            setDivStyle({
                position: 'absolute',
                top: top + 'px',
                left: left + 'px',
                cursor: 'move'
            });
        }
    }, []);

    return (
        <div
            id={containerID.current}
            draggable="true"
            style={divStyle}
        >
            <canvas
                id={canvasID.current}
                ref={canvasRef}
                width={radius * 2}
                height={radius * 2}
                style={canvasStyle}
            />
        </div>
    );

};


export default Status;
