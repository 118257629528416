import React, {useContext} from 'react';
import {createGrid, useWindowSize} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';
import Table from '../widgets/table';
import {basicTbl, finEnableStatus, finPosition, finStatus} from '../../helpers/tblConfigBCAS';
import LineChartRechart from '../widgets/lineChartRechart';

const gridArray = createGrid(100);

const BCAS = () => {
    const screenSize = useWindowSize();
    const {widgetState} = useContext(ClientContext);

    return (
        <>
            <div className="blackBody"/>

            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: 'Courier New, sans-serif',
                }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute'
                    }}
                    >
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                            height: 'fit-content',
                            background: 'green',
                            textAlign: 'center',
                        }}
                        >
                            <p style={{color: 'black'}}>Unclassified</p>
                        </div>
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                        }}
                        >
                            <p style={{color: 'rgb(87, 168, 101)'}}>Missile - BTF-2 Ver. 3.1 2/17/2022</p>
                        </div>
                    </div>

                    <div style={{
                        border: '1px solid white',
                        blockSize: 'fit-content',
                        marginLeft: '800px',
                        width: 'fit-content',
                        justifyContent: 'center',
                    }}
                    >
                        <h1 style={{
                            color: 'red',
                            textDecoration: 'underline',
                            fontFamily: 'Courier New, -sans-serif',
                            fontSize: '3rem',
                            whiteSpace: 'nowrap',
                        }}
                        >
                            BCAS
                        </h1>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '5px',
                        paddingLeft: '300px',
                    }}
                    >
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={basicTbl}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            // tableHeight={390}
                            tableWidth={350}
                            textColor="yellow"
                            textSize="1rem"
                            underlineHeader="underline"
                            headColor="yellow"
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <LineChartRechart
                            shouldShift
                            chartTitle="Upper Left Fin"
                            baseYDomain={[-30, 30]}
                            baseYTicks={[-30, -15, 0, 15, 30]}
                            yMajorLines={[-30, -15, 0, 15, 30]}
                            axisColor="red"
                            backgroundColor="rgb(173,173,173)"
                            innerChartWidth={930}
                            innerChartHeight={200}
                            outerChartWidth={950}
                            outerChartHeight={230}
                            lines={[{key: 'batVolt', color: 'darkred'}]}
                            gridColor="rgb(102,102,102)"
                            gridLineColor="white"
                            majorGridLineColor="darkred"
                            data={widgetState.lineChart.data}
                            dataPointsShown={30}
                            referenceMultiple={10}
                            minorReferenceMultiple={1}
                            lightBorder="rgb(151, 192, 207)"
                            darkBorder="rgb(72, 98, 107)"
                            buttons
                            margin={{top: 0, right: 10, bottom: 5, left: -10}}
                            showLatestPoint
                            xKey=""
                            tickFontSize="1.2em"
                            xLabel={{text: ''}}
                            yLabel={{text: ''}}
                        />
                        <LineChartRechart
                            shouldShift
                            chartTitle="Upper Right Fin"
                            baseYDomain={[-30, 30]}
                            baseYTicks={[-30, -15, 0, 15, 30]}
                            yMajorLines={[-30, -15, 0, 15, 30]}
                            axisColor="red"
                            backgroundColor="rgb(173,173,173)"
                            innerChartWidth={930}
                            innerChartHeight={200}
                            outerChartWidth={950}
                            outerChartHeight={230}
                            lines={[{key: 'batVolt', color: 'darkred'}]}
                            gridColor="rgb(102,102,102)"
                            gridLineColor="white"
                            majorGridLineColor="darkred"
                            data={widgetState.lineChart.data}
                            dataPointsShown={30}
                            referenceMultiple={10}
                            minorReferenceMultiple={1}
                            lightBorder="rgb(151, 192, 207)"
                            darkBorder="rgb(72, 98, 107)"
                            buttons
                            margin={{top: 0, right: 10, bottom: 5, left: -10}}
                            showLatestPoint
                            xKey=""
                            tickFontSize="1.2em"
                            xLabel={{text: ''}}
                            yLabel={{text: ''}}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <LineChartRechart
                            shouldShift
                            chartTitle="Lower Left Fin"
                            baseYDomain={[-30, 30]}
                            baseYTicks={[-30, -15, 0, 15, 30]}
                            yMajorLines={[-30, -15, 0, 15, 30]}
                            axisColor="red"
                            backgroundColor="rgb(173,173,173)"
                            innerChartWidth={930}
                            innerChartHeight={200}
                            outerChartWidth={950}
                            outerChartHeight={230}
                            lines={[{key: 'batVolt', color: 'darkred'}]}
                            gridColor="rgb(102,102,102)"
                            gridLineColor="white"
                            majorGridLineColor="darkred"
                            data={widgetState.lineChart.data}
                            dataPointsShown={30}
                            referenceMultiple={10}
                            minorReferenceMultiple={1}
                            lightBorder="rgb(151, 192, 207)"
                            darkBorder="rgb(72, 98, 107)"
                            buttons
                            margin={{top: 0, right: 10, bottom: 5, left: -10}}
                            showLatestPoint
                            xKey=""
                            tickFontSize="1.2em"
                            xLabel={{text: ''}}
                            yLabel={{text: ''}}
                        />
                        <LineChartRechart
                            shouldShift
                            chartTitle="Upper Right Fin"
                            baseYDomain={[-30, 30]}
                            baseYTicks={[-30, -15, 0, 15, 30]}
                            yMajorLines={[-30, -15, 0, 15, 30]}
                            axisColor="red"
                            backgroundColor="rgb(173,173,173)"
                            innerChartWidth={930}
                            innerChartHeight={200}
                            outerChartWidth={950}
                            outerChartHeight={230}
                            lines={[{key: 'batVolt', color: 'darkred'}]}
                            gridColor="rgb(102,102,102)"
                            gridLineColor="white"
                            majorGridLineColor="darkred"
                            data={widgetState.lineChart.data}
                            dataPointsShown={30}
                            referenceMultiple={10}
                            minorReferenceMultiple={1}
                            lightBorder="rgb(151, 192, 207)"
                            darkBorder="rgb(72, 98, 107)"
                            buttons
                            margin={{top: 0, right: 10, bottom: 5, left: -10}}
                            showLatestPoint
                            xKey=""
                            tickFontSize="1.2em"
                            xLabel={{text: ''}}
                            yLabel={{text: ''}}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={finEnableStatus}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={200}
                            tableWidth={250}
                            textColor="yellow"
                            textSize="1rem"
                            underlineHeader="underline"
                            headColor="yellow"
                        />
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={finPosition}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding="5px"
                            tableHeight={200}
                            tableWidth={500}
                            textColor="yellow"
                            textSize="1rem"
                            underlineHeader="underline"
                            headColor="yellow"
                        />
                    </div>
                    <div>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={finStatus}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={230}
                                tableWidth={2020}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BCAS;