import React, {useRef} from 'react';
import {barDescriptors, radarEvents, radarStatuses, radarStatusesReadable, radarEventsReadable} from '../../enums';
import {BarColors, RadarStatusKeys} from '../../helpers/types';

type ColorType = typeof barDescriptors.RADAR_STATUSES | typeof barDescriptors.RADAR_EVENTS;

type Props = {
    colors: BarColors;
    updateColors: (type: ColorType, status: RadarStatusKeys, color: string) => void;
}

const RadarColors = ({colors, updateColors}: Props) => {
    const uniqueRef = useRef(performance.now());

    if(!Object.keys(colors).length){
        return (
            <div/>
        );
    }

    return (
        <form className="colorForm">
            <h4 style={{textAlign: 'center'}}>Radar</h4>
            <div style={{display: 'flex', padding: '10px'}}>
                <datalist id={`suggestedColors${uniqueRef.current}`}>
                    <option>#ffffff{/*white*/}</option>
                    <option>#000000{/*black*/}</option>
                    <option>#ff0000{/*red*/}</option>
                    <option>#00ff00{/*green*/}</option>
                    <option>#0000ff{/*blue*/}</option>
                    <option>#ffff00{/*yellow*/}</option>
                    <option>#ffa500{/*orange*/}</option>
                </datalist>
                <table style={{margin: '10px'}}>
                    <thead>
                    <tr>
                        <th>Statuses</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(radarStatuses).map(status => {
                            return (
                                <tr key={status}>
                                    <td>{radarStatusesReadable[status]}:</td>
                                    <td>
                                        <input
                                            onInput={(e) => updateColors(barDescriptors.RADAR_STATUSES, status, e.currentTarget.value)}
                                            type="color"
                                            list={`suggestedColors${uniqueRef.current}`}
                                            value={colors[barDescriptors.RADAR_STATUSES][status]}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
                <table style={{margin: '10px', height: '100%'}}>
                    <thead>
                    <tr>
                        <th>Events</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(radarEvents).map(event => {
                            return (
                                <tr key={event}>
                                    <td>{radarEventsReadable[event]}:</td>
                                    <td>
                                        <input
                                            onInput={(e) => updateColors(barDescriptors.RADAR_EVENTS, event, e.currentTarget.value)}
                                            type="color"
                                            list={`suggestedColors${uniqueRef.current}`}
                                            value={colors[barDescriptors.RADAR_EVENTS][event]}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default RadarColors;
