import React, {useState} from 'react';
import kwaj from '../../images/kwajBlackedOut.png';
import {isInside} from '../../helpers/isInside';
import skull from '../../images/skull.png';
import smiley from '../../images/smileyFace.png';

type Props = {
    points: {x: number; y: number;}[][],
    circleX?: string;
    circleY?: string;
}

function MapLines({points, circleX, circleY}: Props){
    const [scale, setScale] = useState(1);

    function scaleUp(){
        setScale(scale + .2);
    }

    function scaleDown(){
        if(scale > 1){
            setScale(scale - .2);
        }
    }

    function moveLeft(event: React.MouseEvent<HTMLButtonElement>){
        const scrollable = event.currentTarget.closest('.scrollContainer')?.querySelector('.scrollable');
        if(!scrollable){
            return;
        }
        scrollable.scrollLeft = scrollable.scrollLeft - 10;
    }

    function moveRight(event: React.MouseEvent<HTMLButtonElement>){
        const scrollable = event.currentTarget.closest('.scrollContainer')?.querySelector('.scrollable');
        if(!scrollable){
            return;
        }
        scrollable.scrollLeft = scrollable.scrollLeft + 10;
    }

    function moveUp(event: React.MouseEvent<HTMLButtonElement>){
        const scrollable = event.currentTarget.closest('.scrollContainer')?.querySelector('.scrollable');
        if(!scrollable){
            return;
        }
        scrollable.scrollTop = scrollable.scrollTop - 10;
    }

    function moveDown(event: React.MouseEvent<HTMLButtonElement>){
        const scrollable = event.currentTarget.closest('.scrollContainer')?.querySelector('.scrollable');
        if(!scrollable){
            return;
        }
        scrollable.scrollTop = scrollable.scrollTop + 10;
    }

    function getUniquePoints(lines: {x: number; y: number;}[][]){
        if(!lines.length){
            return [];
        }

        const uniquePoints = [lines[0][0], lines[0][1]]; // first & second point
        let pointToFind = lines[0][1]; // secondPoint
        while(uniquePoints.length < lines.length) {
            lines.some((line) => {
                return line.some((point, index) => {
                    if(point.x === pointToFind.x && point.y === pointToFind.y){
                        if(index === 0){
                            const alreadyFound = uniquePoints.some(uniquePoint => uniquePoint.x === line[1].x && uniquePoint.y === line[1].y);
                            if(!alreadyFound){
                                uniquePoints.push(line[1]);
                                pointToFind = line[1];
                                return true;
                            }
                        } else{
                            const alreadyFound = uniquePoints.some(uniquePoint => uniquePoint.x === line[0].x && uniquePoint.y === line[0].y);
                            if(!alreadyFound){
                                uniquePoints.push(line[0]);
                                pointToFind = line[0];
                                return true;
                            }
                        }
                    }
                    return false;
                });
            });
        }

        return uniquePoints.map(point => {
            return {
                x: point.x * (document.querySelector('#map')?.clientWidth || 1),
                y: point.y * (document.querySelector('#map')?.clientHeight || 1)
            };
        });
    }

    return (
        <div className="scrollContainer" style={{position: 'relative'}}>
            <div className="scrollable" style={{position: 'relative', overflow: 'hidden'}}>
                <div style={{transformOrigin: 'top left', transform: `scale(${scale})`}}>
                    <img src={kwaj} alt="kwaj" style={{width: '100%'}}/>
                    <svg id="map"
                         style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                    >
                        {
                            points.map((line, index) => (
                                <polyline
                                    key={index}
                                    fill="none"
                                    stroke="red"
                                    strokeWidth={1}
                                    points={line.map(point => (
                                        point.x * (document.querySelector('#map')?.clientWidth || 1) + ' ' + point.y * (document.querySelector('#map')?.clientHeight || 1)
                                    )).join(', ')}
                                />
                            ))
                        }
                        {
                            circleX && circleY &&
                            /*<circle cx={circleX} cy={circleY} r="5"
                                    fill={isInside(getUniquePoints(points), points.length, {
                                        x: circleX,
                                        y: circleY
                                    }) ? 'red' : 'green'}
                            />*/
                            <image x={Number(circleX) * (document.querySelector('#map')?.clientWidth || 1) - 10}
                                   y={Number(circleY) * (document.querySelector('#map')?.clientHeight || 1) - 10} /*shift position to (roughly) center of image*/
                                   href={isInside(getUniquePoints(points), points.length, {
                                       x: Number(circleX) * (document.querySelector('#map')?.clientWidth || 1),
                                       y: Number(circleY) * (document.querySelector('#map')?.clientHeight || 1),
                                   }) ? skull : smiley}
                                   width="20"
                            />
                        }
                    </svg>
                </div>
            </div>
            <div style={{position: 'absolute', bottom: 0, left: 0}}>
                <button onClick={scaleDown}>-</button>
                <button onClick={scaleUp}>+</button>
                <button onClick={moveLeft}>&#8592;</button>
                <button onClick={moveRight}>&#8594;</button>
                <button onClick={moveUp}>&#8593;</button>
                <button onClick={moveDown}>&#8595;</button>
            </div>
        </div>
    );
}

export default MapLines;
