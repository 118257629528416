import {useContext, useEffect, useState} from 'react';
import ClientContext from '../helpers/ClientContext';

const oneDay = 1000 * 60 * 60 * 24;
const oneHour = 1000 * 60 * 60;

const useMissionClock = (time: number, utcTime: number, isHolding: boolean, includeDays = false) => {
    const {configuration} = useContext(ClientContext);
    const [items, setItems] = useState({utcString: '', tMinusString: '', liftoffString: '', isLaunched: false});

    /**
     *
     * @param timeString
     * @returns {number[]}
     */
    function splitString(timeString: String){
        const arr = timeString.split(/[:.]/);
        return [parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4])];
    }

    function getTotalMilliseconds(days: number, hours: number, minutes: number, seconds: number, miliseconds: number){
        return oneDay * days + oneHour * hours + (1000 * 60) * minutes + 1000 * seconds + miliseconds;
    }

    function getTimeString(totalMilliseconds: number, includeDays: boolean){
        const days = Math.floor(totalMilliseconds / oneDay);
        totalMilliseconds -= days * oneDay;
        const hours = Math.floor(totalMilliseconds / oneHour);
        totalMilliseconds -= hours * oneHour;
        const minutes = Math.floor(totalMilliseconds / (1000 * 60));
        totalMilliseconds -= minutes * (1000 * 60);
        const seconds = Math.floor(totalMilliseconds / 1000);
        totalMilliseconds -= seconds * 1000;
        const miliseconds = Math.floor(totalMilliseconds);

        let noDays = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${miliseconds.toString().padStart(3, '0')}`;
        if(includeDays){
            noDays = `${days.toString().padStart(3, '0')}:${noDays}`;
        }

        return noDays;
    }

    useEffect(() => {
        /**********Count up on UTC time**********/
        const seconds = configuration.increment * utcTime;
        const tMinusSeconds = configuration.increment * time;

        const [utcDay, utcHour, utcMinute, utcSecond, utcMilSecond] = splitString(configuration.utc);

        let utcTotalMilSeconds = getTotalMilliseconds(utcDay, utcHour, utcMinute, utcSecond, utcMilSecond);
        utcTotalMilSeconds += seconds * 1000;
        let liftoffTotalMilSeconds = utcTotalMilSeconds;

        const utcString = getTimeString(utcTotalMilSeconds, true);

        /**********Count down on t-minus**********/
        const [tMinusDay, tMinusHour, tMinusMinute, tMinusSecond, tMinusMilSecond] = splitString('0:' + configuration.tMinus);

        let tMinusTotalMilSeconds = getTotalMilliseconds(tMinusDay, tMinusHour, tMinusMinute, tMinusSecond, tMinusMilSecond);
        tMinusTotalMilSeconds -= tMinusSeconds * 1000;
        liftoffTotalMilSeconds += tMinusTotalMilSeconds;
        const isLaunched = tMinusTotalMilSeconds <= 0;
        tMinusTotalMilSeconds = Math.abs(tMinusTotalMilSeconds);

        const tMinusString = getTimeString(tMinusTotalMilSeconds, includeDays);

        /**********Calculate Liftoff**********/
        let liftoffString = 'Holding';

        if(!isHolding || isLaunched){
            liftoffString = getTimeString(liftoffTotalMilSeconds, includeDays);
        }

        setItems({utcString, tMinusString, liftoffString, isLaunched});
    }, [time, utcTime]);

    return items;
};

export default useMissionClock;
