import {useState, useLayoutEffect} from 'react';
import {csvFiles} from '../enums';

export const firebaseConfig = {
    apiKey: 'AIzaSyAlu0TjudIlU67OH7WvgJjn2t077YaKJXA',
    authDomain: 'itac-c25d2.firebaseapp.com',
    projectId: 'itac-c25d2',
    storageBucket: 'itac-c25d2.appspot.com',
    messagingSenderId: '620991585406',
    appId: '1:620991585406:web:ba55295ea4ac7dba0b0c2f'
};

/**
 * @author Jason Carnahan
 * @param divisions: number of divisions to divide the screen
 * @returns {[]}: the divisions will be in the same aspect ratio as the monitor
 */
export function createGrid(divisions){
    // x will store all the x-axis division locations
    // y will store all the y-axis division locations
    // z will store both of these axes and return them
    const x = [];
    const y = [];
    const z = [];

    for (let i = 1; i <= divisions; i++) {
        x.push((i / (divisions + 1)) * window.innerWidth);
        y.push((i / (divisions + 1)) * window.innerHeight);
    }
    z.push(x);
    z.push(y);
    return z;
}

/**
 * Returns a random number between 0 and Max
 * @param max
 * @returns {number}
 */
export function getRandomInt(max){
    return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Returns the string of a random number between Min and Max with 2 decimal precision
 * @param min
 * @param max
 * @returns {string}
 */
export function randRange(min, max){
    return (Math.random() * (max - min) + min).toFixed(2);
}

function getOffset(data, adjustment){
    let original = data;
    const isNull = original === undefined || original === null || original === '' || original === '-';
    if(isNull){
        original = 0;
    }

    let decimals = 0;
    if(isNull){
        const decimalSplice = adjustment.value.split('.')[1];
        if(decimalSplice){
            decimals = decimalSplice.length;
        }
    } else{
        const decimalArr = original.split('.')[1];
        if(!decimalArr){
            decimals = 0;
        } else{
            decimals = decimalArr.length;
        }
    }

    return (Number(original) + Number(adjustment.value)).toFixed(decimals);
}

export function adjustData(minTime, time, locationData, prevAdjustments){
    const adjustedData = [];
    for (let i = minTime; i <= time; i++) {
        const dataCopy = JSON.parse(JSON.stringify(locationData[i]));

        if(prevAdjustments[i]){
            Object.entries(prevAdjustments[i]).forEach(([key, adjustedVal]) => {
                dataCopy[key] = adjustedVal;
            });
        }

        adjustedData.push(dataCopy);
    }

    return adjustedData;
}

export const widgetReducer = (state, action) => {
    const {message, locationData} = action;
    const dataFromServer = JSON.parse(message.data);

    if(dataFromServer.type === 'locations'){
        state = {
            ...state
        };
        state.locations = dataFromServer.data;
        return state;
    }

    if(dataFromServer.data?.widgets){
        const {time} = dataFromServer.data.widgets;
        const curLocationData = locationData[time]
            ? [locationData[time]]
            : [{
                time: -1,
                utcTime: -1,
                roll: -1,
                pitch: -1,
                yaw: -1,
                batVolt: -1,
                curDraw: -1,
                CDRsig: -1,
                velocity: -1,
                lat: -1,
                lon: -1,
                rand: -1,
                ranc: -1,
                alt: -1,
                weapon: -1,
                keepOutX: -1,
                keepOutY: -1,
                ftrOneGo: -1,
                ftrOneCmdGo: -1,
                ftrOneSigStr: -1,
                tmXmtrTck69: -1,
                srmOneDome: -1,
                aurReady: -1,
                fcasEcuOp: -1,
                vmcGood: -1,
                gyroGood: -1,
                ftrTwoGo: -1,
                ftrTwoCmdGo: -1,
                ftrTwoSigStr: -1,
                ftrOneLTck: -1,
                srmTwoDome: -1,
                ofpThroughput: -1,
                discretesInExpected: -1,
                gpsOperational: -1,
                pduGood: -1,
                navStatus: -1,
                esadAPostGo: -1,
                esadBPostGo: -1,
                ftsOneBattVolt: -1,
                ftsTwoBattVolt: -1,
                pduVolts: -1,
                srmDomePs: -1,
                ofpCsum: -1,
                discretesOutExpected: -1,
                imuGood: -1,
                pduSwitchExpected: -1,
                finsUnlock: -1,
                stageOneFlightMode: -1,
                stageTwoFlightMode: -1,
                stageThreeFlightMode: -1,
                stageOneSrm: -1,
                ftsBattTck: -1,
                ftsOneWireCutterRelay: -1,
                weaponState: -1,
                bcasStageOneApSafe: -1,
                stageTwoSrm: -1,
                ftsBattR: -1,
                ftsTwoWireCutterRelay: -1,
                configVersion: -1,
                bcasStageTwoApSafe: -1,
                stageThreeSrm: -1,
                mduTck: -1,
                vmcTck: -1,
                pidOn: -1,
                bcasStageThreeApSafe: -1,
            }];

        state = {
            ...state,
            ...dataFromServer.data.widgets,
        };
        state.location.originalData = curLocationData;
        const {minTime, prevAdjustments, adjustments} = state;

        const adjustedData = {};
        Object.keys(curLocationData[0]).forEach(key => {
            if(!prevAdjustments[time]){
                prevAdjustments[time] = {};
            }

            let original = curLocationData[0][key];
            const adjustment = adjustments[key];

            if(!adjustments[key]){
                adjustedData[key] = original;
            } else if(adjustments[key].type === 'overwrite'){
                prevAdjustments[time][key] = adjustment.value;
                adjustedData[key] = adjustment.value;
            } else if(adjustment.type === 'offset' && adjustment.value !== 0){
                const offset = getOffset(original, adjustment);
                prevAdjustments[time][key] = offset;
                adjustedData[key] = offset;
            } else if(adjustment.type === 'drift'){
                if(prevAdjustments[time - 1] && prevAdjustments[time - 1][key]){
                    original = prevAdjustments[time - 1][key];
                }
                const offset = getOffset(original, adjustment);
                prevAdjustments[time][key] = offset;
                adjustedData[key] = offset;
            } else{
                adjustedData[key] = original;
            }
        });

        if(minTime === null){
            state.location.data = [adjustedData];
        } else{
            state.location.data = adjustData(minTime, time, locationData, prevAdjustments);
        }
    }

    return state;
};

export const useWindowSize = () => {
    const [size, setSize] = useState({height: window.innerHeight, width: window.innerWidth});
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize({height: window.innerHeight, width: window.innerWidth});
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

/**
 *
 * @param imgWidth
 * @param imgHeight
 * @param bounds
 * @param graphData
 * @param lines
 * @param points
 * @param setPoints
 */
export const drawRoute = (imgWidth, imgHeight, bounds, graphData, lines, points, setPoints) => {
    /**
     *
     * @param x
     * @returns {number}
     */
    const getX = (x) => {
        const position = (x - bounds.minLon) / (bounds.maxLon - bounds.minLon);
        return imgWidth * position;
    };

    /**
     *
     * @param y
     * @returns {number}
     */
    const getY = (y) => {
        const position = (y - bounds.minLat) / (bounds.maxLat - bounds.minLat);
        return imgHeight * position;
    };

    if(graphData.length > 1){
        const newPoints = [];

        lines.forEach(line => {
            let curPoints = '';
            if(line.xKey === 'lon'){
                graphData[0].preLatLons?.forEach(latLon => {
                    const endX = getX(latLon.lon);
                    const endY = getY(latLon.lat);

                    if(curPoints === ''){
                        curPoints = `${endX} ${endY}`;
                    } else{
                        curPoints += `, ${endX} ${endY}`;
                    }
                });
            }
            graphData.forEach((point, index) => {
                if(!point){
                    return;
                }

                if(!index || (point[line.xKey] !== 0 && !point[line.xKey]) || (point[line.yKey] !== 0 && !point[line.yKey])){
                    return;
                }

                const endX = getX(point[line.xKey]);
                const endY = getY(point[line.yKey]);

                if(Number.isNaN(endX) || Number.isNaN(endY)){
                    return;
                }

                if(curPoints === ''){
                    curPoints = `${endX} ${endY}`;
                } else{
                    curPoints += `, ${endX} ${endY}`;
                }
            });

            newPoints.push(curPoints);
        });

        setPoints(newPoints);
    } else{
        const newPoints = [];
        const newPoint = graphData[graphData.length - 1];
        lines.forEach((line, lineIndex) => {
            let newLinePoints = points[lineIndex];
            if(line.xKey === 'lon' && graphData[0].time === 1){
                graphData[0].preLatLons?.forEach(latLon => {
                    const endX = getX(latLon.lon);
                    const endY = getY(latLon.lat);

                    if(newLinePoints === ''){
                        newLinePoints = `${endX} ${endY}`;
                    } else{
                        newLinePoints += `, ${endX} ${endY}`;
                    }
                });
            }
            if(newPoint[line.xKey] === -1 || newPoint[line.yKey] === -1 || (newPoint[line.xKey] !== 0 && !newPoint[line.xKey]) || (newPoint[line.yKey] !== 0 && !newPoint[line.yKey])){
                newPoints.push(points[lineIndex]); // don't add any points
                return;
            }

            const endX = getX(newPoint[line.xKey]);
            const endY = getY(newPoint[line.yKey]);
            if(Number.isNaN(endX) || Number.isNaN(endY)){
                newPoints.push(points[lineIndex]); // don't add any points
                return;
            }

            if(newLinePoints !== ''){
                newLinePoints += (', ' + endX + ' ' + endY);
            } else{
                newLinePoints += (endX + ' ' + endY);
            }
            newPoints.push(newLinePoints);
        });

        setPoints(newPoints);
    }
};

/**
 *
 * @param arr
 * @param key
 * @returns {number|*}
 */
export const getEndOfArray = (arr, key) => {
    const end = arr[arr.length - 1];

    if(!end){
        return -1;
    }

    return end[key];
};

export const getTMinusTime = (time, configuration, includeDays = false) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const oneHour = 1000 * 60 * 60;

    /**
     *
     * @param timeString
     * @returns {number[]}
     */
    function splitString(timeString){
        const arr = timeString.split(/[:.]/);
        return [parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]), parseInt(arr[3])];
    }

    /**
     *
     * @param days
     * @param hours
     * @param minutes
     * @param seconds
     * @param miliseconds
     * @returns {*}
     */
    function getTotalMiliseconds(days, hours, minutes, seconds, miliseconds){
        return oneDay * days + oneHour * hours + (1000 * 60) * minutes + 1000 * seconds + miliseconds;
    }

    /**
     *
     * @param totalMiliseconds
     * @param includeDays
     * @returns {string}
     */
    function getTimeString(totalMiliseconds, includeDays){
        const days = Math.floor(totalMiliseconds / oneDay);
        totalMiliseconds -= days * oneDay;
        const hours = Math.floor(totalMiliseconds / oneHour);
        totalMiliseconds -= hours * oneHour;
        const minutes = Math.floor(totalMiliseconds / (1000 * 60));
        totalMiliseconds -= minutes * (1000 * 60);
        const seconds = Math.floor(totalMiliseconds / 1000);
        totalMiliseconds -= seconds * 1000;
        const miliseconds = Math.floor(totalMiliseconds);

        let noDays = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString()
                                                                                                           .padStart(2, '0')}.${miliseconds.toString()
                                                                                                                                           .padStart(3, '0')}`;
        if(includeDays){
            noDays = `${days.toString().padStart(3, '0')}:${noDays}`;
        }

        return noDays;
    }

    const tMinusSeconds = configuration.increment * Number(time);

    const [tMinusHour, tMinusMinute, tMinusSecond, tMinusMilSecond] = splitString(configuration.tMinus);

    let tMinusTotalMilSeconds = getTotalMiliseconds(0, tMinusHour, tMinusMinute, tMinusSecond, tMinusMilSecond);
    tMinusTotalMilSeconds -= tMinusSeconds * 1000;

    const isLaunched = tMinusTotalMilSeconds <= 0;
    tMinusTotalMilSeconds = Math.abs(tMinusTotalMilSeconds);

    return `${isLaunched ? '' : '-'}${getTimeString(tMinusTotalMilSeconds, includeDays)}`;
};

export function getFlightPathProps(configuration){
    switch (configuration.dataSet) {
        case csvFiles.BOOST_GLIDE:
        case csvFiles.GROUND_BOOST_GLIDE:
            return {
                baseYDomain: [0, 200000],
                baseYTicks: [0, 50000, 100000, 150000, 200000],
                yMajorLines: [50000, 100000, 150000],
                // dataPointsShown: 640,
                dataPointsShown: getNumSecondsInRun(configuration)
            };
        case csvFiles.BALLISTIC:
            return {
                baseYDomain: [0, 50000],
                baseYTicks: [0, 25000, 50000],
                // dataPointsShown: 186,
                dataPointsShown: getNumSecondsInRun(configuration),
                yMajorLines: [25000],
            };
        default:
            return {
                baseYDomain: [0, 200000],
                baseYTicks: [0, 50000, 100000, 150000, 200000],
                yMajorLines: [50000, 100000, 150000],
                // dataPointsShown: 660,
                dataPointsShown: getNumSecondsInRun(configuration)
            };

    }
}

export function getFlightPathDownrangeProps(dataSet){
    switch (dataSet) {
        case csvFiles.BOOST_GLIDE:
        case csvFiles.GROUND_BOOST_GLIDE:
            return {
                referenceMultiple: 50,
                xDomain: [0, 850],
                xLabel: {text: 'km', offset: 5}
            };
        case csvFiles.BALLISTIC:
            return {
                referenceMultiple: 10000,
                xDomain: [0, 150000],
                xLabel: {text: 'Ft', offset: 5}
            };
        default:
            return {
                referenceMultiple: 50,
                xDomain: [0, 850],
                xLabel: {text: 'km', offset: 5}
            };
    }
}

export function getFlightPathTimeProps(configuration){
    switch (configuration.dataSet) {
        case csvFiles.BOOST_GLIDE:
        case csvFiles.GROUND_BOOST_GLIDE:
            return {
                xDomain: [640 - getNumSecondsInRun(configuration), 800],
                referenceMultiple: 100
            };
        case csvFiles.BALLISTIC:
            return {
                xDomain: [186 - getNumSecondsInRun(configuration), 200],
                referenceMultiple: 50
            };
        default:
            return {
                xDomain: [640 - getNumSecondsInRun(configuration), 800],
                referenceMultiple: 100
            };
    }
}

export function getMachSpeed(velocity){
    if(!velocity){
        return 0;
    }

    return (velocity / 768).toFixed(1);
}

export function getNumSecondsInRun(configuration){
    const tMinusArr = configuration.tMinus.split(/[:.]/); //split on colons and periods;
    const numSeconds = Number(tMinusArr[0]) * 60 * 60 + Number(tMinusArr[1]) * 60 + Number(tMinusArr[2]);
    let dataPointsShown;
    switch (configuration.dataSet) {
        case csvFiles.BOOST_GLIDE:
        case csvFiles.GROUND_BOOST_GLIDE:
            dataPointsShown = 640;
            break;
        case csvFiles.BALLISTIC:
            dataPointsShown = 186;
            break;
        default:
            dataPointsShown = 640;
    }

    return numSeconds + dataPointsShown;
}

export const urlStart = `${window.location.protocol}//${window.location.hostname}`;

export const isDevEnvironment = window.location.hostname === 'localhost';
