import React, {useState, useReducer, useEffect, useContext} from 'react';
import LineChartRechart from './lineChartRechart';
import {
    createGrid,
    getTMinusTime,
    getFlightPathProps,
    getFlightPathTimeProps, getFlightPathDownrangeProps
} from '../../helpers/helper';
import CompassHeading from './compassHeading';
import VerticalLineChart from './verticalLineChart';
import Horizon from './horizon';
import ColumnGauge from './columnGauge';
import {conductorTable1, framelock, ftlu, missionStatus} from '../../helpers/tableConfigs';
import Table from './table';
import ImageHoverZoom from './imageHoverZoom';
import StatusCircles from './statusCircles';
import EditableTable from './editableTable';
import DisplayList from './displayList';
import EditableList from './editableList';
import ClientContext from '../../helpers/ClientContext';
import RadarForm from './radarForm';
import {barDescriptors, csvFiles, radarEvents, radarStatuses, tmEvents, tmStatuses} from '../../enums';
import RadarColors from './RadarColors';
import useListItems from '../../hooks/useListItems';


const gridArray = createGrid(100);

const DropdownMenu = () => {
    const {client, configuration, widgetState} = useContext(ClientContext);
    const [colors, setColors] = useState({
        [barDescriptors.RADAR_STATUSES]: {
            [radarStatuses.ACQUIRED]: '#ffffff',
            [radarStatuses.TRACKING]: '#ffffff',
            [radarStatuses.POWERED]: '#ffffff',
            [radarStatuses.NON_POWERED]: '#ffffff',
            [radarStatuses.PROPAGATING]: '#ffffff',
        },
        [barDescriptors.RADAR_EVENTS]: {
            [radarEvents.RADIATING]: '#ffffff',
            [radarEvents.DROPOUT]: '#ffffff',
            [radarEvents.DISCRIMINATING]: '#ffffff',
        },
        [barDescriptors.TM_STATUSES]: {
            [tmStatuses.STATIC]: '#ffffff',
            [tmStatuses.TRACKING]: '#ffffff',
            [tmStatuses.NOT_TRACKING]: '#ffffff',
        },
        [barDescriptors.TM_EVENTS]: {
            [tmEvents.DROPOUT]: '#ffffff',
        }
    });
    const radars = useListItems('radars');
    const [form, setForm] = useState(radars[0]);
    const [value, setValue] = useState('HorizontalLineChart1');
    const [radarForms, setRadarForms] = useState([]);
    const [timeOptions, setTimeOptions] = useState([<option/>]);
    const [numDataPoints, setNumDataPoints] = useState(0);

    useEffect(() => {
        if(!numDataPoints){
            return;
        }

        let curTime = 0;
        const newTimeOptions = [];
        while(curTime <= numDataPoints - 1) {
            newTimeOptions.push(
                <option key={curTime} value={curTime}>
                    {getTMinusTime(curTime, configuration)}
                </option>
            );
            curTime++;
        }

        setTimeOptions(newTimeOptions);
    }, [configuration, numDataPoints]);

    useEffect(() => {
        if(!client || !configuration.id || numDataPoints){
            return;
        }

        const handleMessage = (message) => {
            const {type, data} = JSON.parse(message.data);
            if(type === 'locationData'){
                setNumDataPoints(data.length);
                client.removeEventListener('message', handleMessage);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getLocationData',
                configurationId: configuration.id,
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [configuration, client]);

    useEffect(() => {
        setForm(radars[0]);
    }, [radars])

    const updateRadar = (index, updatedRadar) => {
        const newRadars = [...radarForms];

        if(updatedRadar === null){
            newRadars.splice(index, 1);
        } else{
            newRadars[index] = updatedRadar;
        }

        setForm(newRadars[0]);
        setRadarForms(newRadars);
    };

    const updateColors = (type, status, color) => {
        const newColors = JSON.parse(JSON.stringify(colors));
        newColors[type][status] = color;
        setColors(newColors);
    };

    const showWidget = () => {
        switch(value){
            case 'HorizontalLineChart1':
                return (
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathTimeProps(configuration)}
                        chartTitle="Horizontal Line Chart"
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        innerChartWidth={875}
                        innerChartHeight={290}
                        outerChartWidth={900}
                        outerChartHeight={300}
                        lines={[{key: 'alt', color: 'blue'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        referenceMultiple={50}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="talo"
                        type="number"
                        margin={{left: 40, right: 20, bottom: 25}}
                        tickFontSize="1.1em"
                        xLabel={{text: 'TALO (s)', offset: 5}}
                        yLabel={{text: 'Altitude (ft)', offset: 30}}
                    />
                );
            case 'HorizontalLineChart2':
                return (
                    <LineChartRechart
                        {...getFlightPathProps(configuration)}
                        {...getFlightPathDownrangeProps(configuration.dataSet)}
                        chartTitle="Horizontal Line Chart"
                        axisColor="red"
                        backgroundColor="black"
                        innerChartWidth={875}
                        innerChartHeight={290}
                        outerChartWidth={900}
                        outerChartHeight={300}
                        lines={[{key: 'alt', color: 'red', dashArray: '5 5'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="red"
                        majorGridLineColor="red"
                        data={widgetState.location.data}
                        referenceStroke={1}
                        minorReferenceMultiple={0}
                        xKey="rand"
                        type="number"
                        margin={{left: 50, right: 20, bottom: 25}}
                        tickFontSize="1.1em"
                        tableBorder="1px solid white"
                        titleColor="white"
                        xLabel={{...getFlightPathDownrangeProps(configuration).xLabel, text: 'Downrange (km)', color: 'red'}}
                        yLabel={{text: 'Altitude (ft)', color: 'red', offset: 30}}
                    />
                );
            case 'HorizontalLineChart3':
                return (
                    <LineChartRechart
                        chartTitle="FTS Battery Voltage"
                        baseYDomain={[0, 70]}
                        baseYTicks={[0, 20, 40, 60]}
                        yMajorLines={[15, 45]}
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        innerChartWidth={1000}
                        innerChartHeight={290}
                        outerChartWidth={1100}
                        outerChartHeight={340}
                        lines={[{key: 'batVolt', color: 'blue'}]}
                        gridColor="rgb(102,102,102)"
                        gridLineColor="white"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        dataPointsShown={30}
                        referenceMultiple={10}
                        minorReferenceMultiple={1}
                        lightBorder="rgb(151, 192, 207)"
                        darkBorder="rgb(72, 98, 107)"
                        buttons
                        margin={{top: 0, right: 40, bottom: -4, left: 20}}
                        showLatestPoint
                        xKey="time"
                    />
                );
            case 'VerticalLineChart':
                return (
                    <VerticalLineChart
                        chartTitle="Vertical Linechart"
                        xDomain={[-500, 500]}
                        baseYTicks={configuration.dataSet === csvFiles.BALLISTIC ? [0, 50000, 100000, 150000] : [0, 300, 600, 900]}
                        axisColor="black"
                        backgroundColor="rgb(104,131,140)"
                        chartWidth={525}
                        chartHeight={230}
                        lines={[{key: 'ranc', color: 'red'}]}
                        gridColor="rgb(215, 215, 215)"
                        gridLineColor="black"
                        majorGridLineColor="black"
                        data={widgetState.location.data}
                        dataPointsShown={getFlightPathProps(configuration).dataPointsShown}
                        verticalKey="rand"
                        type="number"
                        margin={{left: 40, right: 20}}
                        tickFontSize="1.1em"
                        bound={[{key: 'ranc', limit: -50, color: 'blue'}, {key: 'ranc', limit: 50, color: 'blue'}]}
                    />
                );
            case 'Compass':
                return (
                    <CompassHeading
                        grid={gridArray}
                        width="375px"
                        height="375px"
                        demo={false}
                        degree={Number(parseFloat(widgetState.location.data[widgetState.location.data.length - 1].yaw))}
                    />
                );
            case 'Horizon':
                return (
                    <Horizon
                        grid={gridArray}
                        pitch={parseFloat(
                            widgetState.location.data[widgetState.location.data.length - 1].pitch
                        )}
                        roll={parseFloat(
                            widgetState.location.data[widgetState.location.data.length - 1].roll
                        )}
                        width="250px"
                        height="250px"
                        demo={false}
                    />
                );
            case 'ColumnGauge':
                return (
                    <div style={{width: '200px'}}>
                        <ColumnGauge
                            title="Acceleration"
                            leftColumnTitle="Lon"
                            rightColumnTitle="Lat"
                            columnWidth="200"
                            columnHeight="400"
                            lineScale={500}
                            start={200}
                            lon={widgetState.location.data[0].lon}
                            lat={widgetState.location.data[0].lat}
                        />
                    </div>
                );
            case 'StatusTable':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{border: '1px solid white', flex: 0}}>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                title="FTLU Monitors"
                                tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                config={ftlu}
                                radius={15}
                            />
                        </div>
                    </div>
                );
            case 'MissionStatus':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{border: '1px solid white', flex: 0}}>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.missionTime.data[widgetState.missionTime.data.length - 1]}
                                config={missionStatus}
                            />
                        </div>
                    </div>
                );
            case 'FramelockStatus':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{border: '1px solid white', flex: 0}}>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                                config={framelock}
                                radius={10}
                            />
                        </div>
                    </div>
                );
            case 'ConductorTable':
                return (
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.lineChart.data[widgetState.lineChart.data.length - 1]}
                        config={conductorTable1}
                        radius={11}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={212}
                        tableWidth={285}
                        textColor="teal"
                        textSize="14px"
                    />
                );
            case 'ImageHoverZoom':
                return (
                    <ImageHoverZoom
                        graphData={widgetState.location.data}
                        //[maxLat, minLat, maxLon, minLon]
                        // maxMinData={['.707044', '-.49952', '.3128', '-.1262']}
                        maxMinData={['100', '0', '100', '0']}
                        imgHeight={300 * 2}
                        imgWidth={500 * 2}
                        //If zoom, you get lens and smaller zoomed in screen
                        zoom
                        client={client}
                        xPos={widgetState.locations.x}
                        yPos={widgetState.locations.y}
                        lines={[
                            {color: 'red', xKey: 'lon', yKey: 'lat'},
                            {color: 'blue', xKey: 'lon2', yKey: 'lat2'},
                        ]}
                    />
                );
            case 'AssetTable':
                return (
                    <div style={{display: 'flex'}}>
                        <div>
                            <div style={{
                                borderStyle: 'solid',
                                borderColor: 'white',
                                padding: '5px',
                            }}>
                                <p style={{
                                    color: 'white',
                                    textAlign: 'left',
                                    fontFamily: 'Courier New',
                                    fontSize: '25px'
                                }}
                                >
                                    <b>Assets:</b>
                                </p>
                                <StatusCircles
                                    listName="assets"
                                />
                            </div>
                        </div>
                        <div style={{
                            borderRadius: '0.5em',
                            backgroundColor: '#E6E6E6',
                            boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '0.5em',
                            marginLeft: '2rem',
                        }}>
                            <div style={{
                                backgroundColor: '#0a767a',
                                color: 'white',
                                borderRadius: '0.5em 0.5em 0 0',
                                padding: '0.4em 0.5em 0.4em 0.5em',
                                marginBottom: '0.2em',
                                flex: 0,
                            }}>
                                <div style={{textAlign: 'center'}}>Assets</div>
                            </div>
                            <div style={{overflowY: 'auto', flex: 1}}>
                                <EditableTable
                                    title="Assets"
                                    initialList={[]}
                                    textSize="13px"
                                    listName="assets"
                                    client={client}
                                    colors={[{name: 'Red', color: 'red'}, {name: 'Yellow', color: 'yellow'}, {
                                        name: 'Green',
                                        headerColor: '#01fd05',
                                        color: 'green'
                                    }]}
                                    time={widgetState.location.data.at(-1).time}
                                />
                            </div>
                        </div>
                    </div>
                );
            case 'TestEvents':
                return (
                    <>
                        <div style={{
                            borderStyle: 'solid',
                            borderColor: 'white',
                            height: 400,
                            width: 400,
                            overflowY: 'auto',
                            padding: '5px'
                        }}>
                            <p style={{color: 'yellow', textAlign: 'center', fontFamily: 'Courier New'}}><u>Test
                                Events</u></p>
                            <DisplayList listName="testEvents"/>
                        </div>

                        <div style={{
                            borderRadius: '0.5em',
                            backgroundColor: '#E6E6E6',
                            boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            height: 400,
                            flexDirection: 'column',
                            paddingBottom: '0.5em',
                            marginLeft: '2rem',
                        }}>
                            <div style={{
                                backgroundColor: '#0a767a',
                                color: 'white',
                                borderRadius: '0.5em 0.5em 0 0',
                                padding: '0.4em 0.5em 0.4em 0.5em',
                                marginBottom: '0.2em',
                                flex: 0,
                            }}>
                                <div style={{textAlign: 'center'}}>Test Events</div>
                            </div>
                            <div style={{overflowY: 'auto', flex: 1}}>
                                <EditableTable
                                    title="Test Events"
                                    initialList={[]}
                                    textSize="13px"
                                    listName="testEvents"
                                    client={client}
                                    canEdit
                                    colors={[{name: 'White', color: 'white'}, {name: 'Yellow', color: 'yellow'}, {
                                        name: 'Green',
                                        color: '#01fd05'
                                    }]}
                                    time={widgetState.location.data.at(-1).time}
                                />
                            </div>
                        </div>
                    </>
                );
            case 'Conditions':
                return (
                    <>
                        {/*show conditions*/}
                        <div style={{
                            borderStyle: 'solid',
                            borderColor: 'white',
                            height: '400px',
                            width: '400px',
                            padding: '5px',
                            marginLeft: '250px',
                            overflowY: 'auto',
                        }}>
                            <p style={{
                                color: 'white',
                                textAlign: 'left',
                                fontFamily: 'Courier New',
                                fontSize: '25px'
                            }}>
                                <b>Conditions:</b>
                            </p>
                            <DisplayList
                                listName="conditions"
                                textColor="white"
                            />
                        </div>
                        {/*Add Conditions */}
                        <div style={{
                            borderRadius: '0.5em',
                            backgroundColor: '#E6E6E6',
                            boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '0.5em',
                            marginLeft: '2rem',
                            height: '400px',
                            overflowY: 'auto',
                        }}>
                            <div style={{
                                backgroundColor: '#0a767a',
                                color: 'white',
                                borderRadius: '0.5em 0.5em 0 0',
                                padding: '0.4em 0.5em 0.4em 0.5em',
                                marginBottom: '1em',
                                flex: 0,
                            }}>
                                <div style={{textAlign: 'center'}}>Conditions</div>
                            </div>
                            <div style={{overflowY: 'auto'}}>
                                <EditableList
                                    initialList={[]}
                                    textSize="13px"
                                    listName="conditions"
                                    client={client}
                                />
                            </div>
                        </div>
                    </>
                );
            case 'RadarBar':{
                return (
                    <div>
                        <div style={{display: 'flex'}}>
                            <RadarForm
                                form={form}
                                key={form.timeCreated}
                                radarID={0}
                                updateRadar={updateRadar}
                                statusColors={colors[barDescriptors.RADAR_STATUSES]}
                                eventColors={colors[barDescriptors.RADAR_EVENTS]}
                                timeOptions={timeOptions}
                                configuration={configuration}
                            />
                            <div style={{backgroundColor: 'white', marginLeft: '1rem', height: '100%'}}>
                                <RadarColors updateColors={updateColors} colors={colors}/>
                            </div>
                        </div>
                    </div>
                );
            }
            default:
                return <></>;
        }
    };

    const showDescription = () => {
        switch(value){
            case 'HorizontalLineChart1':
            case 'HorizontalLineChart2':
            case 'HorizontalLineChart3':
                return (
                    <h4>
                        Horizontal Line Chart
                    </h4>
                );
            case 'VerticalLineChart':
                return (
                    <h4>
                        Vertical Line Chart
                    </h4>
                );
            case 'Compass':
                return (
                    <h4>
                        Compass Heading takes in the degree value from a .csv and rotates based off
                        of that value.
                    </h4>
                );
            case 'Horizon':
                return (
                    <h4>
                        Horizon gauge takes in the pitch and roll values from a .csv file.
                    </h4>
                );
            case 'ColumnGauge':
                return (
                    <h4>
                        Column Gauge takes in 2 values from a .csv file. These values will shift the level of
                        the gauge for the corresponding value.
                    </h4>
                );
            case 'StatusTable':
                return (
                    <h4>
                        The Table widgets can be manipulated to show different configurations. This configuration
                        shows status circles that will change color based off the bounds set for the value read in.
                    </h4>
                );
            case 'FramelockStatus':
                return (
                    <h4>
                        Framelock Status Table shows another possible configuration for a table. Status circles and text
                        colors can changed based off bounds set on values read in.
                    </h4>
                );
            case 'MissionStatus':
                return (
                    <h4>
                        Mission Status Table shows the mission time and countdown. TALO time is red if the time is
                        before liftoff
                        and green after liftoff.
                    </h4>
                );
            case 'ConductorTable':
                return (
                    <h4>
                        Conductor Table can be used to set bounds on different values that are read in from
                        a .csv file. These bounds can be used to change the color behind the values.
                    </h4>
                );
            case 'ImageHoverZoom':
                return (
                    <h4>
                        The Image with Zoom Widget allows you to click around a image to show a zoomed in
                        portion of the picture.
                    </h4>
                );
            case 'AssetTable':
                return (
                    <h4>
                        The Asset Table is a preset list of assets passed to the widget before a scenario.
                        You can change the status circles beside the asset by using the radial buttons. The
                        Asset Table widget on the left will be manipulated from the Proctor page.
                    </h4>
                );
            case 'TestEvents':
                return (
                    <h4>
                        The Test Events widget allows you to add/remove Test Events during a scenario.
                        You can change the Test Events color with the radial buttons. The Test Events widget on
                        the left will be manipulated from the Proctor page.
                    </h4>
                );
            case 'Conditions':
                return (
                    <h4>
                        The Conditions widget allows you to add/remove conditions during a scenario.
                        The Conditions widget on the left will be manipulated from the Proctor page.
                    </h4>
                );
            default:
                return '';
        }
    };

    /*useEffect(() => {
        const handleMessage = message => {
            dispatchWidget(message);
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getData',
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, []);*/

    return (
        <div>
            <select
                style={{marginBottom: '1rem'}}
                className="form-select"
                onChange={e => setValue(e.currentTarget.value)}
            >
                <option value="HorizontalLineChart1">Horizontal Line Chart (Gray)</option>
                <option value="HorizontalLineChart2">Horizontal Line Chart (Black)</option>
                <option value="HorizontalLineChart3">Horizontal Line Chart (Gray w/ btns)</option>
                <option value="VerticalLineChart">Vertical Line Chart</option>
                <option value="Compass">Compass</option>
                <option value="Horizon">Horizon</option>
                <option value="ColumnGauge">Column Gauge</option>
                <option value="StatusTable">Status Table</option>
                <option value="FramelockStatus">Framelock Status Table</option>
                <option value="MissionStatus">Mission Status</option>
                <option value="ConductorTable">Conductor Table</option>
                <option value="ImageHoverZoom">Image w/ Zoom</option>
                <option value="AssetTable">Asset Table</option>
                <option value="TestEvents">Test Events</option>
                <option value="Conditions">Conditions</option>
                <option value="RadarBar">Radar Bar</option>
            </select>
            <div style={{color: 'white'}}>
                {showDescription()}
            </div>
            <div style={{
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
            }}>
                {showWidget()}
            </div>
        </div>
    );
};

export default DropdownMenu;
