import React, {useRef} from 'react';
import {
    barDescriptors,
    tmEvents,
    tmStatuses,
    tmStatusesReadable,
    tmEventsReadable,
} from '../../enums';
import {BarColors, TmStatusKeys} from '../../helpers/types';

type ColorType = typeof barDescriptors.TM_STATUSES | typeof barDescriptors.TM_EVENTS;

type Props = {
    colors: BarColors;
    updateColors: (type: ColorType, status: TmStatusKeys, color: string) => void;
}

const TmColors = ({colors, updateColors}: Props) => {
    const uniqueRef = useRef(performance.now());

    if(!Object.keys(colors).length){
        return (
            <div/>
        );
    }

    return (
        <form className="colorForm">
            <h4 style={{textAlign: 'center'}}>Telemetry</h4>
            <div style={{display: 'flex', padding: '10px'}}>
                <datalist id={`suggestedColors${uniqueRef.current}`}>
                    <option>#ffffff</option>
                    {/*white*/}
                    <option>#000000</option>
                    {/*white*/}
                    <option>#ff0000</option>
                    {/*red*/}
                    <option>#00ff00</option>
                    {/*green*/}
                    <option>#0000ff</option>
                    {/*blue*/}
                    <option>#ffff00</option>
                    {/*yellow*/}
                    <option>#ffa500</option>
                    {/*orange*/}
                </datalist>
                <table style={{margin: '10px'}}>
                    <thead>
                    <tr>
                        <th>Statuses</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(tmStatuses).map(status => {
                            return (
                                <tr key={status}>
                                    <td>{tmStatusesReadable[status]}:</td>
                                    <td>
                                        <input
                                            onInput={(e) => updateColors(barDescriptors.TM_STATUSES, status, e.currentTarget.value)}
                                            type="color"
                                            list={`suggestedColors${uniqueRef.current}`}
                                            value={colors[barDescriptors.TM_STATUSES][status]}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
                <table style={{margin: '10px', height: '100%'}}>
                    <thead>
                    <tr>
                        <th>Events</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(tmEvents).map(event => {
                            return (
                                <tr key={event}>
                                    <td>{tmEventsReadable[event]}:</td>
                                    <td>
                                        <input
                                            onInput={(e) => updateColors(barDescriptors.TM_EVENTS, event, e.currentTarget.value)}
                                            type="color"
                                            list={`suggestedColors${uniqueRef.current}`}
                                            value={colors[barDescriptors.TM_EVENTS][event]}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </form>
    );
};

export default TmColors;
