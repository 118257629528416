import React, {useEffect} from 'react';

type Props = {
    message: string;
    timeoutLen?: number;
    closeNotification: (key: number) => void;
    uniqueKey: number;
    color: string;
}

const Notif = ({message, timeoutLen = 5, closeNotification, uniqueKey, color}: Props) => {
    useEffect(() => {
        if(timeoutLen !== -1) {
            const timer = setTimeout(() => {
                closeNotification(uniqueKey);
            }, timeoutLen * 1000);

            return () => {
                clearTimeout(timer);
            }
        }
    }, []);

    return (
        <div
            className="noHeight toast"
            key={uniqueKey}
            style={{
                backgroundColor: color,
                borderRadius: '0.3em',
                color: 'white',
                position: 'relative',
                transition: 'max-height .25s linear, padding-top .25s linear, padding-bottom .25s linear',
            }}>
            <span
                tabIndex={0}
                role="button"
                style={{
                    position: 'absolute',
                    right: 5,
                    top: 0,
                    fontFamily: 'sans-serif',
                    cursor: 'pointer',
                }}
                onClick={() => closeNotification(uniqueKey)}
            >
                X
            </span>
            {message}
        </div>
    )
}

export default Notif;
