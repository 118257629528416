import React from 'react';
import {barDescriptors, tmStatusesReadable, tmEventsReadable} from '../../enums';
import {BarColors, TmEventKeys, TmStatusKeys} from '../../helpers/types';

type Props = {
    statuses: TmStatusKeys[];
    events: TmEventKeys[];
    barColors: BarColors
}

const TmColorKey = ({statuses, events, barColors}: Props) => {
    if(!statuses.length && !events.length){
        return(
            <div />
        )
    }

    return (
        <div>
            <h4 style={{textAlign: 'center'}}>Telemetry Color Key</h4>
            <div style={{display: 'flex', padding: '10px', fontSize: '1.3rem'}}>
                <table style={{margin: '10px', height: '100%'}}>
                    <thead style={{display: statuses.length ? 'block' : 'none'}}>
                        <tr>
                            <th>Statuses</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            statuses.map((status, index) => {
                                const fillColor = barColors[barDescriptors.TM_STATUSES][status];

                                return (
                                    <tr key={index}>
                                        <td>
                                            {tmStatusesReadable[status]}:
                                        </td>
                                        <td>
                                            <div style={{backgroundColor: fillColor, width: '100px', height: '20px', border: '1px solid black'}} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <table style={{margin: '10px', height: '100%'}}>
                    <thead style={{display: events.length ? 'block' : 'none'}}>
                        <tr>
                            <th>Events</th>
                        </tr>
                    </thead>
                        <tbody>
                        {
                            events.map((event, index) => {
                                const fillColor = barColors[barDescriptors.TM_EVENTS][event];


                                return (
                                    <tr key={index}>
                                        <td>
                                            {tmEventsReadable[event]}:
                                        </td>
                                        <td>
                                            <div style={{backgroundColor: fillColor, width: '100px', height: '20px', border: '1px solid black'}} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TmColorKey;
