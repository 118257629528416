import React, {useContext} from 'react';

import {getEndOfArray, getNumSecondsInRun} from '../../helpers/helper';
import DropdownMenu from '../widgets/dropdownMenu';
import Timeline from '../widgets/timeline';
import ClientContext from '../../helpers/ClientContext';

const Home = () => {
    const {run, configuration, widgetState} = useContext(ClientContext);

    return (
        <>
            <div className="blackBody"/>
            <div className="pageContent" style={{padding: '1em', overflowY: 'scroll'}}>
                {/*Timeline*/}
                <div style={{
                    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.3)',
                    borderRadius: '0.5em',
                    marginBottom: '1em',
                    display: 'flex'
                }}
                >
                    <div style={{
                        padding: '1em',
                        backgroundColor: '#0a767a',
                        borderRadius: '0.5em 0 0 0.5em',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <h2 style={{color: 'white', marginBottom: 0}}>Timing</h2>
                    </div>
                    <div style={{padding: '0.5em', backgroundColor: 'white', borderRadius: '0 0.5em 0.5em 0', flex: 1}}>
                        <Timeline
                            min={0}
                            max={getNumSecondsInRun(configuration)}
                            events={run.keyEvents}
                            height={71}
                            time={
                                getEndOfArray(widgetState.location.data, 'time')
                            }
                            timeColor="black"
                        />
                    </div>
                </div>

                {/* Dropdown menu */}
                <DropdownMenu/>
            </div>
        </>
    );
};

export default Home;
