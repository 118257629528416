import React, {useEffect, useState, useRef} from 'react';
import {getTMinusTime} from '../../helpers/helper';
import useListItems from '../../hooks/useListItems';
import {useParams} from 'react-router-dom';
import MUIDataTable, {DisplayData} from 'mui-datatables';
import {ConfigurationType, RunType, UserType} from '../../helpers/types';

const Reports = () => {
    const {id: runId} = useParams<{id: string;}>();
    const uncheckedRuns = useListItems('runs');
    const [run, setRun] = useState<RunType>({
        _id: '',
        id: '',
        name: '',
        date: '',
        users: [],
        interactions: [],
        rooms: [],
        points: {},
        configurationID: '',
        chats: [],
        moddedData: {},
        keyEvents: [],
        utc: '',
        tMinus: '',
        increment: 1,
        pageOrder: [],
        chevronPoints: [],
        keepOutPoints: [],
        checklists: [],
        checklistId: 0,
        checklistPosition: '-1'
    });
    const [runs, setRuns] = useState<RunType[]>([]);
    const uncheckedConfigs = useListItems('configurations');
    const [configId, setConfigId] = useState<string>('');
    const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);
    const [configuration, setConfiguration] = useState<ConfigurationType>({
        _id: '',
        id: '',
        name: '',
        barColors: {
            1: {
                1: 'white',
                2: 'white',
                3: 'white',
                4: 'white',
                5: 'white',
            },
            2: {
                1: 'white',
                2: 'white',
                3: 'white',
            },
            3: {
                1: 'white',
                2: 'white',
                3: 'white',
            },
            4: {
                1: 'white',
            },
        },
        radars: [],
        tms: [],
        utc: '',
        tMinus: '',
        increment: 0,
        dataSet: 1,
    });
    const uncheckedUsers = useListItems('users');
    const [users, setUsers] = useState<UserType[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const origRowsPerPageRef = useRef(10);

    function handlePrint(){
        setRowsPerPage(10000); // high number that will show all rows
        setTimeout(() => {
            setRowsPerPage(origRowsPerPageRef.current);
        }, 0); // happens after print dialog
    }

    useEffect(() => {
        //shows all table rows when printing and resets when print dialog is closed

        if(!run.id || !configuration.id){
            return;
        }

        document.querySelector('[data-testid="Print-iconButton"]')?.addEventListener('click', handlePrint);

        return () => {
            document.querySelector('[data-testid="Print-iconButton"]')?.removeEventListener('click', handlePrint);
        };
    }, [run, configuration]);

    useEffect(() => {
        if(!Array.isArray(uncheckedUsers)){
            return;
        }
        setUsers(uncheckedUsers);
    }, [uncheckedUsers]);

    useEffect(() => {
        if(!runId || !runs.length){
            return;
        }

        const newRun = runs.find(uncheckedRun => uncheckedRun.id === runId);
        if(newRun){
            setRun(newRun);
            setConfigId(newRun.configurationID);
        }
    }, [runId, runs]);

    useEffect(() => {
        if(!configId || !configurations.length){
            return;
        }

        const newConfig = configurations.find(uncheckedConfig => uncheckedConfig.id === configId);
        if(newConfig){
            setConfiguration(newConfig);
        }
    }, [configurations, configId]);

    const columnsDef = [
        {
            name: 'timestamp',
            label: 'T-Minus',
            options: {
                filter: false,
                customBodyRender: (timestamp: number) => {
                    return getTMinusTime(timestamp, configuration).toString();
                },
                sortCompare: (order: string) => {
                    return (obj1: {data: string;}, obj2: {data: string;}) => {
                        let val1 = parseInt(obj1.data, 10);
                        let val2 = parseInt(obj2.data, 10);
                        return (val1 - val2) * (order === 'asc' ? 1 : -1);
                    };
                }
            },
        },
        {
            name: 'user',
            label: 'User',
        },
        {
            name: 'position',
            label: 'Position',
        },
        {
            name: 'identifier',
            label: 'Action',
            options: {
                filter: false,
            },
        },
    ];

    useEffect(() => {
        if(!Array.isArray(uncheckedRuns)){
            return;
        }
        setRuns(uncheckedRuns);
    }, [uncheckedRuns]);

    useEffect(() => {
        if(!Array.isArray(uncheckedConfigs)){
            return;
        }
        setConfigurations(uncheckedConfigs);
    }, [uncheckedConfigs]);

    if(!run.id || !configuration.id){
        return <div>Loading</div>;
    }

    return (
        <>
            <div className="grayBody"/>
            <div id="reports" className="overflowAuto"
                 style={{padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <h1 style={{marginBottom: '1rem'}}>{run.name} Interactions</h1>
                <MUIDataTable columns={columnsDef} data={run.interactions.map(interaction => {
                    type TableInteraction = {
                        _id?: string | undefined, timestamp: string, user: string, identifier: string, action: number
                    }
                    const newInteraction: TableInteraction = {...interaction, user: ''};
                    newInteraction.user = users.find(user => user.id === interaction.userID)?.name || '';
                    return newInteraction;
                })} title={'Interactions'}
                              options={{
                                  rowsPerPage,
                                  onChangeRowsPerPage: numberOfRows => {
                                      setRowsPerPage(numberOfRows);
                                      origRowsPerPageRef.current = numberOfRows;
                                  },
                                  selectableRows: 'none',
                                  // tableBodyMaxHeight: '70vh', /* no max height so that print works */
                                  downloadOptions: {
                                      // filterOptions: {useDisplayedRowsOnly: true},
                                      filename: `${run.name} Report.csv`
                                  },
                                  onDownload: (buildHead, buildBody, columns, data: DisplayData) => {
                                      data.forEach((outerData) => {
                                          outerData.data[0] = `"${outerData.data[0]}"`;
                                      });

                                      return buildHead(columns) + buildBody(data);
                                  },
                                  responsive: 'standard'
                              }}
                />
            </div>
        </>
    );
};

export default Reports;
