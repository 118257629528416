import React, {Fragment, useState} from 'react';
import kwaj from '../../images/kwajBlackedOut.png';

type PointType = {
    x: number;
    y: number;
}
type Props = {
    points: PointType[][];
    setPoints: (points: PointType[][]) => void;
    isClosed?: boolean;
}

function InteractiveLines({points, setPoints, isClosed = false}: Props){
    const [tempPoint, setTempPoint] = useState<{x: number, y: number}>({x: 0, y: 0});

    function handleMapClick(event: React.MouseEvent<SVGElement>){
        if(isClosed && points.length > 1 && isComplete()){
            return;
        }

        const img = event.currentTarget;
        const width = img.clientWidth;
        const height = img.clientHeight;

        const {x, y} = getCoords(event, img);
        addPoint(x / width, y / height);
    }

    function isComplete(){
        if(!points.length){
            return false;
        }

        const firstPoint = points[0][0];
        const lastPoint = points[points.length - 1][1];

        if(!lastPoint){
            return false;
        }

        return firstPoint.x === lastPoint.x && firstPoint.y === lastPoint.y;
    }

    function getCoords(event: React.MouseEvent<SVGElement>, img: SVGElement){
        /* Get the x and y positions of the image: */
        const a = img.getBoundingClientRect();

        /* Calculate the cursor's x and y coordinates, relative to the image: */
        let x = (event.pageX - a.left);
        let y = (event.pageY - a.top);

        return {x, y};
    }

    function getPoints(line: {x: number, y: number}[]){
        let width = 1;
        let height = 1;
        const img = document.querySelector('#map');
        if(img){
            width = img.clientWidth;
            height = img.clientHeight;
        }

        if(line.length > 1){
            return line.map(point => (
                point.x * width + ' ' + point.y * height
            )).join(', ');
        }

        return line[0].x * width + ' ' + line[0].y * height + ', ' + tempPoint.x + ' ' + tempPoint.y;
    }

    function addPoint(x: number, y: number){
        const oldPoints = [...points];
        if(oldPoints.length){
            if(oldPoints[oldPoints.length - 1].length % 2){ // already one point
                //prevent a single-point line
                if(x === oldPoints[oldPoints.length - 1][0].x && y === oldPoints[oldPoints.length - 1][0].y){
                    return;
                }
                oldPoints[oldPoints.length - 1].push({x, y});
                if(isClosed){
                    const firstPoint = points[0][0];
                    if(!(firstPoint.x === x && firstPoint.y === y)){ //if we haven't finished the shape
                        oldPoints.push([{x, y}]);
                    }
                }
            } else{
                oldPoints.push([{x, y}]);
            }
        } else{
            oldPoints.push([{x, y}]);
        }
        setPoints(oldPoints);
    }

    function isMostRecentLine(index: number){
        if(isComplete()){
            return index === points.length - 1;
        }

        return index === points.length - 2; //ignore temp line
    }

    return (
        <div style={{position: 'relative'}}>
            <img src={kwaj} alt="kwaj" style={{width: '100%'}}/>
            <svg id="map" onClick={handleMapClick} onMouseMove={(e) => {
                const {x, y} = getCoords(e, e.currentTarget);
                setTempPoint({x, y}); // move the line a little off of the cursor
            }}
                 style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
            >
                {
                    points.length && isClosed && !isComplete() &&
                    <polyline
                        fill="none"
                        stroke="red"
                        strokeWidth={2}
                        points={getPoints(points[points.length - 1])}
                    />
                }
                {
                    points.map((line, index) => (
                        !(isClosed && !isComplete() && index === points.length - 1 && points.length) &&
                        <polyline
                            key={index}
                            className={line[1] ? 'mapLine' : ''}
                            fill="none"
                            stroke={isClosed && isMostRecentLine(index) ? 'green' : 'red'}
                            strokeWidth={2}
                            points={getPoints(line)}
                            onContextMenu={e => { // on right click
                                //easier to determine if shape is closed if points are in order
                                if(isClosed && !isMostRecentLine(index)){
                                    return;
                                }

                                const confirmation = window.confirm('Are you sure you want to delete this line?');
                                if(confirmation){
                                    const newPoints = [...points];
                                    const {x, y} = newPoints.splice(index, 1)[0][0];
                                    if(isClosed){
                                        if(!isComplete()){
                                            newPoints.pop();
                                        }
                                        if(newPoints.length){
                                            newPoints.push([{x, y}]);
                                        }
                                    }
                                    setPoints(newPoints);
                                }

                                e.preventDefault();
                            }}
                            onClickCapture={(e) => {
                                if(isClosed && isComplete()){
                                    return;
                                }
                                if(!line[1]){ // don't want to click on temp line
                                    return;
                                }

                                const img = e.currentTarget.closest('svg');
                                if(!img){
                                    return;
                                }
                                const width = img.clientWidth;
                                const height = img.clientHeight;

                                const {x, y} = getCoords(e, img);

                                // vertical line
                                if(line[0].x === line[1].x){
                                    addPoint(line[0].x, y / height);
                                    return;
                                }

                                const slope = (line[0].y - line[1].y) / (line[0].x - line[1].x);
                                const yIntercept = line[0].y - slope * line[0].x;

                                const closestX = (y / height - yIntercept) / slope;
                                const closestY = slope * x / width + yIntercept;
                                if(Math.abs(x / width - closestX) < Math.abs(y / height - closestY)){
                                    addPoint(closestX, slope * closestX + yIntercept);
                                } else{
                                    addPoint((closestY - yIntercept) / slope, closestY);
                                }

                                e.stopPropagation();
                            }}
                        />
                    ))
                }
                {/* circles need to be drawn last so that they are on top of lines */}
                {
                    points.map((line, index) => (
                        <Fragment key={index}>
                            {
                                line.map((point, pointIndex) => (
                                    <circle className="mapPoint" onClickCapture={(e) => {
                                        e.stopPropagation();
                                        if(isClosed && isComplete()){
                                            return;
                                        }
                                        addPoint(point.x, point.y);
                                    }} key={pointIndex}
                                            cx={point.x * (document.querySelector('#map')?.clientWidth || 1)}
                                            cy={point.y * (document.querySelector('#map')?.clientHeight || 1)}
                                            r="4" fill="red"
                                    />
                                ))
                            }
                        </Fragment>
                    ))
                }
            </svg>
        </div>
    );
}

export default InteractiveLines;
