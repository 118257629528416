//BCAS "Basic table"
export const basicTbl = {
    rows: [
        [
            {
                type: 'staticText',
                value: 'BCAS Voltage',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasVoltage',
            },
            {
                type: 'staticText',
                value: 'Volts',
                color: 'white'
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Time',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasTime',
            },
            {
                type: 'staticText',
                value: '',
                color: 'white'
            }
        ],
    ]

};

//BCAS "Fin Enable Status"
export const finEnableStatus = {
    head: ['Fin Enable Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: 'Mode',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finEnableStatusMode',
            }
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finEnableStatusUL',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finEnableStatusUR',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finEnableStatusLL',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finEnableStatusLR',
            },
        ],

    ]
};

// BCAS "Fin Position"
export const finPosition = {
    head: ['Fin Position'],
    rows: [
        [
            {
                type: 'staticText',
                value: '',
            },
            {
                type: 'staticText',
                value: 'Status',
                color: 'yellow',
            },
            {
              type: 'staticText',
              value: '',
            },
            {
                type: 'staticText',
                value: 'Min',
                color: 'yellow',
            },
            {
                type: 'staticText',
                value: '',
            },
            {
                type: 'staticText',
                value: 'Max',
                color: 'yellow',
            },
            {
                type: 'staticText',
                value: '',
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finPosStatusUL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMinUL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMaxUL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finPosStatusUR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMinUR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMaxUR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finPosStatusLL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMinLL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMaxLL'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'finPosStatusLR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMinLR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
            {
                type: 'dynamicText',
                key: 'finPosMaxLR'
            },
            {
                type: 'staticText',
                value: 'Deg'
            },
        ],

    ]
};

// BCAS "Fin Status"
export const finStatus = {
    head: ['Fin Status'],
    rows: [
        [
            {
                type: 'staticText',
                value: '',
            },
            {
                type: 'staticText',
                value: 'Tracker Mismatch',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Brake Fault',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Brake Disengage',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Current Out Range',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Encoder Error',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Short Upper',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Short Lower',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Open Degraded',
                color: 'yellow'
            },
            {
                type: 'staticText',
                value: 'Fin Deployed',
                color: 'yellow'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'trackerMismatchUL'
            },
            {
                type: 'dynamicText',
                key: 'brakeFaultUL'
            },
            {
                type: 'dynamicText',
                key: 'brakeDisengageUL'
            },
            {
                type: 'dynamicText',
                key: 'curOutRangUL'
            },
            {
                type: 'dynamicText',
                key: 'encoderErrorUL'
            },
            {
                type: 'dynamicText',
                key: 'shortUpperUL'
            },
            {
                type: 'dynamicText',
                key: 'shortLowerUL'
            },
            {
                type: 'dynamicText',
                key: 'openDegradedUL'
            },
            {
                type: 'dynamicText',
                key: 'finDeployedUL'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Upper Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'trackerMismatchUR'
            },
            {
                type: 'dynamicText',
                key: 'brakeFaultUR'
            },
            {
                type: 'dynamicText',
                key: 'brakeDisengageUR'
            },
            {
                type: 'dynamicText',
                key: 'curOutRangUR'
            },
            {
                type: 'dynamicText',
                key: 'encoderErrorUR'
            },
            {
                type: 'dynamicText',
                key: 'shortUpperUR'
            },
            {
                type: 'dynamicText',
                key: 'shortLowerUR'
            },
            {
                type: 'dynamicText',
                key: 'openDegradedUR'
            },
            {
                type: 'dynamicText',
                key: 'finDeployedUR'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Left',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'trackerMismatchLL'
            },
            {
                type: 'dynamicText',
                key: 'brakeFaultLL'
            },
            {
                type: 'dynamicText',
                key: 'brakeDisengageLL'
            },
            {
                type: 'dynamicText',
                key: 'curOutRangLL'
            },
            {
                type: 'dynamicText',
                key: 'encoderErrorLL'
            },
            {
                type: 'dynamicText',
                key: 'shortUpperLL'
            },
            {
                type: 'dynamicText',
                key: 'shortLowerLL'
            },
            {
                type: 'dynamicText',
                key: 'openDegradedLL'
            },
            {
                type: 'dynamicText',
                key: 'finDeployedLL'
            },
        ],
        [
            {
                type: 'staticText',
                value: 'Lower Right',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'trackerMismatchLR'
            },
            {
                type: 'dynamicText',
                key: 'brakeFaultLR'
            },
            {
                type: 'dynamicText',
                key: 'brakeDisengageLR'
            },
            {
                type: 'dynamicText',
                key: 'curOutRangLR'
            },
            {
                type: 'dynamicText',
                key: 'encoderErrorLR'
            },
            {
                type: 'dynamicText',
                key: 'shortUpperLR'
            },
            {
                type: 'dynamicText',
                key: 'shortLowerLR'
            },
            {
                type: 'dynamicText',
                key: 'openDegradedLR'
            },
            {
                type: 'dynamicText',
                key: 'finDeployedLR'
            },
        ],
    ]
};