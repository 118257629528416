import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {roles, rolesReadable} from '../../enums';
import useListItems from '../../hooks/useListItems';
import ClientContext from '../../helpers/ClientContext';
import {UserType, RoleKeys} from '../../helpers/types';
import MUIDataTable, {DisplayData, MUIDataTableColumn, MUIDataTableMeta} from 'mui-datatables';
import {initializeApp} from 'firebase/app';
import {firebaseConfig} from '../../helpers/helper';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

type Users = {
    [index: string]: UserType
}

const CreateUsers = () => {
    const {addNotification} = useContext(ClientContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [passwordResetUser, setPasswordResetUser] = useState<UserType | null>(null);
    const [isCreateFormOpen, setCreatFormOpen] = useState(true);
    const [curUser, setCurUser] = useState<UserType>({_id: '', id: '', name: '', email: '', disabled: 1, role: 2});
    const [savedUsers, setSavedUsers] = useState<Users>({});
    const uncheckedUsers = useListItems('users');
    const [users, setUsers] = useState<UserType[]>([]);
    const [password, setPassword] = useState('');
    const [passwordReEnter, setPasswordReEnter] = useState('');

    useEffect(() => {
        if(!Array.isArray(uncheckedUsers)){
            return;
        }
        setUsers(uncheckedUsers);
    }, [uncheckedUsers]);

    useEffect(() => {
        const newUsers: Users = {};
        users.forEach(user => {
            newUsers[user.id] = user;
        });
        setSavedUsers(newUsers);
    }, [users]);

    /**
     *
     * @param user
     */
    const openEditForm = (user: UserType) => {
        setCurUser(user);
        setIsModalOpen(true);
        setCreatFormOpen(false);
    };

    /**
     *
     * @param event
     */
    const updateUser = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        const userIdInput = form.querySelector('input[name="userId"]') as HTMLInputElement;
        const userId = userIdInput.value;
        const nameInput = form.querySelector('input[name="name"]') as HTMLInputElement;
        const name = nameInput.value;
        const emailInput = form.querySelector('input[name="email"]') as HTMLInputElement;
        const email = emailInput.value;
        const roleInput = form.querySelector('#role') as HTMLSelectElement;
        const role = parseInt(roleInput.value);
        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/update`, {
            userId,
            name,
            email,
            role,
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                } else{
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });
    };

    const updatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!passwordResetUser){
            return;
        }

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/updatePassword`, {
            userId: passwordResetUser.id,
            password,
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                } else{
                    setPasswordResetUser(null);
                    addNotification({message: `Password set successfully`, color: 'green'});
                    setPassword('');
                    setPasswordReEnter('');
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });

    };

    const columnsDef: MUIDataTableColumn[] = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
            },
        },
        {
            name: 'role',
            label: 'Role',
            options: {
                customBodyRender: (role: RoleKeys) => role in rolesReadable ? rolesReadable[role] : 'none'
            }
        },
        {
            name: 'action',
            label: 'Action',
            options: {
                download: false,
                filter: false,
                customBodyRender: (value: any, tableData: MUIDataTableMeta) => {
                    const user = Object.values(savedUsers).find(curUser => curUser.email === tableData.rowData[1]);
                    if(!user){
                        return <div>Error</div>
                    }

                    return (
                        <div style={{whiteSpace: 'nowrap'}}>
                            {
                                user.disabled ?
                                    <button type="button"
                                            style={{
                                                borderRadius: '0.5em'
                                            }}
                                            onClick={() => enableUser(user)}
                                    >
                                        Enable
                                    </button>
                                    :
                                    <button type="button"
                                            style={{
                                                borderRadius: '0.5em'
                                            }}
                                            onClick={() => disableUser(user)}
                                    >
                                        Disable
                                    </button>
                            }
                            <button
                                type="button"
                                onClick={() => openEditForm(user)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >Edit
                            </button>
                            {/*<button
                                type="button"
                                onClick={() => resetPassword(user.email)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >Reset Password
                            </button>*/}
                            <button
                                type="button"
                                onClick={() => setPasswordResetUser(user)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >Set New Password
                            </button>
                        </div>
                    );
                }
            }
        },
    ];

    /**
     *
     * @param user
     */
    const disableUser = (user: UserType) => {
        const {id, name, email} = user;

        const confirmation = window.confirm(`Are you sure you want to disable the account for ${name}?`);
        if(!confirmation){
            return;
        }

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/disable`, {
            userId: id,
            name,
            email,
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });
    };

    /**
     *
     * @param user
     */
    const enableUser = (user: UserType) => {
        const {id, name, email} = user;

        const confirmation = window.confirm(`Are you sure you want to enable the account for ${name}?`);
        if(!confirmation){
            return;
        }

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/enable`, {
            userId: id,
            name,
            email,
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });
    };

    /**
     *
     * @param email
     */
    const resetPassword = (email: string) => {
        const confirmation = window.confirm(`Are you sure you want to send the password reset link to ${email}?`);
        if(!confirmation){
            return;
        }

        sendPasswordResetEmail(auth, email).then(() => {
            addNotification({message: `Password reset link has been sent to ${email}`, color: 'green'});
        }).catch((error) => {
            console.log(error);
            addNotification({message: error, color: 'red'});
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if(password !== passwordReEnter){
            addNotification({message: `Passwords must match.`, color: 'red'});
            return;
        }

        const nameInput = form.querySelector('#name') as HTMLInputElement;
        const emailInput = form.querySelector('#email') as HTMLInputElement;
        const roleInput = form.querySelector('#role') as HTMLSelectElement;
        const user = {
            name: nameInput.value,
            email: emailInput.value,
            password,
            role: parseInt(roleInput.value),
        };

        const userData = {userData: user, error: false, message: ''};

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/add`, userData)
            .then(res => {
                if(res.data.error){
                    addNotification({message: res.data.message, color: 'red'});
                } else{
                    form.reset();
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                const message = error.response?.data?.message || 'Error creating user'
                addNotification({message, color: 'red'});
            });
    };

    const openCreateUserForm = () => {
        setIsModalOpen(true);
        setCreatFormOpen(true);
    };

    return (
        <>
            <div className="grayBody"/>
            <div id="users">
                {/*Modal for Adding a New User*/}
                {
                    isModalOpen &&
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 101, //mui-datatables header has a z-index of 100
                    }}
                    >
                        {
                            isCreateFormOpen ?
                                <form onSubmit={handleSubmit} style={{
                                    backgroundColor: 'white',
                                    width: '500px',
                                    borderRadius: '0.5em',
                                    padding: '1em',
                                    marginBottom: '20em'
                                }}
                                >
                                    <h3 style={{textAlign: 'center'}}>Create User</h3>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr',
                                        gap: '0.3em 0.5em',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <label htmlFor="name">Name: </label>
                                        <input
                                            type="text"
                                            id="name"
                                            required
                                            className="form-control"
                                            placeholder="Name"
                                        />
                                        <label htmlFor="email">Email: </label>
                                        <input
                                            type="email"
                                            id="email"
                                            required
                                            className="form-control"
                                            placeholder="Email"
                                        />
                                        <label htmlFor="password">Password: </label>
                                        <input
                                            type="password"
                                            id="password"
                                            required
                                            className="form-control"
                                            placeholder="Password"
                                            value={password}
                                            autoComplete="new-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <label htmlFor="password">Re-Enter Password: </label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            required
                                            className="form-control"
                                            placeholder="Password"
                                            value={passwordReEnter}
                                            onChange={(e) => setPasswordReEnter(e.target.value)}
                                            autoComplete="new-password"
                                            style={{
                                                border: '2px solid',
                                                borderColor: password === passwordReEnter ? 'green' : 'red'
                                            }}
                                        />
                                        <label htmlFor="role">Role: </label>
                                        <select
                                            id="role"
                                            style={{
                                                borderRadius: '0.5em'
                                            }}
                                        >
                                            {
                                                Object.values(roles).map((key) => (
                                                    <option key={key} value={key}>
                                                        {rolesReadable[key]}
                                                    </option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                        <input type="submit" value="Create User" className="btn btn-primary"/>
                                        <button onClick={() => setIsModalOpen(false)} type="button"
                                                style={{
                                                    marginLeft: '0.3em',
                                                    borderRadius: '0.5em'
                                                }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </form>
                                :
                                <form onSubmit={updateUser} style={{
                                    backgroundColor: 'white',
                                    width: '400px',
                                    borderRadius: '0.5em',
                                    padding: '1em',
                                    marginBottom: '20em'
                                }}
                                >
                                    <h3 style={{textAlign: 'center'}}>Edit User</h3>
                                    <input type="hidden" name="userId" value={curUser.id}/>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr',
                                        gap: '0.3em 0.5em',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <label htmlFor="editName">Name</label>
                                        <input id="editName" name="name" type="text" defaultValue={curUser.name}/>
                                        <label htmlFor="editEmail">Email</label>
                                        <input id="editEmail" name="email" type="email" defaultValue={curUser.email}/>
                                        <label htmlFor="role">Role: </label>
                                        <select id="role" defaultValue={curUser.role}>
                                            {
                                                Object.values(roles).map((key) => (
                                                    <option value={key}>
                                                        {rolesReadable[key]}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                        <button type="submit">Save</button>
                                        <button onClick={() => setIsModalOpen(false)} type="button"
                                                style={{marginLeft: '0.3em'}}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </form>
                        }
                    </div>
                }
                {/*Modal for setting a new password*/}
                {
                    passwordResetUser &&
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 101, //mui-datatables header has a z-index of 100
                    }}
                    >
                        <form onSubmit={updatePassword} style={{
                            backgroundColor: 'white',
                            width: '400px',
                            borderRadius: '0.5em',
                            padding: '1em',
                            marginBottom: '20em'
                        }}
                        >
                            <h3 style={{textAlign: 'center'}}>Set New Password</h3>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: '0.3em 0.5em',
                                alignItems: 'center',
                            }}
                            >
                                <label htmlFor="password">Password: </label>
                                <input
                                    type="password"
                                    id="password"
                                    required
                                    className="form-control"
                                    placeholder="Password"
                                    value={password}
                                    autoComplete="new-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <label htmlFor="password">Re-Enter Password: </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    required
                                    className="form-control"
                                    placeholder="Password"
                                    value={passwordReEnter}
                                    onChange={(e) => setPasswordReEnter(e.target.value)}
                                    autoComplete="new-password"
                                    style={{
                                        border: '2px solid',
                                        borderColor: password === passwordReEnter ? 'green' : 'red'
                                    }}
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                <button type="submit">Save</button>
                                <button onClick={() => setPasswordResetUser(null)} type="button"
                                        style={{marginLeft: '0.3em'}}
                                >
                                    Close
                                </button>
                            </div>
                        </form>

                    </div>
                }
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1em',
                    color: 'black',
                    marginTop: '2em'
                }}
                >
                    <div className="table-page-container">
                        <h3 style={{color: 'black', textAlign: 'center'}}>Manage Users</h3>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <button
                                type="button"
                                onClick={openCreateUserForm}
                                style={{
                                    borderRadius: '0.5em',
                                    backgroundColor: 'blue',
                                    borderColor: 'white',
                                    color: 'white'
                                }}
                            >
                                Add User
                            </button>
                        </div>
                        <MUIDataTable columns={columnsDef} data={Object.values(savedUsers)} title="Users" options={{
                            selectableRows: 'none',
                            print: false,
                            downloadOptions: {filterOptions: {useDisplayedRowsOnly: true}}
                            /*onDownload: (buildHead, buildBody, columns, data: DisplayData) => {
                                data.forEach((outerData) => {
                                    const role: RoleKeys = outerData.data[columnsDef.findIndex(col => col.name === 'role')];
                                    outerData.data[2] = role in rolesReadable ? rolesReadable[role] : 'none';
                                });

                                return buildHead(columns) + buildBody(data);
                            }*/
                        }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateUsers;
