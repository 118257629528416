import CompassHeading from './compassHeading';
import {useEffect, useRef} from 'react';
import DraggableSnap from '../../js/DraggableSnap';

const {v4: uuidv4} = require('uuid');

const generateKey = () => `ftrCompass_${new Date().getTime()}`;

type Props = {
    draggable?: boolean;
    grid: [number[], number[]];
    pitch: number;
    roll: number;
    width: string;
    height: string;
    demo: boolean;
}

const FtrCompass = (props: Props) => {
    const {draggable = false, grid, roll, width, height} = props;
    const containerID = uuidv4();
    const ftrCompassID = generateKey();
    const ftrCompassRef = useRef<HTMLCanvasElement>(null!);

    const colors = {
        green: '#54ED32',
        yellow: '#EDE432',
        red: '#E81C1C',
        brown: '#a8640e'
    };

    const drawWorld = (cnvs: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
        const canvas= cnvs;
        const deg = 0;

        //Draw the larger grey circle
        ctx.fillStyle = 'grey';
        ctx.beginPath();
        ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 30,
            0,
            Math.PI * 2
        );
        ctx.fill();

        // Draw the white circle
        ctx.fillStyle = '#FFFFFF';
        ctx.beginPath();
        ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2 - 40,
            0,
            Math.PI * 2
        );
        ctx.fill();

        //
        let i = 5;
        while (i <= 360) {
            ctx.font = '20px Arial';
            ctx.fillStyle = 'black'

            // Set default font
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((10 * Math.PI) / 180);
            ctx.translate(-canvas.width / 2, -canvas.height / 2);
            ctx.moveTo(canvas.width / 2, 70);
            ctx.lineTo(canvas.width / 2, 65);
            ctx.stroke();

            switch (i) {
                case 20:
                    ctx.font = '15px Arial';
                    ctx.fillText('3.33', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 40:
                    ctx.font = '15px Arial';
                    ctx.fillText('4.17', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 60:
                    ctx.font = '15px Arial';
                    ctx.fillText('5.00', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 300:
                    ctx.font = '15px Arial';
                    ctx.fillText('0.00', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 320:
                    ctx.font = '15px Arial';
                    ctx.fillText('0.83', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 340:
                    ctx.font = '15px Arial';
                    ctx.fillText('1.67', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                case 360:
                    ctx.font = '15px Arial';
                    ctx.fillText('2.50', canvas.width / 2 - 10, 60);
                    ctx.moveTo(canvas.width / 2, 74);
                    ctx.lineTo(canvas.width / 2, 65);
                    ctx.stroke();
                    break;
                default:
            }
            // Go until 360
            i += 5;
        }

        //Stop rotating
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((-deg * Math.PI) / 180);
        ctx.translate(-canvas.width / 2, -canvas.height / 2);
    };

    const ftrCompassWidget = async () => {
        // Get the canvas and its context. We will always be manipulating it.
        const canvas = ftrCompassRef.current;
        if(!canvas){
            return;
        }

        const ctx = canvas.getContext('2d');
        if(!ctx){
            return;
        }

        drawWorld(canvas, ctx);
    }

    useEffect(() => {
        ftrCompassWidget().then();
        if(draggable) {
            const ftrCompassWrapper = new DraggableSnap(
                document.getElementById(containerID)
            );
            ftrCompassWrapper.addDragging(grid[0], grid[1])
        }
    })

    return (
        <div>
            <canvas
                id={ftrCompassID}
                ref={ftrCompassRef}
                width={width}
                height={height}
                style={{border: '5px solid #000000'}}
            />
        </div>
    )

};

export default FtrCompass;