import React, {useContext, useRef, useState} from 'react';
import {
    tmStatuses,
    tmStatusesReadable,
    tmEvents,
    tmEventsReadable,
} from '../../enums';
import ClientContext from '../../helpers/ClientContext';
import {getFlightPathProps, getTMinusTime} from '../../helpers/helper';
import TmBar from './TmBar';
import Select, {Options} from 'react-select';
import {ConfigurationType, Option, Tm, TmEventKeys, TmStatusKeys} from '../../helpers/types';

type Props = {
    telemetryID: number;
    form: Tm;
    updateTelemetry: (index: number, updatedTm: Tm | null) => void;
    statusColors: {
        [tmStatuses.STATIC]: string;
        [tmStatuses.TRACKING]: string;
        [tmStatuses.NOT_TRACKING]: string;
    }
    eventColors: {
        [tmEvents.DROPOUT]: string;
    }
    timeOptions: JSX.Element[];
    configuration: ConfigurationType;
}

const TelemetryForm = ({
                           telemetryID,
                           form,
                           updateTelemetry,
                           statusColors,
                           eventColors,
                           timeOptions,
                           configuration
                       }: Props) => {
    const {addNotification} = useContext(ClientContext);
    const {events, statuses, startTime, stopTime, title} = form;
    const maxTime = getFlightPathProps(configuration).dataPointsShown;
    const statusTimeRef = useRef<HTMLSelectElement>(null!);
    const statusRef = useRef<HTMLSelectElement>(null!);
    const eventStartTimeRef = useRef<HTMLSelectElement>(null!);
    const eventStopTimeRef = useRef<HTMLSelectElement>(null!);
    const randomStartTimeRef = useRef<HTMLSelectElement>(null!);
    const randomStopTimeRef = useRef<HTMLSelectElement>(null!);
    const minDurationRef = useRef<HTMLInputElement>(null!);
    const maxDurationRef = useRef<HTMLInputElement>(null!);
    const minGapRef = useRef<HTMLInputElement>(null!);
    const maxGapRef = useRef<HTMLInputElement>(null!);
    const eventRef = useRef<HTMLSelectElement>(null!);
    const [selectedRandomEvents, setSelectedRandomEvents] = useState<Option[]>([]);

    const addStatus = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!statusRef.current || !statusTimeRef.current){
            return;
        }

        const uncheckedStatus = parseInt(statusRef.current.value);

        function parseStatus(status: number): TmStatusKeys{
            const tmStatusVals = Object.values(tmStatuses);
            const validStatus = tmStatusVals.find((validName) => validName === status);
            if(validStatus){
                // `validStatus` comes from the list of `tmStatusVals` so the compiler is happy.
                return validStatus;
            }
            throw new Error('That is not a tm status.');
        }

        const status = parseStatus(uncheckedStatus);

        const statusTime = parseInt(statusTimeRef.current.value);

        const times = statuses.map(({time}) => time);
        if(times.includes(statusTime) || statusTime > maxTime){
            return;
        }

        const newStatuses = [...form.statuses];
        newStatuses.push({status, time: statusTime});
        newStatuses.sort((a, b) => a.time - b.time);

        const newForm = {...form};
        newForm.statuses = newStatuses;

        updateTelemetry(telemetryID, newForm);
    };

    function parseEvent(event: number): TmEventKeys{
        const tmEventVals = Object.values(tmEvents);
        const validEvent = tmEventVals.find((validName) => validName === event);
        if(validEvent){
            // `validEvent` comes from the list of `tmEventVals` so the compiler is happy.
            return validEvent;
        }
        throw new Error('That is not a tm event.');
    }

    const handleEventSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!eventRef.current || !eventStartTimeRef.current || !eventStopTimeRef.current){
            return;
        }

        const uncheckedEvent = parseInt(eventRef.current.value);

        const event = parseEvent(uncheckedEvent);

        const eventsStartTime = parseInt(eventStartTimeRef.current.value);
        const eventsStopTime = parseInt(eventStopTimeRef.current.value);

        const isValid = validateEvent(eventsStartTime, eventsStopTime, event, true);
        if(!isValid){
            return;
        }

        const newEvents = [...form.events];
        newEvents.push({event, time: eventsStartTime, time2: eventsStopTime});
        newEvents.sort((a, b) => a.time - b.time);

        const newForm = {...form};
        newForm.events = newEvents;

        updateTelemetry(telemetryID, newForm);
    };

    function validateEvent(eventsStartTime: number, eventsStopTime: number, event: TmEventKeys, showError: boolean){
        //Check different cases
        if(eventsStartTime > eventsStopTime){
            if(showError){
                addNotification({
                    message: 'The event\'s start time cannot be greater than the event stop time.',
                    color: 'red',
                });
            }
            return false;
        }
        if(eventsStartTime > stopTime){
            if(showError){
                addNotification({
                    message: 'The event\'s start time cannot be greater than the Tm stop time.',
                    color: 'red',
                });
            }
            return false;
        }

        //Check to make sure there are not previous events with same start/stop time
        const overlaps = events.some(({event: curEvent, time, time2}) => {
            if(eventsStartTime >= time && eventsStartTime <= time2){
                if(showError){
                    addNotification({
                        message: `Start overlaps with ${tmEventsReadable[curEvent]}: ${time} - ${time2}`,
                        color: 'red',
                    });
                }

                return true;
            }

            if(eventsStopTime >= time && eventsStopTime <= time2){
                if(showError){
                    addNotification({
                        message: `End overlaps with ${tmEventsReadable[curEvent]}: ${time} - ${time2}`,
                        color: 'red',
                    });
                }

                return true;
            }

            return false;
        });

        if(overlaps){
            return false;
        }

        return true;
    }

    const deleteStatus = (index: number) => {
        const newStatuses = [...form.statuses];
        newStatuses.splice(index, 1);

        const newForm = {...form};
        newForm.statuses = newStatuses;

        updateTelemetry(telemetryID, newForm);
    };

    const deleteEvent = (index: number) => {
        const newEvents = [...form.events];
        newEvents.splice(index, 1);

        const newForm = {...form};
        newForm.events = newEvents;

        updateTelemetry(telemetryID, newForm);
    };

    const removeTelemetry = () => {
        if(events.length || statuses.length){
            if(!window.confirm('Are you sure you want to remove the Telemetry?')){
                return;
            }
        }

        updateTelemetry(telemetryID, null);
    };

    const sendTime = (time: number, name: 'startTime' | 'stopTime') => {
        const newForm = {...form};

        if(time > maxTime){
            time = maxTime;

            newForm.stopTime = time;
        } else if(time < 0){
            time = 0;

            newForm.startTime = time;
        } else if(Number.isNaN(time)){
            time = 0;
            newForm[name] = time;
        } else{
            newForm[name] = time;
        }

        updateTelemetry(telemetryID, newForm);
    };

    const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newForm = {...form};
        newForm.title = event.target.value;
        updateTelemetry(telemetryID, newForm);
    };

    function updateSelectedRandomEvents(selectedOptions: Options<Option>){
        if(selectedOptions === null || !Array.isArray(selectedOptions)){
            return;
        }

        setSelectedRandomEvents(selectedOptions);
    }

    function addRandomEvents(event: React.MouseEvent<HTMLButtonElement>){
        if(!minDurationRef.current.value || !maxDurationRef.current.value || !minGapRef.current.value || !maxGapRef.current.value){
            addNotification({
                message: 'Min Duration, Max Duration, Min Gap, and Max Gap are required',
                color: 'red',
            });
            return;
        }
        if(!selectedRandomEvents.length){
            addNotification({
                message: 'Must select at least 1 Event',
                color: 'red',
            });
            return;
        }

        const randomStartTime = parseInt(randomStartTimeRef.current.value);
        const randomStopTime = parseInt(randomStopTimeRef.current.value);

        if(randomStartTime > randomStopTime){
            addNotification({
                message: 'The start time cannot be greater than the stop time',
                color: 'red',
            });
            return;
        }

        const minDuration = parseInt(minDurationRef.current.value);
        const maxDuration = parseInt(maxDurationRef.current.value);

        if(minDuration < 1 || maxDuration < 1){
            addNotification({
                message: 'Min Duration and Max Duration must be at least one second',
                color: 'red',
            });
            return;
        }
        if(minDuration > maxDuration){
            addNotification({
                message: 'Max Duration must be greater than or equal to Min Duration',
                color: 'red',
            });
            return;
        }

        const minGap = parseInt(minGapRef.current.value);
        const maxGap = parseInt(maxGapRef.current.value);

        if(minGap < 0 || maxGap < 0){
            addNotification({
                message: 'Min Gap and Max Gap must be at least zero seconds',
                color: 'red',
            });
            return;
        }
        if(minGap > maxGap){
            addNotification({
                message: 'Max Gap must be greater than or equal to Min Gap',
                color: 'red',
            });
            return;
        }

        const durationDiff = maxDuration - minDuration;
        const gapDiff = maxGap - minGap;
        let curTime = randomStartTime + Math.floor(Math.random() * (gapDiff + 1));
        const events = [];
        while(curTime <= randomStopTime) {
            const duration = minDuration + Math.floor(Math.random() * (durationDiff + 1)) - 1; // subtract 1 at end bc duration of 0 is still 1 second

            const start = curTime;
            let stop = curTime + duration;
            if(stop > randomStopTime){
                stop = randomStopTime;
            }
            const event = parseEvent(parseInt(selectedRandomEvents[Math.floor(Math.random() * selectedRandomEvents.length)].value));
            const isValid = validateEvent(start, stop, event, false);
            if(isValid){
                events.push({event, time: start, time2: stop});
            }

            curTime = stop + 1;
            const gap = minGap + Math.floor(Math.random() * (gapDiff + 1));
            curTime += gap;
        }

        const newForm = {...form};
        newForm.events = [...newForm.events, ...events];
        newForm.events.sort((a, b) => a.time - b.time);

        updateTelemetry(telemetryID, newForm);
    }

    return (
        <div className="telemetryForm" style={{
            padding: '5px',
            backgroundColor: 'white',
            marginBottom: '10px',
            borderRadius: '0.5rem',
        }}
        >
            <div style={{padding: '0.5rem'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem'}}>
                    <button className="deleteBtn" type="button" style={{padding: '0 0.5rem'}} onClick={removeTelemetry}>
                        X
                    </button>
                </div>
                <input type="text" style={{width: '100%', fontSize: '1.5rem', paddingLeft: '.5rem'}} placeholder="Title"
                       onInput={updateTitle} value={title}
                />
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'start',
                    gap: '0.5rem 1rem',
                    alignItems: 'center',
                    marginTop: '0.5rem'
                }}
                >
                    <label htmlFor={`start${telemetryID}`} style={{marginBottom: 0}}>Start Time:</label>
                    <select id={`start${telemetryID}`}
                            onChange={e => sendTime(parseInt(e.currentTarget.value), 'startTime')} value={startTime}
                    >
                        {timeOptions}
                    </select>
                    <label htmlFor={`stop${telemetryID}`} style={{marginBottom: 0}}>Stop Time:</label>
                    <select id={`stop${telemetryID}`}
                            onChange={e => sendTime(parseInt(e.currentTarget.value), 'stopTime')} value={stopTime}
                    >
                        {timeOptions}
                    </select>
                </div>

                {/*Statuses*/}
                <div style={{border: '1px solid black', padding: '0.3rem', marginTop: '0.5rem'}}>
                    <h4 style={{textAlign: 'center'}}>Statuses</h4>
                    <form style={{bottom: 0}} onSubmit={addStatus}>
                        <table>
                            <tbody>
                            {
                                statuses.map(({status, time}, index) =>
                                    <tr key={time}>
                                        <td>{tmStatusesReadable[status]}</td>
                                        <td>{getTMinusTime(time, configuration)}</td>
                                        <td>
                                            <button type="button" onClick={() => deleteStatus(index)}>Delete</button>
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td>
                                    <select
                                        className="status"
                                        ref={statusRef}
                                    >
                                        <option value={tmStatuses.STATIC}>
                                            {tmStatusesReadable.STATIC}
                                        </option>
                                        <option value={tmStatuses.TRACKING}>
                                            {tmStatusesReadable.TRACKING}
                                        </option>
                                        <option
                                            value={tmStatuses.NOT_TRACKING}
                                        >{tmStatusesReadable.NOT_TRACKING}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select ref={statusTimeRef}>
                                        {timeOptions}
                                    </select>
                                </td>
                                <td>
                                    <button type="submit">Add</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                {/*Events*/}
                <div style={{border: '1px solid black', padding: '0.3rem', marginTop: '0.5rem'}}>
                    <h4 style={{textAlign: 'center'}}>Events</h4>
                    <form style={{bottom: 0}} onSubmit={handleEventSubmit}>
                        <table>
                            <tbody>
                            {
                                events.map(({event, time, time2}, index) =>
                                    <tr key={time}>
                                        <td>{tmEventsReadable[event]}</td>
                                        <td>{getTMinusTime(time, configuration)}</td>
                                        <td>{getTMinusTime(time2, configuration)}</td>
                                        <td>
                                            <button style={{backgroundColor: 'red', color: 'white'}} type="button"
                                                    onClick={() => deleteEvent(index)}
                                            >
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td>
                                    <select
                                        className="events"
                                        ref={eventRef}
                                    >
                                        <option value={tmEvents.DROPOUT}>{tmEventsReadable.DROPOUT}</option>
                                    </select>
                                </td>
                                <td>
                                    <select ref={eventStartTimeRef}>
                                        {timeOptions}
                                    </select>
                                </td>
                                <td>
                                    <select ref={eventStopTimeRef}>
                                        {timeOptions}
                                    </select>
                                </td>
                                <td>
                                    <button type="submit">+</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div style={{border: '1px solid black', marginTop: '.5rem', padding: '0.5rem'}}>
                    <h4 style={{textAlign: 'center'}}>Random Events</h4>
                    <div style={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'repeat(3, auto) 1fr',
                        gap: '.5rem'
                    }}
                    >
                        <label htmlFor="randomStartTime" style={{marginBottom: 0}}>
                            Start
                        </label>
                        <select id="randomStartTime" ref={randomStartTimeRef}>
                            {timeOptions}
                        </select>
                        <label htmlFor="randomStopTime" style={{marginBottom: 0, marginLeft: '1rem'}}>
                            Stop
                        </label>
                        <select id="randomStopTime" ref={randomStopTimeRef}>
                            {timeOptions}
                        </select>
                        <label htmlFor="minDuration" style={{marginBottom: 0}}>
                            Min Duration (s)
                        </label>
                        <input ref={minDurationRef} id="minDuration" type="number" step={1}
                               style={{width: 0, minWidth: '100%'}} min="1"
                        />
                        <label htmlFor="maxDuration" style={{marginBottom: 0, marginLeft: '1rem'}}>
                            Max Duration (s)
                        </label>
                        <input ref={maxDurationRef} id="maxDuration" type="number" step={1}
                               style={{width: 0, minWidth: '100%'}} min="1"
                        />
                        <label htmlFor="minGap" style={{marginBottom: 0}}>
                            Min Gap
                        </label>
                        <input ref={minGapRef} id="minGap" type="number" step={1} min="0"
                               style={{width: 0, minWidth: '100%'}}
                        />
                        <label htmlFor="maxGap" style={{marginBottom: 0, marginLeft: '1rem'}}>
                            Max Gap
                        </label>
                        <input ref={maxGapRef} id="maxGap" type="number" step={1} min="0"
                               style={{width: 0, minWidth: '100%'}}
                        />
                        <div style={{gridColumn: '1 / -1', display: 'flex', alignItems: 'center'}}>
                            <label htmlFor="randomEvents" style={{marginBottom: 0}}>Events</label>
                            <div style={{flex: 1, marginLeft: '.5rem'}}>
                                <Select
                                    id="randomEvents"
                                    options={Object.values(tmEvents).map(event => ({
                                        label: tmEventsReadable[event],
                                        value: event.toString()
                                    }))}
                                    onChange={updateSelectedRandomEvents}
                                    value={selectedRandomEvents}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div style={{gridColumn: '4 / span 1', display: 'flex', justifyContent: 'flex-end'}}>
                            <button onClick={addRandomEvents}>Add</button>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '0.5rem'}}>
                    <TmBar statuses={statuses} events={events} stopTime={stopTime} maxTime={maxTime} time={maxTime}
                           statusColors={statusColors} eventColors={eventColors}
                    />
                </div>
            </div>
        </div>
    );
};

export default TelemetryForm;
