import {createGrid, useWindowSize} from '../../helpers/helper';
import React, {useContext} from 'react';
import ClientContext from '../../helpers/ClientContext';
import {
    acif,
    acArrw,
    hob,
    pdu,
    imu,
    bem,
    bcas,
    fcas,
    egr,
    isd,
    esaf,
    o2s
} from '../../helpers/tblConfigCommLogs';
import Table from '../widgets/table';

const gridArray = createGrid(100);

const CommLogs = () => {
    const screenSize = useWindowSize();
    const {widgetState} = useContext(ClientContext);

    return (
        <>
            <div className="blackBody"/>

            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: 'Courier New, sans-serif',

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute'
                    }}
                    >
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                            height: 'fit-content',
                            background: 'green',
                            textAlign: 'center',
                        }}
                        >
                            <p style={{color: 'black'}}>Unclassified</p>
                        </div>
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                        }}
                        >
                            <p style={{color: 'rgb(87, 168, 101)'}}>Missile - BTF-2 Ver. 3.1 2/17/2022</p>
                        </div>
                    </div>
                </div>

                <div style={{
                    flexDirection: 'column'
                }}>
                    <div style={{
                        border: '1px solid white',
                        blockSize: 'fit-content',
                        marginLeft: '800px',
                        width: 'fit-content',
                        height: '100px',
                        justifyContent: 'center',
                    }}
                    >
                        <h1 style={{
                            color: 'red',
                            textDecoration: 'underline',
                            fontFamily: 'Courier New, -sans-serif',
                            fontSize: '5rem',
                            whiteSpace: 'nowrap',
                        }}
                        >
                            Comm Logs
                        </h1>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '505px'
                }}

                >
                    {/*ACIF*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={acif}
                        radius={10}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={250}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*AC ARRW*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={acArrw}
                        radius={13}
                        tableBorder="1px solid white"
                        rowPadding="7px"
                        tableHeight={200}
                        tableWidth={600}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*HOB*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={hob}
                        radius={19}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={250}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}

                >
                    {/*FCAS*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={fcas}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*PDU*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={pdu}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*IMU*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={imu}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*BEM*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={bem}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*bcas*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={bcas}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*EGR*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={egr}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*ISD*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={isd}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*ESAF*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={esaf}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                    {/*02S*/}
                    <Table
                        draggable={false}
                        grid={gridArray}
                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                        config={o2s}
                        radius={7}
                        tableBorder="1px solid white"
                        rowPadding="5px"
                        tableHeight={200}
                        tableWidth={550}
                        textColor="yellow"
                        textSize="1rem"
                        underlineHeader="underline"
                        headColor="yellow"
                    />
                </div>
            </div>
        </>
    )
}

export default CommLogs;