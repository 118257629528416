import React, {useLayoutEffect, useEffect, useState, useContext, useRef} from 'react';
import '../../img.css';
import PropTypes from 'prop-types';
import massiveMap from '../../images/mapCropped.png';
import DraggableSnap from '../../js/DraggableSnap';
import {adjustData, createGrid, drawRoute} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';

const ImageHoverZoom = props => {
    const {
        graphData,
        maxMinData,
        imgHeight,
        imgWidth,
        zoom,
        xPos,
        yPos,
        resultSize,
        resultPos,
        lines,
        scaleX = 1,
        scaleY = 1,
    } = props;

    const {client, widgetState, locationData} = useContext(ClientContext);

    const [cx, setCx] = useState();
    const [points, setPoints] = useState(() => lines.map(() => ''));
    const scaleXRef = useRef(scaleX);
    const scaleYRef = useRef(scaleY);
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(true);

    useEffect(() => {
        scaleXRef.current = scaleX;
        scaleYRef.current = scaleY;
    }, [scaleX, scaleY]);

    const bounds = {
        //[maxLat, minLat, maxLon, minLon]
        maxLat: maxMinData[0],
        minLat: maxMinData[1],
        maxLon: maxMinData[2],
        minLon: maxMinData[3],
    };

    /**
     *
     * @param e
     * @param img
     * @returns {{x: number, y: number}}
     */
    function getCursorPos(e, img){
        /* Get the x and y positions of the image: */
        const a = img.getBoundingClientRect();

        /* Calculate the cursor's x and y coordinates, relative to the image: */
        let x = (e.pageX - a.left) / scaleXRef.current;
        let y = (e.pageY - a.top) / scaleYRef.current;

        /* Consider any page scrolling: sdf */
        x -= window.scrollX;
        y -= window.scrollY;

        return {x, y};
    }

    /**
     *
     * @param e
     * @param lens
     * @param img
     */
    const moveLens = (e, lens, img) => {
        if(e.target.closest('#result')){
            return;
        }

        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();

        /* Get the cursor's x and y positions: */
        const pos = getCursorPos(e, img);

        /* Calculate the position of the lens: */
        let x = pos.x - (lens.offsetWidth / 2);
        let y = pos.y - (lens.offsetHeight / 2);

        /* Prevent the zoom from being positioned outside the image: */
        if(x > img.offsetWidth - lens.offsetWidth){
            x = img.offsetWidth - lens.offsetWidth;
        }
        if(x < 0){
            x = 0;
        }
        if(y > img.offsetHeight - lens.offsetHeight){
            y = img.offsetHeight - lens.offsetHeight;
        }
        if(y < 0){
            y = 0;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setLocationData',
                fields: {x: x / imgWidth, y: y / imgHeight},
            })
        );
    };

    /**
     *
     * @param imgID
     * @param resultID
     */
    const imageZoom = (imgID, resultID) => {
        const img = document.getElementById(imgID);
        const result = document.getElementById(resultID);

        /* Create lens: */
        const lens = document.createElement('DIV');
        lens.setAttribute('class', 'img-zoom-lens');
        img.parentElement.insertBefore(lens, img);

        /* Calculate the ratio between result DIV and lens: */
        const curCx = result.offsetWidth / lens.offsetWidth;
        const cy = result.offsetHeight / lens.offsetHeight;

        const graphWrapper = new DraggableSnap(result, document.querySelector('#original'));
        const gridArray = createGrid(100);
        graphWrapper.addDragging(gridArray[0], gridArray[1]);

        result.querySelector('div').style.transform = `scale(${curCx}, ${cy})`;

        if(zoom){
            /* Execute a function when someone moves the cursor over the image, or the lens: */
            lens.addEventListener('click', e => {
                moveLens(e, lens, img);
            });
            img.addEventListener('click', e => {
                moveLens(e, lens, img);
            });
        } else{
            lens.style.display = 'none';
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setLocationData',
                fields: {scale: curCx * imgWidth / resultSize.width.split('px').shift()},
            })
        );

        setCx(curCx);

        setIsLoading(false);
    };

    useEffect(() => {
        drawRoute(imgWidth, imgHeight, bounds, graphData, lines, points, setPoints);
    }, [graphData]);

    useEffect(() => {
        if(!isLoadingRef.current || widgetState.time === undefined){
            return;
        }
        const oldData = adjustData(0, widgetState.time, locationData.slice(0, widgetState.time + 1), widgetState.prevAdjustments);
        drawRoute(imgWidth, imgHeight, bounds, oldData, lines, points, setPoints);

        isLoadingRef.current = false;

    }, [widgetState.time])

    useLayoutEffect(() => {
        const result = document.querySelector('#result');
        const lens = document.querySelector('.img-zoom-lens');

        if(!lens || isLoading){
            return;
        }

        /* Set the position of the lens: */
        lens.style.left = xPos * imgWidth + 'px';
        lens.style.top = yPos * imgHeight + 'px';

        /* Display what the lens "sees": */
        result.scrollLeft = xPos * imgWidth * cx;
        result.scrollTop = yPos * imgHeight * cx;
    }, [xPos, yPos, isLoading]);

    useLayoutEffect(() => {
        if(zoom){
            imageZoom('original', 'result');
        }
    }, []);

    return (
        <div style={{position: 'relative'}}>
            <div id="original" style={{height: imgHeight, width: imgWidth, position: 'relative'}}>
                <div>
                    <svg className="mapSVG" style={{height: imgHeight, width: imgWidth}}>
                        <defs>
                            <marker id="triangle" viewBox="-8 0 10 10"
                                    refX="1" refY="5"
                                    markerUnits="strokeWidth"
                                    markerWidth="10" markerHeight="10"
                                    orient="auto">
                                <path d="M -8 0 L 2 5 L -8 10 z" fill="#0AD2D2"/>
                            </marker>
                        </defs>
                        {
                            lines.map((line, index) => {
                                return (
                                    <polyline
                                        key={index}
                                        fill="none"
                                        stroke={line.color}
                                        strokeWidth={1}
                                        points={points[index]}
                                        // markerEnd="url(#triangle)"
                                    />
                                );
                            })
                        }
                    </svg>
                    <img height={imgHeight} width={imgWidth} src={massiveMap} alt=""/>
                </div>
                {
                    zoom &&
                    <div id="result" style={{...resultSize, ...resultPos}}>
                        <div style={{transformOrigin: '0 0', position: 'relative'}}>
                            <svg className="mapSVG" style={{height: imgHeight, width: imgWidth}}>
                                {
                                    lines.map((line, index) => {
                                        return (
                                            <polyline
                                                key={index}
                                                fill="none"
                                                stroke={line.color}
                                                strokeWidth={1}
                                                points={points[index]}
                                                // markerEnd="url(#triangle)"
                                            />
                                        );
                                    })
                                }
                            </svg>
                            <img height={imgHeight} width={imgWidth} src={massiveMap} alt=""/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

ImageHoverZoom.defaultProps = {
    maxMinData: ['', '', '', ''],
    imgHeight: '',
    imgWidth: '',
    zoom: false,
    resultPos: {bottom: 0, left: 0},
    resultSize: {height: '200px', width: '200px'},
};

ImageHoverZoom.propTypes = {
    graphData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))).isRequired,
    xPos: PropTypes.number.isRequired,
    yPos: PropTypes.number.isRequired,
    lines: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    maxMinData: PropTypes.arrayOf(PropTypes.string),
    imgHeight: PropTypes.number,
    imgWidth: PropTypes.number,
    zoom: PropTypes.bool,
    resultSize: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    resultPos: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default ImageHoverZoom;


