const barTypes = {
    RADAR: 1,
    TM: 2,
} as const;

const barDescriptors = {
    RADAR_STATUSES: 1,
    RADAR_EVENTS: 2,
    TM_STATUSES: 3,
    TM_EVENTS: 4
} as const;

const radarStatuses = {
    ACQUIRED: 1,
    TRACKING: 2,
    POWERED: 3,
    NON_POWERED: 4,
    PROPAGATING: 5
} as const;

const radarStatusesReadable = Object.freeze({
    1: 'Acquired',
    ACQUIRED: 'Acquired',
    2: 'Tracking',
    TRACKING: 'Tracking',
    3: 'Powered',
    POWERED: 'Powered',
    4: 'Non-Powered',
    NON_POWERED: 'Non-Powered',
    5: 'Propagating',
    PROPAGATING: 'Propagating',
});

const radarEvents = {
    RADIATING: 1,
    DROPOUT: 2,
    DISCRIMINATING: 3
} as const;

const radarEventsReadable = {
    1: 'Radiating',
    RADIATING: 'Radiating',
    2: 'Dropout',
    DROPOUT: 'Dropout',
    3: 'Discriminating',
    DISCRIMINATING: 'Discriminating',
} as const;

const tmStatuses = {
    STATIC: 1,
    TRACKING: 2,
    NOT_TRACKING: 3
} as const;

const tmStatusesReadable = {
    1: 'Static',
    STATIC: 'Static',
    2: 'Tracking',
    TRACKING: 'Tracking',
    3: 'Not Tracking',
    NOT_TRACKING: 'Not Tracking',
} as const;

const tmEvents = {
    DROPOUT: 1
} as const;

const tmEventsReadable = Object.freeze({
    1: 'Dropout',
    DROPOUT: 'Dropout',
});

const roles = {
    ADMIN: 1,
    NON_ADMIN: 2,
} as const;

const rolesReadable = Object.freeze({
    ADMIN: 'Admin',
    1: 'Admin',
    NON_ADMIN: 'Non-Admin',
    2: 'Non-Admin',
});

const pages = {
    FTSO: 1,
    HS: 2,
    TRAJECTORIES: 3,
    TEST_CONDUCTOR: 4,
    STATUS_BOARD: 5,
    CONTROLLER: 6,
    CONTROLLER2: 7,
    RADARS: 8,
    TELEMETRY: 9,
    SAFETY2: 10,
    MAPS: 11,
    AIRCRAFT_INTEGRATION: 12,
    BCAS: 13,
    COMM_LOGS: 14,
    FLIGHT_SAFETY: 15,
    CHECKLIST: 16,
} as const;

const pagesReadable = {
    1: 'Safety',
    FTSO: 'Safety',
    2: 'Flight H&S',
    HS: 'Flight H&S',
    3: 'Misc.',
    TRAJECTORIES: 'Misc.',
    4: 'Weapon',
    TEST_CONDUCTOR: 'Weapon',
    5: 'Status Board',
    STATUS_BOARD: 'Status Board',
    6: 'Controller',
    CONTROLLER: 'Controller',
    7: 'Off-Nom Controller',
    CONTROLLER2: 'Off-Nom Controller',
    8: 'Radar',
    RADARS: 'Radar',
    9: 'Telemetry',
    TELEMETRY: 'Telemetry',
    10: 'Safety2',
    SAFETY2: 'Safety2',
    11: 'Maps',
    MAPS: 'Maps',
    12: 'Aircraft Integration',
    AIRCRAFT_INTEGRATION: 'Aircraft Integration',
    13: 'BCAS',
    BCAS: 'BCAS',
    14: 'Comm Logs',
    COMM_LOGS: 'Comm Logs',
    15: 'Flight Safety',
    FLIGHT_SAFETY: 'Flight Safety',
    16: 'Checklist',
    CHECKLIST: 'Checklist'
} as const;

const actions = Object.freeze({
    testEvent1: 1,
    testEvent2: 2,
    testEvent3: 3,
    testEvent4: 4,
    testEvent5: 5,
    asset1: 6,
    asset2: 7,
    asset3: 8,
    asset4: 9,
    asset5: 10,
});

const csvFiles = {
    BOOST_GLIDE: 1,
    GROUND_BOOST_GLIDE: 2,
    BALLISTIC: 3,
} as const;

const csvFilePaths = Object.freeze({
    BOOST_GLIDE: './csvFiles/boost_glide.csv',
    1: './csvFiles/boost_glide.csv',
    GROUND_BOOST_GLIDE: './csvFiles/ground_boost_glide.csv',
    2: './csvFiles/ground_boost_glide.csv',
    BALLISTIC: './csvFiles/ballistic.csv',
    3: './csvFiles/ballistic.csv'
});

const csvFilesReadable = {
    BOOST_GLIDE: 'Boost Glide',
    '1': 'Boost Glide',
    GROUND_BOOST_GLIDE: 'Ground Boost Glide',
    '2': 'Ground Boost Glide',
    BALLISTIC: 'Ballistic',
    '3': 'Ballistic',
} as const;

const csvFilesPoints = Object.freeze({
    [csvFiles.BOOST_GLIDE]: {
        0: {offNom: 40000},
        6: {offNom: 39730.28262},
        115: {offNom: 182454.2197},
        221: {offNom: 127331.9346},
        276: {offNom: 133726.1749},
        543: {offNom: 115366.2194},
        561: {offNom: 115858.7263},
        639: {offNom: 15532.3601},
    },
    [csvFiles.GROUND_BOOST_GLIDE]: {
        0: {offNom: 2900},
        115: {offNom: 182454.2197},
        221: {offNom: 127331.9346},
        276: {offNom: 133726.1749},
        543: {offNom: 115366.2194},
        561: {offNom: 115858.7263},
        639: {offNom: 15532.3601},
    },
    [csvFiles.BALLISTIC]: {
        0: {offNom: 7.25},
        97: {offNom: 34574.65},
        185: {offNom: 0},
    },
});

const varTitles = {
    roll: 'Roll',
    pitch: 'Pitch',
    yaw: 'Yaw',
    offNom: 'Off-Nom Altitude',
    batVolt: 'Battery Voltage',
    curDraw: 'Current Draw',
    CDRsig: 'CDR Signal',
    velocity: 'Velocity (Mach)',
    lat: 'Aircraft Latitude',
    lon: 'Aircraft Longitude',
    rand: 'Downrange',
    ranc: 'Crossrange',
    alt: 'Nominal Altitude',
    weapon: 'Weapon',
    lat2: 'Weapon Latitude',
    lon2: 'Weapon Longitude',
    bounds: 'Bounds',
    keepOutX: 'Keep Out X',
    keepOutY: 'Keep Out Y',
    ftrOneGo: 'FTR 1 GO',
    ftrOneCmdGo: 'FTR 1 CMD GO',
    ftrOneSigStr: 'FTR_1 Sig Strength TM Out',
    tmXmtrTck69: 'TM Xmtr-TCK69',
    srmOneDome: 'SRM 1 Dome PS04',
    aurReady: 'AUR_Ready',
    fcasEcuOp: 'FCAS ECU Operational',
    vmcGood: 'VMC Good',
    gyroGood: 'Gyro Good',
    ftrTwoGo: 'FTR 2 GO',
    ftrTwoCmdGo: 'FTR 2 CMD GO',
    ftrTwoSigStr: 'FTR_2 Sig Strength TM Out',
    ftrOneLTck: 'FTR_1 L-TCK58',
    srmTwoDome: 'SRM 2 DOME PS05',
    ofpThroughput: 'OFP Throughput',
    discretesInExpected: 'Discretes In Expected',
    gpsOperational: 'GPS Operational',
    pduGood: 'PDU Good',
    navStatus: 'Nav Status.TXA Quality',
    esadAPostGo: 'ESAD A POST GO',
    esadBPostGo: 'ESAD B POST GO',
    ftsOneBattVolt: 'FTS-1 2BVDC Batt Volt',
    ftsTwoBattVolt: 'FTS-2 2BVDC Batt Volt',
    pduVolts: 'PDU 28 Volts',
    srmDomePs: 'SRM 3 DOME PS06',
    ofpCsum: 'OfpCsum',
    discretesOutExpected: 'Discretes Out Expected',
    imuGood: 'IMU Good',
    pduSwitchExpected: 'PDU Switch Expected',
    finsUnlock: 'Fins Unlock',
    stageOneFlightMode: 'Stage 1 Flight Mode',
    stageTwoFlightMode: 'Stage 2 Flight Mode',
    stageThreeFlightMode: 'Stage 3 Flight Mode',
    stageOneSrm: 'stage1SRM',
    ftsBattTck: 'FTS Batt -TCK50',
    ftsOneWireCutterRelay: 'FTS 1 Wire Cutter Relay',
    weaponState: 'WEAPON STATE',
    bcasStageOneApSafe: 'BCAS Stage 1 AP Safe',
    stageTwoSrm: 'stage2SRM',
    ftsBattR: 'FTS Batt R -TCK52',
    ftsTwoWireCutterRelay: 'FTS 2 Wire Cutter Relay',
    configVersion: 'Config Version',
    bcasStageTwoApSafe: 'BCAS Stage 2 AP Safe',
    stageThreeSrm: 'stage3SRM',
    mduTck: 'MDU-TCK71',
    vmcTck: 'VMC-TCK65',
    pidOn: 'PID On',
    bcasStageThreeApSafe: 'BCAS Stage 3 AP Safe',
    ofpVersion: 'OFP Version',
    configFileVersion: 'Config File Version',
    configFileDate: 'Config File Date',
    sepVersion: 'SEP Version',
    idkmVersion: 'iDKM Version',
    storeLocation: 'Store Location',
    currentRtAddress: 'Current RT Address',
    warmupComplete: 'Warmup Complete',
    tmOn: 'TM On',
    storeStationIdRcvd: 'Store Station ID Rcvd',
    powerDropoutStatus: 'Power Dropout Status',
    lastBitPassed: 'Last Bit Passed',
    gpsKeyStatus: 'GPS Key Status',
    satsInTrack: 'Sats In Track',
    almanacRcvd: 'Almanac RCVD',
    ephemerisRcvd: 'Ephemeris RCVD',
    minGps: 'Min GPS',
    environDataRcvd: 'Environ Data Received',
    minTds: 'Min TDS',
    minTxa: 'Min TXA',
    safeToRelease: 'Safe To Release',
    txaQuality: 'TXA_Quality',
    txaGood: 'TXA_Good',
    julisaAurReady: 'AUR Ready',
    ftsOn: 'FTS On',
    ftsBatOn: 'FTS Battery On',
    releaseConsent: 'releaseConsent',
    validCtsRcvd: 'Valid CTS Received',
    ctssLatch: 'CTSS Latch',
    mated: 'Mated',
    timelineAbort: 'Timeline Abort',
    nvmDataErased: 'NVM Data Erased',
    nvmWeaponState: 'NVM Weapon State',
    armState: 'Arm State',
    terminateCmd: 'Terminate Cmd',
    gliderTempOne: 'Glider Temp 1',
    gliderTempTwo: 'Glider Temp 2',
    paaTempOne: 'PAA Temp 1',
    paaTempTwo: 'PAA Temp 2',
    paaTempThree: 'PAA Temp 3',
    casTempOne: 'CAS Temp 1',
    casTempTwo: 'CAS Temp 2',
    casTempThree: 'CAS Temp 3',
    sesadReadyToArm: 'SESAD-Ready To Arm',
    isdArmPwrValid: 'ISD Arm Pwr Valid',
    isdInhibitTimer: 'ISD Inhibit Timer',
    isdUmbilical: 'ISD 1760 Umbilical',
    isdMasStatus: 'ISD MAS Status',
    sepInitialRoll: 'SEP Initial Roll',
    isdFirePresent: 'ISD Fire Present',
    flightPhaseTwoHun: 'Flight Phase 200Hz',
    flightPhaseOneHun: 'Flight Phase 100Hz',
    flightPhaseFifty: 'Flight Phase 50Hz',
    flightPhaseTen: 'Flight Phase 10Hz',
    overtempWarning: 'Overtemp Warning',
    overtempCaution: 'Overtemp Caution',
    txaReinited: 'TXA_Reinited',
    txaHalted: 'TXA_Halted',
    simLaunchCountdown: 'Sim Launch Countdown',
    bitComplete: 'BIT_Complete',
    bitFail: 'BIT_Fail',
    bitCritFail: 'Critical_Fail',
    bitMpGood: 'MP_Good',
    bitGpsGood: 'GPS_Good',
    bitBcasGood: 'BCAS_Good',
    bitImuGood: 'IMU_Good',
    bitPduGood: 'PDU_Good',
    bitBemGood: 'BEM_Good',
    bitEsafGood: 'ESAF_Good',
    bitFcasGood: 'FCAS_Good',
    bitHobGood: 'HOB_Good',
    bitIsdGood: 'ISD_Good',
    bitSesadGood: 'SESAD Good',
    ibitComplete: 'Complete',
    ibitBitFail: 'BIT_Fail',
    ibitCritFail: 'Critical_Fail',
    ibitMpGood: 'MP_Good',
    ibitGpsGood: 'GPS_Good',
    ibitBcasGood: 'BCAS_Good',
    ibitImuGood: 'IMU_Good',
    ibitPduGood: 'PDU_Good',
    ibitBemGood: 'BEM_Good',
    ibitEsafGood: 'ESAF_Good',
    ibitFcasGood: 'FCAS_Good',
    ibitHobGood: 'HOB_Good',
    ibitIsdGood: 'ISD_Good',
    ibitSesadGood: 'SESAD_Good',
    cbitComplete: 'Complete',
    cbitBitFail: 'BIT_Fail',
    cbitCritFail: 'Critical_Fail',
    cbitMpGood: 'MP_Good',
    cbitGpsGood: 'GPS_Good',
    cbitBcasGood: 'BCAS_Good',
    cbitImuGood: 'IMU_Good',
    cbitPduGood: 'PDU_Good',
    cbitBemGood: 'BEM_Good',
    cbitEsafGood: 'ESAF_Good',
    cbitFcasGood: 'FCAS_Good',
    cbitHobGood: 'HOB_Good',
    cbitIsdGood: 'ISD_Good',
    cbitSesadGood: 'SESAD Good',
    vmcFault: 'VMC Fault',
    gpsReceiver: 'GPS Receiver',
    casFault: 'CAS Fault',
    imuFault: 'IMU Fault',
    pduFault: 'PDU Fault',
    bemFault: 'BEM Fault',
    esafFault: 'ESAF Fault',
    fcasFault: 'FCAS Fault',
    hobFault: 'HOB Fault',
    isdFault: 'ISD Fault',
    sesadFault: 'SESAD Fault',
    mas: 'MAS',
    boosterTb: 'Booster_Tb',
    sesad: 'Sesad',
    isd: 'Isd',
    casTb: 'Cas_Tb',
    cas: 'Cas',
    bcasVoltage: 'BCAS Voltage',
    bcasTime: 'Time',
    finEnableStatusMode: 'Mode',
    finEnableStatusUL: 'Upper Left',
    finEnableStatusUR: 'Upper Right',
    finEnableStatusLL: 'Lower Left',
    finEnableStatusLR: 'Lower Right',
    finPosStatusUL: 'Upper Left',
} as const;

export {
    barTypes,
    barDescriptors,
    radarStatuses,
    radarStatusesReadable,
    radarEvents,
    radarEventsReadable,
    tmStatuses,
    tmStatusesReadable,
    tmEvents,
    tmEventsReadable,
    roles,
    rolesReadable,
    actions,
    csvFiles,
    csvFilesReadable,
    csvFilesPoints,
    pages,
    pagesReadable,
    csvFilePaths,
    varTitles,
};
