import React, {useEffect, useState, useLayoutEffect, useRef, useContext, Fragment} from 'react';
import ClientContext from '../../helpers/ClientContext';
import '../../css/chatButton.css';
import DraggableSnap from '../../js/DraggableSnap';
import useListItems from '../../hooks/useListItems';
import {roles} from '../../enums';
import {getTMinusTime, useWindowSize} from '../../helpers/helper';
import clock from '../../images/clock.png';
import {Position} from '../../helpers/types';

type PositionDataType = {
    [positionId: string]: string;
}

type Props = {
    listName: string;
    right?: string;
    title: string;
    isDirect?: boolean;
    isController?: boolean;
    openScheduled?: boolean;
    toggleScheduled?: () => void;
    shouldScale?: boolean;
}

type MessageType = {
    userId: string;
    userReadable: string;
    message: string;
    controller: boolean;
    isTimestamped?: boolean;
    position: string;
    timestamp: string;
}

const Chat = (props: Props) => {
    const {client, firebaseId, authedUser, configuration, run} = useContext(ClientContext);
    const {listName, right = '', title, isDirect, isController, openScheduled, shouldScale = false} = props;
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
    const [chatOpen, _setChatOpen] = useState(true);
    const chatOpenRef = useRef(chatOpen);
    const setChatOpen = (data: boolean) => {
        chatOpenRef.current = data;
        _setChatOpen(data);
    }
    const timer = useRef<number | null>(null);
    const [value, setValue] = React.useState('');
    const chatBoxRef = useRef<HTMLDivElement>(null!);
    const openButtonRef = useRef<HTMLButtonElement>(null!);
    const uncheckedMessages = useListItems(listName);
    const uncheckedChatDisabled = useListItems('isChatDisabled', false);
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [numMessages, setNumMessages] = useState(0);
    const [isChatDisabled, setIsChatDisabled] = useState(false);
    const [isChatDisabledText, setIsChatDisabledText] = useState<'no' | 'yes'>('no');
    const [shownTimestamps, setShownTimestamps] = useState<number[]>([]);
    const shouldDisableChat = !isController && !isDirect && authedUser.role !== roles.ADMIN;
    const screenSize = useWindowSize();
    const [openButtonLocation, setOpenButtonLocation] = useState({
        position: 'fixed',
        bottom: '30px',
        right,
        top: '',
        left: ''
    });
    const uncheckedPositions = useListItems('positions');
    const [positions, setPositions] = useState<Position[]>([]);
    const [positionData, setPositionData] = useState<PositionDataType>({'-1': 'Admin'});

    useEffect(() => {
        if(!Array.isArray(uncheckedPositions)){
            return;
        }

        setPositions(uncheckedPositions);
    }, [uncheckedPositions]);

    useEffect(() => {
        if(!Object.keys(positions).length){
            return;
        }

        const newPositions: PositionDataType = {'-1': 'Admin'};
        positions.forEach(position => {
            newPositions[position.id] = position.name;
        });
        setPositionData(newPositions);

    }, [positions]);

    const getPosition = () => {
        if(!isController){
            return openButtonLocation;
        }

        return {};
    };

    function handleTimestampClick(index: number){
        const indexOfIndex = shownTimestamps.indexOf(index);

        if(indexOfIndex === -1){
            setShownTimestamps([...shownTimestamps, index]);
        } else{
            const newShowTimestamps = [...shownTimestamps];
            newShowTimestamps.splice(indexOfIndex, 1);
            setShownTimestamps(newShowTimestamps);
        }
    }

    useEffect(() => {
        if(typeof uncheckedChatDisabled === 'boolean'){
            setIsChatDisabled(uncheckedChatDisabled);
            if(uncheckedChatDisabled){
                setIsChatDisabledText('yes');
            } else{
                setIsChatDisabledText('no');
            }
        }
    }, [uncheckedChatDisabled]);

    useEffect(() => {
        if(Array.isArray(uncheckedMessages)){
            setMessages(uncheckedMessages);
        }
    }, [uncheckedMessages]);

    const toggleChat = () => {
        if(chatOpen){
            const {x, y, width, height} = chatBoxRef.current.getBoundingClientRect();
            const newLocation = {...openButtonLocation};
            if(y > height / 2 && x > width / 2){
                newLocation.bottom = window.innerHeight - (y + height) + 'px';
                newLocation.top = '';
                newLocation.right = window.innerWidth - (x + width) + 'px';
                newLocation.left = '';
            } else if(y > height / 2 && x < width / 2){
                newLocation.bottom = window.innerHeight - (y + height) + 'px';
                newLocation.top = '';
                newLocation.right = '';
                newLocation.left = x + 'px';
            } else if(y < height / 2 && x > width / 2){
                newLocation.top = y + 'px';
                newLocation.bottom = '';
                newLocation.right = window.innerWidth - (x + width) + 'px';
                newLocation.left = '';
            } else{
                newLocation.top = y + 'px';
                newLocation.bottom = '';
                newLocation.right = '';
                newLocation.left = x + 'px';
            }
            setOpenButtonLocation(newLocation);
        }
        const isOpen = !chatOpen;

        if(isOpen){
            if(timer.current){
                clearInterval(timer.current);
                timer.current = null;
            }
        }

        setChatOpen(isOpen);
    };

    /**
     *
     */
    const setChatDisabled = (newChatDisabled: boolean) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setChatDisabled',
                isChatDisabled: newChatDisabled,
            })
        );
    };

    const saveMessage = (item: string) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'saveMessage',
                list: listName,
                title,
                positionId: authedUser.position,
                item: {
                    list: listName,
                    userId: firebaseId,
                    message: item,
                    controller: isController,
                    userReadable: authedUser.name,
                    position: authedUser.position,
                },
            })
        );
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if(!isDirect && isChatDisabled){
            return;
        }

        if(value){
            saveMessage(value);
        }
        setValue('');
    };

    /**
     * scroll to bottom on page load
     */
    useLayoutEffect(() => {
        const elem = chatBoxRef.current.querySelector('.messagesContainer');

        /*This should only happen during development when the page does a hot reload*/
        if(!elem){
            return;
        }

        elem.scrollTop = elem.scrollHeight;
    }, []);

    function scrollChatToBottom(){
        setTimeout(() => { //move to bottom of call stack
            const elem = chatBoxRef.current.querySelector('.messagesContainer');

            /*This should only happen during development when the page does a hot reload*/
            if(!elem){
                return;
            }

            elem.scrollTop = elem.scrollHeight;
        }, 0);
    }

    /**
     *
     */
    useLayoutEffect(() => {
        if(numMessages === messages.length){
            return;
        }
        setNumMessages(messages.length);

        if(isScrolledToBottom && chatOpen){
            scrollChatToBottom();
        }

        if(!chatOpenRef.current && openButtonRef.current && !timer.current){
            if(isDirect){
                setChatOpen(true);
                scrollChatToBottom();
            } else{
                timer.current = window.setInterval(() => {
                    openButtonRef.current.classList.toggle('newMessage');
                }, 500);
            }
        }
    }, [messages]);

    useEffect(() => {
        if(!chatOpen){
            return;
        }

        function obCallback(payload: IntersectionObserverEntry[]){
            if(payload[0].intersectionRatio === 1){
                setIsScrolledToBottom(true);
            } else{
                setIsScrolledToBottom(false);
            }
        }

        const ob = new IntersectionObserver(obCallback);
        const bottomOfChat = chatBoxRef.current.querySelector('.bottomOfChat');

        const graphWrapper = new DraggableSnap(chatBoxRef.current, document.body, true);
        graphWrapper.addDragging([], []);

        /*This should only happen during development when the page does a hot reload*/
        if(bottomOfChat){
            ob.observe(bottomOfChat);

            return () => {
                ob.unobserve(bottomOfChat);
            };
        }
    }, [chatOpen]);

    if(!isController && !chatOpen){
        return (
            <button
                ref={openButtonRef}
                className={isDirect ? 'direct' : ''}
                type="button"
                style={{borderRadius: '0.5em', zIndex: 1, ...openButtonLocation, position: 'fixed'}}
                onClick={toggleChat}
                onMouseDownCapture={e => e.stopPropagation()}
            >
                +
            </button>
        );
    }

    function addTimestamp(index: number){
        if(!client){
            return;
        }

        const user = run.users.find(curUser => curUser.position === listName);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'addTimestamp',
                messageIndex: index,
                listName,
                positionId: listName,
                userId: user ? user.userID : ''
            })
        );
    }

    return (
        <div ref={chatBoxRef}
             className={`chat visibleChat ${isDirect ? 'directChat' : 'globalChat'} ${isController ? 'controllerChat' : ''}`}
             style={{
                 transformOrigin: 'bottom right',
                 transform: shouldScale ? `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - (document.querySelector('.nav-items')?.clientHeight || 0)) / (1152 - (document.querySelector('.nav-items')?.clientHeight || 0))})` : '',
                 height: 324, ...getPosition(),
                 backgroundColor: '#9D9D9D',
                 display: 'flex',
                 flexDirection: 'column',
                 borderRadius: openScheduled ? '0.5em 0.5em 0em 0em' : '0.5em',
             }}
        >
            <div style={{
                borderRadius: '0.5em 0.5em 0 0',
                backgroundColor: isDirect ? 'limegreen' : 'slateblue',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
            }}
            >
                <div style={{flex: 1}}/>
                <div style={{flex: isDirect ? 6 : 2, textAlign: 'center'}}>{title}</div>
                <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                    {
                        !isController && !isDirect && authedUser.role === roles.ADMIN &&
                        {
                            'yes':
                                <button style={{borderRadius: '0.5em'}}
                                        type="button"
                                        onClick={() => setChatDisabled(false)}
                                >
                                    Enable
                                </button>,
                            'no':
                                <button style={{borderRadius: '0.5em'}}
                                        type="button"
                                        onClick={() => setChatDisabled(true)}
                                >
                                    Disable
                                </button>,
                        }[isChatDisabledText]
                    }
                    {
                        !isController &&
                        <button
                            type="button"
                            className={isDirect ? 'direct' : ''}
                            style={{
                                borderRadius: '0.5em',
                                borderColor: isDirect ? 'limegreen' : 'slateblue',
                                top: 0,
                                marginLeft: '0.75rem'
                            }}
                            onClick={toggleChat}
                            onMouseDownCapture={e => e.stopPropagation()}
                        >
                            -
                        </button>
                    }
                </div>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', padding: '0.3rem'}}>
                <div className="messagesContainer" style={{
                    padding: '5px',
                    borderRadius: '0.5em',
                    height: '250px',
                    overflowY: 'auto',
                }}
                >
                    {
                        shouldDisableChat && isChatDisabled ?
                            <div style={{
                                textAlign: 'center',
                                fontSize: '2rem',
                                display: 'grid',
                                placeItems: 'center',
                                height: '100%',
                            }}
                            >
                                <div>Disabled</div>
                            </div>
                            :
                            messages.map((message, index) => (
                                <Fragment key={index}>
                                    {
                                        (index === 0 ||
                                            Number(message.timestamp) - Number(messages[index - 1].timestamp) > 60) &&
                                        <div className="lineText">
                                            {getTMinusTime(message.timestamp, configuration)}
                                        </div>
                                    }
                                    <div style={{marginBottom: '5px'}}>
                                        <div className="message" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: message.userId === firebaseId ? '50px' : '',
                                            paddingRight: message.userId !== firebaseId ? '50px' : ''
                                        }}
                                        >
                                            <span onMouseDownCapture={e => e.stopPropagation()}
                                                  onClick={() => handleTimestampClick(index)} style={{
                                                borderRadius: '0.5em',
                                                backgroundColor: (isDirect && message.controller) ? 'limegreen' : 'white',
                                                marginLeft: message.userId === firebaseId ? 'auto' : '',
                                                padding: '5px',
                                                cursor: 'pointer',
                                            }}
                                            >
                                               {!isDirect && `${positionData[message.position] || '?'}: `}{message.message}
                                                {
                                                    isDirect && message.controller && isController && (
                                                        message.isTimestamped ?
                                                            <span
                                                                style={{paddingLeft: '0.5rem', paddingRight: '0.2rem'}}
                                                            >
                                                                <strong>&#10003;</strong>
                                                            </span>
                                                            :
                                                            <button onClick={() => addTimestamp(index)} style={{
                                                                backgroundColor: 'transparent',
                                                                border: 'none',
                                                                boxShadow: 'none'
                                                            }}
                                                            >
                                                                <img style={{width: '1.3rem'}} src={clock}/>
                                                            </button>
                                                    )
                                                }
                                            </span>
                                        </div>
                                        {
                                            shownTimestamps.includes(index) &&
                                            <div style={{textAlign: message.userId === firebaseId ? 'right' : 'left'}}>
                                                {getTMinusTime(message.timestamp, configuration)}
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            ))
                    }
                    <div className="bottomOfChat"/>
                </div>
                <div style={{marginTop: 'auto'}}>
                    <form onSubmit={handleSubmit} style={{textAlign: 'center'}}>
                        {isDirect && isController &&
                        <button
                            type="button"
                            style={{
                                borderRadius: '0.5em'
                            }}
                            onClick={props.toggleScheduled}
                        >
                            scheduled
                        </button>
                        }
                        <input type="text" value={value} onChange={handleChange}
                               disabled={!isDirect && isChatDisabled}
                        />
                        <button
                            type="submit"
                            style={{
                                borderRadius: '0.5em'
                            }}
                        >
                            send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;
