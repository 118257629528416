import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import DraggableSnap from '../../js/DraggableSnap';
import Status from './status';
import {TableCell} from '@material-ui/core';

const {v4: uuidv4} = require('uuid');

/**
 *
 * @param top
 * @param left
 * @param tableBorder
 * @param tableWidth
 * @param tableHeight
 * @param draggable
 * @param grid
 * @param title
 * @param tableData
 * @param config
 * @param radius
 * @param rowPadding
 * @param textColor
 * @param textSize
 * @returns {JSX.Element}
 * @constructor
 */
const Table = ({
                   tableBorder,
                   tableWidth,
                   tableHeight,
                   draggable,
                   grid,
                   title,
                   tableData,
                   config,
                   radius,
                   rowPadding,
                   textColor,
                   textSize,
                   align,
                   underlineHeader,
                   headColor,
                   colSpan
               }) => {
    const tableRef = React.createRef();
    const containerID = useRef(uuidv4());
    const tableID = useRef(`table_${new Date().getTime()}`);

    const divStyle = {
        cursor: 'move',
        backgroundColor: 'black',
        border: tableBorder,
        width: tableWidth + 'px',
    };
    const divTableStyle = {
        textAlign: align,
        height: tableHeight,
        color: 'white',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        width: '100%',
    };
    const rowStyle = {
        padding: '0',
        textDecorationColor: 'white',
        solid: '#ddd',
        width: '100%',
        lineHeight: radius * 1.2 + 'px',
        paddingBottom: '10px'
    };

    useEffect(() => {
        if(draggable){
            const tableWrapper = new DraggableSnap(
                document.getElementById(containerID.current)
            );
            tableWrapper.addDragging(grid[0], grid[1]);
        }
    }, []);

    const renderTableHeader = columns => {
        return columns.map((column, index) => {
            return (
                <TableCell
                    colSpan={colSpan}
                    key={index}
                    style={{
                        fontFamily: 'Courier New, sans-serif',
                        padding: '0',
                        textAlign: 'center',
                        fontSize: textSize,
                        textDecoration: underlineHeader,
                        color: headColor,
                        margin: 'auto',
                        borderBottom: 'black'
                    }}
                >
                    {column}
                </TableCell>
            );
        });
    };

    const renderTableData = rows => {
        return rows.map((row, rowIndex) => {
            return (
                <tr
                    key={rowIndex}
                    style={rowStyle}
                >
                    {row.map((column, columnIndex) => {
                        if(column.type === 'light'){
                            return (
                                <td
                                    key={`row${rowIndex}column${columnIndex}`}
                                    style={{paddingLeft: radius, paddingRight: radius}}>
                                    <Status bounds={column.bounds} data={tableData[column.key]} radius={radius}/>
                                </td>
                            );
                        }
                        if(column.type === 'textOverColor'){
                            let color = '';
                            const bounds = column.bounds;
                            const boundsVals = Object.keys(bounds);
                            boundsVals.some(limit => {
                                if(tableData && tableData[column.key] <= limit){
                                    color = bounds[limit];
                                    return true;
                                }
                                return false;
                            });
                            if(!color){
                                color = bounds[boundsVals[boundsVals.length - 1]];
                            }

                            let tdColor = 'white';
                            if(color === 'yellow'){
                                tdColor = 'black';
                            }

                            return (
                                <td
                                    key={`row${rowIndex}column${columnIndex}`}
                                    style={{
                                        fontFamily: 'Courier New, sans-serif',
                                        // fontWeight: 'bold',
                                        fontSize: textSize,
                                        marginTop: '0px',
                                        padding: rowPadding,
                                        backgroundColor: color,
                                    }}
                                >
                                    <div style={{
                                        textAlign: 'center',
                                        color: tdColor
                                    }}>
                                        {tableData ? tableData[column.key] : ''}
                                    </div>
                                </td>
                            );
                        }
                        if(column.type === 'dynamicText'){
                            return (
                                <td
                                    key={`row${rowIndex}column${columnIndex}`}
                                    style={{
                                        fontFamily: 'Courier New, sans-serif',
                                        // fontWeight: 'bold',
                                        fontSize: textSize,
                                        marginTop: '0px',
                                        padding: rowPadding,
                                    }}
                                >
                                    <div style={{
                                        textAlign: 'center',
                                    }}>
                                        {tableData ? tableData[column.key] : ''}
                                    </div>
                                </td>
                            );
                        }
                        if(column.type === 'compareText'){
                            const textData = column.getText(tableData);
                            const styles = {
                                fontFamily: 'Courier New, sans-serif',
                                // fontWeight: 'bold',
                                marginTop: '0px',
                                padding: rowPadding,
                                ...textData.styles,
                            };

                            return (
                                <td
                                    key={`row${rowIndex}column${columnIndex}`}
                                    style={{...styles, fontSize: textSize}}
                                >
                                    {textData.text}
                                </td>
                            );
                        }
                        return (
                            <td
                                key={`row${rowIndex}column${columnIndex}`}
                                style={{
                                    fontFamily: 'Courier New, sans-serif',
                                    // fontWeight: 'bold',
                                    marginTop: '0px',
                                    padding: rowPadding,
                                    color: textColor,
                                    fontSize: textSize,
                                }}
                            >
                                {column.value}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    return (
        <div
            id={containerID.current}
            draggable={draggable}
            style={divStyle}
        >
            <table
                id={tableID.current}
                ref={tableRef}
                style={divTableStyle}
            >
                {
                    title ?
                        <caption
                            style={{
                                captionSide: 'top',
                                textAlign: 'center',
                                padding: '0 20px',
                                color: 'white',
                                fontFamily: 'Courier New',
                                // fontWeight: 'bold',
                                fontSize: textSize,
                            }}
                        >{title}</caption> : null
                }
                <tbody>
                {
                    config.head &&
                    <tr>{renderTableHeader(config.head)}</tr>
                }
                {renderTableData(config.rows)}
                </tbody>
            </table>
        </div>
    );
};

Table.defaultProps = {
    top: 0,
    left: 0,
    tableBorder: '',
    draggable: false,
    grid: [],
    title: '',
    radius: window.screen.width * .011,
    rowPadding: '5px',
    textColor: 'white',
    textSize: '14px',
    colSpan: '3'
};

Table.propTypes = {
    tableData: PropTypes.shape({
        title: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.string),
        rows: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.array)
        ])
    }).isRequired,
    config: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(
                    PropTypes.objectOf(
                        PropTypes.oneOfType([
                            PropTypes.string,
                            PropTypes.number,
                            PropTypes.func,
                            PropTypes.objectOf(
                                PropTypes.string
                            )
                        ])
                    )
                )
            ])
        )
    ).isRequired,
    top: PropTypes.number,
    left: PropTypes.number,
    tableBorder: PropTypes.string,
    tableWidth: PropTypes.number, // default props break layout
    tableHeight: PropTypes.number, // default props break layout
    draggable: PropTypes.bool,
    grid: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.number)
    ),
    title: PropTypes.string,
    radius: PropTypes.number,
    rowPadding: PropTypes.string,
    textColor: PropTypes.string,
    textSize: PropTypes.string,
};

export default Table;
