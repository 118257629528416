import React, {useContext} from 'react';
import {createGrid, useWindowSize} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';
import Table from '../widgets/table';
import {
    batteryParameters,
    esadParameters, missionInfoTbl,
    safetyOfFlightTbl,
    sepHealthStatusTbl, thermalBatVoltTbl,
    toneTable
} from '../../helpers/tblConfigFlightSafety';
import LineChartRechart from '../widgets/lineChartRechart';
import FtrCompass from '../widgets/ftrCompass';
import CompassHeading from '../widgets/ftrCompass';

const gridArray = createGrid(100);

const FlightSafety = () => {
    const screenSize = useWindowSize();
    const {widgetState} = useContext(ClientContext);

    return (
        <>
            <div className="blackBody"/>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: 'Courier New, sans-serif',

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute'
                    }}
                    >
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                            height: 'fit-content',
                            background: 'green',
                            textAlign: 'center',
                        }}
                        >
                            <p style={{color: 'black'}}>Unclassified</p>
                        </div>
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                        }}
                        >
                            <p style={{color: 'rgb(87, 168, 101)'}}>Missile - BTF-2 Ver. 3.1 2/17/2022</p>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <div style={{
                            border: '1px solid white',
                            blockSize: 'fit-content',
                            marginLeft: '600px',
                            width: 'fit-content',
                            justifyContent: 'center',
                            backgroundColor: 'red',
                        }}
                        >
                            <h1 style={{
                                color: 'black',
                                textDecoration: 'underline',
                                fontFamily: 'Courier New, -sans-serif',
                                fontSize: '3rem',
                                whiteSpace: 'nowrap',
                            }}
                            >
                                FTS - Flight Safety
                            </h1>
                        </div>
                        <div style={{ marginLeft: '200px'}}>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={missionInfoTbl}
                                radius={10}
                                // tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={125}
                                tableWidth={500}
                                // textColor="yellow"
                                textSize="1.2rem"
                                underlineHeader="underline"
                                headColor="limegreen"
                            />
                        </div>
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '100px'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column',}}>
                            <h1 style={{color:'limegreen', marginTop: '-40px', marginLeft: '60px'}}><u>FTR PARAMETERS</u></h1>
                            <div style={{display: 'flex', flexDirection: 'row',}}>
                                <div style={{}}>
                                    <FtrCompass
                                        grid={gridArray}
                                        width="250px"
                                        height="250px"
                                        demo={false}
                                        degree={Number(parseFloat(widgetState.location.data[widgetState.location.data.length - 1].yaw))}
                                    />
                                </div>
                                <div style={{}}>
                                    <FtrCompass
                                        grid={gridArray}
                                        width="250px"
                                        height="250px"
                                        demo={false}
                                        degree={Number(parseFloat(widgetState.location.data[widgetState.location.data.length - 1].yaw))}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{
                            marginTop: '-40px',
                            marginRight: '10px'
                        }}>
                            <h1 style={{color:'limegreen', marginLeft: '30px'}}><u>Battery Parameters</u></h1>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={batteryParameters}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={125}
                                tableWidth={500}
                                textColor="rgb(227,175,128)"
                                textSize="1.2rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>
                        <div style={{borderTop: '1px solid gray'}}>
                            <LineChartRechart
                                shouldShift
                                chartTitle="Lower Left Fin"
                                baseYDomain={[0, 250]}
                                baseYTicks={[0, 63, 125, 188, 250]}
                                yMajorLines={[63, 125, 188, 250]}
                                axisColor="red"
                                backgroundColor="black"
                                innerChartWidth={500}
                                innerChartHeight={200}
                                lines={[{key: 'batVolt', color: 'blue'}]}
                                gridColor="white"
                                gridLineColor="red"
                                majorGridLineColor="darkred"
                                data={widgetState.lineChart.data}
                                dataPointsShown={30}
                                referenceMultiple={10}
                                minorReferenceMultiple={1}
                                lightBorder="rgb(151, 192, 207)"
                                darkBorder="rgb(72, 98, 107)"
                                buttons
                                margin={{top: 0, right: 10, bottom: 20, left: -10}}
                                showLatestPoint
                                xKey=""
                                tickFontSize="1.2em"
                                xLabel={{text: ''}}
                                yLabel={{text: ''}}
                            />
                        </div>
                        <div style={{borderTop: '1px solid gray'}}>
                            <LineChartRechart
                                shouldShift
                                chartTitle="Lower Left Fin"
                                baseYDomain={[0, 50]}
                                baseYTicks={[0,13,25,38,50]}
                                yMajorLines={[0,13,25,38,50]}
                                axisColor="red"
                                backgroundColor="black"
                                innerChartWidth={500}
                                innerChartHeight={200}
                                lines={[{key: 'batVolt', color: 'blue'}]}
                                gridColor="white"
                                gridLineColor="red"
                                majorGridLineColor="darkred"
                                data={widgetState.lineChart.data}
                                dataPointsShown={30}
                                referenceMultiple={10}
                                minorReferenceMultiple={1}
                                lightBorder="rgb(151, 192, 207)"
                                darkBorder="rgb(72, 98, 107)"
                                buttons
                                margin={{top: 0, right: 10, bottom: 20, left: -10}}
                                showLatestPoint
                                xKey=""
                                tickFontSize="1.2em"
                                xLabel={{text: ''}}
                                yLabel={{text: ''}}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '1920px'
                    }}>
                        <div style={{
                            height: '300px',
                            width: '500px'
                        }}>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={toneTable}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="10px"
                                textSize="1.2rem"
                                textColor="rgb(227,175,128)"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>

                        <div style={{
                            marginTop:'-55px'
                        }}>
                            <h1 style={{color: 'limegreen', marginLeft:'80px'}}><u>ESAD Parameters</u></h1>
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={esadParameters}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={125}
                                tableWidth={500}
                                textColor="rgb(227,175,128)"
                                textSize="1.2rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '-50px'
                        }}>
                            <h1 style={{color:'rgb(238,121,78)', marginLeft:'300px'}}><u>FLIGHT SAFETY</u></h1>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Table
                                    draggable={false}
                                    grid={gridArray}
                                    tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                    config={sepHealthStatusTbl}
                                    radius={6}
                                    rowPadding="10px"
                                    tableHeight={100}
                                    tableWidth={500}
                                    textColor="yellow"
                                    textSize="1.2rem"
                                    underlineHeader="underline"
                                    headColor="limegreen"
                                />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                        config={safetyOfFlightTbl}
                                        radius={10}
                                        rowPadding="5px"
                                        tableHeight={125}
                                        tableWidth={500}
                                        textColor="yellow"
                                        textSize="1.2rem"
                                        underlineHeader="underline"
                                        headColor="limegreen"
                                    />
                                    <Table
                                        draggable={false}
                                        grid={gridArray}
                                        tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                        config={thermalBatVoltTbl}
                                        radius={10}
                                        rowPadding="5px"
                                        tableHeight={125}
                                        tableWidth={500}
                                        textColor="yellow"
                                        textSize="1.2rem"
                                        underlineHeader="underline"
                                        headColor="limegreen"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlightSafety