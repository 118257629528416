import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ClientContext from '../../helpers/ClientContext';
import useListItems from '../../hooks/useListItems';
import {getTMinusTime} from '../../helpers/helper';

type Props = {
    listName: string;
    title: string;
    timeOptions: JSX.Element[];
}

type MessageType = {
    list: string;
    message: string;
    firebaseId: string;
}

type MessageTypes = {
    [time: string]: MessageType[];
}

type SortedItemType = {
    time: string;
    list: string;
    message: string;
    firebaseId: string;
}

const ScheduledMessages = (props: Props) => {
    const {client, firebaseId, authedUser, configuration} = useContext(ClientContext);

    const {listName, title, timeOptions} = props;

    const [messageValue, setMessageValue] = React.useState('');
    const [timeValue, setTimeValue] = React.useState('0');
    const [sortedItems, setSortedItems] = useState<SortedItemType[]>([]);
    const uncheckedItems = useListItems('scheduledMessages');
    const [items, setItems] = useState<MessageTypes>({});

    useEffect(() => {
        if(!Array.isArray(uncheckedItems) && typeof uncheckedItems === 'object'){
            // @ts-ignore
            setItems(uncheckedItems);
        }
    }, [uncheckedItems]);

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageValue(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!client){
            return;
        }

        if(timeValue && messageValue){
            client.send(
                JSON.stringify({
                    type: 'update',
                    content: 'addScheduledMessage',
                    time: timeValue,
                    message: {
                        list: listName,
                        userReadable: authedUser.name, // not exactly correct but we're hiding the user readable for now
                        message: messageValue,
                        position: authedUser.position,
                    },
                })
            );
        }
    };

    const removeMessage = (item: SortedItemType) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'removeScheduledMessage',
                list: listName,
                time: item.time,
                message: item.message,
                title,
            })
        );
    };

    const sendMessageNow = (item: SortedItemType) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'sendScheduledMessageNow',
                list: listName,
                item: {
                    ...item,
                    userId: firebaseId,
                    position: authedUser.position,
                },
                time: item.time,
                message: item.message,
            })
        );
    };

    useEffect(() => {
        const newItems: SortedItemType[] = [];

        Object.entries(items).forEach(([time, messages]) => {
            messages.forEach(message => {
                if(message.list === listName){
                    newItems.push({...message, time});
                }
            });
        });

        setSortedItems(newItems);

    }, [listName, items]);

    return (
        <div style={{backgroundColor: 'ghostwhite', borderRadius: '0em 0em 0.5em 0.5em'}}>
            <div style={{
                textAlign: 'center',
                position: 'relative'
            }}>
                <strong>{title}</strong>
            </div>
            <div className="messagesContainer" style={{
                padding: '5px',
                borderRadius: '0.5em 0.5em 0.5em 0.5em',
                height: '250px',
                overflowY: 'auto',
            }}>
                {
                    sortedItems.map((item, index) => (
                        <div className="message" key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px'
                        }}
                        >
                            {getTMinusTime(item.time, configuration)}:
                            <span style={{
                                borderRadius: '0.5em 0.5em 0.5em 0.5em',
                                backgroundColor: 'limegreen',
                                marginLeft: item.firebaseId === firebaseId ? 'auto' : '',
                                padding: '5px',
                                flex: 1
                            }}
                            >
                                {item.message}
                            </span>
                            <button className="deleteBtn" type="button"
                                    style={{marginLeft: '0.5rem', padding: '0 0.8rem 0 0.8rem'}}
                                    onClick={() => removeMessage(item)}>
                                X
                            </button>
                            <button className="successButton" type="button"
                                    style={{padding: '0 0.8rem 0 0.8rem'}}
                                    onClick={() => sendMessageNow(item)} title="Send Immediately">
                                &#187;
                            </button>
                        </div>
                    ))
                }
            </div>

            <div style={{
                paddingTop: '20px'
            }}>
                <form onSubmit={handleSubmit} style={{display: 'flex', alignItems: 'center', padding: '0.3rem'}}>
                    <select style={{borderRadius: '0.5rem', padding: '0.2rem'}} onChange={e => setTimeValue(e.currentTarget.value)}
                            name="keyEventTime"
                            value={timeValue}>
                        {timeOptions}
                    </select>
                    <textarea style={{marginLeft: '5px', marginRight: '5px', borderRadius: '0.5rem'}}
                              onChange={handleMessageChange} value={messageValue}/>
                    <button
                        type="submit"
                        style={{
                            borderRadius: '0.5em 0.5em 0.5em 0.5em'
                        }}
                    >
                        add
                    </button>
                </form>
            </div>
        </div>
    );
};

ScheduledMessages.propTypes = {
    listName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default ScheduledMessages;
