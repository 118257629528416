import React, {useEffect, useContext, useState} from 'react';
import EditableList from '../widgets/editableList';
import {
    getEndOfArray,
    getNumSecondsInRun,
} from '../../helpers/helper';
import '../../css/proctor.css';
import EditableTable from '../widgets/editableTable';
import Timeline from '../widgets/timeline';
import ClientContext from '../../helpers/ClientContext';
import {actions} from '../../enums';
import useListItems from '../../hooks/useListItems';

const Controller = (props) => {
    const {client, authedUser, run, configuration, widgetState} = useContext(ClientContext);
    const users = useListItems('users');
    const [connectedUsers, setConnectedUsers] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const positions = useListItems('positions');
    const curChecklistId = useListItems('checklistId', run.checklistId)

    /**
     *
     * @param input
     */
    const handleOverwrite = input => {
        const paramTitle = input.closest('.paramContainer').querySelector('.paramTitle').textContent;

        if(input.value){
            client.send(
                JSON.stringify({
                    type: 'update',
                    content: 'modifyValue',
                    modType: 'overwrite',
                    key: input.id,
                    value: input.value,
                    identifier: `${paramTitle} was set to Overwrite by ${input.value}`,
                    positionId: authedUser.position,
                }),
            );
        }
    };

    /**
     *
     * @param input
     */
    const handleDrift = input => {
        const paramTitle = input.closest('.paramContainer').querySelector('.paramTitle').textContent;

        if(input.value){
            client.send(
                JSON.stringify({
                    type: 'update',
                    content: 'modifyValue',
                    modType: 'drift',
                    key: input.id,
                    value: input.value,
                    identifier: `${paramTitle} was set to Drift by ${input.value}`,
                    positionId: authedUser.position,
                }),
            );
        }
    };

    /**
     *
     * @param input
     */
    const handleOffset = input => {
        const paramTitle = input.closest('.paramContainer').querySelector('.paramTitle').textContent;

        if(input.value){
            client.send(
                JSON.stringify({
                    type: 'update',
                    content: 'modifyValue',
                    modType: 'offset',
                    key: input.id,
                    value: input.value,
                    identifier: `${paramTitle} was set to Offset by ${input.value}`,
                    positionId: authedUser.position,
                })
            );
        }
    };

    /**
     *
     * @param event
     */
    const applyModification = event => {
        const adjustmentContainer = event.target.closest('.adjustmentContainer');
        const input = adjustmentContainer.querySelector('select');

        if(!input){
            return;
        }

        const modifyValue = adjustmentContainer.querySelector('.modifyValue');
        if(input.value === 'offset'){
            handleOffset(modifyValue);
        } else if(input.value === 'overwrite'){
            handleOverwrite(modifyValue);
        } else if(input.value === 'drift'){
            handleDrift(modifyValue);
        }
    };

    /**
     *
     * @param event
     */
    const handleReset = event => {
        const input = event.target.closest('.adjustmentContainer').querySelector('input');
        const paramTitle = input.closest('.paramContainer').querySelector('.paramTitle').textContent;

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'modifyValue',
                modType: 'offset',
                key: input.id,
                value: 0,
                identifier: `${paramTitle} was reset`,
                positionId: authedUser.position,
            })
        );
    };

    /**
     *
     * @param key
     * @returns {string|*}
     */
    const getResultValue = (key) => {
        // const adjustment = widgetState.adjustments[key];
        // const hasAdjustment = !(adjustment.type === 'offset' && (adjustment.value === 0 || adjustment.value === '0'));
        const adjusted = widgetState.location.data[widgetState.location.data.length - 1][key];
        const isNull = adjusted === undefined || adjusted === null || adjusted === '';

        if(isNull){
            return '\u00A0'; //no-break space
        }

        return adjusted;
    };

    /**
     *
     * @param key
     * @returns {JSX.Element|string}
     */
    const getDisplayValue = (key) => {
        const adjustment = widgetState.adjustments[key];
        if(!adjustment){
            return '\u00A0';
        }

        let originalValue = widgetState.location.originalData[widgetState.location.originalData.length - 1][key];
        if(!originalValue){
            originalValue = 0;
        }

        if(adjustment.type === 'overwrite'){
            return (
                <div style={{
                    textDecoration: 'line-through',
                    textAlign: 'center'
                }}
                >
                    {originalValue}
                </div>
            );
        }

        if((adjustment.type === 'offset') && adjustment.value !== 0 && adjustment.value !== '0'){
            let sign = '';
            if(Math.sign(adjustment.value) === 1){
                sign = '+';
            }
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{paddingRight: '10px'}}>
                        {originalValue}
                    </div>
                    <div>({sign}{adjustment.value})</div>
                </div>
            );
        }

        if((adjustment.type === 'drift')){
            const adjusted = widgetState.location.data[widgetState.location.data.length - 1][key] || '0';
            const diff = Number(adjusted) - Number(originalValue);
            let adjustmentDecimals = 0;
            const adjustmentArr = adjusted.split('.');
            if(adjustmentArr[1]){
                adjustmentDecimals = adjustmentArr[1].length;
            }

            let sign = '';
            if(Math.sign(diff) === 1){
                sign = '+';
            }
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{paddingRight: '10px'}}>
                        {originalValue}
                    </div>
                    <div>({sign}{diff.toFixed(adjustmentDecimals)})</div>
                </div>
            );
        }

        return '\u00A0';
    };

    function getAdjustmentType(key){
        const adjustment = widgetState.adjustments[key];
        if(!adjustment){
            return '';
        }

        return adjustment.type;
    }

    useEffect(() => {
        const handleMessage = message => {
            if(JSON.parse(message.data).type === 'connectedUsers'){
                setConnectedUsers(JSON.parse(message.data).connectedUsers);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getConnectedUsers'
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };

    }, []);

    useEffect(() => {
        const runId = run.id;
        if(connectedUsers[runId]){
            setFilteredUsers(users.filter(curUser => connectedUsers[runId].includes(curUser.id)));
        }
    }, [connectedUsers]);

    function getUserName(user){
        const runUser = run.users.find(curUser => curUser.userID === user.id);
        if(!runUser){
            return user.name;
        }
        const position = positions.find(position => position.id === runUser.position)?.name;
        if(!position){
            return user.name;
        }

        return `${position}: ${user.name}`;
    }

    function updateCurChecklist(index){
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'updateCurChecklist',
                value: index,
            })
        );
    }

    return (
        <div>
            <div className="grayBody"/>
            <div id="controllerPage" className="overflowAuto" style={{padding: '1em', height: '100vh'}}>
                <div id="remoteVideo"/>
                {/*Timeline*/}
                <div style={{
                    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.3)',
                    borderRadius: '0.5em',
                    marginBottom: '1em',
                    display: 'flex'
                }}
                >
                    <div style={{
                        padding: '1em',
                        backgroundColor: '#0a767a',
                        borderRadius: '0.5em 0 0 0.5em',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    >
                        <h2 style={{color: 'white', marginBottom: 0}}>Timing</h2>
                    </div>
                    <div style={{padding: '0.5em', backgroundColor: 'white', borderRadius: '0 0.5em 0.5em 0', flex: 1}}>
                        <Timeline
                            min={0}
                            max={getNumSecondsInRun(configuration)}
                            events={run.keyEvents}
                            height={71}
                            time={getEndOfArray(widgetState.location.data, 'time')}
                            timeColor="black"
                        />
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <div id="parametersControl" style={{
                        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.3)',
                        borderRadius: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                        // height: document.querySelector('#lists')?.clientHeight + 'px',
                        // maxHeight: document.querySelector('#lists')?.clientHeight + 'px',
                    }}
                    >
                        <div style={{
                            padding: '0.1rem',
                            backgroundColor: '#0a767a',
                            borderRadius: '0.5em 0.5em 0 0',
                            flex: 0
                        }}
                        >
                            <h2 style={{color: 'white', marginBottom: 0, fontSize: '1.5rem'}}>Parameters Control</h2>
                        </div>
                        <div style={{
                            backgroundColor: 'white',
                            borderRadius: '0 0 0.5em 0.5em',
                            paddingBottom: '0.5em',
                            flex: 1,
                            display: 'flex',
                            overflowY: 'auto',
                        }}
                        >
                            <div style={{
                                padding: '0.2rem',
                                backgroundColor: 'white',
                                overflowY: 'auto',
                                flex: 1
                            }}
                            >
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, 172px)',
                                }}
                                >
                                    {Object.keys(widgetState.adjustments).sort((a, b) => {
                                        const aTitle = widgetState.adjustments[a]?.title || '';
                                        const bTitle = widgetState.adjustments[b]?.title || '';
                                        if(aTitle > bTitle){
                                            return 1;
                                        } else if(bTitle > aTitle){
                                            return -1;
                                        }
                                        return 0;
                                    }).map(key =>
                                        <div
                                            className="paramContainer"
                                            key={key}
                                            style={{
                                                width: '100%',
                                                borderRadius: '0.5em',
                                                // boxShadow: '0 3px 8px rgba(0, 0, 0, 0.5)',
                                                border: '0.05rem solid black',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                fontSize: '0.9rem',
                                            }}
                                        >
                                            <div style={{flex: 0}}>
                                                <div style={{
                                                    backgroundColor: '#0a767a',
                                                    color: 'white',
                                                    padding: '0 0.5em',
                                                    borderRadius: '0.5em 0.5em 0 0',
                                                    overflowX: 'hidden'
                                                }}
                                                >
                                                    <div style={{
                                                        fontSize: '1em',
                                                        textAlign: 'center',
                                                        whiteSpace: 'nowrap'
                                                    }} title={widgetState.adjustments[key]?.title}
                                                         className="paramTitle"
                                                    >
                                                        {widgetState.adjustments[key]?.title}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="adjustmentContainer" style={{
                                                flex: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                            >
                                                <div style={{flex: 0}}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        backgroundColor: 'white'
                                                    }}
                                                    >
                                                        {getResultValue(key)}
                                                    </div>
                                                    <hr style={{
                                                        backgroundColor: 'black',
                                                        border: '1px solid black',
                                                        marginTop: 0,
                                                        marginBottom: 0
                                                    }}
                                                    />
                                                </div>
                                                <div style={{
                                                    backgroundColor: '#cbcbcb',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flex: 1,
                                                    paddingTop: '0.1em',
                                                    paddingRight: '0.2em',
                                                    paddingLeft: '0.2em',
                                                    paddingBottom: '0.2em',
                                                    borderRadius: '0 0 0.5em 0.5em',
                                                    justifyContent: 'space-between'
                                                }}
                                                >
                                                    <div style={{fontFamily: 'sans-serif'}}>
                                                        <div style={{fontSize: '0.8rem'}}>{getDisplayValue(key)}</div>
                                                        <div style={{display: 'flex'}}>
                                                            <input style={{width: '100%'}} type="number" id={key}
                                                                   step=".0001" className="modifyValue"
                                                                   defaultValue={widgetState.adjustments[key]?.value || 0}
                                                            />
                                                            <select
                                                                style={{fontSize: '0.85rem', borderRadius: '0.3rem'}}
                                                                defaultValue={getAdjustmentType(key)}
                                                            >
                                                                <option value="offset">Offset</option>
                                                                <option value="overwrite">Overwrite</option>
                                                                <option value="drift">Drift (/s)</option>
                                                            </select>
                                                            {/*<div style={{display: 'flex', alignItems: 'center', paddingLeft: '0.2rem'}}>
                                                                <input type="radio" name={`${key}Adjustment`}
                                                                       id={`${key}Offset`} value="offset"
                                                                       defaultChecked={getAdjustmentType(key) === 'offset'}
                                                                />
                                                                <label htmlFor={`${key}Offset`}
                                                                       style={{marginBottom: 0}}
                                                                >
                                                                    <img src={plusMinus} alt="offset" title="offset"
                                                                         style={{height: '1.5rem'}}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <input type="radio" name={`${key}Adjustment`}
                                                                       id={`${key}Overwrite`} value="overwrite"
                                                                       defaultChecked={getAdjustmentType(key) === 'overwrite'}
                                                                />
                                                                <label htmlFor={`${key}Overwrite`}
                                                                       style={{marginBottom: 0}}
                                                                >
                                                                    <img src={pencilEdit} alt="overwrite"
                                                                         title="overwrite" style={{height: '1.5rem'}}
                                                                    />
                                                                </label>
                                                            </div>*/}
                                                        </div>
                                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <button type="button" onClick={applyModification}
                                                                    style={{
                                                                        backgroundColor: 'white',
                                                                        lineHeight: '1rem',
                                                                        paddingLeft: '0.2rem',
                                                                        paddingRight: '0.2rem',
                                                                    }}
                                                            >
                                                                Apply
                                                            </button>
                                                            <button type="button" onClick={handleReset}
                                                                    style={{
                                                                        backgroundColor: 'white',
                                                                        lineHeight: '1rem',
                                                                        paddingLeft: '0.2rem',
                                                                        paddingRight: '0.2rem',
                                                                    }}
                                                            >
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="lists" style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(auto-fill, minmax(350px, 400px))`,
                    gridAutoRows: '350px',
                    marginTop: '1rem',
                    gap: '1em',
                }}
                >
                    {/*Assets div*/}
                    <div style={{
                        borderRadius: '0.5em',
                        backgroundColor: '#E6E6E6',
                        boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '0.5em',
                    }}
                    >
                        <div style={{
                            backgroundColor: '#0a767a',
                            color: 'white',
                            borderRadius: '0.5em 0.5em 0 0',
                            padding: '0.4em 0.5em 0.4em 0.5em',
                            marginBottom: '0.2em',
                            flex: 0,
                        }}
                        >
                            <div style={{textAlign: 'center'}}>Status Board</div>
                        </div>
                        <div style={{overflowY: 'auto', flex: 1}}>
                            <EditableTable
                                title="Status Board"
                                canEdit
                                initialList={[]}
                                textSize="13px"
                                listName="assets"
                                colors={[{
                                    name: 'Green',
                                    headerColor: '#01fd05',
                                    color: 'green'
                                }, {name: 'Yellow', color: 'yellow'}, {name: 'Red', color: 'red'}]}
                                actions={[actions.asset1, actions.asset2, actions.asset3]}
                                time={widgetState.location.data.at(-1).time}
                            />
                        </div>
                    </div>
                    {/*Conditions div*/}
                    <div style={{
                        borderRadius: '0.5em',
                        backgroundColor: '#E6E6E6',
                        boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '0.5em',
                    }}
                    >
                        <div style={{
                            backgroundColor: '#0a767a',
                            color: 'white',
                            borderRadius: '0.5em 0.5em 0 0',
                            padding: '0.4em 0.5em 0.4em 0.5em',
                            marginBottom: '0.2em',
                            flex: 0,
                        }}
                        >
                            <div style={{textAlign: 'center'}}>Status</div>
                        </div>
                        <div style={{overflowY: 'auto'}}>
                            <EditableList
                                initialList={[]}
                                textSize="13px"
                                listName="conditions"
                            />
                        </div>
                    </div>
                    {/*Test Events div*/}
                    <div style={{
                        borderRadius: '0.5em',
                        backgroundColor: '#E6E6E6',
                        boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '0.5em',
                    }}
                    >
                        <div style={{
                            backgroundColor: '#0a767a',
                            color: 'white',
                            borderRadius: '0.5em 0.5em 0 0',
                            padding: '0.4em 0.5em 0.4em 0.5em',
                            marginBottom: '0.2em',
                            flex: 0,
                        }}
                        >
                            <div style={{textAlign: 'center'}}>Critical Events</div>
                        </div>
                        <div style={{overflowY: 'auto', flex: 1}}>
                            <EditableTable
                                initialList={[]}
                                textSize="13px"
                                listName="testEvents"
                                title="Critical Events"
                                canEdit
                                colors={[{name: 'White', color: 'white'}, {name: 'Yellow', color: 'yellow'}, {
                                    name: 'Green',
                                    color: '#01fd05'
                                }]}
                                actions={[actions.testEvent1, actions.testEvent2, actions.testEvent3]}
                                time={widgetState.location.data.at(-1).time}
                            />
                        </div>
                    </div>
                    {/*Current Users Div*/}
                    <div style={{
                        borderRadius: '0.5em',
                        backgroundColor: '#E6E6E6',
                        boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '0.5em',
                    }}
                    >
                        <div style={{
                            backgroundColor: '#0a767a',
                            color: 'white',
                            borderRadius: '0.5em 0.5em 0 0',
                            padding: '0.4em 0.5em 0.4em 0.5em',
                            marginBottom: '1em',
                            flex: 0,
                        }}
                        >
                            <div style={{textAlign: 'center'}}>Current Users Online</div>
                        </div>
                        <div style={{overflowY: 'auto'}}>
                            {
                                filteredUsers.map((user) => {
                                    return (
                                        <p style={{
                                            paddingLeft: '10px',
                                            color: user.id === authedUser.id ? 'green' : 'black'
                                        }} key={user.id}
                                        >
                                            {getUserName(user)}
                                        </p>
                                    );
                                })
                            }
                        </div>
                    </div>
                    {/*TODO: an entire box is probably excessive for just a select*/}
                    <div style={{
                        borderRadius: '0.5em',
                        backgroundColor: '#E6E6E6',
                        boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '0.5em',
                    }}
                    >
                        <div style={{
                            backgroundColor: '#0a767a',
                            color: 'white',
                            borderRadius: '0.5em 0.5em 0 0',
                            padding: '0.4em 0.5em 0.4em 0.5em',
                            marginBottom: '1em',
                            flex: 0,
                        }}
                        >
                            <div style={{textAlign: 'center'}}>Set Checklist</div>
                        </div>
                        <div style={{overflowY: 'auto', textAlign: 'center'}}>
                            <label htmlFor="">Checklist: </label>
                            <select value={curChecklistId} onChange={e => updateCurChecklist(Number(e.target.value))}>
                                {
                                    run.checklists.map((checklist, index) => (
                                        <option value={index}>{index + 1}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Controller;
