import React from 'react';
import {tmEvents, tmStatuses} from '../../enums';
import {TmEventKeys, TmStatusKeys} from '../../helpers/types';

type Props = {
    statuses: {
        time: number;
        status: TmStatusKeys,
    }[];
    events: {
        time: number;
        time2: number;
        event: TmEventKeys,
    }[];
    stopTime: number;
    maxTime: number;
    radarWidth?: number;
    time: number;
    statusColors: {
        [tmStatuses.STATIC]: string;
        [tmStatuses.TRACKING]: string;
        [tmStatuses.NOT_TRACKING]: string;
    };
    eventColors: {
        [tmEvents.DROPOUT]: string;
    };
}

const TmBar = ({statuses, events, stopTime, maxTime, radarWidth = 400, time, statusColors, eventColors}: Props) => {
    if(time === -1){
        time = maxTime;
    }

    const proportion = radarWidth / maxTime;

    if(!Object.keys(statusColors).length || !Object.keys(statusColors).length){
        return (
            <div/>
        );
    }

    return (
        <svg width={radarWidth} height="30">
            {
                statuses.map(({status, time: statusTime}, index) => {
                    if(statusTime > stopTime || statusTime > time){
                        return null;
                    }

                    let endTime = stopTime;
                    if(index + 1 < statuses.length){ // if not at end of statuses
                        endTime = statuses[index + 1].time;
                    }
                    const startX = proportion * statusTime;
                    let width = proportion * (time < endTime ? time : endTime) - startX;
                    if(width < 1){ // doesn't look right when less than 1
                        width = 1;
                    }

                    let fillColor = 'white';
                    if(Object.keys(statusColors).includes(status.toString())){
                        fillColor = statusColors[status];
                    }

                    return <rect key={statusTime} x={startX} width={width} height="30" style={{fill: fillColor}}/>;
                })
            }
            {
                events.map(({event, time: eventStartTime, time2: eventStopTime}) => {
                    if(eventStartTime > stopTime || eventStartTime > time){
                        return null;
                    }

                    const fillColor = eventColors[event];
                    const startX = proportion * eventStartTime;

                    let width = proportion * (time < eventStopTime ? time : eventStopTime) - startX;
                    if(width < 1){ // doesn't look right with less than 1
                        width = 1;
                    }
                    return <rect key={eventStartTime} x={startX} width={width} height="30" style={{fill: fillColor}}/>;
                })
            }
            <rect width={radarWidth} height="30" style={{fill: 'transparent', strokeWidth: 2, stroke: 'black'}}/>
        </svg>
    );
};

export default TmBar;
