import React, {useEffect, useState} from 'react';
import Audios from "./Audios";
import CallAudio from "./CallAudio";
import {WebSocket} from "../../helpers/types";
import {MediaConnection} from "peerjs";

type Props = {
    client: WebSocket;
    clientRef: React.RefObject<WebSocket>
    firebaseId: string;
    isAuthenticated: 0 | 1 | 2;
    isClientConnected: boolean;
};

export type Calls = {
    [key: string]: {
        call: MediaConnection;
        stream: MediaStream;
        name: string;
    };
};

export type Room = {
    title: string;
    users: {
        [key: string]: {
            isDeafened: boolean;
            isDisabled: boolean;
        }
    };
    usersInRoom: string[];
};

export default function AudiosAndCallAudios(props: Props) {
    const {
        client,
        clientRef,
        firebaseId,
        isAuthenticated,
        isClientConnected,
    } = props;
    const [calls, setCalls] = useState<Calls>({});
    const [rooms, setRooms] = useState<Room[]>([]);
    const [unMutedRooms, setUnMutedRooms] = useState<number[]>([]);

    useEffect(() => {
        if (!client) {
            return;
        }

        type Message = {
            data: string
        }

        function handleMessage(message: Message) {
            const dataFromServer = JSON.parse(message.data);
            if (dataFromServer.type === 'list' && dataFromServer.list === 'unMutedRooms') {
                setUnMutedRooms(dataFromServer.data[firebaseId] || []);
            }
        }

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getList',
                list: 'unMutedRooms'
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [client]);

    return <>
        <Audios
            calls={calls}
            client={client}
            clientRef={clientRef}
            firebaseId={firebaseId}
            isAuthenticated={isAuthenticated}
            isClientConnected={isClientConnected}
            rooms={rooms}
            setCallsHook={setCalls}
            setRoomsHook={setRooms}
            setUnMutedRooms={setUnMutedRooms}
            unMutedRooms={unMutedRooms}
        />
        {
            rooms.map((room, roomKey) => {
                const isDeafened = Object.values(rooms).some((room) => {
                    if (room.users[firebaseId]) {
                        return room.users[firebaseId].isDeafened;
                    }

                    return false;
                });
                const isDisabled = Object.values(rooms).some((room) => {
                    if (room.users[firebaseId]) {
                        return room.users[firebaseId].isDisabled;
                    }

                    return false;
                });
                return Object.entries(room.users).map(([peerId, user]) => {
                    if (peerId === firebaseId || !calls[peerId]) {
                        return null;
                    }

                    return (
                        <CallAudio
                            key={peerId}
                            muted={!unMutedRooms.includes(roomKey) || isDisabled || user.isDisabled || isDeafened}
                            srcObject={calls[peerId].stream}
                        />
                    );
                });
            })
        }
    </>;
}