/**
 * DraggableSnap.js is a defined class that allows a specific element
 * to be dragged across the screen and retain the new position. This
 * implementation is specifically "snap dragging" where the element can
 * be dragged anywhere on screen then snap to the nearest grid intersection.
 *
 * The type of snapping used always moves the dragged element to the closest
 * intersection. This type of snapping felt most natural to me, but it can
 * be changed here.
 *
 * Dragging was done with mouseup/move/down because in FireFox a drag event does
 * not set mouse positions. Chrome, however, does. :( Also, dragging was
 * done in a class so each element's drag is handled independently.
 */
class DraggableSnap{
    // Constructor requires the element you want to drag. This is typically
    // a div and position: absolute.
    element;

    constructor(element, boundsElement, fixedObject = false){
        this.element = element;
        this.boundsElement = boundsElement;
        this.fixedObject = fixedObject;
    }

    /**
     * The main dragging ability. xDividers and yDividers are arrays of the
     * locations on the current document of where the grid lines are positioned.
     * Make sure to update the element's dividers are updated if the grid gets
     * resized.
     * @param {float []} xDividers, x-axis of the grid divisions
     * @param {float []} yDividers, y-axis of the grid divisions
     */
    addDragging(xDividers, yDividers){
        // Get this element and set its onmousedown to dragMouseDown. Then
        // create variables for position calculations and grid arrays.
        const elementToDrag = this.element;
        const boundsElement = this.boundsElement;
        const fixedObject = this.fixedObject;

        // Used to calculate and recalculate position of the element as it drags
        let pos1 = 0;
        let pos2 = 0;
        let pos3 = 0;
        let pos4 = 0;

        // If the element is just sitting on the screen, it will only have this one event listener attached.
        elementToDrag.onmousedown = dragMouseDown;

        /**
         * Recalculate new Top and Left positions as the mouse drags across the screen
         * @param e, Mouse Event
         */
        function elementDrag(e){
            // Make sure event is captured
            e.preventDefault();

            // Make a vector to new position
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;

            // Update current mouse position
            pos3 = e.clientX;
            pos4 = e.clientY;

            // Move element to the dragged location
            elementToDrag.style.top = elementToDrag.offsetTop - pos2 + 'px';
            elementToDrag.style.left = elementToDrag.offsetLeft - pos1 + 'px';
        }

        /**
         * Calculates the nearest intersection point on the screen grid and snaps the component's
         * Left and Top to that intersection.
         */
        function closeDragElement(){
            // Get the mouse position of the drop and set variables for the snap location
            const x = xDividers;
            const y = yDividers;
            let snapX = 0;
            let snapY = 0;
            let i = 0;
            const left = parseFloat(elementToDrag.style.left);
            const top = parseFloat(elementToDrag.style.top);
            let diff = 0;

            if(Number.isNaN(left) || Number.isNaN(top)){
                document.onmouseup = null;
                document.onmousemove = null;
                return;
            }

            if(xDividers.length && yDividers.length){
                // Search in the x-axis until our position is greater than a grid division
                while(i < x.length){
                    if(left > x[i]){
                        // Find if our drop point is closer to the division we passed or the division
                        // we are approaching. Which ever one, set snapX to it.
                        diff = left - x[i];
                        if(diff > x[i + 1] - left){
                            snapX = x[i + 1];
                        } else{
                            snapX = x[i];
                        }
                    }
                    i++;
                }
                // Do the same thing for the y-axis
                i = 0;
                while(i < y.length){
                    if(top > y[i]){
                        diff = top - y[i];
                        if(diff > y[i + 1] - top){
                            snapY = y[i + 1];
                        } else{
                            snapY = y[i];
                        }
                    }
                    i++;
                }
            } else{
                snapX = left;
                snapY = top;
            }

            if(boundsElement){
                let curElement;
                if(fixedObject){
                    curElement = elementToDrag.querySelector('.visibleChat') || elementDrag;
                } else{
                    curElement = elementToDrag;
                }
                if(snapX > boundsElement.offsetWidth - curElement.offsetWidth){
                    snapX = boundsElement.offsetWidth - curElement.offsetWidth;
                }
                if(snapX < 0){
                    snapX = 0;
                }
                if(snapY > boundsElement.offsetHeight - curElement.offsetHeight){
                    snapY = boundsElement.offsetHeight - curElement.offsetHeight;
                }
                if(snapY < 0){
                    snapY = 0;
                }
            }

            // Move the element to the snapped location and drop it.
            elementToDrag.style.left = snapX + 'px';
            elementToDrag.style.top = snapY + 'px';
            document.onmouseup = null;
            document.onmousemove = null;
        }

        /**
         * Handle what happens when the left mouse button is pressed down on a component
         * @param e, Mouse Event
         */
        function dragMouseDown(e){
            // Check to make sure it is a left mouse click
            if(e.button === 0){
                // Get mouse position
                pos3 = e.clientX;
                pos4 = e.clientY;

                // Handle element moving or letting go
                document.onmouseup = closeDragElement;
                document.onmousemove = elementDrag;
            }
        }
    }
}

export default DraggableSnap;
