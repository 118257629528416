import {useContext, useState, useEffect} from 'react';
import ClientContext from '../helpers/ClientContext';
import {WebSocket} from '../helpers/types';

const useListItems = (listName: string, init: [] | {} | boolean | number = [], nonContextClient: WebSocket | undefined | null = undefined) => {
    let client: WebSocket | null;
    const context = useContext(ClientContext);
    if(nonContextClient === undefined){
        client = context.client;
    } else{
        client = nonContextClient;
    }

    const [items, setItems] = useState(init);

    useEffect(() => {
        if(client === null){
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {

            const dataFromServer = JSON.parse(message.data);
            if(dataFromServer.type === 'list' && dataFromServer.list === listName){
                setItems(dataFromServer.data ?? init);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getList',
                list: listName
            })
        );

        return () => {
            if(client){
                client.removeEventListener('message', handleMessage);
            }
        };
    }, [client, listName]);
    return items;
};

export default useListItems;

