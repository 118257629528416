import React, {useEffect, useState} from 'react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Label} from 'recharts';
import PropTypes from 'prop-types';

const getEmptyData = dataPointsShown => {
    let i = 0;
    const initData = [];
    while(i < dataPointsShown){
        initData.push({name: `${dataPointsShown - i} `, y: null});
        i++;
    }

    return initData;
};

const VerticalLineChart = props => {
    const {
        chartTitle,
        xDomain,
        baseYTicks,
        axisColor,
        backgroundColor, // of container div
        chartWidth,
        chartHeight,
        lines,
        bound,
        dataPointsShown,
        referenceMultiple,
        minorReferenceMultiple,
        verticalKey,
        tickFontSize,
        gridColor,
        gridLineColor,
        xLabel,
        yLabel,
    } = props;

    //TODO: change when we decide how we want to seed chart pre-data
    const [data, setData] = useState(getEmptyData(dataPointsShown));

    useEffect(() => {
        const propData = props.data;
        const propDataLength = propData.length;
        if(propDataLength === 1){
            const {x} = propData[0];
            if(x === -1){ //TODO: replace if we change how we signal that we're out of data
                return;
            }
        }

        let newData; // copy by value
        if(propDataLength > 1){ // timeline was adjusted
            let filteredData = [];
            if(propDataLength > dataPointsShown){
                propData.some((propDatum, index) => {
                    filteredData.push(propDatum);
                    return (index + 1) >= dataPointsShown;
                });
            } else{
                filteredData = propData.map(datum => {
                    datum.bound = [];
                    datum.name = datum[verticalKey];
                    datum.x = datum[verticalKey];
                    bound.forEach((boundary) => {
                        datum.bound.push(Number(datum[boundary.key]) + boundary.limit);
                    })
                    return datum;
                });

                if(propDataLength < dataPointsShown){
                    let filteredIndex = 0;
                    while(filteredData.length < dataPointsShown){
                        filteredData.unshift({name: `${dataPointsShown - filteredIndex} `, y: null});
                        filteredIndex++;
                    }
                }
            }

            newData = filteredData;
        } else{ // normal push of time increment worth of data
            const datum = propData[0];
            const x = datum[verticalKey];

            if(x === 0 || x === '0'){
                newData = getEmptyData(dataPointsShown);
            } else{
                newData = data.map(obj => ({...obj}));
            }
            if(x !== newData[newData.length - 1].name){ // could happen when playing after pausing
                newData.shift(); // remove first element from array
                datum.bound = [];
                datum.name = datum[verticalKey];
                datum.x = datum[verticalKey];
                bound.forEach((boundary) => {
                    datum.bound.push(Number(datum[boundary.key]) + boundary.limit);
                })
                newData.push(datum);
            }
        }

        // updateMajorLines(newData);
        setData(newData);
    }, [props.data]);

    return (
        <div style={{backgroundColor, width: chartWidth * 1.03, display: 'flex'}}>
            <div style={{flex: 1}}>
                <div style={{textAlign: 'center', fontSize: '1.5em'}}>{chartTitle}</div>
                <LineChart
                    layout="vertical"
                    width={chartWidth}
                    height={chartHeight * 2}
                    data={data}
                    margin={props.margin}
                >
                    <CartesianGrid strokeDasharray="3 3" fill={gridColor} stroke={gridLineColor} />

                    <XAxis
                        tick={{fontSize: tickFontSize}}
                        type="number"
                        domain={xDomain}
                        stroke={axisColor}
                    >
                        {
                            xLabel &&
                            <Label value={xLabel.text} offset={xLabel.offset || 0} position="bottom"
                                   style={{fill: xLabel.color || 'black'}}/>
                        }
                    </XAxis>
                    <YAxis
                        tick={{fontSize: tickFontSize}}
                        reversed
                        dataKey="name"
                        type="number"
                        domain={[0, baseYTicks.at(-1)]}
                        stroke={axisColor}
                        ticks={baseYTicks}
                    >
                        {
                            yLabel &&
                            <Label angle={270} offset={yLabel.offset !== undefined ? yLabel.offset : -10}
                                   value={yLabel.text} position="left" style={{fill: yLabel.color || 'black'}}/>
                        }
                    </YAxis>
                    <YAxis
                        tick={{fontSize: tickFontSize}}
                        reversed
                        yAxisId="right"
                        orientation="right"
                        tickLine={false}
                        allowDecimals
                        allowDataOverflow
                        domain={[0, baseYTicks.at(-1)]}
                        stroke={axisColor}
                        ticks={baseYTicks}
                    />
                    {
                        lines.map((line, index) => (
                            <Line
                                key={`line${index}`}
                                dot={false}
                                isAnimationActive={false}
                                dataKey={line.key}
                                stroke={line.color}
                                strokeWidth={2}
                                strokeDasharray={line.dashArray}
                                type="monotone"
                            />
                        ))
                    }
                    {
                        bound.map((boundLine, index) => (
                            <Line
                                key={`bound${index}`}
                                dot={false}
                                isAnimationActive={false}
                                dataKey={`bound[${index}]`}
                                stroke={boundLine.color}
                                strokeWidth={2}
                                type="monotone"
                            />
                            )
                        )
                    }
                </LineChart>
            </div>
        </div>
    );
};

VerticalLineChart.defaultProps = {
    chartTitle: '',
    xDomain: [],
    baseYTicks: [],
    axisColor: 'black',
    backgroundColor: 'rgb(104,131,140)',
    chartWidth: 300,
    chartHeight: 300,
    lines: [],
    gridColor: 'rgb(102,102,102)',
    dataPointsShown: 10,
    verticalKey: 'x',
    margin: {},
    tickFontSize: '1em',
    gridLineColor: 'black',
    referenceMultiple: 50,
    minorReferenceMultiple: 0,
};

VerticalLineChart.propTypes = {
    chartTitle: PropTypes.string,
    xDomain: PropTypes.arrayOf(PropTypes.number),
    baseYTicks: PropTypes.arrayOf(PropTypes.number),
    axisColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    chartWidth: PropTypes.number,
    chartHeight: PropTypes.number,
    lines: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    gridColor: PropTypes.string,
    dataPointsShown: PropTypes.number,
    verticalKey: PropTypes.string,
    margin: PropTypes.objectOf(PropTypes.number),
    tickFontSize: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))).isRequired,
    gridLineColor: PropTypes.string,
    referenceMultiple: PropTypes.number,
    minorReferenceMultiple: PropTypes.number,
    bound: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.string]))).isRequired
};

export default VerticalLineChart;
