import React, {useContext, useEffect, useState} from 'react';
import RadarForm from '../widgets/radarForm';
import TelemetryForm from '../widgets/telemetryForm';
import ClientContext from '../../helpers/ClientContext';
import RadarColors from '../widgets/RadarColors';
import TmColors from '../widgets/TmColors';
import useListItems from '../../hooks/useListItems';
import axios from 'axios';
import {
    barDescriptors,
    csvFiles,
    csvFilesReadable,
    radarEvents,
    radarStatuses,
    tmEvents,
    tmStatuses
} from '../../enums';
import {Radar, ConfigurationType, Tm} from '../../helpers/types';
import {getTMinusTime} from '../../helpers/helper';

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

type Configurations = {
    [index: string]: ConfigurationType
}

const Configuration = () => {
    const {client} = useContext(ClientContext);

    const {addNotification} = useContext(ClientContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savedConfigurations, setSavedConfigurations] = useState<Configurations>({});
    const [curConfiguration, setCurrentConfiguration] = useState<string>('');
    const [radarForms, setRadarForms] = useState<Radar[]>([]);
    const [telemetryForms, setTelemetryForms] = useState<Tm[]>([]);
    const [colors, setColors] = useState({
        [barDescriptors.RADAR_STATUSES]: {
            [radarStatuses.ACQUIRED]: '#ffffff',
            [radarStatuses.TRACKING]: '#ffffff',
            [radarStatuses.POWERED]: '#ffffff',
            [radarStatuses.NON_POWERED]: '#ffffff',
            [radarStatuses.PROPAGATING]: '#ffffff',
        },
        [barDescriptors.RADAR_EVENTS]: {
            [radarEvents.RADIATING]: '#ffffff',
            [radarEvents.DROPOUT]: '#ffffff',
            [radarEvents.DISCRIMINATING]: '#ffffff',
        },
        [barDescriptors.TM_STATUSES]: {
            [tmStatuses.STATIC]: '#ffffff',
            [tmStatuses.TRACKING]: '#ffffff',
            [tmStatuses.NOT_TRACKING]: '#ffffff',
        },
        [barDescriptors.TM_EVENTS]: {
            [tmEvents.DROPOUT]: '#ffffff',
        }
    });
    const [curUtcDay, setCurUtcDay] = useState('');
    const [curUtcHour, setCurUtcHour] = useState('');
    const [curUtcMinute, setCurUtcMinute] = useState('');
    const [curUtcSecond, setCurUtcSecond] = useState('');
    const [curUtcMilSecond, setCurUtcMilSecond] = useState('');
    const [curTMinusHour, setCurTMinusHour] = useState('');
    const [curTMinusMinute, setCurTMinusMinute] = useState('');
    const [curTMinusSecond, setCurTMinusSecond] = useState('');
    const [dataSet, setDataSet] = useState(-1);
    const [curTMinusMilSecond, setCurTMinusMilSecond] = useState('');
    const [increment, setIncrement] = useState(1);
    const [configName, setConfigName] = useState('');
    const uncheckedConfigurations = useListItems('configurations');
    const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);
    const [timeOptions, setTimeOptions] = useState([<option/>]);
    const [numDataPoints, setNumDataPoints] = useState(0);

    const adminConfigurationId = '60dc865e24bfd905989c6906';

    useEffect(() => {
        if(!curConfiguration || !Object.keys(savedConfigurations).length || !numDataPoints){
            return;
        }

        let curTime = 0;
        const newTimeOptions = [];
        while(curTime <= numDataPoints - 1) {
            newTimeOptions.push(
                <option key={curTime} value={curTime}>
                    {getTMinusTime(curTime, savedConfigurations[curConfiguration])}
                </option>
            );
            curTime++;
        }

        setTimeOptions(newTimeOptions);
    }, [curConfiguration, savedConfigurations, numDataPoints]);

    useEffect(() => {
        if(!client || !curConfiguration || !Object.keys(savedConfigurations).length || numDataPoints){
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            const {type, data} = JSON.parse(message.data);
            if(type === 'locationData'){
                setNumDataPoints(data.length);
                client.removeEventListener('message', handleMessage);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getLocationData',
                configurationId: curConfiguration,
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [curConfiguration, savedConfigurations, client]);

    useEffect(() => {
        if(!Array.isArray(uncheckedConfigurations)){
            return;
        }
        setConfigurations(uncheckedConfigurations);
    }, [uncheckedConfigurations]);


    const addRadar = () => {
        const newRadar = {
            title: '',
            events: [],
            statuses: [],
            startTime: 0,
            stopTime: timeOptions.length - 1,
            timeCreated: performance.now().toString()
        };

        setRadarForms([...radarForms, newRadar]);
    };

    const addTelemetry = () => {
        const newTelemetry = {
            title: '',
            events: [],
            statuses: [],
            startTime: 0,
            stopTime: timeOptions.length - 1,
            timeCreated: performance.now().toString()
        };

        setTelemetryForms([...telemetryForms, newTelemetry]);
    };

    const updateConfiguration = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(!curConfiguration){
            return;
        }

        event.preventDefault();

        const configurationId = curConfiguration;
        const radars = radarForms;
        const tms = telemetryForms;
        const barColors = colors;

        if(parseInt(curUtcHour) > 23 || parseInt(curTMinusHour) > 23){
            addNotification({message: 'Zulu/T- Hours cannot be greater than 23', color: 'red'});
            return;
        }

        if(parseInt(curUtcDay) < 0 || parseInt(curUtcHour) < 0 || parseInt(curUtcMinute) < 0 || parseInt(curUtcSecond) < 0 || parseInt(curTMinusHour) < 0 || parseInt(curTMinusMinute) < 0 || parseInt(curTMinusSecond) < 0){
            addNotification({message: 'Zulu/T- cannot be less than 0', color: 'red'});
            return;
        }

        if(parseInt(curUtcMinute) > 59 || parseInt(curUtcSecond) > 59 || parseInt(curTMinusMinute) > 59 || parseInt(curTMinusSecond) > 59){
            addNotification({message: 'Zulu/T- Seconds or Minutes cannot be greater than 59', color: 'red'});
            return;
        }

        //set UTC time
        const utcTemp = curUtcDay + ':' + curUtcHour + ':' + curUtcMinute + ':' + curUtcSecond + '.' + curUtcMilSecond;

        //set  tMinus time
        const tMinusTemp = curTMinusHour + ':' + curTMinusMinute + ':' + curTMinusSecond + '.' + curTMinusMilSecond;

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/configurations/update`, {
            configurationId,
            fields: {
                radars,
                tms,
                barColors,
                utc: utcTemp,
                tMinus: tMinusTemp,
                increment,
                dataSet,
                name: configName,
            },
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                } else{
                    addNotification({message: 'Configuration Saved', color: 'green'});
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });
    };

    const openNewConfigurationForm = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;
        const nameInput = form.querySelector('#name') as HTMLInputElement;
        const configuration = {
            name: nameInput.value,
        };

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/configurations/add`, configuration)
            .then(res => {
                if(res.data.error){
                    addNotification({message: res.data.message, color: 'red'});
                } else{
                    form.reset();
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                addNotification({message: error.message, color: 'red'});
            });

    };

    const updateRadar = (index: number, updatedRadar: Radar | null) => {
        const newRadars = [...radarForms];

        if(updatedRadar === null){
            newRadars.splice(index, 1);
        } else{
            newRadars[index] = updatedRadar;
        }

        setRadarForms(newRadars);
    };

    const updateTelemetry = (index: number, updatedTelemetry: Tm | null) => {
        const newTms = [...telemetryForms];

        if(updatedTelemetry === null){
            newTms.splice(index, 1);
        } else{
            newTms[index] = updatedTelemetry;
        }

        setTelemetryForms(newTms);
    };

    const updateColors = (type: number, status: number, color: string) => {
        const newColors = JSON.parse(JSON.stringify(colors));
        newColors[type][status] = color;
        setColors(newColors);
    };

    const populateFields = (curConfig: ConfigurationType | null) => {
        if(curConfig === null){
            return;
        }

        //populate
        const utcTemp = curConfig.utc.split(/[:.]/);
        setCurUtcDay(utcTemp[0]);
        setCurUtcHour(utcTemp[1]);
        setCurUtcMinute(utcTemp[2]);
        setCurUtcSecond(utcTemp[3]);
        setCurUtcMilSecond(utcTemp[4]);

        //populate T- time
        const tMinusTemp = curConfig.tMinus.split(/[:.]/);
        setCurTMinusHour(tMinusTemp[0]);
        setCurTMinusMinute(tMinusTemp[1]);
        setCurTMinusSecond(tMinusTemp[2]);
        setCurTMinusMilSecond(tMinusTemp[3]);

        //populate increment
        setIncrement(curConfig.increment);

        //populate dataSet
        setDataSet(curConfig.dataSet);
    };

    useEffect(() => {
        const newConfigurations: Configurations = {};
        configurations.forEach(configuration => {
            newConfigurations[configuration.id] = configuration;
        });
        setSavedConfigurations(newConfigurations);

    }, [configurations]);

    return (
        <>
            <div className="grayBody"/>
            <div id="configurationPage" className="overflowAuto" style={{padding: '1rem'}}>
                {
                    isModalOpen &&
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2,
                    }}
                    >
                        <form onSubmit={handleSubmit} style={{
                            backgroundColor: 'white',
                            width: '500px',
                            borderRadius: '0.5em',
                            padding: '1em',
                            marginBottom: '20em',
                        }}
                        >
                            <h3 style={{marginBottom: '1rem'}}>New Configuration</h3>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="name" style={{marginBottom: 0}}>Configuration Name:</label>
                                <input
                                    style={{flex: 1, marginLeft: '1rem'}}
                                    type="text"
                                    id="name"
                                    required
                                    className="form-control"
                                    placeholder="Configuration Name"
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                <input type="submit" value="Create Configuration" className="btn btn-primary"/>
                                <button onClick={() => setIsModalOpen(false)} type="button"
                                        style={{marginLeft: '0.3em'}}
                                >
                                    Close
                                </button>
                            </div>
                        </form>

                    </div>
                }

                {/*list of configurations*/}
                <div
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h2 style={{marginBottom: '1rem'}}>Configurations</h2>
                    <label htmlFor="configurationId" style={{marginRight: '0.5rem'}}>
                        <b>Configuration:</b>
                    </label>
                    <select
                        style={{marginRight: '0.5rem'}}
                        id="configurationId"
                        className="form-select"
                        onChange={(e) => {
                            setCurrentConfiguration(e.currentTarget.value);
                            const curConfig = savedConfigurations[e.currentTarget.value];
                            setRadarForms(curConfig.radars);
                            setTelemetryForms(curConfig.tms);
                            setColors(curConfig.barColors);
                            setDataSet(curConfig.dataSet);
                            setConfigName(curConfig.name);
                            populateFields(curConfig);
                        }}
                        defaultValue="DEFAULT"
                    >
                        <option value="DEFAULT" disabled hidden>
                            select a configuration
                        </option>
                        {
                            Object.values(savedConfigurations).filter(configuration => isDevEnvironment || configuration.id !== adminConfigurationId).map((configuration, index) =>
                                <option
                                    key={index}
                                    value={configuration.id}
                                >
                                    {configuration.name}
                                </option>
                            )
                        }
                    </select>
                    <button onClick={openNewConfigurationForm}>Add New</button>
                </div>
                <hr style={{width: '100%'}}/>

                {/*UTC and tMinus input*/}
                {
                    curConfiguration &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    >
                        <h2 style={{marginBottom: '1rem'}}>Settings</h2>
                        <div>
                            {/*Select a Data Set*/}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: '0.5rem',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                padding: '0.5rem',
                                borderRadius: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                            >
                                <label htmlFor="configName" style={{marginBottom: 0}}>Name:</label>
                                <input
                                    style={{margin: 0}}
                                    id="configName"
                                    className="form-control"
                                    type="text"
                                    value={configName}
                                    onChange={e => setConfigName(e.currentTarget.value)}
                                />
                                <label htmlFor="dataset" style={{marginBottom: 0}}>Dataset:</label>
                                <select
                                    id="dataset"
                                    className="form-select"
                                    onChange={(e) => {
                                        setDataSet(parseInt(e.currentTarget.value));
                                    }}
                                >
                                    <option value="DEFAULT" disabled hidden>
                                        select a data set
                                    </option>
                                    {
                                        Object.values(csvFiles).map((file, index) => {
                                                const dataset = configurations.filter(config => config.id === curConfiguration)[0].dataSet;
                                                return (<option
                                                    key={index}
                                                    value={file}
                                                    selected={file === dataset}
                                                >
                                                    {csvFilesReadable[file]}
                                                </option>);
                                            }
                                        )
                                    }
                                </select>
                            </div>

                            <div style={{
                                padding: '0.5rem',
                                backgroundColor: 'white',
                                margin: 'auto',
                                borderRadius: '0.5rem',
                            }}
                            >
                                <table className="utcTable">
                                    <tbody>
                                    {/*UTC*/}
                                    <tr>
                                        <td style={{paddingBottom: '0.5rem'}}>Zulu</td>
                                        <td style={{paddingBottom: '0.5rem'}}>
                                            <input
                                                type="number"
                                                id="utcDay"
                                                value={curUtcDay}
                                                onChange={e => setCurUtcDay(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="dd"
                                            />
                                        </td>
                                        <td style={{paddingBottom: '0.5rem'}}>:</td>
                                        <td style={{paddingBottom: '0.5rem'}}>
                                            <input
                                                type="number"
                                                id="utcHour"
                                                value={curUtcHour}
                                                onChange={e => setCurUtcHour(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="HH"
                                                min="0"
                                                max="23"
                                            />
                                        </td>
                                        <td style={{paddingBottom: '0.5rem'}}>:</td>
                                        <td style={{paddingBottom: '0.5rem'}}>
                                            <input
                                                type="number"
                                                id="utcMinute"
                                                value={curUtcMinute}
                                                onChange={e => setCurUtcMinute(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="mm"
                                                min="0"
                                                max="59"
                                            />
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="utcSecond"
                                                value={curUtcSecond}
                                                onChange={e => setCurUtcSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="ss"
                                                min="0"
                                                max="59"
                                            />
                                        </td>
                                        <td>.</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="utcMilSecond"
                                                value={curUtcMilSecond}
                                                onChange={e => setCurUtcMilSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="SSS"
                                                min="0"
                                                max="999"
                                            />
                                        </td>
                                    </tr>
                                    {/*tMinus*/}
                                    <tr>
                                        <td>T-</td>
                                        <td>
                                        </td>
                                        <td/>
                                        <td>
                                            <input
                                                type="number"
                                                id="tMinusHour"
                                                value={curTMinusHour}
                                                onChange={e => setCurTMinusHour(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="HH"
                                                min="0"
                                                max="23"
                                            />
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="tMinusMinute"
                                                value={curTMinusMinute}
                                                onChange={e => setCurTMinusMinute(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="mm"
                                                min="0"
                                                max="59"
                                            />
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="tMinusSecond"
                                                value={curTMinusSecond}
                                                onChange={e => setCurTMinusSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="ss"
                                                min="0"
                                                max="59"
                                            />
                                        </td>
                                        <td>.</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="tMinusMilSecond"
                                                value={curTMinusMilSecond}
                                                onChange={e => setCurTMinusMilSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="SSS"
                                                min="0"
                                                max="999"
                                            />
                                        </td>
                                    </tr>
                                    {/*Increment*/}
                                    {/*<tr>
                                        <td>Increment</td>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td>
                                            <input
                                                type="number"
                                                style={{width: '60px'}}
                                                onChange={e => setIncrement(parseInt(e.currentTarget.value))}
                                                value={increment}
                                            />
                                        </td>
                                    </tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr style={{width: '100%'}}/>
                    </div>
                }

                {/*Radar and Telemetry*/}
                {
                    curConfiguration &&
                    <>
                        <h2 style={{marginBottom: '1rem'}}>Radar & TM Colors</h2>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{backgroundColor: 'white', paddingTop: '1rem', borderRadius: '0.5rem'}}>
                                <div>
                                    <RadarColors updateColors={updateColors} colors={colors}/>
                                </div>
                                <hr/>
                                <div>
                                    <TmColors updateColors={updateColors} colors={colors}/>
                                </div>
                            </div>
                        </div>

                        <hr style={{width: '100%'}}/>

                        <h2 style={{marginBottom: '1rem'}}>Radar & TM Bars</h2>
                        <div style={{
                            display: 'flex',
                            padding: '10px',
                            justifyContent: 'center'
                        }}
                        >
                            <div style={{marginRight: '1rem'}}>
                                <button type="button"
                                        style={{padding: '0.5rem', fontSize: '1.1em', marginBottom: '0.5rem'}}
                                        onClick={addRadar}
                                >
                                    Add Radar
                                </button>

                                {radarForms.map((form, index) =>
                                    <RadarForm
                                        key={form.timeCreated}
                                        form={form}
                                        radarID={index}
                                        updateRadar={updateRadar}
                                        statusColors={colors[barDescriptors.RADAR_STATUSES]}
                                        eventColors={colors[barDescriptors.RADAR_EVENTS]}
                                        timeOptions={timeOptions}
                                        configuration={savedConfigurations[curConfiguration]}
                                    />
                                )}
                            </div>
                            <div>
                                <button type="button"
                                        style={{padding: '0.5rem', fontSize: '1.1em', marginBottom: '0.5rem'}}
                                        onClick={addTelemetry}
                                >
                                    Add Telemetry
                                </button>

                                {telemetryForms.map((form, index) =>
                                    <TelemetryForm
                                        key={form.timeCreated}
                                        telemetryID={index}
                                        form={form}
                                        updateTelemetry={updateTelemetry}
                                        statusColors={colors[barDescriptors.TM_STATUSES]}
                                        eventColors={colors[barDescriptors.TM_EVENTS]}
                                        timeOptions={timeOptions}
                                        configuration={savedConfigurations[curConfiguration]}
                                    />
                                )}
                            </div>
                        </div>
                        <button onClick={updateConfiguration} style={{
                            backgroundColor: 'blue',
                            border: 'none',
                            color: 'white',
                            padding: '0.3rem 0.75rem',
                            boxShadow: 'black 0 1px 1px 0',
                            fontSize: '1.4rem',
                            position: 'fixed',
                            bottom: '20px',
                            right: '40px',
                        }}
                        >
                            <strong>Save</strong>
                        </button>
                        <div style={{marginBottom: '3rem'}}/>
                    </>
                }
            </div>
        </>
    );
};

export default Configuration;
