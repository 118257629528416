import React, {useContext} from 'react';
import MapLines from '../widgets/MapLines';
import {getEndOfArray, useWindowSize} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';

function Maps(){
    const {run, widgetState} = useContext(ClientContext);
    const screenSize = useWindowSize();

    return (
        <>
            <div className="blackBody"></div>
            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
                padding: '1rem'
            }}
            >
                <div style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
                    <div style={{
                        position: 'absolute',
                        width: '1150px',
                        border: '1px solid white',
                    }}
                    >
                        <MapLines points={run.chevronPoints}/>
                    </div>
                    <div style={{
                        position: 'absolute',
                        width: '1150px',
                        border: '1px solid white',
                        top: '515px'
                    }}
                    >
                        <MapLines points={run.keepOutPoints}
                                  circleX={getEndOfArray(widgetState.location.data, 'keepOutX')}
                                  circleY={getEndOfArray(widgetState.location.data, 'keepOutY')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Maps;
