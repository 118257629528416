//"Flight Safety" Battery Parameters
export const batteryParameters = {
    rows: [
        [
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'A',
                color: 'yellow'
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'B',
                color: 'yellow'
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'BATTERY VOLTAGE',
                color: 'rgb(212,176,128)'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatVoltA'
            },
            {
                type: 'static text',
                value: 'V',
                color: 'white'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatVoltB'
            },
            {
                type: 'static text',
                value: 'V',
                color: 'white'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BATTERY CURRENT',
                color: 'rgb(212,176,128)'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatCurA'
            },
            {
                type: 'static text',
                value: 'A',
                color: 'white'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatCurB'
            },
            {
                type: 'static text',
                value: 'A',
                color: 'white'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BATTERY TEMP',
                color: 'rgb(212,176,128)'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatTempA'
            },
            {
                type: 'static text',
                value: 'C',
                color: 'white'
            },
            {
                type: 'dynamicText',
                key: 'ftsBatTempB'
            },
            {
                type: 'static text',
                value: 'C',
                color: 'white'
            }
        ],
    ]
};

export const esadParameters = {
    rows: [
        [
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'A'
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'B'
            }
        ],
        [
            {
                type: 'static text',
                value: 'INPUT POWER',
            },
            {
                type: 'dynamicText',
                key: 'esadInputPwrA'
            },
            {
                type: 'static text',
                value: 'V'
            },
            {
                type: 'dynamicText',
                key: 'esadInputPwrB'
            },
            {
                type: 'static text',
                value: 'V'
            }
        ],
        [
            {
                type: 'static text',
                value: 'POST'
            },
            {
                type: 'light',
                key: 'esadPostA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadPostB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'ARM State'
            },
            {
                type: 'light',
                key: 'esadArmStateA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadArmStateB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'HIGH VOLTAGE MON',
            },
            {
                type: 'dynamicText',
                key: 'esadHighVoltA'
            },
            {
                type: 'static text',
                value: 'V'
            },
            {
                type: 'dynamicText',
                key: 'esadHighVoltB'
            },
            {
                type: 'static text',
                value: 'V'
            }
        ],
        [
            {
                type: 'static text',
                value: 'FAIL-SAFE'
            },
            {
                type: 'light',
                key: 'esadFailSafeA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadFailSafeB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'TERMINATE COMMAND'
            },
            {
                type: 'light',
                key: 'esadTermCmdA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadTermCmdB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: ''
            },
        ],
        [
            {
                type: 'static text',
                value: 'UMBILICAL DISCONNECT'
            },
            {
                type: 'light',
                key: 'esadUmbDisA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadUmbDisB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'MISSILE AWAY SWITCH'
            },
            {
                type: 'light',
                key: 'esadMisAwaySwitchA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadMisAwaySwitchB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'UMBILICAL TIMER'
            },
            {
                type: 'light',
                key: 'esadUmbTimerA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadUmbTimerB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'MISSILE AWAY TIMER'
            },
            {
                type: 'light',
                key: 'esadMisAwayTimerA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadMisAwayTimerB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'IGN INHIBIT STATUS'
            },
            {
                type: 'light',
                key: 'esadIgnStatA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadIgnStatB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'SD SAFING TIMER'
            },
            {
                type: 'light',
                key: 'esadSafingTimerA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadSafingTimerB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'SD SAFING'
            },
            {
                type: 'light',
                key: 'esadSafingA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadSafingB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'GLIDER SEPARATION'
            },
            {
                type: 'light',
                key: 'esadGliderSepA',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'esadGliderSepB',
                bounds: {
                    0: 'white',
                    1: 'red',
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ]
    ]
};

export const toneTable = {
    rows: [
        [
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'A'
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'static text',
                value: 'B'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SSTO',
            },
            {
                type: 'dynamicText',
                key: 'ftsSstoA'
            },
            {
                type: 'static text',
                value: 'V'
            },
            {
                type: 'dynamicText',
                key: 'ftsSstoB'
            },
            {
                type: 'static text',
                value: 'V'
            }
        ],
        [
            {
                type: 'static text',
                value: 'TONE A'
            },
            {
                type: 'light',
                key: 'ftsToneAA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsToneAB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'TONE B'
            },
            {
                type: 'light',
                key: 'ftsToneBA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsToneBB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'TONE C'
            },
            {
                type: 'light',
                key: 'ftsToneCA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsToneCB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'TONE D'
            },
            {
                type: 'light',
                key: 'ftsToneDA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsToneDB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'OPTIONAL'
            },
            {
                type: 'light',
                key: 'optionalA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'optionalB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'ARM'
            },
            {
                type: 'light',
                key: 'ftsArmA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsArmB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ],
        [
            {
                type: 'static text',
                value: 'TERMINATE'
            },
            {
                type: 'light',
                key: 'ftsTerminateA',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            },
            {
                type: 'light',
                key: 'ftsTerminateB',
                bounds: {
                    0: 'white',
                    1: 'red'
                }
            },
            {
                type: 'static text',
                value: ''
            }
        ]
    ]
};

export const sepHealthStatusTbl = {
    head: ['SEP Health Status'],
    rows: [
        [
            {
                type: 'static text',
                value: 'IMU Fail',
            },
            {
                type: 'dynamicText',
                key: 'sepImuFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BCAS_Fail',
            },
            {
                type: 'dynamicText',
                key: 'sepBcasFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BCAS_Comm_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepBcasCommFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BCAS_Low_Voltage_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepBcasLowVoltFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BCAS_Overcurrent_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepBCASOverFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AUR_Comm_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepAurCommFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AUR_State_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepAurStateFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Platform_ID_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepPlatIdFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Fin_Wiggle_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepFinWiggleFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SEA_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepSeaFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Aircraft_Comm_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepAircraftCommFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Nav_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepNavFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD_Inhibit_PreBoost'
            },
            {
                type: 'dynamicText',
                key: 'sepIsdInhibitPreBoost'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Roll_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepRollFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Boost_Fail'
            },
            {
                type: 'dynamicText',
                key: 'sepBoostFail'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SEP Initial Roll'
            },
            {
                type: 'dynamicText',
                key: 'sepInitialRoll'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SEP Mission State'
            },
            {
                type: 'dynamicText',
                key: 'sepMissionState'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SEP AUR Ready'
            },
            {
                type: 'dynamicText',
                key: 'sepAurReady'
            }
        ],
    ]
};

export const safetyOfFlightTbl = {
    head: ['Safety of Flight'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Glider Temp 1'
            },
            {
                type: 'dynamicText',
                key: 'gliderTempOne'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Glider Temp 2'
            },
            {
                type: 'dynamicText',
                key: 'gliderTempTwo'
            }
        ],
        [
            {
                type: 'static text',
                value: 'PAA Temp 1'
            },
            {
                type: 'dynamicText',
                key: 'paaTempOne'
            }
        ],
        [
            {
                type: 'static text',
                value: 'PAA Temp 2'
            },
            {
                type: 'dynamicText',
                key: 'paaTempTwo'
            }
        ],
        [
            {
                type: 'static text',
                value: 'PAA Temp 3'
            },
            {
                type: 'dynamicText',
                key: 'paaTempThree'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CAS Temp 1'
            },
            {
                type: 'dynamicText',
                key: 'casTempOne'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CAS Temp 2'
            },
            {
                type: 'dynamicText',
                key: 'casTempTwo'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CAS Temp 3'
            },
            {
                type: 'dynamicText',
                key: 'casTempThree'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SESAD-Ready To Arm'
            },
            {
                type: 'dynamicText',
                key: 'sesadReadyToArm'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD Arm Pwr Valid'
            },
            {
                type: 'dynamicText',
                key: 'isdArmPwrValid'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD Inhibit Timer'
            },
            {
                type: 'dynamicText',
                key: 'isdInhibitTimer'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD 1760 Umbilical'
            },
            {
                type: 'dynamicText',
                key: 'isdUmbilical'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD MAS Status'
            },
            {
                type: 'dynamicText',
                key: 'isdMasStatus'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SEP Initial Roll'
            },
            {
                type: 'dynamicText',
                key: 'sepInitialRoll'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD Fire Present'
            },
            {
                type: 'dynamicText',
                key: 'isdFirePresent'
            }
        ],
    ]
};

export const thermalBatVoltTbl = {
    head: ['THERMAL BATTERY VOLTAGES'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Glider Batt A Squibbed'
            },
            {
                type: 'dynamicText',
                key: 'gliderBatASquib'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Glider Batt B Squibbed'
            },
            {
                type: 'dynamicText',
                key: 'gliderBattBSquib'
            }
        ],
        [
            {
                type: 'static text',
                value: 'ISD_BT_VOLT'
            },
            {
                type: 'dynamicText',
                key: 'isdBtVolt'
            }
        ],
        [
            {
                type: 'static text',
                value: 'SESAD_BT_VOLT'
            },
            {
                type: 'dynamicText',
                key: 'sesadBtVolt'
            }
        ],
        [
            {
                type: 'static text',
                value: 'BCAS Voltage'
            },
            {
                type: 'dynamicText',
                key: 'bcasVoltage'
            }
        ],
    ]
};

export const missionInfoTbl = {
    head: ['MISSION INFO'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Mission Start'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
            {
                type: 'static text',
                value: 'se'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Mission Flight Time'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
            {
                type: 'static text',
                value: 'se'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Mission State'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
        ],
        [
            {
                type: 'static text',
                value: 'Store Location'
            },
            {
                type: 'dynamicText',
                key: 'x'
            },
        ],
    ]
};