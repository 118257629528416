import React, {useContext, useEffect, useState} from 'react';
import DirectChat from '../widgets/directChat';
import ClientContext from '../../helpers/ClientContext';
import useListItems from '../../hooks/useListItems';
import {RunType, Position} from '../../helpers/types';

type RunTypes = {
    [runId: string]: RunType;
}

type PositionDataType = {
    positionId: string;
    positionName: string;
}

type RoomType = {
    title: string;
    users: {
        [firebaseId: string]: {
            name: string;
            isDisabled: boolean;
        };
    },
    usersInRoom: string[];
}

type UserAudio = {
    userId: string;
    name: string;
    isDisabled: boolean;
}

const Controller2 = () => {
    const {client, runId} = useContext(ClientContext);
    const uncheckedRooms = useListItems('rooms', {});
    const [rooms, setRooms] = useState<RoomType[]>([]);
    const uncheckedRuns = useListItems('runs');
    const [runs, setRuns] = useState<RunType[]>([]);
    const uncheckedPositions = useListItems('positions');
    const [positions, setPositions] = useState<Position[]>([]);
    const uncheckedUserAudio = useListItems('userAudio');
    const [userAudio, setUserAudio] = useState<UserAudio[]>([]);
    const [savedRuns, setSavedRuns] = useState<RunTypes>({});
    const [positionData, setPositionData] = useState<PositionDataType[]>([]);

    useEffect(() => {
        if(!Array.isArray(uncheckedRuns)){
            return;
        }

        setRuns(uncheckedRuns);
    }, [uncheckedRuns]);

    useEffect(() => {
        if(!Array.isArray(uncheckedUserAudio)){
            return;
        }

        setUserAudio(uncheckedUserAudio);
    }, [uncheckedUserAudio]);

    useEffect(() => {
        const newRuns: RunTypes = {};
        runs.forEach(run => {
            newRuns[run.id] = run;
        });

        setSavedRuns(newRuns);
    }, [runs]);

    useEffect(() => {
        if(!Array.isArray(uncheckedPositions)){
            return;
        }

        setPositions(uncheckedPositions);
    }, [uncheckedPositions]);

    useEffect(() => {
        if(!Array.isArray(uncheckedRooms)){
            return;
        }

        setRooms(uncheckedRooms);
    }, [uncheckedRooms]);

    useEffect(() => {
        if(!Object.keys(savedRuns).length || !Object.keys(positions).length){
            return;
        }

        const newPositions: PositionDataType[] = [];
        const foundPositions: string[] = [];
        savedRuns[runId].users.forEach(user => {
            if(!foundPositions.includes(user.position)){
                foundPositions.push(user.position);
                const positionName = positions.find(curPosition => curPosition.id === user.position)?.name;
                if(positionName){
                    newPositions.push({
                        positionId: user.position,
                        positionName,
                    });
                }
            }
        });
        setPositionData(newPositions);

    }, [savedRuns, positions]);

    /*const mute = (userId) => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                field: 'isMuted',
                value: true,
            })
        );
    }

    const unMute = (userId) => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                field: 'isMuted',
                value: false,
            })
        );
    }

    const deafen = (userId) => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                field: 'isDeafened',
                value: true,
            })
        );
    }

    const unDeafen = (userId) => {
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                field: 'isDeafened',
                value: false,
            })
        );
    }*/

    const disable = (userId: string) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                value: true,
            })
        );
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'leaveRoom',
                firebaseId: userId,
            })
        );
    };

    const enable = (userId: string) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setMuted',
                userId,
                value: false,
            })
        );
    };

    return (
        <div id="controller2">
            <div className="grayBody"/>
            <div className="overflowAuto" style={{padding: '1em'}}>
                <h2>Off-Nominal Green Cards</h2>
                {
                    positionData.length ?
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 400px)',
                                gap: '10px',
                                marginBottom: '2em',
                            }}
                        >
                            {
                                positionData.map(position => (
                                    <DirectChat
                                        key={position.positionId}
                                        listName={position.positionId}
                                        title={position.positionName}
                                        isController
                                    />
                                ))
                            }
                        </div>
                        :
                        <div style={{textAlign: 'center', marginTop: '3rem', marginBottom: '3rem', fontSize: '1.1rem'}}>
                            No position data set
                        </div>
                }
                <h2>User Audio Control</h2>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 13em)', gap: '0.5em'}}>
                    {
                        userAudio.map(({userId, name, isDisabled}) => {
                            return (
                                <div style={{border: '1px solid black', padding: '0.5rem', borderRadius: '0.5rem'}}
                                     key={userId}
                                >
                                    <div style={{textAlign: 'center', fontSize: '1.3rem'}}><strong>{name}</strong>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        {/*{
                                                user.isMuted ?
                                                    <button type="button" onClick={() => unMute(userId)}>Un-Mute</button>
                                                    :
                                                    <button type="button" onClick={() => mute(userId)}>Mute</button>
                                            }
                                            {
                                                user.isDeafened ?
                                                    <button type="button" onClick={() => unDeafen(userId)}>Un-Deafen</button>
                                                    :
                                                    <button type="button" onClick={() => deafen(userId)}>Deafen</button>
                                            }*/}
                                        {
                                            isDisabled ?
                                                <button type="button" onClick={() => enable(userId)}>Enable</button>
                                                :
                                                <button type="button" onClick={() => disable(userId)}>Disable</button>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Controller2;
