import React, {useState, useEffect, useContext} from 'react';
import useListItems from '../../hooks/useListItems';
import axios from 'axios';
import ClientContext from '../../helpers/ClientContext';
import {Position} from '../../helpers/types';
import MUIDataTable, {MUIDataTableColumn, MUIDataTableMeta} from 'mui-datatables';

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

type Positions = {
    [index: string]: Position
}

const Positions = () => {
    const {addNotification} = useContext(ClientContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateFormOpen, setCreateFormOpen] = useState(true);
    const [savedPositions, setSavedPositions] = useState<Positions>({});
    const [curPosition, setCurPosition] = useState<Position>({id: '', _id: '', name: ''});
    const uncheckedPositions = useListItems('positions');
    const [positions, setPositions] = useState<Position[]>([]);

    useEffect(() => {
        if(!Array.isArray(uncheckedPositions)){
            return;
        }
        setPositions(uncheckedPositions);

    }, [uncheckedPositions]);


    const openCreatePositionForm = () => {
        setIsModalOpen(true);
        setCreateFormOpen(true);
    };

    /**
     *
     * @param event
     */
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;

        const nameInput = form.querySelector('#name') as HTMLInputElement;
        const position = {
            name: nameInput.value,
        };

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/positions/add`, position)
            .then(res => {
                if(res.data.error){
                    addNotification({message: res.data.message, color: 'red'});
                } else{
                    form.reset();
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                addNotification({message: error.message, color: 'red'});
            });
    };

    /**
     *
     * @param event
     */
    const updatePosition = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;
        const positionIdInput = form.querySelector('input[name="positionId"]') as HTMLInputElement;
        const positionId = positionIdInput.value;
        const nameInput = form.querySelector('input[name="name"]') as HTMLInputElement;
        const name = nameInput.value;

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/positions/update`, {
            positionId,
            name,
        })
            .then(res => {
                const json = res.data;
                if(json.error){
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                } else{
                    addNotification({message: `Position has been saved`, color: 'green'});
                    setIsModalOpen(false);
                }
            })
            .catch(error => {
                addNotification({message: error, color: 'red'});
            });
    };

    /**
     *
     * @param position
     */
    const openEditForm = (position: Position) => {
        setCurPosition(position);
        setIsModalOpen(true);
        setCreateFormOpen(false);
    };

    const confirmDeletePosition = (position: Position) => {
        const positionId = position.id;

        if(window.confirm('Are you sure you want to delete this Position?')){
            axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/positions/delete`, {
                positionId,
            })
                .then(res => {
                    if(res.data.error){
                        addNotification({message: res.data.message, color: 'red'});
                    }
                })
                .catch(error => {
                    addNotification({message: error.message, color: 'red'});
                });
        }
    };

    const columnsDef: MUIDataTableColumn[] = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
            },
        },
        {
            name: 'action',
            label: 'Action',
            options: {
                download: false,
                customBodyRender: (value: any, tableData: MUIDataTableMeta) => {
                    const position = Object.values(savedPositions).find(curPosition => curPosition.name === tableData.rowData[0]);
                    if(!position){
                        return <div>Error</div>
                    }

                    return (
                        <div style={{whiteSpace: 'nowrap'}}>
                            <button
                                type="button"
                                onClick={() => openEditForm(position)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                onClick={() => confirmDeletePosition(position)}
                                style={{
                                    borderRadius: '0.5em'
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    );
                }
            }
        }
    ];

    useEffect(() => {
        const newPositions: Positions = {};
        positions.forEach(position => {
            newPositions[position.id] = position;
        });
        setSavedPositions(newPositions);
    }, [positions]);

    return (
        <>
            <div className="grayBody"/>
            <div id="positions">
                {
                    isModalOpen &&
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 101, //mui-datatables header has a z-index of 100
                    }}
                    >
                        {
                            isCreateFormOpen ?
                                <form onSubmit={handleSubmit} style={{
                                    backgroundColor: 'white',
                                    width: '400px',
                                    borderRadius: '0.5em',
                                    padding: '1em',
                                    marginBottom: '20em'
                                }}
                                >
                                    <h3 style={{textAlign: 'center'}}>Create Position</h3>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr',
                                        gap: '0.3em 0.5em',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <label htmlFor="name">Position Name: </label>
                                        <input
                                            type="text"
                                            id="name"
                                            required
                                            className="form-control"
                                            placeholder="Position Name"
                                        />
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                        <input type="submit" value="Create Position" className="btn btn-primary"/>
                                        <button onClick={() => setIsModalOpen(false)} type="button"
                                                style={{marginLeft: '0.3em', borderRadius: '0.5em'}}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </form>
                                :
                                /*Edit Position*/
                                <form onSubmit={updatePosition} style={{
                                    backgroundColor: 'white',
                                    width: '400px',
                                    borderRadius: '0.5em',
                                    padding: '1em',
                                    marginBottom: '20em'
                                }}
                                >
                                    <h3 style={{textAlign: 'center'}}>Edit Position</h3>
                                    <input type="hidden" name="positionId" value={curPosition.id}/>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr',
                                        gap: '0.3em 0.5em',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <label htmlFor="editPosition">Position Name: </label>
                                        <input type="text" id="editName" name="name" required className="form-control"
                                               defaultValue={curPosition.name}
                                        />
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1em'}}>
                                        <button type="submit">Save</button>
                                        <button onClick={() => setIsModalOpen(false)} type="button"
                                                style={{marginLeft: '0.3em'}}
                                        >Close
                                        </button>
                                    </div>
                                </form>
                        }
                    </div>
                }

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1em',
                    color: 'black',
                    marginTop: '2em'
                }}
                >
                    <div className="table-page-container">
                        <h3 style={{color: 'black', textAlign: 'center'}}>Positions</h3>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <button type="button"
                                    onClick={openCreatePositionForm}
                                    style={{
                                        borderRadius: '0.5em',
                                        backgroundColor: 'blue',
                                        borderColor: 'white',
                                        color: 'white'
                                    }}
                            >
                                Create Position
                            </button>
                        </div>
                        <MUIDataTable columns={columnsDef} data={Object.values(savedPositions)} title="Positions"
                                      options={{
                                          selectableRows: 'none',
                                          print: false,
                                          downloadOptions: {filename: 'ITAC Positions.csv'}
                                      }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Positions;
