import React, {useEffect} from 'react';
import commsVidWebm from '../../videos/comms.webm';
import commsWebMp4 from '../../videos/comms.mp4';
import {useHistory} from "react-router-dom";

const Faq = () => {
    const history = useHistory();

    useEffect(() => {
        document.querySelector('#commsVideo').playbackRate = 0.5;
    }, []);

    return (
        <div>
            <div className="blackBody"/>
            <div className="overflowAuto">
                <div class="container" style={{display: 'flex', justifyContent: 'center'}}>
                    <video id="commsVideo" controls style={{border: '1px solid white'}}>
                        <source src={commsVidWebm} type="video/webm"/>
                        <source src={commsWebMp4} type="video/mp4"/>
                    </video>
                    <button class="backBtn" onClick={() => history.goBack()}>Go Back</button>
                </div>
            </div>
        </div>
    );
};

export default Faq;
