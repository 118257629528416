import React, {useContext, useEffect, useState} from 'react';
import useListItems from '../../hooks/useListItems';
import ClientContext from '../../helpers/ClientContext';

type Props = {
    listName: string;
}

const EditableList = (props: Props) => {
    const {listName} = props;
    const {client} = useContext(ClientContext);
    const [value, setValue] = React.useState('');
    const uncheckedItems = useListItems(listName);
    const [items, setItems] = useState<string[]>([]);

    useEffect(() => {
        if(Array.isArray(uncheckedItems)){
            setItems(uncheckedItems);
        }
    }, [uncheckedItems]);

    /**
     *
     * @param item
     */
    const sendData = (item: string) => {
        if(!client){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'addListItem',
                list: listName,
                item,
                updateRun: true,
            })
        );
    };

    /**
     *
     * @param event
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    /**
     *
     * @param event
     */
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(value){
            sendData(value);
        }

        setValue('');
    };

    /**
     *
     * @param event
     */
    const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(!client){
            return;
        }
        const text = event.currentTarget.closest('li')?.querySelector('span')?.textContent;
        if(text === undefined){
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'removeListItem',
                list: listName,
                item: text,
                updateRun: true,
            })
        );
    };

    function moveUp(index: number){
        if(!client || index === 0){ // already at top
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'moveListItemUp',
                list: listName,
                index,
            }),
        );
    }

    function moveDown(index: number){
        if(!client || index === items.length){ // already at bottom
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'moveListItemDown',
                list: listName,
                index,
            }),
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit} style={{textAlign: 'center', marginTop: '0.1rem', marginBottom: '0.2rem'}}>
                <input type="text" value={value} onChange={handleChange} />
                <button type="submit">Add</button>
            </form>
            <div style={{padding: '0.1em 1em 0.1em 1em'}}>
                <div style={{
                    border: '3px solid #0a767a',
                    borderRadius: '0.5em',
                    backgroundColor: 'white',
                    paddingTop: '0.5em',
                    paddingBottom: '0.3em',
                    paddingRight: '0.5em'
                }}>
                    <ul className="useArrows" style={{marginBottom: 0, paddingLeft: '2em'}}>
                        {items.map((item, index) => (
                            <li key={index} style={{marginBottom: '0.1em', borderBottom: '1px solid lightgray'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <span>{item}</span>
                                    <div style={{display: 'flex', marginLeft: '1em'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '1em',}}>
                                            <button onClick={() => moveUp(index)} style={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                lineHeight: '1rem'
                                            }}>&#11014;</button>
                                            <button onClick={() => moveDown(index)} style={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                lineHeight: '1rem'
                                            }}>&#11015;</button>
                                        </div>
                                        <button
                                            style={{
                                                fontFamily: 'Arial',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                cursor: 'pointer'
                                            }}
                                            type="button" data-row={`${item}${index}`} onClick={handleRemove}>
                                            X
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default EditableList;
