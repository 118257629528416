import {csvFiles, pages, radarEvents, radarStatuses, tmEvents, tmStatuses, roles} from '../enums';
import {w3cwebsocket} from 'websocket';

export interface WebSocket extends w3cwebsocket {
    addEventListener: (type: string, handler: (message: {data: string}) => void) => void;
    removeEventListener: (type: string, handler: (message: {data: string}) => void) => void;
}

export type Notification = {
    message: string;
    color: string;
    timeout?: number;
}

export type Position = {
    _id: string;
    id: string;
    name: string;
}

export type BarColors = {
    1: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
    },
    2: {
        1: string;
        2: string;
        3: string;
    },
    3: {
        1: string;
        2: string;
        3: string;
    },
    4: {
        1: string;
    },
}

type RadarEventVals = keyof typeof radarEvents;
export type RadarEventKeys = typeof radarEvents[RadarEventVals];
type RadarStatusVals = keyof typeof radarStatuses;
export type RadarStatusKeys = typeof radarStatuses[RadarStatusVals];
export type Radar = {
    _id?: string;
    events: {event: RadarEventKeys, time: number, time2: number}[];
    statuses: {status: RadarStatusKeys, time: number}[];
    title: string;
    startTime: number;
    timeCreated: string;
    stopTime: number;
}

type TmEventVals = keyof typeof tmEvents;
export type TmEventKeys = typeof tmEvents[TmEventVals];
type TmStatusVals = keyof typeof tmStatuses;
export type TmStatusKeys = typeof tmStatuses[TmStatusVals];
export type Tm = {
    _id?: string;
    events: {event: TmEventKeys, time: number, time2: number}[];
    statuses: {status: TmStatusKeys, time: number}[];
    title: string;
    startTime: number;
    timeCreated: string;
    stopTime: number;
}

type CsvFileVals = keyof typeof csvFiles;
type CsvFileKeys = typeof csvFiles[CsvFileVals];
export type ConfigurationType = {
    _id: string;
    id: string;
    name: string;
    barColors: BarColors;
    radars: Radar[];
    tms: Tm[];
    utc: string;
    tMinus: string;
    increment: number;
    dataSet: CsvFileKeys;
}

type RoleVals = keyof typeof roles;
export type RoleKeys = typeof roles[RoleVals];
export type UserType = {
    _id: string;
    id: string;
    name: string;
    email: string;
    disabled: 0 | 1;
    role: RoleKeys;
    position?: string;
}

type PageVals = keyof typeof pages;
export type PageKeys = typeof pages[PageVals];
export type RunUserType = {
    _id?: string;
    permissionPages: PageKeys[];
    rooms: string[];
    userID: string;
    position: string;
}

export type ModdedDataType = {
    start: number;
    stop: number;
    moddedDataValue: string;
}

export type KeyEventType = {
    time: number;
    title: string;
    color: string;
}

type PointType = {
    x: number;
    y: number;
}

export type ChecklistRow = {
    callFrom: string;
    net: string;
    description: string;
    NO: string;
    time: string;
    callTo: string;
}

export type RunType = {
    _id: string;
    id: string;
    name: string;
    date: string;
    users: RunUserType[];
    interactions: {
        _id?: string;
        timestamp: string;
        userID: string;
        identifier: string;
        action: number;
    }[];
    rooms: string[];
    points: {[time: string]: {offNom: number; rand: number;}};
    configurationID: string;
    chats: {
        _id?: string;
        list: string;
        userId: string;
        message: string;
        controller: boolean;
        userReadable: string;
        timestamp: string;
        isTimestamped?: boolean;
    }[];
    moddedData: {[key: string]: ModdedDataType[]};
    keyEvents: KeyEventType[];
    utc: string;
    tMinus: string;
    increment: number;
    pageOrder: PageKeys[];
    chevronPoints: PointType[][],
    keepOutPoints: PointType[][],
    checklists: ChecklistRow[][],
    checklistId: number;
    checklistPosition: string;
}

export type Option = {
    label: string;
    value: string;
}

export function checkIfKeyInEnum(key: string, jsEnum: {}): string{
    type ValidKey = keyof typeof jsEnum; //type that accepts any of jsEnum's keys

    try{
        validateFileId(key);
        return jsEnum[key];
    } catch(e){
        return 'error';
    }

    /**
     * @note if error is not thrown, then it assumes the key is valid
     * @param curKey
     */
    function validateFileId(curKey: string): asserts curKey is ValidKey{
        if(!(curKey in jsEnum)){
            throw Error('invalid key');
        }
    }
}

export function getObjectFromEnum(key: string, jsEnum: {}): {[key: number]: {rand: number; offNom: number}}{
    type ValidKey = keyof typeof jsEnum; //type that accepts any of jsEnum's keys

    try{
        validateFileId(key);
        return jsEnum[key];
    } catch(e){
        return {};
    }

    /**
     * @note if error is not thrown, then it assumes the key is valid
     * @param curKey
     */
    function validateFileId(curKey: string): asserts curKey is ValidKey{
        if(!(curKey in jsEnum)){
            throw Error('invalid key');
        }
    }
}
