import {pages, pagesReadable} from '../../enums';
import {PageKeys} from '../../helpers/types';

type TrainingLinksType = {
    title: string;
    url: string;
    cName: string;
    page: PageKeys;
}

export const trainingLinks: TrainingLinksType[] = [
    {
        title: pagesReadable[pages.FTSO],
        url: '/FTM29-FTSO',
        cName: 'nav-links',
        page: pages.FTSO,
    },
    {
        title: pagesReadable[pages.HS],
        url: '/FTM29_H&S',
        cName: 'nav-links',
        page: pages.HS,
    },
    {
        title: pagesReadable[pages.TRAJECTORIES],
        url: '/trajectories',
        cName: 'nav-links',
        page: pages.TRAJECTORIES,
    },
    {
        title: pagesReadable[pages.TEST_CONDUCTOR],
        url: '/testConductor',
        cName: 'nav-links',
        page: pages.TEST_CONDUCTOR,
    },
    {
        title: pagesReadable[pages.STATUS_BOARD],
        url: '/statusBoard',
        cName: 'nav-links',
        page: pages.STATUS_BOARD,
    },
    {
        title: pagesReadable[pages.CONTROLLER],
        url: '/controller',
        cName: 'nav-links',
        page: pages.CONTROLLER,
    },
    {
        title: pagesReadable[pages.CONTROLLER2],
        url: '/controller2',
        cName: 'nav-links',
        page: pages.CONTROLLER2,
    },
    {
        title: pagesReadable[pages.RADARS],
        url: '/radars',
        cName: 'nav-links',
        page: pages.RADARS,
    },
    {
        title: pagesReadable[pages.TELEMETRY],
        url: '/telemetry',
        cName: 'nav-links',
        page: pages.TELEMETRY,
    },
    {
        title: pagesReadable[pages.SAFETY2],
        url: '/safety2',
        cName: 'nav-links',
        page: pages.SAFETY2,
    },
    {
        title: pagesReadable[pages.MAPS],
        url: '/Maps',
        cName: 'nav-links',
        page: pages.MAPS,
    },
    {
        title:pagesReadable[pages.AIRCRAFT_INTEGRATION],
        url: '/Aircraft Integration',
        cName: 'nav-links',
        page: pages.AIRCRAFT_INTEGRATION
    },
    {
        title:pagesReadable[pages.BCAS],
        url: '/BCAS',
        cName: 'nav-links',
        page: pages.BCAS
    },
    {
        title:pagesReadable[pages.COMM_LOGS],
        url: '/COMM_LOGS',
        cName: 'nav-links',
        page: pages.COMM_LOGS
    },
    {
        title:pagesReadable[pages.FLIGHT_SAFETY],
        url: '/FLIGHT_SAFETY',
        cName: 'nav-links',
        page: pages.FLIGHT_SAFETY
    },
    {
        title:pagesReadable[pages.CHECKLIST],
        url: '/CHECKLIST',
        cName: 'nav-links',
        page: pages.CHECKLIST
    }
];

export const adminLinks = [
    {
        title: 'Home',
        url: '/admin',
        cName: 'nav-links'
    },
    {
        title: 'Configuration',
        url: '/admin/configuration',
        cName: 'nav-links'
    },
    {
        title: 'Manage Users',
        url: '/admin/createUsers',
        cName: 'nav-links'
    },
    {
        title: 'Runs',
        url: '/admin/runs',
        cName: 'nav-links'
    },
    {
        title: 'Positions',
        url: '/admin/positions',
        cName: 'nav-links'
    },
];
