import React, {useEffect, useState, useContext, useRef, Fragment} from 'react';
import Select, {Options, StylesConfig} from 'react-select';
import axios from 'axios';
import useListItems from '../../hooks/useListItems';
import ClientContext from '../../helpers/ClientContext';
import {pages, pagesReadable, csvFilesPoints, varTitles} from '../../enums';
import LineChartRechart from '../widgets/lineChartRechart';
import {getFlightPathProps, getFlightPathTimeProps, getTMinusTime} from '../../helpers/helper';
import {
    checkIfKeyInEnum,
    Option,
    RunType,
    ConfigurationType,
    UserType,
    Position,
    getObjectFromEnum,
    PageKeys,
    ModdedDataType,
    KeyEventType,
    ChecklistRow
} from '../../helpers/types';
import {useParams} from 'react-router-dom';
import InteractiveLines from '../widgets/InteractiveLines';
import Status from '../widgets/status';
import DisplayList from '../widgets/displayList';
import * as XLSX from 'xlsx';
import Checklist from './checklist';

const urlStart = `${window.location.protocol}//${window.location.hostname}`;
const isDevEnvironment = window.location.hostname === 'localhost';

type UserDataType = {
    [userId: string]: {userID: string, position: string, permissionPages: PageKeys[], rooms: string[]}
}

type VarTitleVals = keyof typeof varTitles;
type VarTitleKeys = typeof varTitles[VarTitleVals]

type ModdedDataTypes = {
    [key: string]: ModdedDataType[];
};

type ConfigurationTypes = {
    [configurationId: string]: ConfigurationType;
}

type RunTypes = {
    [runId: string]: RunType;
}

type Positions = {
    [positionId: string]: Position;
}

type PointsType = {
    [time: number]: {offNom: number, rand: number}
}

type PageOption = {
    label: string;
    value: PageKeys;
}

const RunsNext = () => {
    const {addNotification, client} = useContext(ClientContext);
    const {id: runId} = useParams<{id: string;}>();
    const [savedRuns, setSavedRuns] = useState<RunTypes>({});
    const [curRun, setCurrentRun] = useState<RunType>({
        _id: '',
        id: '',
        name: '',
        date: '',
        users: [],
        interactions: [],
        rooms: [],
        points: {},
        configurationID: '',
        chats: [],
        moddedData: {},
        keyEvents: [],
        utc: '',
        tMinus: '',
        increment: 1,
        pageOrder: [],
        chevronPoints: [],
        keepOutPoints: [],
        checklists: [],
        checklistId: 0,
        checklistPosition: '-1'
    });
    const [options, setOptions] = useState<Option[]>([]);
    const [savedConfigurations, setSavedConfigurations] = useState<ConfigurationTypes>({});
    const [roomOptions, setRoomOptions] = useState<Option[]>([]);
    const [pageOptions, setPageOptions] = useState<PageOption[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
    const [userData, setUserData] = useState<UserDataType>({});
    const [savedPositions, setSavedPositions] = useState<Positions>({});
    const [roomNames, setRoomNames] = useState<string[]>([]);
    const [value, setValue] = useState('');
    const uncheckedUsers = useListItems('users');
    const [users, setUsers] = useState<UserType[]>([]);
    const uncheckedRuns = useListItems('runs');
    const [runs, setRuns] = useState<RunType[]>([]);
    const uncheckedConfigs = useListItems('configurations');
    const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);
    const uncheckedPositions = useListItems('positions');
    const [positions, setPositions] = useState<Position[]>([]);
    const [configurationId, setConfigurationId] = useState('');
    const [locationData, setLocationData] = useState([{}]);
    const [moddedData, setModdedData] = useState<ModdedDataTypes>({
        roll: [],
        pitch: [],
        yaw: [],
        offNom: [],
        batVolt: [],
        curDraw: [],
        CDRSig: [],
        velocity: [],
        lat: [],
        lon: [],
        rand: [],
        ranc: [],
        alt: [],
        battery: [],
        weapon: [],
        lat2: [],
        lon2: [],
        keepOutX: [],
        keepOutY: [],
        ftrOneGo: [],
        ftrOneCmdGo: [],
        ftrOneSigStr: [],
        tmXmtrTck69: [],
        srmOneDome: [],
        aurReady: [],
        fcasEcuOp: [],
        vmcGood: [],
        gyroGood: [],
        ftrTwoGo: [],
        ftrTwoCmdGo: [],
        ftrTwoSigStr: [],
        ftrOneLTck: [],
        srmTwoDome: [],
        ofpThroughput: [],
        discretesInExpected: [],
        gpsOperational: [],
        pduGood: [],
        navStatus: [],
        esadAPostGo: [],
        esadBPostGo: [],
        ftsOneBattVolt: [],
        ftsTwoBattVolt: [],
        pduVolts: [],
        srmDomePs: [],
        ofpCsum: [],
        discretesOutExpected: [],
        imuGood: [],
        pduSwitchExpected: [],
        finsUnlock: [],
        stageOneFlightMode: [],
        stageTwoFlightMode: [],
        stageThreeFlightMode: [],
        stageOneSrm: [],
        ftsBattTck: [],
        ftsOneWireCutterRelay: [],
        weaponState: [],
        bcasStageOneApSafe: [],
        stageTwoSrm: [],
        ftsBattR: [],
        ftsTwoWireCutterRelay: [],
        bcasStageTwoApSafe: [],
        stageThreeSrm: [],
        mduTck: [],
        vmcTck: [],
        pidOn: [],
        bcasStageThreeApSafe: []
    });
    const [numDataPoints, setNumDataPoints] = useState(0);
    const [timeOptions, setTimeOptions] = useState([<option/>]);
    const [keyEvents, setKeyEvents] = useState<KeyEventType[]>([]);
    const [keyEventTime, setKeyEventTime] = useState(0);
    const [keyEventTitle, setKeyEventTitle] = useState('');
    const [keyEventColor, setKeyEventColor] = useState('#000000');
    const [pageOrder, setPageOrder] = useState<PageKeys[]>([]);
    const [curUtcDay, setCurUtcDay] = useState('');
    const [curUtcHour, setCurUtcHour] = useState('');
    const [curUtcMinute, setCurUtcMinute] = useState('');
    const [curUtcSecond, setCurUtcSecond] = useState('');
    const [curUtcMilSecond, setCurUtcMilSecond] = useState('');
    const [curTMinusHour, setCurTMinusHour] = useState('');
    const [curTMinusMinute, setCurTMinusMinute] = useState('');
    const [curTMinusSecond, setCurTMinusSecond] = useState('');
    const [curTMinusMilSecond, setCurTMinusMilSecond] = useState('');
    //const [increment, setIncrement] = useState(1);

    const [points, setPoints] = useState<PointsType>({});
    const [chevronPoints, setChevronPoints] = useState<{x: number, y: number}[][]>([]);
    const [keepOutPoints, setKeepOutPoints] = useState<{x: number, y: number}[][]>([]);
    const [checklists, setChecklists] = useState<ChecklistRow[][]>([[]]);
    const [curChecklist, setCurChecklist] = useState(0);
    const [checklistPosition, setChecklistPosition] = useState('-1');

    const runNameRef = useRef<HTMLInputElement>(null!);
    const runDateRef = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        if(!Array.isArray(uncheckedUsers)) {
            return;
        }
        setUsers(uncheckedUsers);
    }, [uncheckedUsers]);

    useEffect(() => {
        if(!Array.isArray(uncheckedRuns)) {
            return;
        }
        setRuns(uncheckedRuns);
    }, [uncheckedRuns]);

    useEffect(() => {
        if(!Array.isArray(uncheckedConfigs)) {
            return;
        }
        setConfigurations(uncheckedConfigs);
    }, [uncheckedConfigs]);

    useEffect(() => {
        if(!Array.isArray(uncheckedPositions)) {
            return;
        }
        setPositions(uncheckedPositions);
    }, [uncheckedPositions]);

    useEffect(() => {
        if(curRun.id || !Object.keys(savedRuns).length) {
            return;
        }

        const newRun = savedRuns[runId];

        const newUsers = newRun.users.map(({userID}) => {
            let name = users.find(user => user.id === userID)?.name;
            if(name === undefined) {
                name = '';
            }
            return {label: name, value: userID};
        });
        setSelectedUsers(newUsers);

        const newUserData: UserDataType = {};
        newRun.users.forEach(user => {
            newUserData[user.userID] = user;
        });

        Object.values(pages).forEach(page => {
            if(!newRun.pageOrder.includes(page)) {
                newRun.pageOrder.push(page);
            }
        });
        setPageOrder(newRun.pageOrder);

        setUserData(newUserData);
        setCurrentRun(newRun);
        setRoomNames(newRun.rooms);
        setConfigurationId(newRun.configurationID);
        setModdedData(newRun.moddedData || {});
        setKeyEvents(newRun.keyEvents);
        setChevronPoints(newRun.chevronPoints);
        setKeepOutPoints(newRun.keepOutPoints);
        setChecklists(newRun.checklists);
        setChecklistPosition(newRun.checklistPosition);
        //populateFields(newRun);
    }, [savedRuns]);

    useEffect(() => {
        const newRuns: RunTypes = {};
        runs.forEach(run => {
            newRuns[run.id] = run;
        });
        setSavedRuns(newRuns);
    }, [runs]);

    useEffect(() => {
        const newConfigurations: ConfigurationTypes = {};
        configurations.forEach(configuration => {
            newConfigurations[configuration.id] = configuration;
        });
        setSavedConfigurations(newConfigurations);

    }, [configurations]);

    useEffect(() => {
        const newPositions: Positions = {};
        positions.forEach(position => {
            newPositions[position.id] = position;
        });
        setSavedPositions(newPositions);
    }, [positions]);

    useEffect(() => {
        setOptions(
            users.map(user => ({
                label: user.name,
                value: user.id
            })));
    }, [users]);

    useEffect(() => {
        setRoomOptions(roomNames.map(room => ({
            label: room,
            value: room
        })));
    }, [roomNames]);

    useEffect(() => {
        setPageOptions(Object.values(pages).map(page => {
            return {
                label: pagesReadable[page],
                value: page
            };
        }));
    }, []);

    useEffect(() => {
        if(!client || !configurationId || !Object.keys(savedConfigurations).length) {
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            const {type, data} = JSON.parse(message.data);
            if(type === 'locationData') {
                if(!Object.keys(moddedData).length) {
                    const newModdedData: ModdedDataTypes = {};
                    Object.keys(data[0]).forEach(key => {
                        if(key !== 'time') {
                            newModdedData[key] = [];
                        }
                    });
                    setModdedData(newModdedData);
                }
                if(!numDataPoints) {
                    setNumDataPoints(data.length);
                }
                setLocationData(data);
            }
        };

        client.addEventListener('message', handleMessage);
        let newPoints: PointsType;
        if(!Object.keys(points).length) {
            newPoints = curRun.points;
        } else {
            newPoints = getObjectFromEnum(savedConfigurations[configurationId].dataSet.toString(), csvFilesPoints);
        }
        setPoints(newPoints);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getLocationData',
                configurationId,
                points: newPoints,
                runId
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [configurationId, savedConfigurations]);

    useEffect(() => {
        if(!configurationId || !Object.keys(savedConfigurations).length) {
            return;
        }

        let curTime = 0;
        const newTimeOptions = [];
        while (curTime <= numDataPoints - 1) {
            newTimeOptions.push(
                <option key={curTime} value={curTime}>
                    {getTMinusTime(curTime, savedConfigurations[configurationId])}
                </option>
            );
            curTime++;
        }

        setTimeOptions(newTimeOptions);
    }, [configurationId, savedConfigurations, numDataPoints]);

    type IsMulti = true;
    const customStyles: StylesConfig<Option, IsMulti> = {
        option: (provided) => ({
            ...provided,
            borderBottom: '1px dotted black'
        })
    };
    const pageCustomStyles: StylesConfig<PageOption, IsMulti> = {
        option: (provided) => ({
            ...provided,
            borderBottom: '1px dotted black'
        })
    };

    /**
     *
     * @param event
     */
    const updateRun = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if(runNameRef.current === null || runDateRef.current === null) {
            return;
        }

        /*        //Check bounds on Zulu and T-
                if(parseInt(curUtcHour) > 23 || parseInt(curTMinusHour) > 23){
                    addNotification({message: 'Zulu/T- Hours cannot be greater than 23', color: 'red'});
                    return;
                }

                if(parseInt(curUtcDay) < 0 || parseInt(curUtcHour) < 0 || parseInt(curUtcMinute) < 0 || parseInt(curUtcSecond) < 0 || parseInt(curTMinusHour) < 0 || parseInt(curTMinusMinute) < 0 || parseInt(curTMinusSecond) < 0){
                    addNotification({message: 'Zulu/T- cannot be less than 0', color: 'red'});
                    return;
                }

                if(parseInt(curUtcMinute) > 59 || parseInt(curUtcSecond) > 59 || parseInt(curTMinusMinute) > 59 || parseInt(curTMinusSecond) > 59){
                    addNotification({message: 'Zulu/T- Seconds or Minutes cannot be greater than 59', color: 'red'});
                    return;
                }

                //set UTC time
                const utcTemp = curUtcDay + ':' + curUtcHour + ':' + curUtcMinute + ':' + curUtcSecond + '.' + curUtcMilSecond;

                //set  tMinus time
                const tMinusTemp = curTMinusHour + ':' + curTMinusMinute + ':' + curTMinusSecond + '.' + curTMinusMilSecond;*/

        //Make sure all users have a position before saving
        const positionCheck = Object.values(userData).some((user) => {
            if(user.position === undefined) {
                const filteredUser = users.find(curUser => curUser.id === user.userID);

                addNotification({message: `Error: ${filteredUser?.name} must have a Position set`, color: 'red'});
                return true;
            }
            return false;
        });

        if(positionCheck) {
            return;
        }

        const run = {
            runId: curRun.id,
            name: runNameRef.current.value,
            date: runDateRef.current.value,
            users: Object.values(userData).map(curUserData => curUserData),
            rooms: roomNames,
            configurationID: configurationId,
            points,
            moddedData,
            keyEvents,
            /*utc: utcTemp,
            tMinus: tMinusTemp,*/
            pageOrder,
            chevronPoints,
            keepOutPoints,
            checklists,
            checklistPosition
        };

        axios.post<{error: boolean; message: string;}>(`${urlStart}${isDevEnvironment ? ':5000' : ''}/runs/update`, run)
            .then(res => {
                const json = res.data;
                if(json.error) {
                    addNotification({message: `Error: ${json.message}`, color: 'red'});
                } else {
                    addNotification({message: 'Run Updated', color: 'green'});
                }
            }).catch(error => {
            addNotification({message: error.message, color: 'red'});
        });

    };

    /**
     *
     * @param event
     * @param userId
     */
    const handlePositionChange = (event: React.ChangeEvent<HTMLSelectElement>, userId: string) => {
        const newPosition = event.target.value;
        const newUserData = JSON.parse(JSON.stringify(userData));
        newUserData[userId].position = newPosition;
        setUserData(newUserData);
    };

    /**
     * This remove is for when the red x button is clicked. It removes the room
     * from the current user data
     * @param event
     */
    const handleRoomRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        const index = parseInt(event.currentTarget.dataset.row || '');
        const newRoomNames = [...roomNames];
        const roomToRemove = newRoomNames[index];
        newRoomNames.splice(index, 1);
        setRoomNames(newRoomNames);

        //we need to take the room to be removed out of the userData
        const newUserData: UserDataType = JSON.parse(JSON.stringify(userData));

        Object.entries(newUserData).forEach(user => {
            newUserData[user[0]].rooms = user[1].rooms.filter(e => e !== roomToRemove);
        });

        setUserData(newUserData);
    };

    function updatePoint(e: React.ChangeEvent<HTMLInputElement>) {
        const time = e.currentTarget.dataset.time;
        if(time === undefined) {
            return;
        }

        const adjusted = Number(e.currentTarget.value);
        const newPoints = JSON.parse(JSON.stringify(points));
        newPoints[time].offNom = adjusted;
        setPoints(newPoints);
    }


    /**
     *
     * @param event
     * @param field
     */
    function updateModdedData(event: React.FormEvent<HTMLFormElement>, field: string) {
        event.preventDefault();
        const form = event.currentTarget;
        const startInput = form.querySelector('[name="start"]') as HTMLInputElement;
        const start = Number(startInput.value);
        const stopInput = form.querySelector('[name="stop"]') as HTMLInputElement;
        const stop = Number(stopInput.value);
        const moddedDataValueInput = form.querySelector('[name="value"]') as HTMLInputElement;
        const moddedDataValue = moddedDataValueInput.value;

        if(start > stop) {
            addNotification({color: 'red', message: 'Start cannot be greater than stop'});
            return;
        }

        if(moddedData[field].length) {
            const hasConflict = moddedData[field].some(data => {

                if(start >= data.start && start <= data.stop) {
                    addNotification({
                        color: 'red',
                        message: `Start time of ${getTMinusTime(start, savedConfigurations[configurationId])} overlaps with a previous entry`
                    });
                    return true;
                }
                if(stop >= data.start && stop <= data.stop) {
                    addNotification({
                        color: 'red',
                        message: `Stop time of ${getTMinusTime(stop, savedConfigurations[configurationId])} overlaps with a previous entry`
                    });
                    return true;
                }

                return false;
            });

            if(hasConflict) {
                return;
            }
        }

        const newModdedData = JSON.parse(JSON.stringify(moddedData));
        newModdedData[field].push({start, stop, moddedDataValue});
        newModdedData[field].sort((a: ModdedDataType, b: ModdedDataType) => {
            return a.start - b.start;
        });
        setModdedData(newModdedData);
    }

    function addKeyEvent(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const isDuplicateTime = keyEvents.some(({time}) => time === keyEventTime);
        if(isDuplicateTime) {
            addNotification({
                message: `There is already a key event at ${getTMinusTime(keyEventTime, savedConfigurations[configurationId])}`,
                color: 'red'
            });
            return;
        }

        const newKeyEvents: KeyEventType[] = JSON.parse(JSON.stringify(keyEvents));
        newKeyEvents.push({time: keyEventTime, title: keyEventTitle, color: keyEventColor});
        newKeyEvents.sort((a, b) => a.time - b.time);
        setKeyEventTitle('');
        setKeyEvents(newKeyEvents);
    }

    /**
     *
     * @param selectedOptions
     * @param userId
     */
    const handleUserPagesChange = (selectedOptions: Options<PageOption>, userId: string) => {
        if(selectedOptions === null || !Array.isArray(selectedOptions)) {
            return;
        }

        const newPages = selectedOptions.map(option => option.value);
        const newUserData = JSON.parse(JSON.stringify(userData));
        newUserData[userId].permissionPages = newPages;
        setUserData(newUserData);
    };

    /**
     *
     * @param selectedOptions
     * @param userId
     */
    const handleUserRoomChange = (selectedOptions: Options<Option>, userId: string) => {
        if(selectedOptions === null || !Array.isArray(selectedOptions)) {
            return;
        }

        const newRoom = selectedOptions.map(option => option.value);
        const newUserData = JSON.parse(JSON.stringify(userData));
        newUserData[userId].rooms = newRoom;
        setUserData(newUserData);
    };

    /**
     *
     * @param selectedOptions
     */
    const updateSelectedUsers = (selectedOptions: Options<Option>) => {
        if(selectedOptions === null || !Array.isArray(selectedOptions)) {
            return;
        }

        const newUserData = JSON.parse(JSON.stringify(userData));
        const newSelectedUsers = selectedOptions.map(option => option.value);
        const oldUserIds = Object.keys(newUserData);
        oldUserIds.forEach(userId => {
            if(!newSelectedUsers.includes(userId)) {
                delete newUserData[userId];
            }
        });
        newSelectedUsers.forEach((userId: string) => {
            if(!oldUserIds.includes(userId)) {
                newUserData[userId] = {userID: userId, permissionPages: [], rooms: []};
            }
        });
        setUserData(newUserData);
        setSelectedUsers(selectedOptions);
    };

    /**
     *
     * @param userId
     */
    const getSelectedPages = (userId: string) => {
        const curUserData = {...userData[userId]};
        return pageOptions.filter(page => curUserData.permissionPages.includes(page.value));
    };

    /**
     *
     * @param userId
     */
    const getSelectedRooms = (userId: string) => {
        const curUserData = userData[userId];
        return roomOptions.filter(room => curUserData.rooms.includes(room.value));
    };

    function resetRun() {
        const confirmation = window.confirm('Are you sure you want to reset this run?');
        if(!confirmation) {
            return;
        }

        if(client === null) {
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            let incoming = JSON.parse(message.data);
            if(!incoming.error) {
                addNotification({
                    message: incoming.message,
                    color: 'green'
                });
            } else {

                addNotification({
                    message: incoming.message,
                    color: 'red'
                });
            }
            client.removeEventListener('message', handleMessage);
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'reset',
                newRunId: runId
            })
        );
    }

    function clearChat() {
        const confirmation = window.confirm('Are you sure you want to clear the chats for this run?');
        if(!confirmation) {
            return;
        }

        if(client === null) {
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            const dataFromServer = JSON.parse(message.data);
            if(dataFromServer.type === 'notification') {
                if(!dataFromServer.error) {
                    addNotification({
                        message: dataFromServer.message,
                        color: 'green'
                    });
                } else {
                    addNotification({
                        message: dataFromServer.message,
                        color: 'red'
                    });
                }
                client.removeEventListener('message', handleMessage);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'clearChat',
                runId
            })
        );
    }

    function clearInteractions() {
        const confirmation = window.confirm('Are you sure you want to clear the interactions for this run? This will delete all interactions recorded in the reports for this run.');
        if(!confirmation) {
            return;
        }

        if(client === null) {
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            const dataFromServer = JSON.parse(message.data);
            if(dataFromServer.type === 'notification') {
                if(!dataFromServer.error) {
                    addNotification({
                        message: dataFromServer.message,
                        color: 'green'
                    });
                } else {
                    addNotification({
                        message: dataFromServer.message,
                        color: 'red'
                    });
                }
                client.removeEventListener('message', handleMessage);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'clearInteractions',
                runId
            })
        );
    }

    const addRoom = () => {
        const newRoomNames = [...roomNames];
        if(newRoomNames.includes(value.trim())) {
            addNotification({
                message: `This room has already been added.`,
                color: 'red'
            });
            return;
        }
        newRoomNames.push(value.trim());
        setRoomNames(newRoomNames);
        setValue('');
    };

    function recalculate() {
        if(client === null) {
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getLocationData',
                configurationId,
                points,
                runId
            })
        );
    }

    function resetPoint(time: number) {
        const newPoints = JSON.parse(JSON.stringify(points));
        const filesPoints = checkIfKeyInEnum(savedConfigurations[configurationId].dataSet.toString(), csvFilesPoints);

        if(filesPoints === 'error') {
            return;
        }

        newPoints[time] = filesPoints[time];
        setPoints(newPoints);
    }

    function populateFields(curRun: RunType | null) {
        if(curRun === null) {
            return;
        }

        //populate utc
        const utcTemp = curRun.utc.split(/[:.]/);
        setCurUtcDay(utcTemp[0]);
        setCurUtcHour(utcTemp[1]);
        setCurUtcMinute(utcTemp[2]);
        setCurUtcSecond(utcTemp[3]);
        setCurUtcMilSecond(utcTemp[4]);

        //populate T- time
        const tMinusTemp = curRun.tMinus.split(/[:.]/);
        setCurTMinusHour(tMinusTemp[0]);
        setCurTMinusMinute(tMinusTemp[1]);
        setCurTMinusSecond(tMinusTemp[2]);
        setCurTMinusMilSecond(tMinusTemp[3]);

        //populate increment
        //setIncrement(curRun.increment);
    }

    /**
     *
     * @param field
     * @param index
     */
    function removeModdedDataValue(field: string, index: number) {
        const newModdedData: ModdedDataTypes = JSON.parse(JSON.stringify(moddedData));
        newModdedData[field].splice(index, 1);
        setModdedData(newModdedData);
    }

    /**
     *
     * @param index
     */
    function removeKeyEvent(index: number) {
        const newKeyEvents = JSON.parse(JSON.stringify(keyEvents));
        newKeyEvents.splice(index, 1);
        setKeyEvents(newKeyEvents);
    }

    function moveUp(index: number) {
        if(index === 0) { // already at beginning
            return;
        }

        const newPageOrder = [...pageOrder];
        const page = newPageOrder.splice(index, 1)[0];
        newPageOrder.splice(index - 1, 0, page);
        setPageOrder(newPageOrder);
    }

    function moveDown(index: number) {
        if(index === pageOrder.length) { // already at end
            return;
        }

        const newPageOrder = [...pageOrder];
        const page = newPageOrder.splice(index, 1)[0];
        newPageOrder.splice(index + 1, 0, page);
        setPageOrder(newPageOrder);
    }

    function moveUpRoom(index: number) {
        if(index === 0) { // already at beginning
            return;
        }

        const newRoomOrder = [...roomNames];
        const room = newRoomOrder.splice(index, 1)[0];
        newRoomOrder.splice(index - 1, 0, room);
        setRoomNames(newRoomOrder);
    }

    function moveDownRoom(index: number) {
        if(index === pageOrder.length) { // already at end
            return;
        }

        const newRoomOrder = [...roomNames];
        const room = newRoomOrder.splice(index, 1)[0];
        newRoomOrder.splice(index + 1, 0, room);
        setRoomNames(newRoomOrder);
    }

    /**
     *
     * @param file
     *
     * parses Excel file and adds to state
     * still have to save run for the checklist to be saved
     */
    async function readExcel(file: File) {
        const d = await new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                if(!e.target) {
                    return;
                }

                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, {type: 'buffer'});

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        //TODO: fix types
        // @ts-ignore
        setChecklists([...checklists, d]);
        setCurChecklist(checklists.length); // set to newly added checklist
    }

    function deleteChecklist() {
        const confirmation = window.confirm('Are you sure you want to delete this checklist?');
        if(!confirmation) {
            return;
        }
        const newChecklists = JSON.parse(JSON.stringify(checklists));
        newChecklists.splice(curChecklist, 1);
        setChecklists(newChecklists);
        setCurChecklist(0);
        addNotification({message: 'Deleted checklist', color: 'green'});
    }

    return (
        <>
            <div className="grayBody"/>
            {
                curRun.id && Object.keys(savedConfigurations).length &&
                <div id="runsNext" className="overflowAuto"
                     style={{paddingBottom: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}
                >
                    <div style={{maxWidth: '1280px'}}>
                        <h1 style={{color: 'black', marginTop: '1rem', fontSize: '2.2rem'}}>
                            Edit Run
                        </h1>
                        <div style={{
                            display: 'flex',
                            marginTop: '1rem'
                        }}
                        >
                            <div
                                style={{backgroundColor: 'white', borderRadius: '0.5rem', padding: '0.75rem', flex: 2}}
                            >
                                {/*Run*/}
                                <h4>Run Info</h4>
                                <table id="runTable">
                                    <tbody>
                                    <tr>
                                        <td><label style={{marginBottom: 0}} htmlFor="editName">Run Name</label></td>
                                        <td>
                                            <input type="text" id="editName" name="name" required
                                                   className="form-control"
                                                   defaultValue={curRun.name} ref={runNameRef}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label style={{marginBottom: 0}} htmlFor="editDate">Date</label>
                                        </td>
                                        <td>
                                            <input type="date" id="editDate" name="date" required
                                                   className="form-control"
                                                   defaultValue={curRun.date} ref={runDateRef}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label style={{marginBottom: 0}}
                                                   htmlFor="editConfiguration"
                                            >Configuration</label>
                                        </td>
                                        <td>
                                            {
                                                Object.values(savedConfigurations).find(configuration => configuration.id === configurationId)?.name
                                            }
                                        </td>
                                    </tr>
                                    {/*<tr>
                                        <td>
                                            <label style={{marginBottom: 0}}
                                                   htmlFor="editZulu"
                                            >Zulu</label>
                                        </td>
                                        <td style={{paddingBottom: '0.5rem'}}>
                                            <input
                                                type="number"
                                                id="utcDay"
                                                value={curUtcDay}
                                                onChange={e => setCurUtcDay(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="dd"
                                            />
                                            :
                                            <input
                                                type="number"
                                                id="utcHour"
                                                value={curUtcHour}
                                                onChange={e => setCurUtcHour(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="HH"
                                                min="0"
                                                max="23"
                                            />
                                            :
                                            <input
                                                type="number"
                                                id="utcMinute"
                                                value={curUtcMinute}
                                                onChange={e => setCurUtcMinute(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="mm"
                                                min="0"
                                                max="59"
                                            />
                                            :
                                            <input
                                                type="number"
                                                id="utcSecond"
                                                value={curUtcSecond}
                                                onChange={e => setCurUtcSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="ss"
                                                min="0"
                                                max="59"
                                            />
                                            .
                                            <input
                                                type="number"
                                                id="utcMilSecond"
                                                value={curUtcMilSecond}
                                                onChange={e => setCurUtcMilSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="SSS"
                                                min="0"
                                                max="999"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label style={{marginBottom: 0}}
                                                   htmlFor="editTminus"
                                            >T-</label>
                                        </td>
                                        <td style={{display: 'flex'}}>
                                            <div style={{width: '60px'}}></div>
                                            <div style={{visibility: 'hidden'}}>:</div>
                                            <input
                                                type="number"
                                                id="tMinusHour"
                                                value={curTMinusHour}
                                                onChange={e => setCurTMinusHour(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="HH"
                                                min="0"
                                                max="23"
                                            />
                                            :
                                            <input
                                                type="number"
                                                id="tMinusMinute"
                                                value={curTMinusMinute}
                                                onChange={e => setCurTMinusMinute(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="mm"
                                                min="0"
                                                max="59"
                                            />
                                            :
                                            <input
                                                type="number"
                                                id="tMinusSecond"
                                                value={curTMinusSecond}
                                                onChange={e => setCurTMinusSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="ss"
                                                min="0"
                                                max="59"
                                            />
                                            .
                                            <input
                                                type="number"
                                                id="tMinusMilSecond"
                                                value={curTMinusMilSecond}
                                                onChange={e => setCurTMinusMilSecond(e.currentTarget.value)}
                                                style={{width: '60px'}}
                                                placeholder="SSS"
                                                min="0"
                                                max="999"
                                            />

                                        </td>
                                    </tr>*/}
                                    <tr>
                                        <td><label style={{marginBottom: 0}} htmlFor="editUsers">Users</label></td>
                                        <td style={{display: 'flex'}}>
                                            <div style={{minWidth: '20rem'}}>
                                                <Select
                                                    id="editUsers"
                                                    styles={{...customStyles}}
                                                    name="users"
                                                    options={options}
                                                    onChange={updateSelectedUsers}
                                                    value={selectedUsers}
                                                    isMulti
                                                />
                                            </div>
                                            <button onClick={() => updateSelectedUsers(options)}>All</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label style={{marginBottom: 0}} htmlFor="editUsers">Actions</label></td>
                                        <td>
                                            <button onClick={resetRun}>Reset Run</button>
                                            <button onClick={clearChat} style={{marginLeft: '0.5rem'}}>
                                                Clear Chat
                                            </button>
                                            <button onClick={clearInteractions} style={{marginLeft: '0.5rem'}}>
                                                Clear Interactions
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*Rooms*/}
                            <div style={{
                                backgroundColor: 'white',
                                padding: '1em',
                                marginLeft: '1rem',
                                borderRadius: '0.5rem',
                                flex: 1
                            }}
                            >
                                <h4 style={{textAlign: 'center'}}>Comm Nets</h4>
                                <div style={{display: 'flex', paddingBottom: '0.8em'}}>
                                    <input type="text" value={value} onChange={(e) => setValue(e.target.value)}/>
                                    <button style={{marginLeft: '0.5rem'}} onClick={addRoom}>Add</button>
                                </div>
                                <table style={{backgroundColor: 'white'}}>
                                    <tbody>
                                    {roomNames.map((name, index) => (
                                        <tr key={index} className="borderedRow">
                                            <td><span className="listText">{name}</span></td>
                                            <td>
                                                <button className="removeBtn" style={{marginLeft: '1em'}}
                                                        type="button" data-row={`${index}`} onClick={handleRoomRemove}
                                                >
                                                    X
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => moveUpRoom(index)}
                                                        style={{borderRadius: '0.3rem', lineHeight: '1.1rem'}}
                                                >
                                                    &#11014;
                                                </button>
                                                <button onClick={() => moveDownRoom(index)}
                                                        style={{borderRadius: '0.3rem', lineHeight: '1.1rem'}}
                                                >
                                                    &#11015;
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{
                                backgroundColor: 'white',
                                padding: '1em',
                                marginLeft: '1rem',
                                borderRadius: '0.5rem',
                                flex: 1
                            }}
                            >
                                <h4 style={{textAlign: 'center', marginBottom: '0.rem'}}>Navigation Order</h4>
                                <table style={{backgroundColor: 'white'}}>
                                    <tbody>
                                    {pageOrder.map((page, index) => (
                                        <tr key={page} className="borderedRow">
                                            <td><span className="listText">{pagesReadable[page]}</span></td>
                                            <td style={{display: 'flex'}}>
                                                <div style={{marginLeft: '1em'}}>
                                                    <button onClick={() => moveUp(index)}
                                                            style={{borderRadius: '0.3rem', lineHeight: '1.1rem'}}
                                                    >
                                                        &#11014;
                                                    </button>
                                                    <button onClick={() => moveDown(index)}
                                                            style={{borderRadius: '0.3rem', lineHeight: '1.1rem'}}
                                                    >
                                                        <span style={{marginTop: '-.2rem', paddingTop: '-.2rem'}}
                                                        >&#11015;</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*Users*/}
                        <hr/>
                        <h3>Users</h3>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 421px)',
                            gridGap: '8px',
                            marginTop: '1rem'
                        }}
                        >
                            {
                                Object.keys(userData).map((userId) => {
                                    const filteredUser = users.filter(curUser => curUser.id === userId)[0];
                                    return (
                                        <div
                                            key={userId}
                                            style={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                padding: '0.4em 0.5em 0.4em 0.5em',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: '0.5rem'
                                            }}
                                        >
                                            <div style={{textAlign: 'center', marginTop: '0.5rem'}}>
                                                <strong>{filteredUser.name}</strong>
                                            </div>
                                            <div style={{
                                                padding: '0.6em 0.5em 0.5em 0.5em',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                flex: 1
                                            }}
                                            >
                                                <div>
                                                    <label htmlFor="editPermissions" style={{marginBottom: 0}}>
                                                        Page Permission
                                                    </label>
                                                    <div style={{display: 'flex'}}>
                                                        <div style={{flex: 1}}>
                                                            <Select
                                                                id="editPermissions"
                                                                styles={pageCustomStyles}
                                                                name="permission"
                                                                onChange={(selectedOptions) => handleUserPagesChange(selectedOptions, userId)}
                                                                options={pageOptions}
                                                                value={getSelectedPages(userId)}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <button type="button"
                                                                onClick={() => handleUserPagesChange(pageOptions, userId)}
                                                        >
                                                            All
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{marginTop: '1rem'}}>
                                                    <label htmlFor="editRooms" style={{marginBottom: 0}}>
                                                        Comm Permission
                                                    </label>
                                                    <div style={{display: 'flex'}}>
                                                        <div style={{flex: 1}}>
                                                            <Select
                                                                id="editRooms"
                                                                styles={customStyles}
                                                                name="rooms"
                                                                onChange={(selectedOptions) => handleUserRoomChange(selectedOptions, userId)}
                                                                options={roomOptions}
                                                                value={getSelectedRooms(userId)}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <button type="button"
                                                                onClick={() => handleUserRoomChange(roomOptions, userId)}
                                                        >
                                                            All
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{marginTop: '1rem', display: 'flex', alignItems: 'center'}}>
                                                    <label htmlFor="editPosition"
                                                           style={{marginBottom: 0, flex: 0}}
                                                    >
                                                        Position:
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        onChange={(event) => handlePositionChange(event, userId)}
                                                        value={userData[userId].position || 'DEFAULT'}
                                                        id="editPosition"
                                                        style={{flex: 1, marginLeft: '0.5rem'}}
                                                    >
                                                        <option value="DEFAULT" disabled hidden>
                                                            Select a Position
                                                        </option>
                                                        {
                                                            Object.values(savedPositions).map((position, i) =>
                                                                <option
                                                                    key={i}
                                                                    value={position.id}
                                                                >
                                                                    {position.name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <hr/>
                        <div style={{marginTop: '1rem'}}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'white',
                                padding: '1rem',
                                borderRadius: '0.5rem'
                            }}
                            >
                                <div>
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <LineChartRechart
                                                {...getFlightPathProps(savedConfigurations[configurationId])}
                                                {...getFlightPathTimeProps(savedConfigurations[configurationId])}
                                                chartTitle="Flight Path"
                                                axisColor="black"
                                                backgroundColor="rgb(104,131,140)"
                                                innerChartWidth={920}
                                                innerChartHeight={290}
                                                lines={[{key: 'offNom', color: 'blue'}, {
                                                    key: 'alt',
                                                    color: 'red',
                                                    dashArray: '5 5'
                                                }]}
                                                gridColor="rgb(215, 215, 215)"
                                                gridLineColor="white"
                                                majorGridLineColor="black"
                                                data={locationData}
                                                referenceStroke={1}
                                                minorReferenceMultiple={0}
                                                xKey="talo"
                                                type="number"
                                                margin={{left: 45, right: 15, bottom: 20}}
                                                tickFontSize="1.1em"
                                                xLabel={{text: 'TALO (s)'}}
                                                yLabel={{text: 'Altitude (m)', offset: 30}}
                                            />
                                        </div>
                                        <div style={{marginLeft: '1rem'}}>
                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: 'auto 150px auto',
                                                gap: '0.2rem'
                                            }}
                                            >
                                                <div><strong>TALO</strong></div>
                                                <div style={{justifySelf: 'center'}}><strong>Altitude</strong></div>
                                                <div/>
                                                {
                                                    Object.entries(points).map(([time, pointData]) => (
                                                        <React.Fragment key={time}>
                                                            <label htmlFor="time"
                                                                   style={{
                                                                       marginRight: '.5rem',
                                                                       alignSelf: 'center',
                                                                       marginBottom: 0
                                                                   }}
                                                            >
                                                                {time}:
                                                            </label>
                                                            <input id={`input${time}`} data-time={time}
                                                                   onChange={updatePoint}
                                                                   step=".0001" type="number" value={pointData.offNom}
                                                            />
                                                            <button onClick={() => resetPoint(parseInt(time))}>
                                                                Reset
                                                            </button>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                marginTop: '0.2rem'
                                            }}
                                            >
                                                <button onClick={recalculate} style={{
                                                    padding: '0.3rem .5rem',
                                                    backgroundColor: 'blue',
                                                    color: 'white',
                                                    border: 'none',
                                                    boxShadow: 'black 0 1px 1px 0'
                                                }}
                                                >
                                                    Recalculate
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '1rem'}}>
                            <div style={{
                                backgroundColor: 'white',
                                padding: '0.5rem',
                                borderRadius: '0.5rem'
                            }}
                            >
                                <h4 style={{textAlign: 'center'}}>Key Events</h4>
                                <form onSubmit={addKeyEvent}>
                                    <datalist id={`keyEventColor`}>
                                        <option>#ffffff{/*white*/}</option>
                                        <option>#000000{/*black*/}</option>
                                        <option>#ff0000{/*red*/}</option>
                                        <option>#00ff00{/*green*/}</option>
                                        <option>#0000ff{/*blue*/}</option>
                                        <option>#ffff00{/*yellow*/}</option>
                                        <option>#ffa500{/*orange*/}</option>
                                        <option>#0a767a{/*itac blue*/}</option>
                                    </datalist>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(4, auto)',
                                        gap: '0.5rem'
                                    }}
                                    >
                                        <div style={{textAlign: 'center'}}>Time</div>
                                        <div style={{textAlign: 'center'}}>Title</div>
                                        <div style={{textAlign: 'center'}}>Color</div>
                                        <div style={{textAlign: 'center'}}>Action</div>
                                        {
                                            keyEvents.map(({time, title, color}, index) => (
                                                <React.Fragment key={time}>
                                                    <div style={{paddingLeft: '.6rem'}}
                                                    >{getTMinusTime(time, savedConfigurations[configurationId])}</div>
                                                    <div style={{paddingLeft: '.3rem'}}>{title}</div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                    >
                                                        <div style={{
                                                            width: '3rem',
                                                            height: '1rem',
                                                            backgroundColor: color || '#0a767a'
                                                        }}
                                                        />
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                        <button className="removeBtn" type="button"
                                                                onClick={() => removeKeyEvent(index)}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                        <select onChange={e => setKeyEventTime(Number(e.currentTarget.value))}
                                                name="keyEventTime"
                                                value={keyEventTime}
                                        >
                                            {timeOptions}
                                        </select>
                                        <input type="text" onChange={e => setKeyEventTitle(e.currentTarget.value)}
                                               value={keyEventTitle} placeholder="Title"
                                        />
                                        <input type="color" onChange={e => setKeyEventColor(e.currentTarget.value)}
                                               value={keyEventColor} list="keyEventColor"
                                        />
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <button className="plusBtn" type="submit">+</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <hr/>
                        <h3>Chevrons</h3>
                        <div style={{display: 'flex', marginBottom: '.5rem'}}>
                            <div style={{backgroundColor: 'white', borderRadius: '.5rem', padding: '.5rem'}}>
                                <ul style={{listStyle: 'none', margin: 0, padding: 0}}>
                                    <li>Click on the map to start a line. Click again to end the line.</li>
                                    <li>Right click on a line to remove it.</li>
                                    <li>Lines and points will be blue when they are hovered over.</li>
                                    <li>Points (the circles) will not appear for users during a test.</li>
                                </ul>
                            </div>
                        </div>
                        <InteractiveLines points={chevronPoints} setPoints={setChevronPoints}/>
                        <div style={{marginTop: '1rem'}}>
                            <h3>Keep out area</h3>
                            <div style={{display: 'flex', marginBottom: '.5rem'}}>
                                <div style={{backgroundColor: 'white', borderRadius: '.5rem', padding: '.5rem'}}>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0}}>
                                        <li><strong>The shape should be closed.</strong></li>
                                        <li>Click on the map to start a line. Click again to end the line.</li>
                                        <li>A new line will automatically be started until the shape is complete.</li>
                                        <li>Only the green line can be removed. Right click on the line to remove it.
                                        </li>
                                        <li>Lines and points will be blue when they are hovered over.</li>
                                        <li>Points (the circles) will not appear for users during a test.</li>
                                    </ul>
                                </div>
                            </div>
                            <InteractiveLines points={keepOutPoints} setPoints={setKeepOutPoints} isClosed/>
                        </div>
                        <hr/>
                        <h3>Checklists</h3>
                        <div>
                            <div>
                                <label htmlFor="cur-checklist">View Checklist: </label>
                                <select value={curChecklist} id="cur-checklist"
                                        onChange={e => setCurChecklist(Number(e.target.value))}>
                                    {checklists.map((checklistRows, index) => (
                                        <option value={index}>{index + 1}</option>))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="checklist-user">User that checks boxes: </label>
                                <select value={checklistPosition} onChange={e => setChecklistPosition(e.target.value)}
                                        name="" id="checklist-user">
                                    <option value="-1">
                                        All users
                                    </option>
                                    {
                                        Object.values(savedPositions).map((position, i) =>
                                            <option
                                                key={i}
                                                value={position.id}
                                            >
                                                {position.name}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                            <div>
                                <label htmlFor="add-checklist">Add Checklist: </label>
                                <input
                                    type="file"
                                    id="add-checklist"
                                    onChange={(e) => {
                                        if(!e.target.files?.length) {
                                            return;
                                        }

                                        const file = e.target.files[0];
                                        readExcel(file);
                                    }}
                                />
                            </div>
                            {
                                checklists[curChecklist] ?
                                    <button onClick={deleteChecklist}>Delete This Checklist</button> : null
                            }
                            <table className="table container">
                                <thead>
                                <tr>
                                    <th scope="col">NO</th>
                                    <th scope="col">Time (hh:mm:ss)</th>
                                    <th scope="col">Call To</th>
                                    <th scope="col">Call From</th>
                                    <th scope="col">Net</th>
                                    <th scope="col">Description-Message-Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(checklists[curChecklist] || []).map((d) => (
                                    <tr key={d.NO}>
                                        <th>{d.NO}</th>
                                        <td>{d.time}</td>
                                        <td>{d.callTo}</td>
                                        <td>{d.callFrom}</td>
                                        <td>{d.net}</td>
                                        <td>{d.description}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <hr/>
                        <h3>Parameters Control</h3>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 600px)',
                            gridGap: '0.5em',
                            marginTop: '1rem'
                        }}
                        >
                            {
                                Object.keys(savedConfigurations).length && Object.entries(moddedData).map(([field, fieldData]) => (
                                    <div key={field} style={{
                                        fontSize: '1em', textAlign: 'center',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        padding: '0.4em 0.5em 0.4em 0.5em',
                                        borderRadius: '0.5rem'
                                    }}
                                    >
                                        {/* @ts-ignore */}
                                        <div style={{marginBottom: '1rem'}}><strong>{varTitles[field] || field}</strong>
                                        </div>
                                        <form onSubmit={(e) => updateModdedData(e, field)} style={{display: 'flex'}}>
                                            <table style={{width: '100%'}}>
                                                <thead>
                                                <tr>
                                                    <td style={{paddingRight: '1rem'}}>Start</td>
                                                    <td style={{paddingRight: '1rem'}}>Stop</td>
                                                    <td>Value</td>
                                                    <td/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    fieldData.map(({start, stop, moddedDataValue}, index) => (
                                                        <tr key={start}>
                                                            <td style={{
                                                                paddingRight: '2rem',
                                                                textAlign: 'right'
                                                            }}
                                                            >{getTMinusTime(start, savedConfigurations[configurationId])}</td>
                                                            <td style={{
                                                                paddingRight: '2rem',
                                                                textAlign: 'right'
                                                            }}
                                                            >{getTMinusTime(stop, savedConfigurations[configurationId])}</td>
                                                            <td>{moddedDataValue}</td>
                                                            <td>
                                                                <button type="button" className="removeBtn"
                                                                        onClick={() => removeModdedDataValue(field, index)}
                                                                        style={{flex: 0}}
                                                                >
                                                                    X
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td>
                                                        <select name="start">
                                                            {timeOptions}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select name="stop">
                                                            {timeOptions}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input name="value" placeholder="value" type="number"
                                                               step=".0001"
                                                               style={{width: '100%'}} required
                                                        />
                                                    </td>
                                                    <td>
                                                        <button className="plusBtn" type="submit" style={{flex: 0}}>
                                                            +
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                ))
                            }
                        </div>
                        <button
                            type="button"
                            onClick={updateRun}
                            style={{
                                backgroundColor: 'blue',
                                border: 'none',
                                color: 'white',
                                padding: '0.3rem 0.75rem',
                                boxShadow: 'black 0 1px 1px 0',
                                position: 'fixed',
                                bottom: '20px',
                                right: '40px'
                            }}
                        >
                            <strong style={{fontSize: '1.4rem'}}>Save</strong>
                        </button>
                        <div style={{marginBottom: '3rem'}}/>
                    </div>
                </div>
            }
        </>
    );
};

export default RunsNext;
