import React, {useRef, useEffect, useReducer, useState, RefObject} from 'react';

const {v4: uuidv4} = require('uuid');

type ReducerState = {
    containerID: string;
    colors: {[colorReadable: string]: string;};
    width: string;
    height: string;
    blueDisplay: number;
    pinkDisplay: number;
    lineScale: number;
    start: number;
}

type Action = {
    gaugeRef: RefObject<HTMLCanvasElement>,
    lon: string;
    lat: string;
}

const columnReducer = (state: ReducerState, action: Action) => {
    if(!action.gaugeRef){
        return state;
    }

    const canvas = action.gaugeRef.current;
    if(!canvas){
        return state;
    }

    const ctx = canvas.getContext('2d');
    if(!ctx){
        return state;
    }

    // Clear the old world to redraw
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //Draw Background
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //Draw Grid
    for(let i = 0; i < 10; i++){
        ctx.fillStyle = 'white';
        ctx.fillRect(0, (canvas.height * i) / 10, canvas.width, 1);
    }

    //Draw Vertical Divider
    ctx.fillStyle = 'white';
    ctx.fillRect(canvas.width / 2, 0, 1, canvas.height);

    //draw starting point
    const temp = state.start;
    ctx.fillStyle = 'grey';
    ctx.fillRect(0, temp, canvas.width, 5);

    //multiply by some number to show the changes in longitude and latitude on the chart.
    const blueHeight = temp - Number(action.lon) * state.lineScale;
    const pinkHeight = temp - Number(action.lat) * state.lineScale;

    //Draw Position of blue and pink
    ctx.fillStyle = 'blue';
    ctx.fillRect(0, blueHeight, canvas.width / 2, 5);
    ctx.fillStyle = 'cyan';
    ctx.fillRect(canvas.width / 2, pinkHeight, canvas.width / 2, 5);

    //we want to show the original longitude and latitude at bottom of gauge.
    state.blueDisplay = Number(action.lon);
    state.pinkDisplay = Number(action.lat);

    return state;

};

type Props = {
    title: string;
    leftColumnTitle: string;
    rightColumnTitle: string;
    start: number;
    columnWidth: string;
    columnHeight: string;
    lineScale: number;
    lon: string;
    lat: string;
}

const ColumnGauge = (props: Props) => {
    const {
        title,
        leftColumnTitle,
        rightColumnTitle,
        start, //used to give the lines a starting point in the gauge
        columnWidth,
        columnHeight,
        lineScale, //used to multiply lon and lat by to actually show change in gauge
        lon,
        lat,
    } = props;

    const [updater, setUpdater] = useState(true);
    const gaugeRef = useRef<HTMLCanvasElement>(null);

    const [columnState, dispatchColumn] = useReducer(columnReducer, {
        //state is by default an object
        containerID: uuidv4(),
        colors: {
            green: '#54ED32',
            yellow: '#EDE432',
            red: '#E81C1C',
            brown: '#A8640E'
        },
        width: columnWidth,
        height: columnHeight,
        blueDisplay: 0,
        pinkDisplay: 0,
        lineScale,
        start,
    });

    useEffect(() => {
        const action = {
            gaugeRef,
            lon,
            lat
        };

        dispatchColumn(action);

        setTimeout(() => {
            setUpdater(!updater);
        }, 20);
    }, [lon, lat]);

    return (
        <div
            id={columnState.containerID}
            style={{width: columnState.width}}
        >
            <pre
                style={{
                    fontFamily: 'Courier New',
                    fontWeight: 'bold',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '1rem'
                }}
            >
                {' '}
                {title}
            </pre>
            <pre
                style={{
                    fontFamily: 'Courier New',
                    fontWeight: 'bold',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '1rem',
                }}
            >
                {leftColumnTitle}
                {'        '}
                {rightColumnTitle}
            </pre>
            <canvas
                ref={gaugeRef}
                width={columnState.width + 'px'}
                height={columnState.height + 'px'}
                style={{border: '1px solid #FFFFFF'}}
            />
            <pre
                style={{
                    fontFamily: 'Courier New',
                    fontWeight: 'bold',
                    color: 'white',
                    textAlign: 'center',
                    paddingTop: '10px',
                    fontSize: '1rem',
                }}
            >

                {Math.round(Number(columnState.blueDisplay) * 100) / 100}
                {'   '}
                {Math.round(Number(columnState.pinkDisplay) * 100) / 100}
            </pre>
        </div>
    );
};

export default ColumnGauge;
