import React, {useContext, useEffect, useRef, useState} from 'react';
import {LineChart, Line, CartesianGrid, ReferenceLine, XAxis, YAxis, Label} from 'recharts';
import PropTypes from 'prop-types';
import ClientContext from '../../helpers/ClientContext';
import {adjustData} from '../../helpers/helper';

//FIXME: using the chart title in the data needs to be replaced with a unique value

/**
 *
 * @param min
 * @param max
 * @param multiple
 * @param lineArr
 */
const addLines = (min, max, multiple, lineArr) => {
    if(multiple === 0){
        return;
    }

    const mod = min % multiple;

    let val;
    if(min > 0){
        val = min + multiple - mod;
    } else if(min < 0){
        val = min - mod;
    } else{
        val = min;
    }

    while(val <= max){
        lineArr.push(val);
        val += multiple;
    }
};

const LineChartRechart = props => {
    const {
        chartTitle,
        baseYDomain, // max,min of y-axis
        xDomain,
        baseYTicks,
        yMajorLines,
        axisColor,
        backgroundColor, // of container div
        innerChartWidth,
        innerChartHeight,
        outerChartHeight,
        outerChartWidth,
        lines,
        gridColor,
        gridLineColor,
        majorGridLineColor,
        dataPointsShown,
        referenceMultiple,
        minorReferenceMultiple,
        buttons,
        lightBorder,
        darkBorder,
        xKey,
        showLatestPoint,
        tickFontSize,
        referenceStroke,
        tableBorder,
        xLabel,
        yLabel,
        shouldShift = false,
    } = props;

    const getEmptyData = () => {
        let i = 0;
        const initData = [];
        while(i < dataPointsShown){
            initData.push({[chartTitle]: `${dataPointsShown - i} `, y: null});
            i++;
        }

        return initData;
    };

    const {widgetState, locationData} = useContext(ClientContext);
    //TODO: change when we decide how we want to seed chart pre-data
    const [data, setData] = useState(getEmptyData(dataPointsShown));
    const [majorLines, setMajorLines] = useState([]);
    const [minorLines, setMinorLines] = useState([]);
    const [yDomain, setYDomain] = useState(baseYDomain);
    const [yTicks, setYTicks] = useState(baseYTicks);
    const [amountZoomed, setAmountZoomed] = useState(0);
    const isLoadingRef = useRef(true);

    //major lines are bold and only show at certain increments
    const updateLines = newData => {
        const newMajorLines = [];
        const newMinorLines = [];
        let min;
        let max;
        newData.forEach((curData) => {
            if(!curData){
                return;
            }

            const name = curData[chartTitle];
            if(typeof name === 'string' && name.includes(' ')){ // filler for blank data
                return;
            }
            if(min === undefined || name < min){
                min = parseInt(name);
            }
            if(max === undefined || name > max){
                max = parseInt(name);
            }
        });

        if(min === undefined || max === undefined){
            return;
        }

        addLines(xDomain.length ? xDomain[0] : min, xDomain.length ? xDomain[1] : max, referenceMultiple, newMajorLines);
        addLines(xDomain.length ? xDomain[0] : min, xDomain.length ? xDomain[1] : max, minorReferenceMultiple, newMinorLines);

        setMajorLines(newMajorLines);
        setMinorLines(newMinorLines);
    };

    useEffect(() => {
        setYDomain(baseYDomain);
    }, [baseYDomain]);

    useEffect(() => {
        setYTicks(baseYTicks);
    }, [baseYTicks]);

    /**
     * multiplying based on based values so that the numbers don't get weird
     * @param newAmountZoomed
     */
    const zoom = newAmountZoomed => {
        const newYDomain = baseYDomain.map(num => {
            if(newAmountZoomed > 0){
                return num / (2 * newAmountZoomed);
            }
            if(newAmountZoomed < 0){
                return num * (2 * Math.abs(newAmountZoomed));
            }
            return num;
        });
        const newYTicks = baseYTicks.map(num => {
            if(newAmountZoomed > 0){
                return num / (2 * newAmountZoomed);
            }
            if(newAmountZoomed < 0){
                return num * (2 * Math.abs(newAmountZoomed));
            }
            return num;
        });

        setYDomain(newYDomain);
        setYTicks(newYTicks);
        setAmountZoomed(newAmountZoomed);
    };

    const moveUp = () => {
        const newYDomain = yDomain.map(num => num + 1);
        const newYTicks = yTicks.map(num => num + 1);
        setYDomain(newYDomain);
        setYTicks(newYTicks);
    };

    const moveDown = () => {
        const newYDomain = yDomain.map(num => num - 1);
        const newYTicks = yTicks.map(num => num - 1);
        setYDomain(newYDomain);
        setYTicks(newYTicks);
    };

    function handleDataArray(propData, propDataLength){
        let filteredData = [];
        if(propDataLength > dataPointsShown){
            let count = 0;
            Object.keys(propData).reverse()
                  .some(originalIndex => {
                      let datum = propData[originalIndex];
                      if(datum){
                          datum[chartTitle] = datum[xKey];
                          datum.x = datum[xKey];
                      } else{
                          datum = {
                              name: -1,
                              x: -1
                          };
                      }
                      filteredData.unshift(datum);
                      count++;
                      return count >= dataPointsShown;
                  });
        } else{
            filteredData = propData.map(datum => {
                datum[chartTitle] = datum[xKey];
                datum.x = datum[xKey];
                return datum;
            });

            if(propDataLength < dataPointsShown){
                const diff = dataPointsShown - propDataLength;
                let filteredIndex = 0;
                while(filteredData.length < dataPointsShown){
                    const newDataPoint = {name: `${dataPointsShown - filteredIndex} `, y: null};
                    const val = propData[0][xKey] - (diff - (diff - filteredIndex - 1));
                    if(shouldShift){
                        newDataPoint[chartTitle] = val;
                    }
                    filteredData.unshift(newDataPoint);
                    filteredIndex++;
                }
            }
        }

        return filteredData;
    }

    useEffect(() => {
        const propData = props.data;
        const propDataLength = propData.length;
        if(propDataLength === 1){
            const {x} = propData[0];
            if(x === -1){ //TODO: replace if we change how we signal that we're out of data
                return;
            }
        }

        let newData; // copy by value
        if(propDataLength > 1){ // timeline was adjusted
            newData = handleDataArray(propData, propDataLength);
        } else{ // normal push of time increment worth of data
            const datum = propData[0];
            const x = datum[xKey];

            if(datum.time === 0 || datum.time === '0'){
                newData = getEmptyData(dataPointsShown);
            } else{
                newData = data.map(obj => ({...obj}));
            }
            if(x !== newData[newData.length - 1][chartTitle]){ // could happen when playing after pausing
                newData.shift(); // remove first element from array
                datum[chartTitle] = datum[xKey];
                datum.x = datum[xKey];
                newData.push(datum);
            }
        }
        updateLines(newData);
        setData(newData);
    }, [props.data, xKey]);

    useEffect(() => {
        if(!isLoadingRef.current || widgetState.time === undefined){
            return;
        }

        const oldData = adjustData(0, widgetState.time, locationData.slice(0, widgetState.time + 1), widgetState.prevAdjustments);
        const newData = handleDataArray(oldData, oldData.length);
        updateLines(newData);
        setData(newData);

        isLoadingRef.current = false;
    }, [widgetState.time]);

    /**
     *
     * @returns {*[]}
     */
    const getXTicks = () => {
        const ticks = [...majorLines];
        if(showLatestPoint){
            ticks.push(data[data.length - 1] ? data[data.length - 1][chartTitle] : undefined);
        }
        return ticks;
    };

    return (
        <div style={{backgroundColor, width: outerChartWidth, height: outerChartHeight, display: 'flex', border: tableBorder}}>
            <div>
                {buttons &&
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <button className="noPadding" onClick={() => zoom(amountZoomed + 1)} type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>+
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            fontSize: '0.8em',
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>&#9670;</button>
                        <button className="noPadding" onClick={() => zoom(amountZoomed - 1)} type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>-
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            fontSize: '0.8em',
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>&#9670;&#9670;</button>
                        <button className="noPadding" onClick={moveUp} type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>&#8593;</button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            fontSize: '0.8em',
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>&#9670;</button>
                        <button className="noPadding" onClick={moveDown} type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>&#8595;</button>
                    </div>
                }
            </div>
            <div>
                {buttons &&
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &#124;&#124;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                        <button className="noPadding" type="button" style={{
                            flex: 1,
                            backgroundColor: '#c6c8cb',
                            borderWidth: '3px',
                            borderColor: '#EBECEF white white #EBECEF'
                        }}>
                            &nbsp;
                        </button>
                    </div>
                }
            </div>
            <div style={{flex: 1}}>
                <div style={{textAlign: 'center', fontSize: '1.5em', color: props.titleColor}}>{chartTitle}</div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <LineChart
                        width={innerChartWidth}
                        height={innerChartHeight}
                        data={data}
                        margin={props.margin}
                    >
                        {/* elements added later have higher z-index, so make grid first, then ref lines, then line */}
                        <CartesianGrid horizontal={false} fill={gridColor}/>
                        {
                            minorReferenceMultiple &&
                            minorLines.map(line => (
                                <ReferenceLine key={`minor${line}`} strokeWidth={1} x={line}
                                               stroke={gridLineColor}/>
                            ))
                        }
                        {
                            yMajorLines.map(line => (
                                <ReferenceLine key={`yMajor${line}`} strokeWidth={referenceStroke} y={line}
                                               stroke={majorGridLineColor}/>
                            ))
                        }
                        {
                            majorLines.map(line => (
                                <ReferenceLine key={`xMajor${line}`} strokeWidth={referenceStroke} x={line}
                                               stroke={majorGridLineColor}/>
                            ))
                        }
                        {
                            lines.map((line, index) => (
                                <Line
                                    key={`line${index}`}
                                    dot={false}
                                    isAnimationActive={false}
                                    dataKey={line.key}
                                    stroke={line.color}
                                    strokeWidth={2}
                                    strokeDasharray={line.dashArray}
                                />
                            ))
                        }
                        {/* show major line values as well as most recent value */}
                        <XAxis type={props.type} domain={xDomain} dataKey={chartTitle}
                               ticks={getXTicks()} stroke={axisColor} tick={{fontSize: tickFontSize}}
                        >
                            {
                                xLabel &&
                                <Label value={xLabel.text} offset={xLabel.offset || 0} position="bottom"
                                       style={{fill: xLabel.color || 'black'}}/>
                            }
                        </XAxis>
                        <YAxis
                            tick={{fontSize: tickFontSize}}
                            orientation="left"
                            tickLine={false}
                            allowDecimals
                            stroke={axisColor}
                            allowDataOverflow
                            domain={yDomain}
                            ticks={yTicks}
                        >
                            {
                                yLabel &&
                                <Label angle={270} offset={yLabel.offset !== undefined ? yLabel.offset : -10}
                                       value={yLabel.text} position="left" style={{fill: yLabel.color || 'black'}}/>
                            }
                        </YAxis>
                        <YAxis
                            tick={{fontSize: tickFontSize}}
                            yAxisId="right"
                            orientation="right"
                            tickLine={false}
                            allowDecimals
                            stroke={axisColor}
                            allowDataOverflow
                            domain={yDomain}
                            ticks={yTicks}
                        />
                        {
                            darkBorder &&
                            <ReferenceLine key="1" strokeWidth={4} x={data[0][chartTitle]}
                                           stroke="rgb(72, 98, 107)"/>
                        }
                        {
                            darkBorder &&
                            <ReferenceLine key="2" strokeWidth={4} y={yDomain[1]} stroke="rgb(72, 98, 107)"/>
                        }
                        {
                            lightBorder && <ReferenceLine key="3" strokeWidth={4} x={data[data.length - 1][chartTitle]}
                                                          stroke="rgb(151, 192, 207)"/>
                        }
                        {
                            lightBorder &&
                            <ReferenceLine key="4" strokeWidth={4} y={yDomain[0]} stroke="rgb(151, 192, 207)"/>
                        }
                    </LineChart>
                </div>
            </div>
        </div>
    );
};

LineChartRechart.defaultProps = {
    chartTitle: '',
    baseYTicks: [],
    yMajorLines: [],
    axisColor: 'black',
    backgroundColor: 'rgb(104,131,140)',
    innerChartWidth: 300,
    innerChartHeight: 300,
    outerChartWidth: 650,
    outerChartHeight: 350,
    lines: [],
    gridColor: 'rgb(102,102,102)',
    gridLineColor: 'white',
    majorGridLineColor: 'black',
    dataPointsShown: 10,
    referenceMultiple: 5,
    minorReferenceMultiple: 0.5,
    buttons: false,
    lightBorder: '',
    darkBorder: '',
    xKey: 'x',
    margin: {},
    type: 'category',
    xDomain: [],
    showLatestPoint: false,
    tickFontSize: '1em',
    referenceStroke: 3,
    tableBorder: '',
    titleColor: '',
    xLabel: {},
};

LineChartRechart.propTypes = {
    baseYDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
    xDomain: PropTypes.arrayOf(PropTypes.number),
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))).isRequired,
    chartTitle: PropTypes.string,
    baseYTicks: PropTypes.arrayOf(PropTypes.number),
    yMajorLines: PropTypes.arrayOf(PropTypes.number),
    axisColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    innerChartWidth: PropTypes.number,
    innerChartHeight: PropTypes.number,
    lines: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    gridColor: PropTypes.string,
    gridLineColor: PropTypes.string,
    majorGridLineColor: PropTypes.string,
    dataPointsShown: PropTypes.number,
    referenceMultiple: PropTypes.number,
    minorReferenceMultiple: PropTypes.number,
    buttons: PropTypes.bool,
    lightBorder: PropTypes.string,
    darkBorder: PropTypes.string,
    xKey: PropTypes.string,
    margin: PropTypes.objectOf(PropTypes.number),
    type: PropTypes.string,
    showLatestPoint: PropTypes.bool,
    tickFontSize: PropTypes.string,
    referenceStroke: PropTypes.number,
    tableBorder: PropTypes.string,
    titleColor: PropTypes.string,
    xLabel: PropTypes.objectOf(PropTypes.string),
    yLabel: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    shouldShift: PropTypes.bool,
};

export default LineChartRechart;
