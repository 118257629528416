import React, {useState, useEffect, useContext} from 'react';
import ClientContext from '../../helpers/ClientContext';
import {getTMinusTime} from '../../helpers/helper';

type Props = {
    startTime: number;
    endTime: number;
    width: number;
    stepSize: number;
    color?: string;
}

const LineTicks = ({startTime, endTime, width, stepSize, color = 'black'}: Props) => {
    const [content, setContent] = useState([<div/>]);
    const {configuration} = useContext(ClientContext);

    useEffect(() => {
        let contents = [];
        let spacing = 0;
        for(let i = stepSize; i < endTime; i += stepSize){
            spacing += width / (endTime / stepSize);
            contents.push(
                <>
                    <div className="tick" style={{
                        borderLeft: `1px solid ${color}`,
                        height: '10px',
                        marginTop: '-50px',
                        marginLeft: (spacing) + 'px'
                    }}>
                    </div>
                    <p style={{marginLeft: (spacing - 56 + 'px')}}>
                        {getTMinusTime(i, configuration)}
                    </p>
                </>
            );
        }
        setContent(contents);
    }, []);


    return (
        <div style={{color}}>
            <hr
                color="black"
                style={{
                    width,
                    marginLeft: 0,
                    backgroundColor: color,
                }}
            />
            <div className="tick" style={{
                borderLeft: `1px solid ${color}`,
                height: '20px',
                marginTop: '-25px',
                marginLeft: 0,
            }}>
            </div>
            <p style={{marginLeft: '-45px'}}>
                {getTMinusTime(startTime, configuration)}
            </p>
            {content}
        </div>
    );
};

export default LineTicks;
