//"Comm Logs" ACIF table
export const acif = {
    head: ['ACIF'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Process Input Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'procInputEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Process Output Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'procOutputEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Backdoor Inputs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'backdoorInputs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Dackdoor Outputs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'dackdoorInputs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total Rx Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalRxMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total Tx Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalTxMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total MC Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalMcMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total Bad Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalBadMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Good Rx Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodRxMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Rx Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidRxMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unhandled MC Msgs',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'unhandledMcMsgs'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total Input Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalInputErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Total Output Writes',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalOutputWrites'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Failed Output Writes',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'failedOutputWrites'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Rx Registrations',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'rxRegistrations'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Tx Registrations',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'txRegistrations'
            }
        ],
        [
            {
                type: 'static text',
                value: 'MC Registrations',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'mcRegistrations'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bad Registrations',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'badRegistrations'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Subaddress',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidSubaddresses'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Broadcast',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidBroadcast'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Word Count',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidWordCount'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Header',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidHeader'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Invalid Checksum',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'invalidChecksum'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Overflow Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'overflowErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'decodeErrors'
            }
        ],
    ]
}

//"Comm Logs" AC ARRW table
export const acArrw = {
    head: ['AC ARRW'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Total_Rcv_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'totalRcvdMsgEvents'
            },
            {
                type: 'static text',
                value: 'Unhandled_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'unhandledMsgEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Validity_Time_Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'validityTimeErrors'
            },
            {
                type: 'static text',
                value: 'Rcv_Message_Handlers',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'rcvMsgHandlers'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Xmt_Message_Handlers',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'xmtMsgHandlers'
            },
            {
                type: 'static text',
                value: 'MC_Message_Handlers',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'mcMsgHandlers'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Message_Detachments',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'msgDetachments'
            },
            {
                type: 'static text',
                value: 'AC_1R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_1r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_2R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_2r_events'
            },
            {
                type: 'static text',
                value: 'AC_3R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_3r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_4R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_4r_events'
            },
            {
                type: 'static text',
                value: 'AC_5R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_5r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_9R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_9r_events'
            },
            {
                type: 'static text',
                value: 'AC_11R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_11r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_12R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_12r_events'
            },
            {
                type: 'static text',
                value: 'AC_13R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_15r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_14R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_14r_events'
            },
            {
                type: 'static text',
                value: 'AC_15R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_15r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_17R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_17r_events'
            },
            {
                type: 'static text',
                value: 'AC_22R_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_22r_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_1T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_1t_events'
            },
            {
                type: 'static text',
                value: 'AC_11T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_11t_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_13T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_13t_events'
            },
            {
                type: 'static text',
                value: 'AC_14T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_14t_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_15T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_15t_events'
            },
            {
                type: 'static text',
                value: 'AC_17T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_17t_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_22T_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_22t_events'
            },
            {
                type: 'static text',
                value: 'AC_MC2_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_mc2_events'
            }
        ],
        [
            {
                type: 'static text',
                value: 'AC_MC17_Msg_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'ac_mc17_events'
            },
            {
                type: 'static text',
                value: 'Good_PTAM_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodPtamEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Good_RTAM_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodRtamEvents'
            },
            {
                type: 'static text',
                value: 'Good_MARM_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodMarmEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Good_TIME_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodTimeEvents'
            },
            {
                type: 'static text',
                value: 'Good_WIND_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodWindEvents'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Good_TGTS_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodTgtsEvents'
            },
            {
                type: 'static text',
                value: 'Good_KEYS_Events',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'goodKeyEvents'
            }
        ],
    ]
}

//"Comm Logs" HOB table
export const hob = {
    head: ['HOB'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'hobUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" FCAS table
export const fcas = {
    head: ['FCAS'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'fcasUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" PDU table
export const pdu = {
    head: ['PDU'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'pduUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" IMU table
export const imu = {
    head: ['IMU'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'imuUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" BEM table
export const bem = {
    head: ['BEM'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemBytesSent',
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bemUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" BCAS table
export const bcas = {
    head: ['BCAS'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'bcasUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" EGR table
export const egr = {
    head: ['EGR'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'egrUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" ISD table
export const isd = {
    head: ['ISD'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'isdUnknMsg'
            }
        ],
    ]
}

//"Comm Logs" ESAF table
export const esaf = {
    head: ['ESAF'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafBytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'esafUnknMsgs'
            }
        ],
    ]
}

//"Comm Logs" O2S table
export const o2s = {
    head: ['O2S'],
    rows: [
        [
            {
                type: 'static text',
                value: 'Messages Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sMsgSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2gMsgRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Messages Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2gMsgMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Sent',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'os2BytesSent'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Received',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sBytesRcvd'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Bytes Missed',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sBytesMissed'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sReadErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Write Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sWriteErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Encode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sEncodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Decode Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sDecodeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Read Line Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sReadLineErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sync Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sSyncErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Size Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sSizeErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Header Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sHeaderErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'CheckSum Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sCheckSumErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Format Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sFormatErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Sequence Errors',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sSeqErrors'
            }
        ],
        [
            {
                type: 'static text',
                value: 'Unknown Message',
                color: 'yellow'
            },
            {
                type: 'dynamicText',
                key: 'o2sUnknMsg'
            }
        ],
    ]
}