import React, {useContext} from 'react';

import {createGrid, useWindowSize} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';
import Table from '../widgets/table';
import {
    missionStatusAI,
    ofpInfo,
    safetyOfFlight,
    sot,
    target,
    flightPhase,
    captiveCarry,
    bitStatus,
    hlbrIbitStatus,
    weaponMonitorBit,
    hlbrCbitStatus,
    continuityLoops,
    missionInfo
} from '../../helpers/tblConfigAircraftintegration';

const gridArray = createGrid(100);

const AircraftIntegration = () => {
    const screenSize = useWindowSize();
    const {widgetState} = useContext(ClientContext);

    return (
        <>
            <div className="blackBody"/>

            <div style={{
                transformOrigin: 'top left',
                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: 'Courier New, sans-serif',

                }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute'
                    }}
                    >
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                            height: 'fit-content',
                            background: 'green',
                            textAlign: 'center',
                        }}
                        >
                            <p style={{color: 'black'}}>Unclassified</p>
                        </div>
                        <div style={{
                            border: '1px solid white',
                            width: 'fit-content',
                        }}
                        >
                            <p style={{color: 'rgb(87, 168, 101)'}}>Missile - BTF-2 Ver. 3.1 2/17/2022</p>
                        </div>
                    </div>

                    <div style={{
                        border: '1px solid white',
                        blockSize: 'fit-content',
                        marginLeft: '500px',
                        width: 'fit-content',
                        justifyContent: 'center',
                    }}
                    >
                        <h1 style={{
                            color: 'rgb(227, 97, 171)',
                            textDecoration: 'underline',
                            fontFamily: 'Courier New, -sans-serif',
                            fontSize: '3rem',
                            whiteSpace: 'nowrap',
                        }}
                        >
                            Aircraft Integration - Julisa
                        </h1>
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={ofpInfo}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={200}
                                tableWidth={500}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={missionStatusAI}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={750}
                                tableWidth={500}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={sot}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                // tableHeight={150}
                                tableWidth={400}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={safetyOfFlight}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={500}
                                tableWidth={400}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="limegreen"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={target}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={108}
                                tableWidth={400}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={flightPhase}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                // tableHeight={240}
                                tableWidth={400}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={captiveCarry}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                // tableHeight={240}
                                tableWidth={400}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '370px'
                        }}
                        >
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={bitStatus}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={390}
                                tableWidth={280}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={hlbrIbitStatus}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={390}
                                tableWidth={280}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={hlbrCbitStatus}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={390}
                                tableWidth={280}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={weaponMonitorBit}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding="5px"
                                tableHeight={390}
                                tableWidth={280}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '450px',
                        }}
                        >
                            <Table
                                draggable={false}
                                grid={gridArray}
                                tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                                config={continuityLoops}
                                radius={10}
                                tableBorder="1px solid white"
                                rowPadding=".2rem"
                                tableHeight={200}
                                tableWidth={200}
                                textColor="yellow"
                                textSize="1rem"
                                underlineHeader="underline"
                                headColor="yellow"
                            />
                        </div>
                    </div>
                    <div style={{
                        marginTop: '800px',
                        marginLeft: '1300px',
                        position: 'absolute'
                    }}
                    >
                        <Table
                            draggable={false}
                            grid={gridArray}
                            tableData={widgetState.location.data[widgetState.location.data.length - 1]}
                            config={missionInfo}
                            radius={10}
                            tableBorder="1px solid white"
                            rowPadding=".2rem"
                            tableHeight={100}
                            tableWidth={400}
                            textColor="yellow"
                            textSize="1rem"
                            underlineHeader="underline"
                            headColor="yellow"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AircraftIntegration;