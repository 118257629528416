import React, {useEffect, useState, useRef, useReducer} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {w3cwebsocket as W3CWebSocket} from 'websocket';
import axios from 'axios';

import Navbar from './components/navbar/navbar';
import Home from './components/pages/home';
import SignIn from './components/pages/signIn';
import FTM29FTSO from './components/pages/FTM29-FTSO';
import FTM29 from './components/pages/FTM29_H&S';
import Trajectories from './components/pages/trajectories';
import TestConductor from './components/pages/testConductor';
import StatusBoard from './components/pages/statusBoard';
import Controller from './components/pages/controller';
import Controller2 from './components/pages/controller2';
import CreateUsers from './components/pages/createUsers';
import Chat from './components/widgets/chat';
import Configuration from './components/pages/configuration';
import NotFound from './components/pages/NotFound';
import Positions from './components/pages/positions';
import ClientContext from './helpers/ClientContext';
import './App.css';
import {roles, pages} from './enums';
import Radars from './components/pages/Radars';
import Telemetry from './components/pages/Telemetry';
import Runs from './components/pages/runs';
import Toast from './components/widgets/Toast';
import RunsNext from './components/pages/runsNext';
import Safety2 from './components/pages/safety2';
import DirectChat from './components/widgets/directChat';
import {isDevEnvironment, urlStart, widgetReducer} from './helpers/helper';
import useListItems from './hooks/useListItems';
import {useWindowSize} from './helpers/helper';
import Reports from './components/pages/Reports';
import Faq from './components/pages/Faq';
import Maps from './components/pages/Maps';
import AircraftIntegration from './components/pages/aircraftIntegration';
import BCAS from './components/pages/bcas';
import CommLogs from './components/pages/commLogs';
import FlightSafety from './components/pages/flightSafety';
import Checklist from "./components/pages/checklist";
import ClassificationBanner from "./components/widgets/classificationBanner";
import AudiosAndCallAudios from "./components/widgets/AudiosAndCallAudios";

const adminRunId = '60dc873e24bfd905989c690d';

axios.defaults.withCredentials = true; // need this for auth cookies

if(window.location.pathname === '/'){
    window.location.href = '/admin';
}

const App = () => {
    const [runNotFound, setRunNotFound] = useState(false);
    const [isClientConnected, setIsClientConnected] = useState(false);
    const [client, _setClient] = useState(null);
    const clientRef = useRef(client);
    const setClient = data => {
        clientRef.current = data;
        _setClient(data);
    };
    const [authedUser, _setAuthedUser] = useState(null);
    const authedUserRef = useRef(authedUser);
    const setAuthedUser = data => {
        authedUserRef.current = data;
        _setAuthedUser(data);
    };
    const [isAuthenticated, _setIsAuthenticated] = useState(1);
    const isAuthenticatedRef = useRef(isAuthenticated);
    const setIsAuthenticated = data => {
        isAuthenticatedRef.current = data;
        _setIsAuthenticated(data);
    };
    const [firebaseId, setFirebaseId] = useState(null);
    const [notifications, _setNotifications] = useState([]);
    const [run, setRun] = useState(null);
    const [configuration, setConfiguration] = useState(null);
    const notificationsRef = useRef(notifications);
    const setNotifications = data => {
        notificationsRef.current = data;
        _setNotifications(data);
    };
    const screenSize = useWindowSize();
    const positions = useListItems('positions', [], client);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [locationData, _setLocationData] = useState([]);
    const locationDataRef = useRef(locationData);
    const setLocationData = data => {
        locationDataRef.current = data;
        _setLocationData(data);
    }

    const [widgetState, dispatchWidget] = useReducer(widgetReducer, {
        lineChart: {
            data: [{x: -1, y: -1}]
        },
        location: {
            data: [{
                time: -1,
                x: -1,
                y: -1,
                z: -1,
                roll: -1,
                pitch: -1,
                yaw: -1,
                curDraw: -1,
                CDRsig: -1,
                altitude: -1,
                range: -1,
                velocity: -1,
                talo: -1,
                utc: -1,
                liftoff: -1,
                tMinus: -1,
                lon: -1,
                lat: -1,
                rand: -1,
                ranc: -1,
                alt: -1,
                weapon: -1,
                ftrOneGo: -1,
                ftrOneCmdGo: -1,
                ftrOneSigStr: -1,
                tmXmtrTck69: -1,
                srmOneDome: -1,
                aurReady: -1,
                fcasEcuOp: -1,
                vmcGood: -1,
                gyroGood: -1,
                ftrTwoGo: -1,
                ftrTwoCmdGo: -1,
                ftrTwoSigStr: -1,
                ftrOneLTck: -1,
                srmTwoDome: -1,
                ofpThroughput: -1,
                discretesInExpected: -1,
                gpsOperational: -1,
                pduGood: -1,
                navStatus: -1,
                esadAPostGo: -1,
                esadBPostGo: -1,
                ftsOneBattVolt: -1,
                ftsTwoBattVolt: -1,
                pduVolts: -1,
                srmDomePs: -1,
                ofpCsum: -1,
                discretesOutExpected: -1,
                imuGood: -1,
                pduSwitchExpected: -1,
                finsUnlock: -1,
                stageOneFlightMode: -1,
                stageTwoFlightMode: -1,
                stageThreeFlightMode: -1,
                stageOneSrm: -1,
                ftsBattTck: -1,
                ftsOneWireCutterRelay: -1,
                weaponState: -1,
                bcasStageOneApSafe: -1,
                stageTwoSrm: -1,
                ftsBattR: -1,
                ftsTwoWireCutterRelay: -1,
                configVersion: -1,
                bcasStageTwoApSafe: -1,
                stageThreeSrm: -1,
                mduTck: -1,
                vmcTck: -1,
                pidOn: -1,
                bcasStageThreeApSafe: -1,
            }]
        },
        missionTime: {
            data: [{utc: 0, liftoff: -1}]
        },
        lists: {
            testEvents: [],
            testConductorMessages: []
        },
        locations: {
            x: 0,
            y: 0,
        },
        adjustments: {}
    });

    axios.interceptors.response.use(function (response) {
        // not doing anything on valid responses
        return response;
    }, function (error) {
        // show sign in screen if 403 is returned
        if(error.response?.status === 403){
            setIsAuthenticated(0);
        }
        return Promise.reject(error);
    });

    //useEffect for fetching all the csv data
    /*useEffect(() => {
        if(!client){
            return;
        }
        const handleMessage = message => {
            if(!locationData.length){
                console.log('no length');
                return;
            }

            dispatchWidget(message, locationData);
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getData',
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [client]);*/

    useEffect(() => {
        axios.get(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/authorize`).then(res => {
            setAuthedUser(res.data.user);
            setIsAuthenticated(2);
        }).catch(e => {
            setIsAuthenticated(0);
        });
    }, []);

    const isAllowed = (user, runObj, page) => {
        if(!runObj){
            return false;
        }

        let found = runObj.users.find((individuals) => user.id === individuals.userID);
        if(!found){
            return false;
        } else{
            return page === -1 || found.permissionPages.includes(page);
        }
    };

    const addNotification = (notification) => {
        notification.key = performance.now();
        const newNotifications = [...notificationsRef.current];
        if(newNotifications.length >= 4){
            closeNotification(newNotifications[0].key);
        }
        newNotifications.push(notification);
        setNotifications(newNotifications);
    };

    const [runId, setRunId] = useState(null);


    const closeNotification = (key) => {
        let newNotifications = [...notificationsRef.current];
        let count = 0;
        newNotifications.some((el, index) => {
            if(el.key === key){
                count = index;
                return true;
            }

            return false;
        });
        const toasts = document.querySelectorAll('.toast');
        toasts[count].classList.add('noHeight');

        setTimeout(() => {
            //grab again in case other notifications are closing at same time
            newNotifications = [...notificationsRef.current];
            newNotifications.some((el, index) => {
                if(el.key === key){
                    count = index;
                    return true;
                }

                return false;
            });
            newNotifications.splice(count, 1);
            setNotifications(newNotifications);
        }, 250); // should be same time as transition for notification
    };

    useEffect(() => {
        if(!client){
            return null;
        }

        const handleMessage = message => {
            const dataFromServer = JSON.parse(message.data);
            if(dataFromServer.type === 'allLocationData'){
                console.log('setting data');
                setLocationData(dataFromServer.data);
            } else if(!dataFromServer || dataFromServer.done){
                setIsModalOpen(true);
            } else {
                if(!locationDataRef.current.length){
                    console.log('no length');
                    return;
                }

                dispatchWidget({message, locationData: locationDataRef.current});
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'getData',
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, [client]);

    const connectClient = (curRunId, userId) => {
        //prod server handles ports
        const {protocol, hostname} = window.location;
        const ws = `ws${protocol === 'https:' ? 's' : ''}`;
        const url = `${ws}://${hostname}${isDevEnvironment ? ':8000' : '/websocket'}?userId=${userId}&runId=${curRunId}`;
        const newClient = new W3CWebSocket(url);

        setRunId(curRunId);
        newClient.onopen = () => {
            setClient(newClient);
            setIsClientConnected(true);
            /*newClient.send(
                JSON.stringify({
                    type: 'update',
                    content: 'getData',
                })
            );*/
        };
    };

    useEffect(() => {
        if(!isAuthenticated){
            if(clientRef.current){
                clientRef.current.close();
                setClient(null);
                setIsClientConnected(0);
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if(!authedUser || authedUser.position){
            return;
        }

        const parsedUrlArr = window.location.pathname.split('/');
        //runId is in the 2nd spot in the array
        let curRunId = parsedUrlArr[1];
        if(curRunId === 'admin'){
            curRunId = adminRunId;
        }
        setFirebaseId(authedUser.id);

        axios.get(`${urlStart}${isDevEnvironment ? ':5000' : ''}/runs/getRun?runId=${curRunId}`, {timeout: 5000})
             .then(runRes => {
                 if(runRes.data.error){
                     setRunNotFound(!runNotFound);
                     connectClient(adminRunId, authedUser.id);
                     addNotification({message: 'run not found', color: 'red'});
                     return;
                 }
                 const runUser = runRes.data.run.users.find(curUser => curUser.userID === authedUser.id);
                 const userInfo = {...authedUser};
                 if(runUser){
                     userInfo.position = runUser.position;
                 } else{
                     userInfo.position = '-1';
                 }
                 setAuthedUser(userInfo);

                 setRun(runRes.data.run);
                 setConfiguration(runRes.data.config);
                 connectClient(curRunId, authedUser.id);
             })
             .catch(error => {
                 addNotification({message: error.message, color: 'red'});
             });
    }, [authedUser]);

    const signOut = () => {
        axios.post(`${urlStart}${isDevEnvironment ? ':5000' : ''}/users/logOut`).then(() => {
            // should trigger login screen
            window.location.reload();
        }).catch((error) => {
            addNotification({message: error, color: 'red'});
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(authedUser);

        console.log(run);

    };

    if(!isAuthenticated){
        return (
            <ClassificationBanner>
                <ClientContext.Provider value={{addNotification}}>
                    <SignIn setIsAuthenticated={setIsAuthenticated} setAuthedUser={setAuthedUser}/>
                    <Toast notifications={notifications} closeNotification={closeNotification}/>
                </ClientContext.Provider>
            </ClassificationBanner>
        );
    }

    if(!isClientConnected || isAuthenticated === 1 || !locationData.length){
        return (
            <ClassificationBanner>
                <div>Loading...</div>
            </ClassificationBanner>
        );
    }


    return (
        <BrowserRouter>
            <ClassificationBanner>
                <ClientContext.Provider
                    value={{client, firebaseId, authedUser, run, configuration, addNotification, runId, widgetState, locationData}}
                >
                    <Navbar signOut={signOut} users={run.users}/>
                    {
                        (runId !== adminRunId && isAllowed(authedUser, run, -1)) ?
                            <div style={{
                                zIndex: 1,
                                position: 'fixed',
                                right: '1px',
                                top: `{document.querySelector('.nav-items')?.clientHeight}px`,
                                transformOrigin: 'top right',
                                transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`,
                            }}
                            >
                                <AudiosAndCallAudios
                                    client={client}
                                    clientRef={clientRef}
                                    firebaseId={firebaseId}
                                    isAuthenticated={isAuthenticated}
                                    isClientConnected={isClientConnected}
                                />
                            </div> : <></>
                    }
                    {
                        (runId !== adminRunId && isAllowed(authedUser, run, -1)) ?
                            <div>
                                {
                                    authedUser.position && authedUser.position !== '-1' &&
                                    <DirectChat
                                        shouldScale
                                        style={{
                                            transformOrigin: 'bottom right',
                                            transform: `scaleX(${screenSize.width / 2048}) scaleY(${(screenSize.height - document.querySelector('.nav-items')?.clientHeight) / (1152 - document.querySelector('.nav-items')?.clientHeight)})`
                                        }}
                                        listName={authedUser.position}
                                        right="calc(400px + 3rem)"
                                        title={`${positions.find(position => position.id === authedUser.position)?.name || ''} Off-Nom`}
                                    />
                                }
                                <Chat
                                    shouldScale
                                    listName="globalMessages"
                                    firebaseId={firebaseId}
                                    right="2rem"
                                    title="Global Chat"
                                />
                            </div> : null
                    }
                    {
                        /**
                         * This modal should open when the run is complete.
                         * It should open for all users but only the Test Conductor should
                         * see options to save the run, continue the run, or reset the run.
                         **/
                        isModalOpen && runId !== adminRunId &&
                        <div style={{
                            height: '100vh',
                            width: '100vw',
                            backgroundColor: 'rgba(0,0,0,.5)',
                            position: 'fixed',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 101, //mui-datatables header has a z-index of 100
                        }}
                        >
                            <form onSubmit={handleSubmit} style={{
                                backgroundColor: 'white',
                                width: '400px',
                                borderRadius: '0.5em',
                                padding: '1em',
                                marginBottom: '20em'
                            }}
                            >
                                <h3 style={{textAlign: 'center'}}>Run Complete</h3>

                                {/*Not fully implemented yet. Just "Run Complete" will show up for regular users.*/}
                                {/*The test conductor will see all the save, restart run, and close*/}
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '1em'}}>
                                    {/*<button disabled type="button">*/}
                                    {/*    Restart Run*/}
                                    {/*</button>*/}
                                    {/*<button disabled type="button" style={{marginLeft: '1em', marginRight: '1em'}}>*/}
                                    {/*    Save Run*/}
                                    {/*</button>*/}
                                    <button onClick={() => setIsModalOpen(false)} type="submit">
                                        Close
                                    </button>
                                </div>

                            </form>
                        </div>
                    }


                    <Switch>
                        {
                            authedUser.role === roles.ADMIN &&
                            <Route exact path="/admin"><Home/></Route>
                        }
                        {
                            runNotFound &&
                            <Route><NotFound/></Route>
                        }
                        {
                            isAllowed(authedUser, run, -1) &&
                            <Route path="/:id/home">
                                <div className="blackBody">
                                    <div style={{
                                        display:'flex',
                                        flexDirection: 'column',
                                        alignItems:'center',
                                        justifyContent:'center',
                                        height:'50%',
                                        color:'white',
                                    }}>
                                        <h1>Welcome to ITAC</h1>
                                        <h2>The Integrated Training and Assessment Controller </h2>
                                    </div>
                                </div>
                            </Route>
                        }
                        {
                            isAllowed(authedUser, run, -1) &&
                            <Route path="/:id/faq"><Faq/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.FTSO) &&
                            <Route path="/:id/FTM29-FTSO"><FTM29FTSO/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.HS) &&
                            <Route path="/:id/FTM29_H&S"><FTM29/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.TRAJECTORIES) &&
                            <Route path="/:id/trajectories"><Trajectories/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.TEST_CONDUCTOR) &&
                            <Route path="/:id/TestConductor"><TestConductor/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.STATUS_BOARD) &&
                            <Route path="/:id/statusBoard"><StatusBoard/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.CONTROLLER) &&
                            <Route path="/:id/controller"><Controller/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.CONTROLLER2) &&
                            <Route path="/:id/controller2"><Controller2/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.RADARS) &&
                            <Route path="/:id/radars"><Radars/></Route>
                        }
                        {

                            isAllowed(authedUser, run, pages.TELEMETRY) &&
                            <Route path="/:id/telemetry"><Telemetry/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.SAFETY2) &&
                            <Route path="/:id/safety2"><Safety2/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.MAPS) &&
                            <Route path="/:id/Maps"><Maps/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.AIRCRAFT_INTEGRATION) &&
                            <Route path="/:id/Aircraft Integration"><AircraftIntegration/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.BCAS) &&
                            <Route path="/:id/BCAS"><BCAS/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.COMM_LOGS) &&
                            <Route path="/:id/COMM_LOGS"><CommLogs/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.FLIGHT_SAFETY) &&
                            <Route path="/:id/FLIGHT_SAFETY"><FlightSafety/></Route>
                        }
                        {
                            isAllowed(authedUser, run, pages.CHECKLIST) &&
                            <Route path="/:id/CHECKLIST"><Checklist/></Route>
                        }
                        {
                            authedUser.role === roles.ADMIN &&
                            [
                                <Route key="configuration" path="/admin/configuration"><Configuration/></Route>,
                                <Route key="createUsers" path="/admin/createUsers"><CreateUsers/></Route>,
                                <Route key="runs" path="/admin/runs"><Runs/></Route>,
                                <Route key="positions" path="/admin/positions"><Positions/></Route>,
                                <Route key="reports" path="/admin/reports/:id/"><Reports/></Route>,
                                <Route key="runsNext" path="/admin/runsNext/:id/"><RunsNext/></Route>,
                            ].map(route => route)
                        }
                        <Route><NotFound/></Route>
                    </Switch>
                    <Toast notifications={notifications} closeNotification={closeNotification}/>
                </ClientContext.Provider>
            </ClassificationBanner>
        </BrowserRouter>
    );
};

export default App;
