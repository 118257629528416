import React, {useEffect, useState} from 'react';
import useListItems from '../../hooks/useListItems';

type Props = {
    textColor?: string;
    listName: string;
    paddingLeft?: string;
    paddingBottom?: string;
}

type ItemType = {
    color?: string;
    text?: string;
    time?: string;
}

const DisplayList = ({textColor = 'yellow', listName, paddingLeft = '1rem', paddingBottom = '0'}: Props) => {
    const uncheckedItems = useListItems(listName);
    const [items, setItems] = useState<ItemType[]>([]);

    useEffect(() => {
        if(Array.isArray(uncheckedItems)){
            setItems(uncheckedItems);
        }
    }, [uncheckedItems]);


    return (
        <div style={{paddingLeft}}>
            <table>
                <tbody>
                {items.map((item, index) => (
                    <tr key={index}>
                        <td key={index} style={{color: item.color || textColor, paddingBottom}}>{item.text || item}</td>
                        {
                            item.time !== undefined &&
                            <td style={{color: item.color || textColor, paddingLeft: '3rem'}}>{item.time}</td>
                        }
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DisplayList;
