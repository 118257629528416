import React, {useEffect, useState, useContext} from 'react';
import Chat from './chat';
import ScheduledMessages from './scheduledMessages';
import {getNumSecondsInRun, getTMinusTime} from '../../helpers/helper';
import ClientContext from '../../helpers/ClientContext';

type Props = {
    listName: string;
    right?: string;
    title: string;
    isController?: boolean;
}

const DirectChat = (props: Props) => {
    const {configuration} = useContext(ClientContext);
    const [openScheduled, setOpenScheduled] = useState(false);
    const [timeOptions, setTimeOptions] = useState([<option/>]);

    useEffect(() => {
        if(!configuration.id){
            return;
        }

        const numDataPoints = getNumSecondsInRun(configuration);

        let curTime = 0;
        const newTimeOptions = [];
        while(curTime <= numDataPoints - 1) {
            newTimeOptions.push(
                <option key={curTime} value={curTime}>
                    {getTMinusTime(curTime, configuration)}
                </option>
            );
            curTime++;
        }

        setTimeOptions(newTimeOptions);
    }, [configuration]);

    const toggleScheduled = () => setOpenScheduled(!openScheduled);

    return (
        <div>
            <Chat
                isDirect
                toggleScheduled={toggleScheduled}
                openScheduled={openScheduled}
                {...props}
            />
            {
                openScheduled &&
                <ScheduledMessages
                    listName={props.listName}
                    title={"Scheduled " + props.title}
                    timeOptions={timeOptions}
                />
            }
        </div>
    )

};

export default DirectChat;
