import React, {useState, useEffect} from 'react';
import Status from './status';
import useListItems from '../../hooks/useListItems';

type Props = {
    listName: string;
}

const StatusCircles = ({listName}: Props) => {
    const uncheckedItems = useListItems(listName);
    const [items, setItems] = useState<{text: string; color: string;}[]>([]);

    useEffect(() => {
        if(Array.isArray(uncheckedItems)){
            setItems(uncheckedItems);
        }
    }, [uncheckedItems]);

    return (
        /*<div style={{display: 'grid', gridTemplateColumns: gridTemplate}}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    <div style={{paddingRight:'50px', paddingLeft:'20px', color:textColor, fontSize:'20px', fontFamily:'Courier New'}}><b>{item.text}</b></div>
                    <div style={{paddingRight:'50px'}}><Status bounds={{Infinity: item.color}}/></div>
                    {
                        rows &&
                            <div><Status bounds={{Infinity: item.color2}}/></div>
                    }
                </Fragment>
            ))}
        </div>*/
        <table className="statusTable" style={{display: 'grid', color: 'white'}}>
            <tbody>
            {items.map((item, index) => (
                <tr key={index}>
                    <td>{item.text}</td>
                    <td><Status bounds={{Infinity: item.color}}/></td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default StatusCircles;

