import React, {useState, useEffect, Fragment, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import ClientContext from '../../helpers/ClientContext';
import {getTMinusTime} from '../../helpers/helper';
import {KeyEventType} from '../../helpers/types';

type Props = {
    events: KeyEventType[];
    min: number;
    max: number;
    height: number;
    time: number;
    actions: { [time: string]: string[] };
    clearActions: () => null;
    hasInteractions?: boolean;
}

const Timeline = ({
                      events,
                      min,
                      max,
                      height,
                      time,
                      actions,
                      clearActions,
                      hasInteractions,
                  }: Props) => {
    const {client, configuration} = useContext(ClientContext);

    height -= 24; // height of buttons
    let initTime = time;
    if(time === -1) {
        initTime = max;
    }

    const [rangeTime, setRangeTime] = useState<number>(initTime);
    const [displayTime, _setDisplayTime] = useState(initTime);
    const displayTimeRef = useRef(displayTime);
    const setDisplayTime = (data: number) => {
        displayTimeRef.current = data;
        _setDisplayTime(data);
    }
    const [tMinusTime, setTMinusTime] = useState('00:00:00.000');
    const [isPaused, setIsPaused] = useState(true);
    const [isHolding, setIsHolding] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState('1');
    const isSettingTime = useRef(false);

    /**
     *
     */
    const pauseServer = () => {
        if(!client) {
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'stop'
            })
        );
    };

    /**
     *
     * @param newTime
     * @param minTime
     */
    const setServerTime = (newTime: number, minTime: number) => {
        if(!client) {
            return;
        }

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setTime',
                newTime,
                minTime
            })
        );
    };

    const rangeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        pauseServer();
        isSettingTime.current = true;
        setDisplayTime(parseInt(event.target.value));
    };

    const pauseData = () => {
        pauseServer();
        setIsPaused(true);
    };

    const playData = () => {
        if(isPaused) {
            setServerTime(displayTime, min);
            setIsPaused(false);
        }
    };

    const holdData = () => {
        if(!client) {
            return;
        }

        setIsHolding(!isHolding);
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setHold'
            })
        );
    };

    const restartTimer = () => {
        isSettingTime.current = false;
        setRangeTime(displayTimeRef.current);
        if(!isPaused) {
            setServerTime(displayTimeRef.current, min);
        }
    };

    /**
     *
     * @param bookmark
     */
    const setTime = (bookmark: number) => {
        if(isPaused){
            setDisplayTime(bookmark);
            setRangeTime(bookmark);
        } else {
            setServerTime(bookmark, min);
        }
    };

    useEffect(() => {
        setTMinusTime(getTMinusTime(displayTime, configuration));
    }, [displayTime])

    useEffect(() => {
        if(isSettingTime.current) {
            return;
        }

        if(time === -1) {
            setDisplayTime(max);
            setRangeTime(max);
        } else {
            setDisplayTime(time);
            setRangeTime(time);
        }
        if(time === max) {
            pauseServer();
        }
    }, [time]);

    useEffect(() => {
        if(!hasInteractions) {
            return;
        }

        const list = document.querySelector('#clicksList');
        if(!list) {
            return;
        }
        list.scrollTop = list.scrollHeight;
    }, [actions]);

    useEffect(() => {
        const slider = document.querySelector('.slider') as HTMLInputElement;
        if(!slider) {
            return;
        }
        slider.value = rangeTime.toString();
    }, [rangeTime]);

    useEffect(() => {
        if(!client) {
            return;
        }

        type Message = {
            data: string
        }
        const handleMessage = (message: Message) => {
            const dataFromServer = JSON.parse(message.data);

            if(dataFromServer.type === 'pauseHold') {
                setIsPaused(dataFromServer.paused);
                setIsHolding(dataFromServer.holding);
                setPlaybackSpeed(dataFromServer.playbackSpeed);
                client.removeEventListener('message', handleMessage);
            }
        };

        client.addEventListener('message', handleMessage);

        client.send(
            JSON.stringify({
                type: 'update',
                content: 'checkPauseHold'
            })
        );

        return () => {
            client.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if(!client) {
            return;
        }

        const data = parseFloat(playbackSpeed);
        client.send(
            JSON.stringify({
                type: 'update',
                content: 'setPlaybackSpeed',
                data
            })
        );
    }, [playbackSpeed]);

    return (
        <>
            <div style={{
                /*display: 'grid',
                gridTemplateRows: `${height}px 16px`,
                gridTemplateColumns: `${width * .7}px ${width * .3}px`,
                gap: '0.1em 0.5em'*/
            }}>
                <div style={{
                    width: '100%',
                    height: height / 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{position: 'relative', flex: 1}}>
                        {
                            events.map(event => {
                                return (
                                    <div
                                        title={String(event.title)}
                                        key={event.time}
                                        style={{
                                            left: `calc(${event.time / (max + 1) * 100}% - ${height / 8}px)`,
                                            position: 'absolute',
                                            cursor: 'help'
                                        }}
                                    >
                                        <svg height={height / 2} width={height / 2} onClick={() => setTime(event.time)}>
                                            <polygon
                                                fill={event.color || '#0a767a'}
                                                points={`0 ${height / 4}, ${height / 4} ${height / 2}, ${height / 2} ${height / 4}, ${height / 4} 0`}
                                            />
                                        </svg>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {/*<div style={{position: 'relative', flex: 1}}>
                        {
                            Object.keys(actions).map((actionTime, index) => {
                                return <div
                                    key={index}
                                    style={{
                                        border: '2px solid red',
                                        height: '100%',
                                        left: `${actionTime / (max + 1) * 100}%`,
                                        width: '1px',
                                        backgroundColor: 'red',
                                        position: 'absolute'
                                    }}
                                />;
                            })
                        }
                    </div>*/}
                </div>
                {
                    hasInteractions &&
                    <div id="clicksList" style={{
                        backgroundColor: 'white',
                        opacity: '75%',
                        padding: '0.5em',
                        overflowY: 'auto',
                        gridRow: '1 / span 2',
                        gridColumn: '2 / span 1'
                    }}>
                        <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0 0.5em'}}>
                            {
                                Object.keys(actions).map((actionTime, index) => {
                                    return actions[actionTime].map(action => {
                                        return (
                                            <Fragment key={`${index}${action}`}>
                                                <div>{actionTime}:</div>
                                                <div id={`action${actionTime}${action}`}>{action}</div>
                                            </Fragment>
                                        );
                                    });
                                })
                            }
                        </div>
                    </div>
                }
                {
                    !hasInteractions && <div/>
                }
                <input type="range" min={min} max={max} style={{width: '100%'}}
                       onChange={rangeChangeHandler} onMouseUp={restartTimer} className="slider"/>
            </div>
            <div style={{color: tMinusTime.charAt(0) === '-' ? 'red' : 'green', fontSize: '1.2rem'}}>
                <span>
                    T-
                </span>
                <span style={{paddingLeft: '1rem'}}>
                    {tMinusTime}
                </span>
            </div>
            <div style={{display: 'flex'}}>
                {
                    isPaused &&
                    <button type="button" onClick={playData}>Play</button>
                }
                {
                    !isPaused &&
                    <button type="button" onClick={pauseData}>Pause</button>
                }
                {
                    !isHolding &&
                    <button type="button" onClick={holdData}>Hold</button>

                }
                {
                    isHolding &&
                    <button type="button" onClick={holdData}>Stop Hold</button>

                }
                {
                    hasInteractions &&
                    <button type="button" onClick={clearActions}>Clear Interactions</button>
                }
                <select
                    style={{marginLeft: '10px'}}
                    className="form-select"
                    onChange={e => setPlaybackSpeed(e.currentTarget.value)}
                    value={playbackSpeed}
                >
                    <option value=".5">.5x</option>
                    <option value="1">1x</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="5">5x</option>
                    <option value="10">10x</option>
                </select>
            </div>
        </>
    );
};

Timeline.defaultProps = {
    events: [],
    // width: window.screen.width * 0.30,
    height: 80,
    actions: {},
    clearActions: () => {
    },
    hasInteractions: false,
    timeColor: 'white',
    hasPlayback: false,
};

Timeline.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    events: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))),
    // width: PropTypes.number,
    height: PropTypes.number,
    actions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    clearActions: PropTypes.func,
    hasInteractions: PropTypes.bool,
    timeColor: PropTypes.string,
    hasPlayback: PropTypes.bool
};

export default Timeline;
