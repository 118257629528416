import {createContext} from 'react';
import {ConfigurationType, Notification, RunType, UserType, WebSocket} from './types';
import {barDescriptors, radarEvents, radarStatuses, tmEvents, tmStatuses} from '../enums';

type ContextType = {
    run: RunType;
    configuration: ConfigurationType;
    client: WebSocket | null;
    addNotification: (notification: Notification) => void;
    authedUser: UserType;
    firebaseId: string;
    runId: string;
}

const initialValues = {
    run: {
        _id: '',
        id: '',
        name: '',
        date: '',
        users: [],
        interactions: [],
        rooms: [],
        points: {},
        configurationID: '',
        chats: [],
        moddedData: {},
        keyEvents: [],
        utc: '',
        tMinus: '',
        increment: 1,
        pageOrder: [],
        chevronPoints: [],
        keepOutPoints: [],
        checklists: [],
        checklistId: 0,
        checklistPosition: '-1'
    },
    configuration: {
        _id: '',
        id: '',
        name: '',
        barColors: {
            [barDescriptors.RADAR_STATUSES]: {
                [radarStatuses.ACQUIRED]: '#ffffff',
                [radarStatuses.TRACKING]: '#ffffff',
                [radarStatuses.POWERED]: '#ffffff',
                [radarStatuses.NON_POWERED]: '#ffffff',
                [radarStatuses.PROPAGATING]: '#ffffff',
            },
            [barDescriptors.RADAR_EVENTS]: {
                [radarEvents.RADIATING]: '#ffffff',
                [radarEvents.DROPOUT]: '#ffffff',
                [radarEvents.DISCRIMINATING]: '#ffffff',
            },
            [barDescriptors.TM_STATUSES]: {
                [tmStatuses.STATIC]: '#ffffff',
                [tmStatuses.TRACKING]: '#ffffff',
                [tmStatuses.NOT_TRACKING]: '#ffffff',
            },
            [barDescriptors.TM_EVENTS]: {
                [tmEvents.DROPOUT]: '#ffffff',
            }
        },
        radars: [],
        tms: [],
        utc: '',
        tMinus: '',
        increment: 1,
        dataSet: 1 as 1,
    },
    client: null,
    addNotification: ({message = '', color = '', timeout = 5}) => {
    },
    authedUser: {_id: '', id: '', name: '', email: '', disabled: 0 as 0, role: 2 as 2, position: '-1'},
    firebaseId: '',
    runId: '',
};

const ClientContext = createContext<ContextType>(initialValues);

export default ClientContext;
